import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { FondoContext } from "../../../context/FondoContext";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import Spinner from "../../layout/Spinner";
import { Growl } from "primereact/growl";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

function ConciliacionBancaria(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    cuenta,
    cuentas,
    obtenerCuentasSelectTodos,
    guardarCuenta,
  } = archivoContext;

  const fondoContext = useContext(FondoContext);
  const {
    movimientosBancos,
    totalIngresos,
    totalEgresos,
    saldoAnterior,
    dateBanco,
    rangeBanco,
    radioBanco,
    agrupar,
    field,
    sort,
    todas,
    chequesSelected,
    bancosSelected,
    obtenerMovimientosBanco,
    buscarMovimientosBancos,
    obtenerMovimientosConciliacion,
    guardarMovimientosBancos,
    guardarMovimientoBanco,
    tranformDate,
    guardarDateBanco,
    guardarRangeBanco,
    guardarRadioBanco,
    setAgrupar,
    setField,
    setSort,
    setTodas,
    acreditarMovimientoBanco,
    eliminarMovimientoBanco,
    obtenerChequesDeposito,
    guardarBancosSelected,
  } = fondoContext;

  const [globalFilter, guardarGlobalFilter] = useState(null);

  const [estadosBancos, guardarEstadosBancos] = useState([
    {
      value: 1,
      label: "PENDIENTE",
    },
    {
      value: 2,
      label: "ACREDITADO",
    },
  ]);

  const [estadoBanco, guardarEstadoBanco] = useState(1); //TODOS
  const [cuentaId, guardarCuentaId] = useState(-1); //TODOS
  const [modalAcreditar, setModalAcreditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [idMovimiento, setIdMovimiento] = useState(null);
  const [fechaAcreditado, guardarFechaAcreditado] = useState(null);

  let dt = useRef();
  let growl = useRef();

  const fullStyles =
    window.screen.availWidth < 780 ? { width: "90vw" } : { width: "50vw" };

  const largeStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "42ch" };

  const mediaStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "36ch" };

  const shortStyles =
    window.screen.availWidth < 780 ? { width: "25ch" } : { width: "30ch" };

  useEffect(() => {
    //guardarMovimientosBancos(null);

    obtenerCuentasSelectTodos();

    obtenerMovimientosConciliacion({
      idEmpresa: empresaNavbar,
      fechaBanco: dateBanco,
      idCuenta: cuentaId,
      estado: estadoBanco,
    });

    guardarFechaAcreditado(new Date());

    guardarBancosSelected(null);
  }, []);

  const cerrar = () => {
    props.history.push("/menu");
  };

  const buscarDatos = () => {
    buscarMovimientosBancos({ idEmpresa: empresaNavbar, globalFilter });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (fechaAcreditado === "") {
      growl.show({
        severity: "error",
        summary: "Movimiento de banco",
        detail: "La fecha de acreditacónn iválida.",
      });
      return;
    }

    //guardarBancosSelected(null);
    setModalAcreditar(false);
    acreditarMovimientoBanco({ bancosSelected, fechaAcreditado });

    setTimeout(() => {
      actualizar();
    }, 2000);
  };

  let es = {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
    dateFormat: "dd/mm/yy",
    weekHeader: "Sm",
  };

  const actualizar = () => {
    obtenerMovimientosConciliacion({
      idEmpresa: empresaNavbar,
      fechaBanco: dateBanco,
      idCuenta: cuentaId,
      estado: estadoBanco,
    });
  };

  let header = (
    <Fragment>
      <button
        type="button"
        className="close xclose"
        aria-label="Close"
        onClick={cerrar}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <h3 className="fwhite titulos text-center py=4">Conciliación Bancaria</h3>
      <hr className="linea-negra" />
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => guardarGlobalFilter(e.target.value)}
          placeholder="Buscador"
        />
        {/*
        <button className="btn btn-success ml-1" onClick={buscarDatos}>
          Buscar
        </button>
        */}

        <button
          type="button"
          className="btn btn-success btn-large ml-4"
          onClick={(e) => {
            if (bancosSelected) {
              setModalAcreditar(true);
            }
          }}
        >
          <i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Acreditar
        </button>

        {/*  
        <button
          type="button"
          className="btn btn-info btn-large ml-0"
          onClick={actualizar}
        >
          Actualizar
        </button>
        */}

        <div className="row">
          <label
            style={{ color: "black", marginLeft: "2.5em", marginTop: "1.5em" }}
          >
            Fecha
          </label>
          <Calendar
            locale={es}
            dateFormat="dd/mm/yy"
            style={{ marginLeft: "1em", width: "20em" }}
            className="mt-3 ml-3"
            value={dateBanco}
            disabled={estadoBanco === 1}
            onChange={(e) => {
              guardarDateBanco(e.value);
              obtenerMovimientosConciliacion({
                idEmpresa: empresaNavbar,
                fechaBanco: e.value,
                idCuenta: cuentaId,
                estado: estadoBanco,
              });
            }}
          ></Calendar>

          <label
            style={{ color: "black", marginLeft: "2.5em", marginTop: "1.5em" }}
          >
            Estado
          </label>
          <Dropdown
            id="estado"
            name="estado"
            value={estadoBanco}
            style={shortStyles}
            className="mt-3 ml-3"
            options={estadosBancos}
            onChange={(e) => {
              guardarEstadoBanco(e.target.value);
              obtenerMovimientosConciliacion({
                idEmpresa: empresaNavbar,
                fechaBanco: dateBanco,
                idCuenta: cuentaId,
                estado: e.target.value,
              });
            }}
            filter={false}
          />

          <label
            style={{ color: "black", marginLeft: "2.5em", marginTop: "1.5em" }}
          >
            Cuenta
          </label>
          <Dropdown
            id="cuenta"
            name="cuenta"
            value={cuentaId}
            style={shortStyles}
            className="mt-3 ml-3"
            options={cuentas}
            onChange={(e) => {
              guardarCuentaId(e.target.value);
              obtenerMovimientosConciliacion({
                idEmpresa: empresaNavbar,
                fechaBanco: dateBanco,
                idCuenta: e.target.value,
                estado: estadoBanco,
              });
            }}
            filter={false}
          />
        </div>
      </div>
    </Fragment>
  );

  const rowHabilitado = (rowData, column) => {
    if (rowData.habilitado === true || rowData.habilitado === "Si") {
      return (rowData.habilitado = "Si");
    }
    if (rowData.habilitado === false || rowData.habilitado === "No") {
      return (rowData.habilitado = "No");
    }
  };

  const rowFecha = (rowData, column) => {
    return tranformDate(rowData.fecha);
  };

  const rowIngreso = (rowData, column) => {
    return parseFloat(rowData.ingreso).toFixed(2);
  };

  const rowEgreso = (rowData, column) => {
    return parseFloat(rowData.egreso).toFixed(2);
  };

  const botones = (rowData, column) => {
    return (
      <Fragment>
        {estadoBanco === 1 ? (
          <div className="row">
            <div className="col-xs-6 col-md-6" style={{ paddingRight: "0" }}>
              <Link to={`movimientobanco/${rowData.id}/0`}>
                <button
                  type="button"
                  className="btn btn-success btn-block"
                  style={{ fontSize: "small" }}
                >
                  Ver
                </button>
              </Link>
            </div>

            <div className="col-xs-6 col-md-6" style={{ paddingLeft: "0" }}>
              <button
                type="button"
                className="btn btn-danger btn-block"
                disabled={estadoBanco === 2}
                style={{ fontSize: "small" }}
                onClick={(e) => {
                  setModalEliminar(true);
                  setIdMovimiento(rowData.id);
                  obtenerChequesDeposito({ idMovimiento: rowData.id });
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        ) : (
          <div style={{ paddingRight: "0" }}>
            <Link to={`movimientobanco/${rowData.id}/0`}>
              <button
                type="button"
                className="btn btn-success btn-block"
                style={{ fontSize: "small" }}
              >
                Ver
              </button>
            </Link>
          </div>
        )}
      </Fragment>
    );
  };

  let rCount = movimientosBancos ? movimientosBancos.length : 0;
  let footer = "Total " + rCount + " movimientos";

  //console.log(movimientosBancos);

  return (
    <Fragment>
      {!movimientosBancos ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <div className="mlpc-table">
                  <DataTable
                    ref={(el) => {
                      dt = el;
                    }}
                    value={movimientosBancos}
                    paginator={true}
                    rows={10}
                    header={header}
                    footer={footer}
                    globalFilter={globalFilter}
                    scrollable={false}
                    responsive={true}
                    resizableColumns={true}
                    selection={bancosSelected}
                    onSelectionChange={(e) => guardarBancosSelected(e.value)}
                    selectionMode="multiple"
                    dataKey="id"
                  >
                    <Column
                      field="id"
                      header="ID de Movimiento de Banco"
                      sortable={true}
                    />
                    {estadoBanco === 1 ? (
                      <Column
                        field="fecha"
                        header="Fecha"
                        sortable={true}
                        body={rowFecha}
                      />
                    ) : (
                      <Column
                        field="fechaAcreditado"
                        header="Fecha"
                        sortable={true}
                        body={rowFecha}
                      />
                    )}
                    <Column
                      field="detalle"
                      header="Detalle"
                      sortable={true}
                      style={{ width: "20%" }}
                    />
                    <Column
                      field="cuenta.numero"
                      header="Cuenta"
                      sortable={true}
                      style={{ width: "20%" }}
                    />
                    <Column
                      field="estado"
                      header="Estado"
                      sortable={true}
                      style={{ width: "10%" }}
                    />
                    <Column
                      field="ingreso"
                      header="Ingreso"
                      sortable={true}
                      style={{ textAlign: "right" }}
                      body={rowIngreso}
                    />
                    <Column
                      field="egreso"
                      header="Egreso"
                      sortable={true}
                      style={{ textAlign: "right" }}
                      body={rowEgreso}
                    />
                    <Column
                      field="botones"
                      header=""
                      body={botones}
                      style={{ width: "20%" }}
                    />
                  </DataTable>{" "}
                </div>
                {estadoBanco === 2 ? ( //Acreditado
                  <div style={{ textAlign: "right" }}>
                    Saldo Anterior
                    <span className="badge badge-secondary ml-2 mr-2">
                      {"$ " + parseFloat(saldoAnterior).toFixed(2)}
                    </span>
                  </div>
                ) : null}
                <div style={{ textAlign: "right" }}>
                  Total ingresos
                  <span className="badge badge-secondary ml-2 mr-2">
                    {"$ " + parseFloat(totalIngresos).toFixed(2)}
                  </span>
                  Total egresos
                  <span className="badge badge-secondary ml-2 mr-2">
                    {"$ " + parseFloat(totalEgresos).toFixed(2)}
                  </span>
                </div>
                <div style={{ textAlign: "right" }}>
                  Saldo actual
                  <span className="badge badge-secondary ml-2 mr-2">
                    {"$ " +
                      parseFloat(
                        Number(saldoAnterior) +
                          Number(totalIngresos) -
                          Number(totalEgresos)
                      ).toFixed(2)}
                  </span>
                </div>

                <Dialog
                  visible={modalAcreditar}
                  header="Acreditación de movimiento bancario"
                  modal={true}
                  style={largeStyles}
                  dismissableMask={true}
                  onHide={() => setModalAcreditar(false)}
                >
                  <div style={{ maxWidth: "100%" }}>
                    <div className="form-group">
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={esLocale}
                      >
                        <KeyboardDatePicker
                          clearable
                          label="Fecha"
                          value={fechaAcreditado}
                          onChange={(date) => guardarFechaAcreditado(date)}
                          format="dd/MM/yyyy"
                          style={{ color: "blue", width: "70%" }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <button
                      className="btn btn-success btn-block"
                      onClick={onSubmit}
                    >
                      Confirmar acreditación
                    </button>
                  </div>
                </Dialog>

                <Dialog
                  header="Movimiento de banco"
                  visible={modalEliminar}
                  modal={true}
                  onHide={() => setModalEliminar(false)}
                >
                  <div className="confirmation-content">
                    <i
                      className="pi pi-exclamation-triangle mt-2 mr-3"
                      style={{ fontSize: "2rem" }}
                    />
                    <span>
                      {" "}
                      Esta seguro de eliminar el movimiento {idMovimiento} ?
                    </span>
                  </div>
                  <br />

                  <div style={{ maxWidth: "100%" }}>
                    <div className="col-xs-10 col-md-12 text-center">
                      <Button
                        label="No"
                        icon="pi pi-times"
                        onClick={() => setModalEliminar(false)}
                        className="p-button-danger linea mr-2"
                      />
                      <Button
                        label="Si"
                        icon="pi pi-check"
                        onClick={() => {
                          eliminarMovimientoBanco({
                            idMovimiento,
                            chequesSelected,
                          });
                          setModalEliminar(false);
                          setTimeout(() => {
                            actualizar();
                          }, 2000);
                        }}
                        className="p-button-success linea ml-2"
                        autoFocus
                      />
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ConciliacionBancaria;
