import React, { useState, useContext, useEffect, useRef } from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { AuthContext } from "../../context/AuthContext";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";

function Account(props) {
  // extraer los valores del context

  const authContext = useContext(AuthContext);
  const { autenticado, registrarUsuario } = authContext;

  let growl = useRef();

  // State para iniciar sesión
  const [usuario, guardarUsuario] = useState({
    nombre: "",
    email: "",
    password: "",
    confirmar: "",
    estado: "",
  });

  // extraer de usuario
  const { nombre, email, password, confirmar, estado } = usuario;

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (nombre.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Registro",
        detail: "El nombre es obligatorio.",
      });
      return;
    }

    if (email.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Registro",
        detail: "El email es obligatorio.",
      });
      return;
    }

    if (!EmailValidator.validate(email)) {
      growl.show({
        severity: "error",
        summary: "Registro",
        detail: "El email es incorrecto.",
      });
      return;
    }

    if (password.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Registro",
        detail: "El password es incorrecto.",
      });
      return;
    }

    var espacios = false;
    var cont = 0;

    while (!espacios && cont < password.length) {
      if (password.charAt(cont) === " ") espacios = true;
      cont++;
    }

    if (espacios) {
      growl.show({
        severity: "error",
        summary: "Registro",
        detail: "El password no puede contener espacios en blanco.",
      });
      return;
    }

    if (password.length < 6) {
      growl.show({
        severity: "error",
        summary: "Registro",
        detail: "El password debe tener al menos 6 caracteres.",
      });
      return;
    }

    if (password !== confirmar) {
      growl.show({
        severity: "error",
        summary: "Registro",
        detail: "Los passwords son diferentes.",
      });
      return;
    }

    // Llamar al context
    registrarUsuario({ nombre, email, password });
  };

  const cerrar = () => {
    guardarUsuario({
      ...usuario,
      estado: true,
    });
  };

  return (
    <div className="background-body">
      <div className="row justify-content-center">
        <div className="col-xs-5 col-md-4 mt-3">
          <div className="card-body text-black background mb-3">
            <button
              type="button"
              className="close xclose"
              aria-label="Close"
              onClick={cerrar}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h3 className="fwhite titulos text-center py=4">
              <i className="fa fa-lock"></i> {""}
              Crear una cuenta
            </h3>
            <hr className="linea-negra" />

            <Growl ref={(el) => (growl = el)} />

            <form onSubmit={onSubmit}>
              <div className="form-group">
                <span className="p-float-label">
                  <InputText
                    id="nombre"
                    type="text"
                    className="form-control mt-4"
                    value={nombre}
                    onChange={(e) =>
                      guardarUsuario({
                        ...usuario,
                        nombre: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="nombre" style={{ color: "black" }}>
                    Usuario
                  </label>
                </span>
              </div>

              <div className="form-group">
                <span className="p-float-label">
                  <InputText
                    id="email"
                    type="text"
                    className="form-control mt-4"
                    value={email}
                    onChange={(e) =>
                      guardarUsuario({
                        ...usuario,
                        email: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="email" style={{ color: "black" }}>
                    Email
                  </label>
                </span>
              </div>

              <div className="form-group">
                <span className="p-float-label">
                  <InputText
                    id="password"
                    type="password"
                    className="form-control mt-4"
                    value={password}
                    onChange={(e) =>
                      guardarUsuario({
                        ...usuario,
                        password: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="password" style={{ color: "black" }}>
                    Password
                  </label>
                </span>
              </div>

              <div className="form-group">
                <span className="p-float-label">
                  <InputText
                    id="confirmar"
                    type="password"
                    className="form-control mt-4"
                    value={confirmar}
                    onChange={(e) =>
                      guardarUsuario({
                        ...usuario,
                        confirmar: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="confirmar" style={{ color: "black" }}>
                    Confirmar password
                  </label>
                </span>
              </div>
              <input
                type="submit"
                className="btn btn-background btn-block"
                value="Crear una cuenta "
              />
              {autenticado || estado ? <Redirect to="/" /> : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
