import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { ArchivoContext } from "../../../context/ArchivoContext";

function Clientes(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    clientes,
    guardarClientes,
    buscarClientes,
    obtenerClientes,
    guardarTiposComprobantes,
  } = archivoContext;

  const [globalFilter, guardarGlobalFilter] = useState(null);

  let dt = useRef();

  useEffect(() => {
    obtenerClientes();
  }, []);

  const cerrar = () => {
    props.history.push("/menu");
  };

  let header = (
    <Fragment>
      <button
        type="button"
        className="close xclose"
        aria-label="Close"
        onClick={cerrar}
        style={{ color: "Blue" }}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <h3 className="fwhite titulos text-center py=4">Clientes</h3>
      <hr className="linea-negra" />
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          id="search-clientes"
          type="search"
          onInput={(e) => guardarGlobalFilter(e.target.value)}
          placeholder="Buscador"
        />

        <Link to={`cliente/0`}>
          <button type="button" className="btn btn-info btn-large ml-4">
            Nuevo
          </button>
        </Link>
      </div>
    </Fragment>
  );

  const botonVer = (rowData, column) => {
    return (
      <div>
        <Link to={`cliente/${rowData.id}`}>
          <button
            type="button"
            className="btn btn-success btn-block"
            onClick={guardarTiposComprobantes(null)}
          >
            Ver
          </button>
        </Link>
      </div>
    );
  };

  let rCount = clientes ? clientes.length : 0;
  let footer = "Total " + rCount + " clientes";

  return (
    <div className="mlpc-table">
      <DataTable
        ref={(el) => {
          dt = el;
        }}
        value={clientes}
        paginator={true}
        rows={10}
        header={header}
        footer={footer}
        globalFilter={globalFilter}
        scrollable={false}
        responsive={true}
        resizableColumns={true}
      >
        {/*}
            <Column
              field="descripcion"
              header="Descripción"
              sortable={true}
              filter={true}
              filterPlaceholder="Buscar"
            />
            */}
        <Column field="id" header="ID de Cliente" sortable={true} />
        <Column
          field="nombre"
          header="Nombre o Razón Social"
          sortable={true}
          style={{ width: "30%" }}
        />
        <Column field="cuit" header="C.U.I.T." sortable={true} />
        <Column field="domicilio" header="Domicilio" sortable={true} />
        <Column field="botones" header="" body={botonVer} />
      </DataTable>{" "}
    </div>
  );
}

export default Clientes;
