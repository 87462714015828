import React, { useEffect, useContext, Fragment, useState } from "react";
import logo from "../../assets/images/gym.png"
import { Link, Redirect } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ArchivoContext } from "../../context/ArchivoContext";

import { Dropdown } from "primereact/dropdown";

const Navbar = (props) => {
  const authContext = useContext(AuthContext);
  const { autenticado, usuarioAutenticado, cerrarSesion } = authContext;
  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    empresasNavbar,
    guardarEmpresaNavbar,
    obtenerEmpresasSelect,
  } = archivoContext;

  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };

  useEffect(() => {
    //usuarioAutenticado();
    //obtenerEmpresasSelect();
    //guardarEmpresaNavbar(Number(localStorage.getItem("empresa")));
  }, []);

  const cerrarSes = () => {
    cerrarSesion();
  };

  return (
    <Fragment>
      {/* Segundo Navbar */}

      <nav id="navbar-dos" className="navbar navbar-expand-lg">
        <a className="navbar-brand" href="/">
          <img src={logo} className="logo-navbar" alt="Homepage" />
        </a>

        {isMobile.any() ? (
          <Fragment>
            {!autenticado ? (
              <Fragment>
                <Link to="/account">
                  <button type="button" className="btn btn-sm btn-login ml-1">
                    Crear una cuenta
                  </button>
                </Link>
                <Link to="/login">
                  <button type="button" className="btn btn-sm btn-login">
                    Iniciar sesión
                  </button>
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <Dropdown
                  id="empresas"
                  value={empresaNavbar}
                  className="mr-0"
                  options={empresasNavbar}
                  onChange={(e) => {
                    guardarEmpresaNavbar(e.target.value);
                    localStorage.setItem("empresa", e.target.value);
                    window.location.href ="/";
                  }}
                  placeholder="Empresa"
                  style={{ width: "10em", fontSize: "xx-small" }}
                />

                <Link to="/">
                  <button
                    type="button"
                    className="btn btn-sm btn-login"
                    onClick={cerrarSes}
                  >
                    Cerrar sesión
                  </button>
                </Link>
              </Fragment>
            )}
          </Fragment>
        ) : null}

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto ml-5">
            {/*
            {autenticado ? (
              <div>
                <Dropdown
                  id="empresas"
                  value={empresaNavbar}
                  className="ml-5"
                  options={empresasNavbar}
                  onChange={(e) => guardarEmpresaNavbar(e.target.value)}
                  placeholder="Seleccioná una empresa"
                />
              </div>
            ) : null}
            */}
          </ul>
        </div>

        {!isMobile.any() ? (
          <Fragment>
            {!autenticado ? (
              <Fragment>
                <Link to="/account">
                  <button type="button" className="btn btn-login-web ml-3">
                    Crear una cuenta
                  </button>
                </Link>
                <Link to="/login">
                  <button type="button" className="btn btn-login-web">
                    Iniciar sesión
                  </button>
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <Dropdown
                  id="empresas"
                  value={empresaNavbar}
                  className="mr-2"
                  options={empresasNavbar}
                  onChange={(e) => {
                    guardarEmpresaNavbar(e.target.value);
                    localStorage.setItem("empresa", e.target.value);
                    window.location.href ="/";
                  }}
                  placeholder="Seleccioná una empresa"
                  style={{ width: "14em" }}
                />

                <Link to="/">
                  <button
                    type="button"
                    className="btn btn-login-web"
                    onClick={cerrarSes}
                  >
                    Cerrar sesión
                  </button>
                </Link>
              </Fragment>
            )}
          </Fragment>
        ) : null}
      </nav>
    </Fragment>
  );
};

export default Navbar;
