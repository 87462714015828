import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";

function Flete(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    flete,
    obtenerFlete,
    guardarFlete,
    crearFlete,
    actualizarFlete,
  } = archivoContext;

  let growl = useRef();

  const idFlete = props.match.params.id;

  useEffect(() => {
    if (idFlete !== "0") {
      obtenerFlete({ idFlete });
    } else {
      guardarFlete({
        id: "",
        nombre: "",
        domicilio: "",
        telefono: "",
        cuit: "",
        habilitado: true,
      });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (flete.nombre !== null && flete.nombre.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Fletes",
        detail: "El nombre del flete es obligatorio.",
      });
      return;
    }

    if (idFlete === "0") {
      crearFlete(flete);
    } else {
      actualizarFlete(flete);
    }
  };

  const cerrar = () => {
    props.history.push("/fletes");
  };

  //console.log(flete)

  return (
    <Fragment>
      {!flete ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {flete.nombre}
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <div class="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="nombre"
                            type="text"
                            className="form-control mt-4"
                            value={flete.nombre}
                            onChange={(e) =>
                              guardarFlete({
                                ...flete,
                                nombre: e.target.value.toUpperCase(),
                              })
                            }
                          />
                          <label htmlFor="nombre" style={{ color: "black" }}>
                            Nombre del flete
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="domicilio"
                            type="text"
                            className="form-control mt-4"
                            value={flete.domicilio}
                            onChange={(e) =>
                              guardarFlete({
                                ...flete,
                                domicilio: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="domicilio" style={{ color: "black" }}>
                            Domicilio
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <InputMask
                            mask="99-99999999-9"
                            id="cuit"
                            type="text"
                            className="form-control mt-4"
                            value={flete.cuit}
                            onChange={(e) =>
                              guardarFlete({
                                ...flete,
                                cuit: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="cuit" style={{ color: "black" }}>
                            C.U.I.T
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="telefono"
                            type="text"
                            className="form-control mt-4"
                            value={flete.telefono}
                            onChange={(e) =>
                              guardarFlete({
                                ...flete,
                                telefono: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="telefono" style={{ color: "black" }}>
                            Teléfono
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="habilitado"
                          style={{ color: "black", marginRight: "1em" }}
                        >
                          Habilitado:
                        </label>
                        <ToggleButton
                          onLabel="Si"
                          offLabel="No"
                          onIcon="pi pi-check"
                          offIcon="pi pi-times"
                          checked={flete.habilitado}
                          onChange={(e) =>
                            guardarFlete({
                              ...flete,
                              habilitado: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn btn-background btn-block mt-4"
                    value="Guardar flete"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Flete;
