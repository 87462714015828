import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AuthContext } from "../../../context/AuthContext";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { FondoContext } from "../../../context/FondoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

function MovimientoBanco(props) {
  // extraer los valores del context

  const authContext = useContext(AuthContext);
  const { autenticado, registrarUsuario } = authContext;

  const archivoContext = useContext(ArchivoContext);
  const { empresaNavbar, cuentas, obtenerCuentasSelect } = archivoContext;

  const fondoContext = useContext(FondoContext);
  const {
    movimientoBanco,
    movimientosBancos,
    chequesSelected,
    obtenerMovimientoBanco,
    guardarMovimientoBanco,
    crearMovimientoBanco,
    obtenerCuentas,
    guardarChequesSelected,
  } = fondoContext;

  const [nombreCuenta, guardarNombreCuenta] = useState(null);

  let growl = useRef();

  const idMovimientoBanco = props.match.params.id;
  const idOrigen = props.match.params.origen;

  useEffect(() => {
    if (idMovimientoBanco !== "0") {
      obtenerMovimientoBanco({ idMovimientoBanco });
    } else {
      guardarMovimientoBanco({
        fecha: new Date(),
        cuentaId: 0,
        detalle: "",
        importe: "",
        ingreso: "",
        egreso: "",
        empresaId: empresaNavbar,
        reciboId: 0,
        clienteId: 0,
        nombreCliente: "",
        estado: "PENDIENTE",
      });
    }
    guardarChequesSelected(null);
    obtenerCuentasSelect();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (movimientoBanco.fecha === "") {
      growl.show({
        severity: "error",
        summary: "Movimiento de banco",
        detail: "La fecha es iválida.",
      });
      return;
    }

    if (movimientoBanco.importe === "" || movimientoBanco.importe === 0) {
      growl.show({
        severity: "error",
        summary: "Movimiento de banco",
        detail: "El importe es obligatorio.",
      });
      return;
    }

    if (movimientoBanco.cuentaId === 0) {
      growl.show({
        severity: "error",
        summary: "Movimiento de banco",
        detail: "La cuenta es obligatoria.",
      });
      return;
    }

    if (movimientoBanco.empresaId === 0) {
      growl.show({
        severity: "error",
        summary: "Movimiento de banco",
        detail: "La empresa es obligatoria.",
      });
      return;
    }

    if (idMovimientoBanco === "0") {
      crearMovimientoBanco({ movimientoBanco, chequesSelected, nombreCuenta });
    }
  };

  const cerrar = () => {
    if (idOrigen === "0") {
      props.history.push("/conciliacionbancaria");
    } else {
      props.history.push("/movimientosbancos");
    }
  };

  const onChangeCuenta = (e) => {
    for (var i = 0; i < cuentas.length; i++) {
      if (cuentas[i].value === e.target.value) {
        guardarMovimientoBanco({
          ...movimientoBanco,
          cuentaId: e.target.value,
        });
      }
    }
  };

  const onSubmitClear = (e) => {
    e.preventDefault();
    guardarMovimientoBanco({
      fecha: new Date(),
      conceptoId: 0,
      detalleConcepto: "",
      detalle: "",
      importe: "",
      ingreso: "",
      egreso: "",
      empresaId: empresaNavbar,
      reciboId: 0,
      clienteId: 0,
      nombreCliente: "",
      estado: "PENDIENTE",
    });
  };
  //console.log(movimientoBanco);

  return (
    <Fragment>
      {!movimientoBanco ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-6 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Movimiento de banco
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <div class="row">
                    <div className="col-xs-12 col-md-12">
                      <div className="form-group">
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={esLocale}
                        >
                          <KeyboardDatePicker
                            clearable
                            label="Fecha"
                            disabled={idMovimientoBanco !== "0"}
                            value={movimientoBanco.fecha}
                            onChange={(date) =>
                              guardarMovimientoBanco({
                                ...movimientoBanco,
                                fecha: date,
                              })
                            }
                            format="dd/MM/yyyy"
                            style={{ color: "blue" }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <label
                            htmlFor="cuenta"
                            style={{ color: "black", fontSize: "0.8em" }}
                          >
                            Cuenta
                          </label>
                        </span>

                        <Dropdown
                          id="cuenta"
                          name="cuenta"
                          value={movimientoBanco.cuentaId}
                          style={{ width: "50%" }}
                          className="mt-3"
                          disabled={idMovimientoBanco !== "0"}
                          options={cuentas}
                          onChange={onChangeCuenta}
                          filter={true}
                          placeholder="Seleccioná una cuenta."
                        />
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="detalle"
                            type="text"
                            className="form-control mt-4"
                            disabled={idMovimientoBanco !== "0"}
                            value={movimientoBanco.detalle}
                            onChange={(e) =>
                              guardarMovimientoBanco({
                                ...movimientoBanco,
                                detalle: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="detalle" style={{ color: "black" }}>
                            Detalle
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <h4>
                          {movimientoBanco.importe > 0 ? (
                            <span
                              className="badge badge-primary"
                              style={{ float: "right", marginRight: "15em" }}
                            >
                              Ingreso
                            </span>
                          ) : null}
                          {movimientoBanco.importe < 0 ? (
                            <span
                              className="badge badge-info"
                              style={{ float: "right", marginRight: "15em" }}
                            >
                              Egreso
                            </span>
                          ) : null}
                        </h4>
                        <span className="p-float-label">
                          <InputText
                            id="importe"
                            type="number"
                            disabled={idMovimientoBanco !== "0"}
                            className="form-control mt-4"
                            style={{ width: "40%" }}
                            value={
                              movimientoBanco ? movimientoBanco.importe : 0
                            }
                            onChange={(e) =>
                              guardarMovimientoBanco({
                                ...movimientoBanco,
                                importe: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="ajuste" style={{ color: "black" }}>
                            Importe (ingreso en positivo)
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 col-md-6">
                      <button
                        className="btn btn-success btn-block"
                        disabled={idMovimientoBanco !== "0"}
                        onClick={onSubmit}
                      >
                        Guardar movimiento de banco
                      </button>
                    </div>
                    <div className="col-xs-6 col-md-6">
                      <button
                        className="btn btn-warning btn-block"
                        disabled={idMovimientoBanco !== "0"}
                        onClick={onSubmitClear}
                      >
                        Limpiar movimientos
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default MovimientoBanco;
