import React, { useState, useRef, Fragment } from "react";
import clienteAxios from "../config/axios";
import { createContext } from "react";
import { Growl } from "primereact/growl";

const FondoContext = createContext();
const { Provider, Consumer } = FondoContext;

function FondoProvider({ children }) {
  let growl = useRef();

  const [movimientosCajas, guardarMovimientosCajas] = useState(null);
  const [movimientoCaja, guardarMovimientoCaja] = useState(null);
  const [totalIngresos, guardarTotalIngresos] = useState(0);
  const [totalEgresos, guardarTotalEgresos] = useState(0);
  const [saldoAnterior, guardarSaldoAnterior] = useState(0);
  const [dateCaja, guardarDateCaja] = useState(new Date());
  const [rangeCaja, guardarRangeCaja] = useState(null);
  const [radioCaja, guardarRadioCaja] = useState("val1");
  const [agrupar, setAgrupar] = useState(false);
  const [todas, setTodas] = useState(false);
  const [field, setField] = useState("detalleConcepto");
  const [sort, setSort] = useState(-1);
  const [movimientosBancos, guardarMovimientosBancos] = useState(null);
  const [movimientoBanco, guardarMovimientoBanco] = useState(null);
  const [dateBanco, guardarDateBanco] = useState(new Date());
  const [rangeBanco, guardarRangeBanco] = useState(null);
  const [radioBanco, guardarRadioBanco] = useState("val1");
  const [chequesTerceros, guardarChequesTerceros] = useState(null);
  const [chequeTercero, guardarChequeTercero] = useState(null);
  const [totalCheques, guardarTotalCheques] = useState(0);
  const [chequesSelected, guardarChequesSelected] = useState(null);
  const [bancosSelected, guardarBancosSelected] = useState(null);
  const [chequesPropios, guardarChequesPropios] = useState(null);
  const [chequePropio, guardarChequePropio] = useState(null);

  function tranformDate(strDate) {
    let result = "";

    if (strDate) {
      let parts = strDate.split("-");
      result = `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    return result;
  }

  // CARTERA DE CHEQUES TERCEROS

  const obtenerCarteraChequesTerceros = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenercarterachequesterceros",
        datos
      );
      let data = respuesta.data;

      let total = Number(data.total[0].importe);
      guardarTotalCheques(total);
      guardarChequesTerceros(data.response);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerChequesTerceros = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenerchequesterceros",
        datos
      );
      let data = respuesta.data;

      let total = Number(data.total[0].importe);
      guardarTotalCheques(total);
      guardarChequesTerceros(data.response);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerChequeTercero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenerchequetercero",
        datos
      );
      let data = respuesta.data;
      data.fecha = data.fecha.replace(/-/g, "/");
      data.emision = data.emision.replace(/-/g, "/");
      data.vencimiento = data.vencimiento.replace(/-/g, "/");

      guardarChequeTercero(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearChequeTercero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/crearchequetercero",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Cheques de terceros",
        detail: "El cheque se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
      await growl.show({
        severity: "error",
        summary: "Cheques de terceros",
        detail: "El cheque ya existe.",
      });
    }
  };

  const actualizarChequeTercero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/actualizarchequetercero",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Cheques de terceros",
        detail: "El cheque se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const cambiarEstadoChequeCartera = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/cambiarestadochequecartera",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Cheques de terceros",
        detail: "El cheque se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const eliminarChequeTercero = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/eliminarchequetercero",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Cheques de terceros",
        detail: "El movimiento se ha eliminado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerChequesDeposito = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenerchequesdeposito",
        datos
      );
      let data = respuesta.data;

      guardarChequesSelected(data);
    } catch (error) {
      console.log(error.response);
    }
  };


  const actualizarChequeRechazado = async (datos) => {

    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/actualizarchequerechazado",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Cheques de terceros",
        detail: "El cheque se ha rechazado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };



    // CARTERA DE CHEQUES PROPIOS

    const obtenerCarteraChequesPropios = async (datos) => {
      try {
        const respuesta = await clienteAxios.post(
          "/api/fondos/obtenercarterachequespropios",
          datos
        );
        let data = respuesta.data;
  
        let total = Number(data.total[0].importe);
        guardarTotalCheques(total);
        guardarChequesPropios(data.response);
      } catch (error) {
        console.log(error.response);
      }
    };
  
    const obtenerChequesPropios = async (datos) => {
      try {
        const respuesta = await clienteAxios.post(
          "/api/fondos/obtenerchequesterceros",
          datos
        );
        let data = respuesta.data;
  
        let total = Number(data.total[0].importe);
        guardarTotalCheques(total);
        guardarChequesPropios(data.response);
      } catch (error) {
        console.log(error.response);
      }
    };
  
    const obtenerChequePropio = async (datos) => {
      try {
        const respuesta = await clienteAxios.post(
          "/api/fondos/obtenerchequetercero",
          datos
        );
        let data = respuesta.data;
        data.fecha = data.fecha.replace(/-/g, "/");
        data.emision = data.emision.replace(/-/g, "/");
        data.vencimiento = data.vencimiento.replace(/-/g, "/");
  
        guardarChequePropio(data);
      } catch (error) {
        console.log(error.response);
      }
    };
  
    const crearChequePropio = async (datos) => {
      try {
        const respuesta = await clienteAxios.post(
          "/api/fondos/crearchequepropio",
          datos
        );
  
        await growl.show({
          severity: "success",
          summary: "Cheques propios",
          detail: "El cheque se ha creado correctamente.",
        });
      } catch (error) {
        console.log(error.response);
        await growl.show({
          severity: "error",
          summary: "Cheques propios",
          detail: "El cheque ya existe.",
        });
      }
    };
  
    const actualizarChequePropio = async (datos) => {
      try {
        const respuesta = await clienteAxios.post(
          "/api/fondos/actualizarchequetercero",
          datos
        );
  
        await growl.show({
          severity: "success",
          summary: "Cheques de terceros",
          detail: "El cheque se ha actualizado correctamente.",
        });
      } catch (error) {
        console.log(error.response);
      }
    };
  
    const cambiarEstadoChequePropioCartera = async (datos) => {
      try {
        const respuesta = await clienteAxios.post(
          "/api/fondos/cambiarestadochequecartera",
          datos
        );
  
        await growl.show({
          severity: "success",
          summary: "Cheques de terceros",
          detail: "El cheque se ha actualizado correctamente.",
        });
      } catch (error) {
        console.log(error.response);
      }
    };
  
    const eliminarChequePropio = async (datos) => {
      try {
        const respuesta = await clienteAxios.post(
          "/api/fondos/eliminarchequetercero",
          datos
        );
  
        await growl.show({
          severity: "success",
          summary: "Cheques de terceros",
          detail: "El movimiento se ha eliminado correctamente.",
        });
      } catch (error) {
        console.log(error.response);
      }
    };
  
    const obtenerChequesPropiosDeposito = async (datos) => {
      try {
        const respuesta = await clienteAxios.post(
          "/api/fondos/obtenerchequesdeposito",
          datos
        );
        let data = respuesta.data;
  
        guardarChequesSelected(data);
      } catch (error) {
        console.log(error.response);
      }
    };
  
  
    const actualizarChequePropioRechazado = async (datos) => {
  
      try {
        const respuesta = await clienteAxios.post(
          "/api/fondos/actualizarchequerechazado",
          datos
        );
  
        await growl.show({
          severity: "success",
          summary: "Cheques de terceros",
          detail: "El cheque se ha rechazado correctamente.",
        });
      } catch (error) {
        console.log(error.response);
      }
    };
  


  // MOVIMIENTOS CAJAS //

  const buscarMovimientosCajas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/buscarmovimientoscajas",
        datos
      );
      let data = respuesta.data;
      guardarMovimientosCajas(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerMovimientosCajas = async (datos) => {
    const { idEmpresa, fechaCaja, rangeCaja, todas } = datos;

    let fechaCaja1;
    let fechaCaja2;

    if (fechaCaja) {
      fechaCaja1 = fechaCaja;
      fechaCaja2 = fechaCaja;
    }

    if (rangeCaja) {
      fechaCaja1 = rangeCaja[0];
      fechaCaja2 = rangeCaja[1];
    }

    const datosCaja = { idEmpresa, fechaCaja1, fechaCaja2, todas };

    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenermovimientoscajas",
        datosCaja
      );
      let data = respuesta.data;

      let saldoAnterior =
        Number(data.saldoAnterior[0].saldoIngresos) -
        Number(data.saldoAnterior[0].saldoEgresos);
      let ingresos = Number(data.total[0].totalIngresos);
      let egresos = Number(data.total[0].totalEgresos);
      guardarSaldoAnterior(saldoAnterior);
      guardarTotalIngresos(ingresos);
      guardarTotalEgresos(egresos);
      guardarMovimientosCajas(data.response);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerMovimientoCaja = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenermovimientocaja",
        datos
      );
      let data = respuesta.data;
      data.fecha = data.fecha.replace(/-/g, "/");
      guardarMovimientoCaja(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearMovimientoCaja = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/crearmovimientocaja",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Movimientos de cajas",
        detail: "El movimiento se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const actualizarMovimientoCaja = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/actualizarmovimientocaja",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Cheques de terceros",
        detail: "El movimiento se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const eliminarMovimientoCaja = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/eliminarmovimientocaja",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Cheques de terceros",
        detail: "El movimiento se ha eliminado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // BANCOS

  const obtenerMovimientosConciliacion = async (datos) => {
    const { idEmpresa, fechaBanco, idCuenta, estado } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenermovimientosconciliacion",
        datos
      );
      let data = respuesta.data;

      let saldoAnterior = 0;
      if (estado === 1) {
        saldoAnterior = 0;
      } else {
        saldoAnterior =
          Number(data.saldoAnterior[0].saldoIngresos) -
          Number(data.saldoAnterior[0].saldoEgresos);
      }
      let ingresos = Number(data.total[0].totalIngresos);
      let egresos = Number(data.total[0].totalEgresos);
      guardarSaldoAnterior(saldoAnterior);
      guardarTotalIngresos(ingresos);
      guardarTotalEgresos(egresos);
      guardarMovimientosBancos(data.response);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerMovimientosBancos = async (datos) => {
    const { idEmpresa, fechaBanco, rangeBanco, idCuenta, estado } = datos;

    let fechaBanco1;
    let fechaBanco2;

    if (fechaBanco) {
      fechaBanco1 = fechaBanco;
      fechaBanco2 = fechaBanco;
    }

    if (rangeBanco) {
      fechaBanco1 = rangeBanco[0];
      fechaBanco2 = rangeBanco[1];
    }

    const datosBanco = {
      idEmpresa,
      fechaBanco1,
      fechaBanco2,
      idCuenta,
      estado,
    };

    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenermovimientosbancos",
        datosBanco
      );
      let data = respuesta.data;

      //console.log(data);

      let saldoAnterior =
        Number(data.saldoAnterior[0].saldoIngresos) -
        Number(data.saldoAnterior[0].saldoEgresos);
      let ingresos = Number(data.total[0].totalIngresos);
      let egresos = Number(data.total[0].totalEgresos);
      guardarSaldoAnterior(saldoAnterior);
      guardarTotalIngresos(ingresos);
      guardarTotalEgresos(egresos);
      guardarMovimientosBancos(data.response);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerMovimientoBanco = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenermovimientobanco",
        datos
      );
      let data = respuesta.data;
      data.fecha = data.fecha.replace(/-/g, "/");

      guardarMovimientoBanco(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearMovimientoBanco = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/crearmovimientobanco",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Movimientos de bancos",
        detail: "El movimiento se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const acreditarMovimientoBanco = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/acreditarmovimientobanco",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Movimientos de bancos",
        detail: "El depósito se ha acreditado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };


  const eliminarMovimientoBanco = async (datos) => {

    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/eliminarmovimientobanco",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Movimientos de bancos",
        detail: "El movimiento se ha eliminado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };


  const obtenerBancoCheque = async (datos) => {

    try {
      const respuesta = await clienteAxios.post(
        "/api/fondos/obtenerbancocheque",
        datos
      );
      let data = respuesta.data;

      guardarBancosSelected(data);
    } catch (error) {
      console.log(error.response);
    }
  };




  return (
    <Fragment>
      <Growl ref={(el) => (growl = el)} />
      <Provider
        value={{
          movimientosCajas,
          movimientoCaja,
          totalIngresos,
          totalEgresos,
          saldoAnterior,
          dateCaja,
          rangeCaja,
          radioCaja,
          agrupar,
          field,
          sort,
          todas,
          movimientosBancos,
          movimientoBanco,
          dateBanco,
          rangeBanco,
          radioBanco,
          chequesTerceros,
          totalCheques,
          chequeTercero,
          chequesSelected,
          bancosSelected,
          chequesPropios,
          chequePropio,
          tranformDate,
          buscarMovimientosCajas,
          obtenerMovimientosCajas,
          obtenerMovimientoCaja,
          guardarMovimientosCajas,
          crearMovimientoCaja,
          actualizarMovimientoCaja,
          guardarMovimientoCaja,
          guardarDateCaja,
          guardarRangeCaja,
          guardarRadioCaja,
          setAgrupar,
          setField,
          setSort,
          setTodas,
          guardarMovimientosBancos,
          guardarMovimientoBanco,
          guardarMovimientosBancos,
          obtenerMovimientosBancos,
          obtenerMovimientoBanco,
          guardarDateBanco,
          guardarRangeBanco,
          crearMovimientoBanco,
          guardarRadioBanco,
          obtenerChequeTercero,
          guardarTotalCheques,
          obtenerChequesTerceros,
          guardarChequeTercero,
          crearChequeTercero,
          obtenerCarteraChequesTerceros,
          actualizarChequeTercero,
          obtenerCarteraChequesPropios,
          obtenerChequesDeposito,
          eliminarMovimientoCaja,
          guardarChequesSelected,
          cambiarEstadoChequeCartera,
          eliminarChequeTercero,
          obtenerMovimientosConciliacion,
          acreditarMovimientoBanco,
          eliminarMovimientoBanco,
          guardarBancosSelected,
          obtenerBancoCheque,
          actualizarChequeRechazado,
          guardarChequePropio,
          crearChequePropio,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { FondoProvider, Consumer as FondoConsumer, FondoContext };
