import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";

function Articulo(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    articulo,
    rubros,
    obtenerArticulo,
    guardarArticulo,
    crearArticulo,
    actualizarArticulo,
    obtenerRubrosSelect,
  } = archivoContext;

  let growl = useRef();

  const options = ["Ventas", "Compras"];

  const idArticulo = props.match.params.id;

  useEffect(() => {
    if (idArticulo !== "0") {
      obtenerArticulo({ idArticulo });
    } else {
      guardarArticulo({
        id: "",
        nombre: "",
        idRubro: 0,
        unidad: "",
        habilitado: true,
        costo: 0,
        tipo: "Ventas",
      });
    }
    obtenerRubrosSelect();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (articulo.nombre !== null && articulo.nombre.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Articulos",
        detail: "El nombre del articulo es obligatorio.",
      });
      return;
    }

    if (articulo.idRubro === 0) {
      growl.show({
        severity: "error",
        summary: "Articulos",
        detail: "El rubro del articulo es obligatorio.",
      });
      return;
    }

    if (articulo.tipo !== null && articulo.tipo.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Articulos",
        detail: "El tipo del articulo es obligatorio.",
      });
      return;
    }

    if (idArticulo === "0") {
      crearArticulo(articulo);
    } else {
      actualizarArticulo(articulo);
    }



  };

  const cerrar = () => {
    props.history.push("/articulos");
  };

  //console.log(articulo);

  return (
    <Fragment>
      {!articulo ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                  style={{ color: "Blue" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {articulo.nombre}
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <div class="row">
                    <div className="col-xs-12 col-md-6">
                      {idArticulo === "0" ? (
                        <div className="form-group">
                          <span className="p-float-label">
                            <InputText
                              id="id"
                              type="number"
                              className="form-control mt-4"
                              value={articulo.id}
                              onChange={(e) =>
                                guardarArticulo({
                                  ...articulo,
                                  id: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="nombre" style={{ color: "black" }}>
                              ID del articulo
                            </label>
                          </span>
                        </div>
                      ) : null}
                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="nombre"
                            type="text"
                            className="form-control mt-4"
                            value={articulo.nombre}
                            onChange={(e) =>
                              guardarArticulo({
                                ...articulo,
                                nombre: e.target.value.toUpperCase(),
                              })
                            }
                          />
                          <label htmlFor="nombre" style={{ color: "black" }}>
                            Nombre del articulo
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <label
                            htmlFor="rubro"
                            style={{ color: "black", fontSize: "0.8em" }}
                          >
                            Rubro
                          </label>
                        </span>
                        <Dropdown
                          id="rubro"
                          value={articulo.idRubro}
                          style={{ width: "50%" }}
                          className="mt-3"
                          options={rubros}
                          onChange={(e) =>
                            guardarArticulo({
                              ...articulo,
                              idRubro: e.target.value,
                            })
                          }
                          filter={true}
                          placeholder="Seleccioná un rubro"
                        />
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="unidad"
                            type="text"
                            className="form-control mt-4"
                            value={articulo.unidad}
                            onChange={(e) =>
                              guardarArticulo({
                                ...articulo,
                                unidad: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="unidad" style={{ color: "black" }}>
                            Unidad de medida
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="habilitado"
                          style={{ color: "black", marginRight: "1em" }}
                        >
                          Habilitado:
                        </label>
                        <ToggleButton
                          onLabel="Si"
                          offLabel="No"
                          onIcon="pi pi-check"
                          offIcon="pi pi-times"
                          checked={articulo.habilitado}
                          onChange={(e) =>
                            guardarArticulo({
                              ...articulo,
                              habilitado: e.value,
                            })
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="tipo"
                          style={{ color: "black", marginRight: "1em" }}
                        >
                          Tipo:
                        </label>
                        <SelectButton
                          value={articulo.tipo}
                          options={options}
                          onChange={(e) =>
                            guardarArticulo({
                              ...articulo,
                              tipo: e.value,
                            })
                          }
                        />
                      </div>

                      {articulo.tipo === "Compras" ? (
                        <div className="form-group">
                          <span className="p-float-label">
                            <InputText
                              id="costo"
                              type="numeric"
                              style={{ width: "30%" }}
                              className="form-control mt-4"
                              value={articulo.costo}
                              onChange={(e) =>
                                guardarArticulo({
                                  ...articulo,
                                  costo: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="costo" style={{ color: "black" }}>
                              Costo
                            </label>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn btn-background btn-block mt-4"
                    value="Guardar articulo"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Articulo;
