import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { FondoContext } from "../../../context/FondoContext";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import Spinner from "../../layout/Spinner";

function MovimientosBancos(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    cuenta,
    cuentas,
    obtenerCuentasSelectTodos,
    guardarCuenta,
  } = archivoContext;

  const fondoContext = useContext(FondoContext);
  const {
    movimientosBancos,
    movimientoBanco,
    totalIngresos,
    totalEgresos,
    saldoAnterior,
    dateBanco,
    rangeBanco,
    radioBanco,
    agrupar,
    field,
    sort,
    todas,
    obtenerMovimientosBanco,
    buscarMovimientosBancos,
    obtenerMovimientosBancos,
    guardarMovimientosBancos,
    tranformDate,
    guardarDateBanco,
    guardarRangeBanco,
    guardarRadioBanco,
    setAgrupar,
    setField,
    setSort,
    setTodas,
  } = fondoContext;

  const [globalFilter, guardarGlobalFilter] = useState(null);

  const [estadosBancos, guardarEstadosBancos] = useState([
    {
      value: 1,
      label: "PENDIENTE",
    },
    {
      value: 2,
      label: "ACREDITADO",
    },
    {
      value: 3,
      label: "TODOS",
    },
  ]);

  const [estadoBanco, guardarEstadoBanco] = useState(3); //TODOS
  const [cuentaId, guardarCuentaId] = useState(-1); //TODOS

  let dt = useRef();

  const fullStyles =
    window.screen.availWidth < 780 ? { width: "90vw" } : { width: "50vw" };

  const largeStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "42ch" };

  const mediaStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "36ch" };

  const shortStyles =
    window.screen.availWidth < 780 ? { width: "25ch" } : { width: "30ch" };

  useEffect(() => {
    //guardarMovimientosBancos(null);

    obtenerCuentasSelectTodos();

    obtenerMovimientosBancos({
      idEmpresa: empresaNavbar,
      fechaBanco: dateBanco,
      rangeBanco: rangeBanco,
      idCuenta: cuentaId,
      estado: estadoBanco,
    });
  }, []);

  const cerrar = () => {
    props.history.push("/menu");
  };

  const buscarDatos = () => {
    buscarMovimientosBancos({ idEmpresa: empresaNavbar, globalFilter });
  };

  let es = {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
    dateFormat: "dd/mm/yy",
    weekHeader: "Sm",
  };

  const actualizar = () => {
    obtenerMovimientosBancos({
      idEmpresa: empresaNavbar,
      fechaBanco: dateBanco,
      rangeBanco: rangeBanco,
      idCuenta: cuentaId,
      estado: estadoBanco,
    });
  };

  let header = (
    <Fragment>
      <button
        type="button"
        className="close xclose"
        aria-label="Close"
        onClick={cerrar}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <h3 className="fwhite titulos text-center py=4">Movimientos de Bancos</h3>
      <hr className="linea-negra" />
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => guardarGlobalFilter(e.target.value)}
          placeholder="Buscador"
        />
        {/*
        <button className="btn btn-success ml-1" onClick={buscarDatos}>
          Buscar
        </button>
        */}
        <Link to={`movimientobanco/0`}>
          <button type="button" className="btn btn-info btn-large ml-4">
            Nuevo
          </button>
        </Link>

        <RadioButton
          style={{ marginLeft: "1em" }}
          value="val1"
          name="banco"
          onChange={(e) => guardarRadioBanco(e.value)}
          checked={radioBanco === "val1"}
        />

        <label style={{ color: "black", marginLeft: "0.5em" }}>Fecha</label>
        <Calendar
          locale={es}
          dateFormat="dd/mm/yy"
          style={{ marginLeft: "1em", width: "20em" }}
          value={dateBanco}
          onChange={(e) => {
            guardarDateBanco(e.value);
            guardarRangeBanco(null);
          }}
          disabled={radioBanco === "val2"}
        ></Calendar>

        <RadioButton
          style={{ marginLeft: "0em" }}
          value="val2"
          name="banco"
          onChange={(e) => guardarRadioBanco(e.value)}
          checked={radioBanco === "val2"}
        />

        <label style={{ color: "black", marginLeft: "0.5em" }}>Rango</label>
        <Calendar
          selectionMode="range"
          inputStyle={{ width: "15em" }}
          locale={es}
          dateFormat="dd/mm/yy"
          style={{ marginLeft: "1em", width: "20em" }}
          value={rangeBanco}
          onChange={(e) => {
            guardarRangeBanco(e.value);
            guardarDateBanco(null);
          }}
          disabled={radioBanco === "val1"}
        ></Calendar>

        <button
          type="button"
          className="btn btn-info btn-large ml-0"
          onClick={actualizar}
        >
          Actualizar
        </button>

        <div className="row">
          <label
            style={{ color: "black", marginLeft: "2.5em", marginTop: "1.5em" }}
          >
            Estado
          </label>
          <Dropdown
            id="estado"
            name="estado"
            value={estadoBanco}
            style={shortStyles}
            className="mt-3 ml-3"
            options={estadosBancos}
            onChange={(e) => {
              guardarEstadoBanco(e.target.value);
              obtenerMovimientosBancos({
                idEmpresa: empresaNavbar,
                fechaBanco: dateBanco,
                rangeBanco: rangeBanco,
                idCuenta: cuentaId,
                estado: e.target.value,
              });
            }}
            filter={false}
          />

          <label
            style={{ color: "black", marginLeft: "2.5em", marginTop: "1.5em" }}
          >
            Cuenta
          </label>
          <Dropdown
            id="cuenta"
            name="cuenta"
            value={cuentaId}
            style={shortStyles}
            className="mt-3 ml-3"
            options={cuentas}
            onChange={(e) => {
              guardarCuentaId(e.target.value);
              obtenerMovimientosBancos({
                idEmpresa: empresaNavbar,
                fechaBanco: dateBanco,
                rangeBanco: rangeBanco,
                idCuenta: e.target.value,
                estado: estadoBanco,
              });
            }}
            filter={false}
          />
        </div>
      </div>
    </Fragment>
  );

  const rowHabilitado = (rowData, column) => {
    if (rowData.habilitado === true || rowData.habilitado === "Si") {
      return (rowData.habilitado = "Si");
    }
    if (rowData.habilitado === false || rowData.habilitado === "No") {
      return (rowData.habilitado = "No");
    }
  };

  const rowFecha = (rowData, column) => {
    return tranformDate(rowData.fecha);
  };

  const rowIngreso = (rowData, column) => {
    return parseFloat(rowData.ingreso).toFixed(2);
  };

  const rowEgreso = (rowData, column) => {
    return parseFloat(rowData.egreso).toFixed(2);
  };

  const botonVer = (rowData, column) => {
    return (
      <div>
        <Link to={`movimientobanco/${rowData.id}`}>
          <button type="button" className="btn btn-success btn-block">
            Ver
          </button>
        </Link>
      </div>
    );
  };

  let rCount = movimientosBancos ? movimientosBancos.length : 0;
  let footer = "Total " + rCount + " movimientos";

  console.log(movimientosBancos);

  return (
    <Fragment>
      {!movimientosBancos ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <div className="mlpc-table">
                  <DataTable
                    ref={(el) => {
                      dt = el;
                    }}
                    value={movimientosBancos}
                    paginator={true}
                    rows={10}
                    header={header}
                    footer={footer}
                    globalFilter={globalFilter}
                    scrollable={false}
                    responsive={true}
                    resizableColumns={true}
                  >
                    <Column
                      field="id"
                      header="ID de Movimiento de Banco"
                      sortable={true}
                    />
                    <Column
                      field="fecha"
                      header="Fecha"
                      sortable={true}
                      body={rowFecha}
                    />
                    <Column
                      field="detalle"
                      header="Detalle"
                      sortable={true}
                      style={{ width: "20%" }}
                    />
                    <Column
                      field="cuenta.numero"
                      header="Cuenta"
                      sortable={true}
                      style={{ width: "20%" }}
                    />
                    <Column
                      field="estado"
                      header="Estado"
                      sortable={true}
                      style={{ width: "10%" }}
                    />
                    <Column
                      field="ingreso"
                      header="Ingreso"
                      sortable={true}
                      style={{ textAlign: "right" }}
                      body={rowIngreso}
                    />
                    <Column
                      field="egreso"
                      header="Egreso"
                      sortable={true}
                      style={{ textAlign: "right" }}
                      body={rowEgreso}
                    />
                    <Column field="botones" header="" body={botonVer} />
                  </DataTable>{" "}
                </div>
                <div style={{ textAlign: "right" }}>
                  Saldo Anterior
                  <span className="badge badge-secondary ml-2 mr-2">
                    {"$ " + parseFloat(saldoAnterior).toFixed(2)}
                  </span>
                </div>
                <div style={{ textAlign: "right" }}>
                  Total ingresos
                  <span className="badge badge-secondary ml-2 mr-2">
                    {"$ " + parseFloat(totalIngresos).toFixed(2)}
                  </span>
                  Total egresos
                  <span className="badge badge-secondary ml-2 mr-2">
                    {"$ " + parseFloat(totalEgresos).toFixed(2)}
                  </span>
                </div>
                <div style={{ textAlign: "right" }}>
                  Saldo actual
                  <span className="badge badge-secondary ml-2 mr-2">
                    {"$ " +
                      parseFloat(
                        Number(saldoAnterior) +
                          Number(totalIngresos) -
                          Number(totalEgresos)
                      ).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default MovimientosBancos;
