import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ArchivoContext } from "../../../context/ArchivoContext";

function Empresas(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const { empresas, empresa, obtenerEmpresas, guardarEmpresa } = archivoContext;

  const [globalFilter, guardarGlobalFilter] = useState(null);

  let dt = useRef();

  useEffect(() => {
    obtenerEmpresas();
  }, []);

  const cerrar = () => {
    props.history.push("/menu");
  };

  let header = (
    <Fragment>
      <button
        type="button"
        className="close xclose"
        aria-label="Close"
        onClick={cerrar}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <h3 className="fwhite titulos text-center py=4">Empresas</h3>
      <hr className="linea-negra" />
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => guardarGlobalFilter(e.target.value)}
          placeholder="Buscador"
          size="50"
        />

        <Link to={`empresa/0`}>
          <button type="button" className="btn btn-info btn-large ml-4">
            Nuevo
          </button>
        </Link>
      </div>
    </Fragment>
  );

  const rowHabilitado = (rowData, column) => {
    if (rowData.habilitado === true || rowData.habilitado === "Si") {
      return (rowData.habilitado = "Si");
    }
    if (rowData.habilitado === false || rowData.habilitado === "No") {
      return (rowData.habilitado = "No");
    }
  };

  const botonVer = (rowData, column) => {
    return (
      <div>
        <Link to={`empresa/${rowData.id}`}>
          <button
            type="button"
            className="btn btn-success btn-block"
          >
            Ver
          </button>
        </Link>
      </div>
    );
  };


  let rCount = empresas ? empresas.length : 0;
  let footer = "Total " + rCount + " empresas";

  return (
    <div className="background-body">
      <div className="row justify-content-center">
        <div className="col-xs-10 col-md-10 mt-3">
          <div className="card-body text-black background mb-3">
            <div className="mlpc-table">
              <DataTable
                ref={(el) => {
                  dt = el;
                }}
                value={empresas}
                paginator={true}
                rows={10}
                header={header}
                footer={footer}
                globalFilter={globalFilter}
                scrollable={false}
                responsive={true}
                resizableColumns={true}

              >
                {/*}
            <Column
              field="descripcion"
              header="Descripción"
              sortable={true}
              filter={true}
              filterPlaceholder="Buscar"
            />
            */}
                <Column
                  field="id"
                  header="ID de Empresa"
                  sortable={true}
                />
                <Column
                  field="cuit"
                  header="C.U.I.T"
                  sortable={true}
                />
                <Column
                  field="nombre"
                  header="Razón Social"
                  sortable={true}
                />
                <Column
                  field="habilitado"
                  header="Habilitado"
                  sortable={true}
                  body={rowHabilitado}
                  style={{textAlign: "center"}}
                />
                <Column
                  field="botones"
                  header=""
                  body={botonVer}
                />
              </DataTable>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Empresas;
