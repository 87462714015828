import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { VentaContext } from "../../../context/VentaContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import MaterialTable from "material-table";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

function Cliente(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    cliente,
    empresa,
    vendedores,
    fletes,
    impuestos,
    listasPrecios,
    provincias,
    tiposComprobantes,
    condicionesIva,
    condicionesVenta,
    obtenerCliente,
    guardarCliente,
    obtenerLocalidadesSelect,
    obtenerVendedoresSelect,
    obtenerFletesSelect,
    crearCliente,
    actualizarCliente,
    guardarImpuestos,
    obtenerImpuestosCliente,
    obtenerListasPreciosSelect,
    obtenerProvinciasSelect,
    obtenerCondicionesIvaSelect,
    obtenerCondicionesVentaSelect,
    obtenerTiposComprobantesEmpresaAjuste,
    guardarTiposComprobantes,
    obtenerEmpresa,
  } = archivoContext;

  const ventaContext = useContext(VentaContext);
  const {
    ajuste,
    comprobante,
    ctacte,
    saldoCtaCte,
    articulosComprobante,
    guardarAjuste,
    guardarComprobante,
    guardarArticulosComprobante,
    crearComprobante,
    obtenerCtaCteCliente,
    tranformDate,
    guardarSaldoCtaCte,
  } = ventaContext;

  const [modalAjuste, setModalAjuste] = useState(false);
  const [modalCtaCte, setModalCtaCte] = useState(false);
  const [todas, setTodas] = useState(false);

  let growl = useRef();

  const idCliente = props.match.params.id;

  useEffect(() => {
    if (empresaNavbar) {
      if (idCliente !== "0") {
        obtenerCliente({ idCliente });
      } else {
        guardarCliente({
          nombre: "",
          cuit: "",
          domicilio: "",
          entre1: "",
          entre2: "",
          telefono: "",
          localidad: "",
          cp: 0,
          idProvincia: 0,
          idVendedor: 0,
          idFlete: 0,
          idListaPrecio: 0,
          descuento: 0,
          cbu: "",
          idCondicionVenta: 0,
          idCondicionIva: 0,
        });
      }
      obtenerEmpresa({ idEmpresa: empresaNavbar });
      obtenerLocalidadesSelect();
      obtenerProvinciasSelect();
      obtenerVendedoresSelect();
      obtenerFletesSelect();
      obtenerListasPreciosSelect();
      obtenerCondicionesIvaSelect();
      obtenerCondicionesVentaSelect();
      obtenerImpuestosCliente({ id: idCliente, empresa: null });
      obtenerEmpresa({ idEmpresa: empresaNavbar });
      guardarComprobante(null);
      guardarImpuestos(null);
      guardarArticulosComprobante(null);
      guardarTiposComprobantes(null);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (cliente.nombre !== null && cliente.nombre.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Clientes",
        detail: "El nombre o razón social es necesario.",
      });
      return;
    }

    if (cliente.idCondicionIva === 0) {
      growl.show({
        severity: "error",
        summary: "Clientes",
        detail: "La condición de I.V.A. es necesaria.",
      });
      return;
    }

    if (cliente.cuit !== null && cliente.cuit.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Clientes",
        detail: "El C.U.I.T. es necesario.",
      });
      return;
    }

    if (cliente.idProvincia === 0) {
      growl.show({
        severity: "error",
        summary: "Clientes",
        detail: "La provincia es necesaria.",
      });
      return;
    }

    if (cliente.idVendedor === 0) {
      growl.show({
        severity: "error",
        summary: "Clientes",
        detail: "El vendedor es necesario.",
      });
      return;
    }

    if (cliente.idListaPrecio === 0) {
      growl.show({
        severity: "error",
        summary: "Clientes",
        detail: "La lista de precio es necesaria.",
      });
      return;
    }

    if (cliente.idCondicionVenta === 0) {
      growl.show({
        severity: "error",
        summary: "Clientes",
        detail: "La condición de venta es necesaria.",
      });
      return;
    }

    if (idCliente === "0") {
      crearCliente(cliente, impuestos);
    } else {
      actualizarCliente(cliente, impuestos);
    }
  };

  const cerrar = () => {
    props.history.push("/clientes");
  };

  const onClickAjuste = () => {
    crearComprobante(
      empresa,
      comprobante,
      cliente,
      impuestos,
      articulosComprobante
    );
  };

  if (
    tiposComprobantes &&
    cliente &&
    (!comprobante || comprobante.numero !== tiposComprobantes.numero)
  ) {
    guardarComprobante({
      ...comprobante,
      fecha: new Date(),
      comprobante: tiposComprobantes.tiposcomprobantes.nombre,
      abreviatura: tiposComprobantes.tiposcomprobantes.abreviatura,
      tipo: tiposComprobantes.tiposcomprobantes.tipo,
      puntoVenta: tiposComprobantes.puntoVenta,
      numero: tiposComprobantes.numero,
      electronica: false,
      clienteId: cliente.id,
      nombreCliente: cliente.nombre,
      cuitCliente: cliente.cuit,
      cbuCliente: cliente.cbu,
      vendedorId: cliente.idVendedor,
      fleteId: cliente.idFlete,
      subtotal: 0,
      descuento: 0,
      neto: 0,
      iva: 0,
      percepcion: 0,
      total: 0,
      exento: 0,
      tipoComprobanteId: tiposComprobantes.tiposcomprobanteId,
      tipoComprobanteIdAfip: tiposComprobantes.tiposcomprobantes.codigoAfip,
      condicionVentaId: 0,
      condicionIvaId: 0,
      listaPrecioId: 0,
      empresaId: empresaNavbar,
      anulado: false,
      cae: "",
      vtocae: null,
      tipoComprobanteIdAsociado: 0,
      tipoComprobanteIdAsociadoAfip: 0,
      puntoVentaAsociado: 0,
      numeroAsociado: 0,
      fechaAsociado: null,
      remito: false,
      puntoVentaRemito: 0,
      numeroRemito: 0,
      tipoComprobanteRemitoId: 0,
      saldo: 0,
      fechaVencimiento: null,
      comentarios: "",
    });

    guardarCliente({
      ...cliente,
      domicilioCompleto: `${cliente.domicilio} ${cliente.cp} ${cliente.localidad}`,
    });
  }

  //console.log(cliente);
  //console.log(empresaNavbar);

  return (
    <Fragment>
      {!cliente || !impuestos ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                  style={{ color: "Blue" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {cliente.nombre}
                </h3>

                <Dialog
                  header="Ajuste de Cuenta Corriente"
                  visible={modalAjuste}
                  style={{ width: "50vw" }}
                  modal={true}
                  onHide={() => setModalAjuste(false)}
                >
                  <div className="form-group">
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <KeyboardDatePicker
                        clearable
                        label="Fecha"
                        value={comprobante ? comprobante.fecha : new Date()}
                        onChange={(date) =>
                          guardarComprobante({
                            ...comprobante,
                            fecha: date,
                          })
                        }
                        format="dd/MM/yyyy"
                        style={{ color: "blue" }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="form-group">
                    <span className="p-float-label">
                      <InputText
                        id="ajuste"
                        type="number"
                        className="form-control mt-4"
                        style={{ width: "30%" }}
                        value={comprobante ? comprobante.subtotal : 0}
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            subtotal: e.target.value,
                            neto: e.target.value,
                            total: e.target.value,
                            saldo: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="ajuste" style={{ color: "black" }}>
                        Ajuste (a favor del cliente en negativo)
                      </label>
                    </span>
                  </div>

                  <div className="form-group">
                    <span className="p-float-label">
                      <InputText
                        id="comentarios"
                        type="text"
                        className="form-control mt-4"
                        value={comprobante ? comprobante.comentarios : ""}
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            comentarios: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="comentarios" style={{ color: "black" }}>
                        Detalle
                      </label>
                    </span>
                  </div>

                  <input
                    type="button"
                    className="btn btn-background btn-block mt-4"
                    value="Guardar ajuste"
                    onClick={onClickAjuste}
                  />
                </Dialog>

                <Dialog
                  header="Cuenta Corriente"
                  visible={modalCtaCte}
                  style={{ fontSize: "small", width: "50vw" }}
                  modal={true}
                  onHide={() => setModalCtaCte(false)}
                >
                  {ctacte ? (
                    <div style={{ maxWidth: "100%" }}>
                      <MaterialTable
                        columns={[
                          {
                            title: "Fecha",
                            field: "fecha",
                            render: (row) => (
                              <span>
                                {
                                  tranformDate(
                                    row["fecha"]
                                  ) /*formatting code here*/
                                }
                              </span>
                            ),
                            editable: "never",
                          },
                          {
                            title: "Comprobante",
                            field: "comprobante",
                            editable: "never",
                            cellStyle: {
                              width: 400,
                              minWidth: 400,
                            },
                          },
                          {
                            title: "Debe",
                            field: "debe",
                            type: "numeric",
                            editable: "never",
                          },
                          {
                            title: "Haber",
                            field: "haber",
                            type: "numeric",
                            editable: "never",
                          },
                          {
                            title: "Saldo",
                            field: "saldo",
                            type: "numeric",
                            editable: "never",
                          },
                        ]}
                        data={ctacte}
                        title={
                          ctacte
                            ? `Saldo: ${parseFloat(saldoCtaCte).toFixed(2)}`
                            : null
                        }
                        options={{
                          search: false,
                          sorting: true,
                          actionsColumnIndex: -1,
                          showTitle: true,
                          toolbar: true,
                        }}
                        localization={{
                          toolbar: {
                            searchPlaceholder: "Buscar",
                          },
                          header: {
                            actions: "Habilitado",
                          },
                          body: {
                            emptyDataSourceMessage: "No hay registros",
                          },
                          pagination: {
                            labelRowsSelect: "filas",
                          },
                        }}
                      />
                    </div>
                  ) : null}
                </Dialog>

                {idCliente !== "0" && empresaNavbar !== 0 ? (
                  <Fragment>
                    <Button
                      label="Ajustes"
                      icon="fa fa-sort"
                      onClick={(e) => {
                        obtenerTiposComprobantesEmpresaAjuste({
                          idEmpresa: empresaNavbar,
                        });
                        setModalAjuste(true);
                      }}
                    />

                    <Button
                      label="Cuenta Corriente"
                      icon="fa fa-exchange"
                      style={{ marginLeft: "1em" }}
                      onClick={(e) => {
                        obtenerCtaCteCliente({
                          idCliente: idCliente,
                          idEmpresa: empresaNavbar,
                          todas,
                        });
                        setModalCtaCte(true);
                      }}
                    />
                    <div
                      className="custom-control custom-switch"
                      style={{ float: "right" }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch1"
                        onClick={(e) => {
                          setTodas(e.target.checked);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        for="customSwitch1"
                      >
                        Todas
                      </label>
                    </div>
                  </Fragment>
                ) : null}
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <Tabs>
                    <TabList>
                      <Tab>Datos Generales</Tab>
                      <Tab>Percepciones</Tab>
                    </TabList>

                    <TabPanel>
                      <div class="row">
                        <div className="col-xs-12 col-md-6">
                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="nombre"
                                type="text"
                                className="form-control mt-4"
                                value={cliente.nombre}
                                onChange={(e) =>
                                  guardarCliente({
                                    ...cliente,
                                    nombre: e.target.value.toUpperCase(),
                                  })
                                }
                              />
                              <label
                                htmlFor="nombre"
                                style={{ color: "black" }}
                              >
                                Nombre de la cliente
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="condicioniva"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Condición de I.V.A.
                              </label>
                            </span>
                            <Dropdown
                              id="condicioniva"
                              value={cliente.idCondicionIva}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={condicionesIva}
                              onChange={(e) =>
                                guardarCliente({
                                  ...cliente,
                                  idCondicionIva: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná la condición de I.V.A."
                            />
                          </div>

                          {cliente.idCondicionIva !== 5 ? (
                            <div className="form-group">
                              <span className="p-float-label">
                                <InputMask
                                  mask="99-99999999-9"
                                  id="cuit"
                                  type="text"
                                  className="form-control mt-4"
                                  value={cliente.cuit}
                                  onChange={(e) =>
                                    guardarCliente({
                                      ...cliente,
                                      cuit: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  htmlFor="cuit"
                                  style={{ color: "black" }}
                                >
                                  C.U.I.T
                                </label>
                              </span>
                            </div>
                          ) : (
                            <div className="form-group">
                              <span className="p-float-label">
                                <InputMask
                                  mask="99999999"
                                  id="cuit"
                                  type="text"
                                  className="form-control mt-4"
                                  value={cliente.cuit}
                                  onChange={(e) =>
                                    guardarCliente({
                                      ...cliente,
                                      cuit: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  htmlFor="cuit"
                                  style={{ color: "black" }}
                                >
                                  D.N.I.
                                </label>
                              </span>
                            </div>
                          )}

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="domicilio"
                                type="text"
                                className="form-control mt-4"
                                value={cliente.domicilio}
                                onChange={(e) =>
                                  guardarCliente({
                                    ...cliente,
                                    domicilio: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="domicilio"
                                style={{ color: "black" }}
                              >
                                Domicilio
                              </label>
                            </span>
                          </div>
                          <div className="row">
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <span className="p-float-label">
                                  <InputText
                                    id="entre1"
                                    type="text"
                                    className="form-control mt-4"
                                    value={cliente.entre1}
                                    onChange={(e) =>
                                      guardarCliente({
                                        ...cliente,
                                        entre1: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="entre1"
                                    style={{ color: "black" }}
                                  >
                                    Entre
                                  </label>
                                </span>
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <span className="p-float-label">
                                  <InputText
                                    id="entre2"
                                    type="text"
                                    className="form-control mt-4"
                                    value={cliente.entre2}
                                    onChange={(e) =>
                                      guardarCliente({
                                        ...cliente,
                                        entre2: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="entre2"
                                    style={{ color: "black" }}
                                  >
                                    Entre
                                  </label>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="localidad"
                                type="text"
                                className="form-control mt-4"
                                value={cliente.localidad}
                                onChange={(e) =>
                                  guardarCliente({
                                    ...cliente,
                                    localidad: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="localidad"
                                style={{ color: "black" }}
                              >
                                Localidad
                              </label>
                            </span>
                          </div>
                        </div>

                        <div className="col-xs-12 col-md-6">
                          <div class="row">
                            <div className="col-xs-12 col-md-4">
                              <div className="form-group">
                                <span className="p-float-label">
                                  <InputText
                                    id="cp"
                                    type="text"
                                    className="form-control mt-4"
                                    value={cliente.cp}
                                    onChange={(e) =>
                                      guardarCliente({
                                        ...cliente,
                                        cp: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="cp"
                                    style={{ color: "black" }}
                                  >
                                    Cód. Postal
                                  </label>
                                </span>
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-8">
                              <div className="form-group">
                                <span className="p-float-label">
                                  <InputText
                                    id="telefono"
                                    type="text"
                                    className="form-control mt-4"
                                    value={cliente.telefono}
                                    onChange={(e) =>
                                      guardarCliente({
                                        ...cliente,
                                        telefono: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="telefono"
                                    style={{ color: "black" }}
                                  >
                                    Teléfono
                                  </label>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="provincia"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Provincia
                              </label>
                            </span>
                            <Dropdown
                              id="provincia"
                              value={cliente.idProvincia}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={provincias}
                              onChange={(e) =>
                                guardarCliente({
                                  ...cliente,
                                  idProvincia: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná una provincia"
                            />
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="vendedor"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Vendedor
                              </label>
                            </span>
                            <Dropdown
                              id="vendedor"
                              value={cliente.idVendedor}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={vendedores}
                              onChange={(e) =>
                                guardarCliente({
                                  ...cliente,
                                  idVendedor: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná un vendedor"
                            />
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="flete"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Flete
                              </label>
                            </span>
                            <Dropdown
                              id="flete"
                              value={cliente.idFlete}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={fletes}
                              onChange={(e) =>
                                guardarCliente({
                                  ...cliente,
                                  idFlete: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná un flete"
                            />
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="listaprecio"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Lista de precio
                              </label>
                            </span>
                            <Dropdown
                              id="listaprecio"
                              value={cliente.idListaPrecio}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={listasPrecios}
                              onChange={(e) =>
                                guardarCliente({
                                  ...cliente,
                                  idListaPrecio: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná una lista de precios"
                            />
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="idCondicionVenta"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Condición de venta
                              </label>
                            </span>
                            <Dropdown
                              id="idCondicionVenta"
                              value={cliente.idCondicionVenta}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={condicionesVenta}
                              onChange={(e) =>
                                guardarCliente({
                                  ...cliente,
                                  idCondicionVenta: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná una condición de venta"
                            />
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="descuento"
                                type="text"
                                style={{ width: "30%" }}
                                className="form-control mt-4"
                                value={cliente.descuento}
                                onChange={(e) =>
                                  guardarCliente({
                                    ...cliente,
                                    descuento: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="descuento"
                                style={{ color: "black" }}
                              >
                                Descuento
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="cbu"
                                type="number"
                                className="form-control mt-4"
                                value={cliente.cbu}
                                onChange={(e) =>
                                  guardarCliente({
                                    ...cliente,
                                    cbu: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="cbu" style={{ color: "black" }}>
                                C.B.U.
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                          columns={[
                            {
                              title: "Nombre del impuesto",
                              field: "nombre",
                              editable: "never",
                            },
                            {
                              title: "Alícuota",
                              field: "alicuota",
                              editable: "onUpdate",
                              type: "numeric",
                            },
                          ]}
                          data={impuestos}
                          title="Impuestos"
                          options={{
                            search: true,
                            sorting: true,
                            actionsColumnIndex: -1,
                          }}
                          localization={{
                            toolbar: {
                              searchPlaceholder: "Buscar",
                            },
                            header: {
                              actions: "Habilitado",
                            },
                            body: {
                              emptyDataSourceMessage: "No hay registros",
                            },
                            pagination: {
                              labelRowsSelect: "filas",
                            },
                          }}
                          editable={{
                            isEditable: (rowData) => rowData, // only name(a) rows would be editable
                            onRowAddCancelled: (rowData) =>
                              console.log("Row adding cancelled"),
                            onRowUpdateCancelled: (rowData) =>
                              console.log("Row editing cancelled"),
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  const dataUpdate = [...impuestos];
                                  const index = oldData.id;
                                  dataUpdate[index] = newData;
                                  guardarImpuestos([...dataUpdate]);

                                  resolve();
                                }, 1000);
                              }),
                          }}
                        />
                      </div>
                    </TabPanel>

                    <input
                      type="submit"
                      className="btn btn-background btn-block mt-4"
                      value="Guardar cliente"
                    />
                  </Tabs>
                  <br />
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Cliente;
