import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const RutaPrivada = ({ component: Component, ...props  }) => {

    const authContext = useContext(AuthContext);
    const { autenticado,  usuarioAutenticado } = authContext;
    const [delay, setDelay] = useState(false);


    useEffect(() => {
        usuarioAutenticado();
        /*
        setTimeout(function() {
            setDelay(true);
        }, 100);
        */
    }, []);


    return ( 
        <Route { ...props } render={ props => !autenticado && autenticado!==null ?  (
            <Redirect to="/" />
        )  : (
            <Component {...props} />
        ) } />

     );
}
 
export default RutaPrivada;