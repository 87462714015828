import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "primereact/button";
import MaterialTable from "material-table";

function ListaPrecio(props) {
  // extraer los valores del context

  const [data, setData] = useState(null);

  const archivoContext = useContext(ArchivoContext);
  const {
    listaPrecio,
    articulos,
    obtenerListaPrecio,
    guardarListaPrecio,
    crearListaPrecio,
    actualizarListaPrecio,
    obtenerArticulosListasPrecios,
    guardarArticulos,
  } = archivoContext;

  let growl = useRef();

  const idListaPrecio = props.match.params.id;

  useEffect(() => {
    if (idListaPrecio !== "0") {
      obtenerListaPrecio({ idListaPrecio });
    } else {
      guardarListaPrecio({
        nombre: "",
        habilitado: true,
      });
    }
    obtenerArticulosListasPrecios(idListaPrecio);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (listaPrecio.nombre !== null && listaPrecio.nombre.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Listas de precios",
        detail: "El nombre de la lista de precio es obligatorio.",
      });
      return;
    }

    if (idListaPrecio === "0") {
      crearListaPrecio(listaPrecio, articulos);
    } else {
      actualizarListaPrecio(listaPrecio, articulos);
    }
  };

  const cerrar = () => {
    props.history.push("/listasprecios");
  };


  return (
    <Fragment>
      {!listaPrecio || !articulos ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {listaPrecio.nombre}
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <Tabs>
                    <TabList>
                      <Tab>Datos Generales</Tab>
                      <Tab>Artículos</Tab>
                    </TabList>

                    <TabPanel>
                      <div className="row">
                        <div className="col-xs-12 col-md-6">
                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="nombre"
                                type="text"
                                className="form-control mt-4"
                                value={listaPrecio.nombre}
                                onChange={(e) =>
                                  guardarListaPrecio({
                                    ...listaPrecio,
                                    nombre: e.target.value.toUpperCase(),
                                  })
                                }
                              />
                              <label
                                htmlFor="nombre"
                                style={{ color: "black" }}
                              >
                                Nombre
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="habilitado"
                              style={{ color: "black", marginRight: "1em" }}
                            >
                            </label>
                            <ToggleButton
                              onLabel="Si"
                              offLabel="No"
                              onIcon="pi pi-check"
                              offIcon="pi pi-times"
                              checked={listaPrecio.habilitado}
                              onChange={(e) =>
                                guardarListaPrecio({
                                  ...listaPrecio,
                                  habilitado: e.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                          columns={[
                            {
                              title: "ID del artículo",
                              field: "articuloId",
                              editable: "never",
                            },
                            {
                              title: "Nombre del artículo",
                              field: "nombre",
                              editable: "never",
                            },
                            {
                              title: "Precio",
                              field: "precio",
                              editable: "onUpdate",
                              type: 'numeric'
                            },
                            {
                              title: "I.V.A.",
                              field: "ivaId",
                              editable: "onUpdate",
                              type: 'numeric',
                              lookup: { 3: 'IVA 21%', 4: 'IVA 10.5%', 5: 'IVA 0%', 6: 'IVA 27%' },
                            },
                          ]}
                          data={articulos}
                          title="Artículos"
                          options={{
                            search: true,
                            sorting: true,
                            actionsColumnIndex: -1

                          }}
                          localization={{
                            toolbar: {
                              searchPlaceholder: "Buscar",
                            },
                            header: {
                              actions: "Acción",
                            },
                            body: {
                              editTooltip: "Editar",
                              editRow: { 
                                cancelTooltip: "Cancelar",
                                saveTooltip: "Guardar"
                              }    
                            } 
                          }}
                          editable={{
                            isEditable: (rowData) => rowData, // only name(a) rows would be editable
                            onRowAddCancelled: (rowData) =>
                              console.log("Row adding cancelled"),
                            onRowUpdateCancelled: (rowData) =>
                              console.log("Row editing cancelled"),
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  const dataUpdate = [...articulos];
                                  const index = oldData.id;
                                  dataUpdate[index] = newData;
                                  guardarArticulos([...dataUpdate]);

                                  resolve();
                                }, 1000);
                              }),
                          }}
                        />
                      </div>
                    </TabPanel>

                    <input
                      type="submit"
                      className="btn btn-background btn-block mt-4"
                      value="Guardar lista de precio"
                    />
                  </Tabs>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ListaPrecio;
