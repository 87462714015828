import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { CompraContext } from "../../../context/CompraContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "primereact/button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import MaterialTable from "material-table";
import Icon from "@material-ui/core/Icon";
import { Dialog } from "primereact/dialog";
import jsPDF from "jspdf";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  grid: {
    marginRight: "0.5em",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },

  marco: {
    marginLeft: 1,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },

  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },

  text: {
    "& > *": {
      marginLeft: theme.spacing(2),
      width: "23ch",
    },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
    },
    [theme.breakpoints.up("sm")]: {
      visibility: "hidden",
    },
  },
}));

function ComprobanteCompras(props) {
  const classes = useStyles();
  // extraer los valores del context
  const archivoContext = useContext(ArchivoContext);
  const {
    impuestos,
    empresaNavbar,
    empresa,
    articulos,
    proveedores,
    listasPrecios,
    condicionesIva,
    obtenerEmpresa,
    tiposComprobantes,
    obtenerTiposComprobantesComprasEmpresaSelect,
    obtenerListasPreciosSelect,
    obtenerArticulosCompras,
    guardarArticulos,
    obtenerImpuestosProveedor,
    obtenerCondicionesIvaSelect,
    guardarTiposComprobantes,
    obtenerProveedores,
  } = archivoContext;

  const compraContext = useContext(CompraContext);
  const {
    comprobante,
    proveedor,
    facturaAsociada,
    verComprobante,
    condicionesVentas,
    articulosComprobante,
    carga,
    obtenerComprobante,
    guardarComprobante,
    guardarProveedor,
    crearComprobante,
    obtenerProveedorxId,
    obtenerProveedorxIdComprobante,
    obtenerCondicionesVentasSelect,
    obtenerComprobanteNro,
    guardarFacturaAsociada,
    guardarVerComprobante,
    guardarArticulosComprobante,
    obtenerArticulosxIdComprobante,
    guardarCarga,
    obtenerArticuloxId,
  } = compraContext;

  const [modalArticulos, setModalArticulos] = useState(false);
  const [modalProveedores, setModalProveedores] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [delay, setDelay] = useState(false);

  let growl = useRef();

  const ivas = [
    { value: 3, label: "IVA 0%" },
    { value: 4, label: "IVA 10.5%" },
    { value: 5, label: "IVA 21%" },
    { value: 6, label: "IVA 27%" },
  ];

  const idComprobante = props.match.params.id;
  const tipoComprobanteNuevo = props.match.params.tipo;

  useEffect(() => {
    if (empresaNavbar) {
      if (idComprobante !== "0") {
        obtenerComprobante({ idComprobante });
        obtenerProveedorxIdComprobante({ idComprobante });
        guardarTiposComprobantes(null);
        obtenerEmpresa({ idEmpresa: empresaNavbar });
        obtenerTiposComprobantesComprasEmpresaSelect({
          idEmpresa: empresaNavbar,
          tipo: tipoComprobanteNuevo,
        });
        obtenerCondicionesVentasSelect();
        obtenerCondicionesIvaSelect();
        obtenerArticulosxIdComprobante({ idComprobante });
        guardarVerComprobante({
          ...verComprobante,
          estado: true,
        });
        setTimeout(function () {
          setDelay(true);
        }, 1000);
      } else {
        guardarTiposComprobantes(null);
        obtenerEmpresa({ idEmpresa: empresaNavbar });
        obtenerTiposComprobantesComprasEmpresaSelect({
          idEmpresa: empresaNavbar,
          tipo: tipoComprobanteNuevo,
        });
        obtenerCondicionesVentasSelect();
        obtenerCondicionesIvaSelect();
        guardarComprobante({
          id: null,
          fecha: "",
          comprobante: "",
          abreviatura: "",
          tipo: "",
          puntoVenta: 0,
          numero: 0,
          proveedorId: "",
          nombreProveedor: "",
          cuitProveedor: "",
          subtotal: 0,
          descuento: 0,
          neto: 0,
          iva: 0,
          total: 0,
          exento: 0,
          tipoComprobanteId: 0,
          tipoComprobanteIdAfip: 0,
          condicionVentaId: 0,
          condicionIvaId: 0,
          empresaId: 0,
          anulado: false,
          remito: false,
          puntoVentaRemito: 0,
          numeroRemito: 0,
          tipoComprobanteRemitoId: 0,
          saldo: parseFloat(0).toFixed(2),
          comentarios: null,
          periodo: periodoActual(),
        });

        guardarProveedor({
          id: "",
          nombre: "",
          cuit: "",
          domicilio: "",
          cp: "",
          localidad: "",
          email: "",
          idCondicionIva: "",
          descuento: "",
          domicilioCompleto: "",
        });

        setTimeout(function () {
          setDelay(true);
        }, 1000);
      }
    }
  }, []);

  const generarFactura = async (e) => {
    e.preventDefault();

    //Validar

    if (proveedor.id === null || proveedor.id === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El ID de Proveedor es obligatorio.",
      });
      return;
    }

    if (
      proveedor.idCondicionIva !== 5 &&
      (proveedor.cuit === null ||
        proveedor.cuit === "" ||
        proveedor.cuit.length !== 13)
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "C.U.I.T. de Proveedor es inválido.",
      });
      return;
    }

    if (
      proveedor.idCondicionIva === 5 &&
      (proveedor.cuit === null || proveedor.cuit === "")
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "D.N.I. de Proveedor es inválido.",
      });
      return;
    }

    if (
      proveedor.idCondicionIva !== 1 &&
      (comprobante.abreviatura === "FCEA" ||
        comprobante.abreviatura === "FCEB" ||
        comprobante.abreviatura === "FCEC" ||
        comprobante.abreviatura === "NDEA" ||
        comprobante.abreviatura === "NDEB" ||
        comprobante.abreviatura === "NDEC" ||
        comprobante.abreviatura === "NCEA" ||
        comprobante.abreviatura === "NCEB" ||
        comprobante.abreviatura === "NCEC") &&
      (proveedor.cuit === null ||
        proveedor.cuit === "" ||
        proveedor.cuit.length !== 13)
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "C.U.I.T. de Proveedor es inválido.",
      });
      return;
    }

    if (proveedor.nombre === null || proveedor.nombre === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El nombre es inválido.",
      });
      return;
    }

    if (proveedor.idCondicionIva === null || proveedor.idCondicionIva === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La condición de iva es inválida.",
      });
      return;
    }

    if (
      proveedor.domicilioCompleto === null ||
      proveedor.domicilioCompleto === ""
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El domicilio es inválido.",
      });
      return;
    }

    if (comprobante.fecha === null || comprobante.fecha === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La fecha es inválida.",
      });
      return;
    }

    if (!validateMMYY(comprobante.periodo)) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El período es inválido.",
      });
      return;
    }

    if (comprobante.comprobante === null || comprobante.comprobante === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El comprobante es inválido.",
      });
      return;
    }

    if (comprobante.tipo === null || comprobante.tipo === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El tipo de comprobante es inválido.",
      });
      return;
    }

    if (comprobante.puntoVenta === null || comprobante.puntoVenta === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El punto de venta del comprobante es inválido.",
      });
      return;
    }

    if (comprobante.numero === null || comprobante.numero === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El número del comprobante es inválido.",
      });
      return;
    }

    if (
      proveedor.condicionVentaId === null ||
      proveedor.condicionVentaId === ""
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La condición de venta es inválida.",
      });
      return;
    }

    if (
      proveedor.idCondicionIva === 1 &&
      comprobante.tipo !== "A" &&
      comprobante.tipo !== "M" &&
      comprobante.tipo !== "X"
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail:
          "El comprobante no corresponde con la condición de iva del proveedor.",
      });
      return;
    }

    if (proveedor.idCondicionIva !== 1 && comprobante.tipo !== "B") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail:
          "El comprobante no corresponde con la condición de iva del proveedor.",
      });
      return;
    }

    if (
      (comprobante.abreviatura === "NCA" ||
        comprobante.abreviatura === "NCB" ||
        comprobante.abreviatura === "NCM") &&
      !facturaAsociada
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail:
          "Es necesario especificar el comprobante asociado a la nota de crédito.",
      });
      return;
    }

    if (comprobante.remito && comprobante.numeroRemito === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El remito es necesario si esta marcado.",
      });
      return;
    }

    if (idComprobante === "0") {
      crearComprobante(empresa, comprobante, proveedor, articulosComprobante);
    }
  };

  // Despues de generar comprobante
  if (verComprobante && verComprobante.estado && idComprobante === "0") {
    props.history.push(`/comprobantecompras/${verComprobante.id}/todos`);
  }

  function periodoActual() {
    const fecha = new Date();
    let mes = ("00" + fecha.getMonth()).slice(-2);
    let anio = fecha.getFullYear();
    return mes + "/" + anio;
  }

  function validateMMYY(cadena) {
    var reg = new RegExp(
      "(((0[123456789]|10|11|12)/(([1][9][0-9][0-9])|([2][0-9][0-9][0-9]))))"
    );

    if (reg.test(cadena)) return true;
    else return false;
  }

  const cerrar = () => {
    guardarVerComprobante(null);

    guardarArticulosComprobante(null);
    props.history.push("/comprobantescompras");
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      if (event.target.id === "idproveedor") {
        if (event.target.value !== "" && event.target.value !== "0") {
          obtenerProveedorxId({ idProveedor: event.target.value });
          //obtenerImpuestosProveedor(proveedor.id);
        } else {
          obtenerProveedores();
          setModalProveedores(true);
        }
      }

      if (event.target.id === "numeroAsociado") {
        return;
      }

      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (typeof form.elements[index + 1] !== "undefined") {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    }
    if (event.keyCode === 27) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (typeof form.elements[index - 1] !== "undefined") {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  }

  const updateComprobante = (e) => {
    var data;

    for (var i = 0; i < tiposComprobantes.length; i++) {
      if (tiposComprobantes[i].value == e.target.value) {
        data = i;
      }
    }

    guardarComprobante({
      ...comprobante,
      tipoComprobanteId: tiposComprobantes[data].value,
      tipoComprobanteIdAfip: tiposComprobantes[data].codigoAfip,
      puntoVenta: tiposComprobantes[data].puntoVenta,
      numero: tiposComprobantes[data].numero,
      comprobante: tiposComprobantes[data].label,
      tipo: tiposComprobantes[data].tipo,
      abreviatura: tiposComprobantes[data].abreviatura,
    });
    if (articulosComprobante) {
      guardarArticulosComprobante(null);
    }
    //guardarFacturaAsociada(false);
  };

  const updateComprobanteAsociado = (e) => {
    var data;

    for (var i = 0; i < tiposComprobantes.length; i++) {
      if (tiposComprobantes[i].value == e.target.value) {
        data = i;
      }
    }

    guardarComprobante({
      ...comprobante,
      tipoComprobanteIdAsociado: tiposComprobantes[data].value,
      tipoComprobanteIdAsociadoAfip: tiposComprobantes[data].codigoAfip,
      puntoVentaAsociado: tiposComprobantes[data].puntoVenta,
      numeroAsociado: 0,
    });
  };

  if (
    proveedor &&
    proveedor.id !== "" &&
    comprobante &&
    proveedor.id !== comprobante.proveedorId &&
    proveedor.nombre !== comprobante.nombreProveedor &&
    !verComprobante
  ) {
    let tipoComprobante;

    if ((empresa.idCondicionIva === 1) & (proveedor.idCondicionIva === 1)) {
      tipoComprobante = "FA";
    }
    if ((empresa.idCondicionIva === 1) & (proveedor.idCondicionIva !== 1)) {
      tipoComprobante = "FB";
    }

    let data = 0;
    for (var i = 0; i < tiposComprobantes.length; i++) {
      if (tiposComprobantes[i].abreviatura == tipoComprobante) {
        data = i;
      }
    }

    guardarComprobante({
      ...comprobante,
      proveedorId: proveedor.id,
      nombreProveedor: proveedor.nombre,
      cuitProveedor: proveedor.cuit,
      cbuProveedor: proveedor.cbu,
      vendedorId: proveedor.idVendedor,
      tipoComprobanteId: tiposComprobantes[data].value,
      tipoComprobanteIdAfip: tiposComprobantes[data].codigoAfip,
      tipoComprobanteRemitoId: tiposComprobantes[data].tipoComprobanteRemitoId,
      puntoVenta: tiposComprobantes[data].puntoVenta,
      numero: tiposComprobantes[data].numero,
      puntoVentaRemito: tiposComprobantes[data].puntoVentaRemito,
      numeroRemito: tiposComprobantes[data].numeroRemito,
      comprobante: tiposComprobantes[data].label,
      tipo: tiposComprobantes[data].tipo,
      abreviatura: tiposComprobantes[data].abreviatura,
      subtotal: 0,
      descuento: 0,
      neto: 0,
      iva: 0,
      percepcion: 0,
      total: 0,
      exento: 0,
      saldo: 0,
      condicionVentaId: 1,
      condicionIvaId: proveedor.idCondicionIva,
      condicionVentaId: proveedor.idCondicionVenta,
    });

    guardarProveedor({
      ...proveedor,
      domicilioCompleto: `${proveedor.domicilio} ${proveedor.cp} ${proveedor.localidad}`,
    });
    if (articulosComprobante) {
      guardarArticulosComprobante(null);
    }
  }

  if (
    tiposComprobantes &&
    comprobante &&
    comprobante.tipoComprobanteId === 0 &&
    comprobante.numero === 0 &&
    comprobante.fecha === "" &&
    comprobante.abreviatura === "" &&
    comprobante.puntoVenta === 0
  ) {
    guardarComprobante({
      ...comprobante,
      puntoVenta: tiposComprobantes[0].puntoVenta,
      numero: tiposComprobantes[0].numero,
      puntoVentaRemito: tiposComprobantes[0].puntoVentaRemito,
      numeroRemito: tiposComprobantes[0].numeroRemito,
      tipoComprobanteRemitoId: tiposComprobantes[0].tipoComprobanteRemitoId,
      remito: empresa.remitos,
      tipoComprobanteId: tiposComprobantes[0].value,
      tipoComprobanteIdAfip: tiposComprobantes[0].codigoAfip,
      comprobante: tiposComprobantes[0].label,
      tipo: tiposComprobantes[0].tipo,
      abreviatura: tiposComprobantes[0].abreviatura,
      fecha: new Date(),
      empresaId: empresa.id,
    });
  }

  const clickAdd = () => {
    obtenerArticulosCompras();

    setModalArticulos(true);
  };

  const agregarItem = (item) => {
    //Verificar campos

    if (articulos[item].ivaId == 0) {
      articulos[item].ivaId = 3;
    }

    // Si es presupuesto iva = 0
    if (comprobante.abreviatura === "P") {
      articulos[item].ivaId = 3;
    }

    let tasaIva = 0;
    if (articulos[item].ivaId == 5) {
      tasaIva = 21;
    }
    if (articulos[item].ivaId == 4) {
      tasaIva = 10.5;
    }
    if (articulos[item].ivaId == 3) {
      tasaIva = 0;
    }
    if (articulos[item].ivaId == 6) {
      tasaIva = 27;
    }

    let tasaPercepcion = 0;

    let valorSubtotal = articulos[item].costo;
    let valorDescuento = (articulos[item].costo * proveedor.descuento) / 100;
    /*
    if (
      comprobante.abreviatura === "NCA" ||
      comprobante.abreviatura === "NCB" ||
      comprobante.abreviatura === "NCM"
    ) {
      valorSubtotal = valorSubtotal * -1;
      valorDescuento = valorDescuento * -1;
    }
    */
    let valorNeto = valorSubtotal - valorDescuento;
    let valorPercepcion = (valorNeto * tasaPercepcion) / 100;
    let valorIva = (valorNeto * tasaIva) / 100;
    let valorTotal = valorNeto + valorPercepcion + valorIva;
    let valorExento;
    if (proveedor.idCondicionIva === 4) {
      //Exento
      valorExento = valorNeto;
    } else {
      valorExento = 0;
    }
    if (!articulosComprobante) {
      guardarArticulosComprobante([
        {
          id: 0,
          articuloId: articulos[item].articuloId,
          detalle: articulos[item].nombre,
          cantidad: "1",
          unitario: articulos[item].costo,
          subtotal: valorSubtotal,
          descuento: valorDescuento,
          neto: valorNeto,
          iva: valorIva,
          percepcion: valorPercepcion,
          total: valorTotal,
          exento: valorExento,
          ivaId: articulos[item].ivaId,
          tasaIva: tasaIva,
          tasaPercepcion: tasaPercepcion,
          tasaDescuento: proveedor.descuento,
        },
      ]);
    } else {
      let lenState = articulosComprobante.length;

      guardarArticulosComprobante((articulosComprobante) => [
        ...articulosComprobante,
        {
          id: lenState,
          articuloId: articulos[item].articuloId,
          detalle: articulos[item].nombre,
          cantidad: "1",
          unitario: articulos[item].costo,
          subtotal: valorSubtotal,
          descuento: valorDescuento,
          neto: valorNeto,
          iva: valorIva,
          percepcion: valorPercepcion,
          total: valorTotal,
          exento: valorExento,
          ivaId: articulos[item].ivaId,
          tasaIva: tasaIva,
          tasaPercepcion: tasaPercepcion,
          tasaDescuento: proveedor.descuento,
        },
      ]);
    }

    if (articulosComprobante) {
      let valorSubtotalOld = 0;
      let valorDescuentoOld = 0;
      let valorNetoOld = 0;
      let valorIvaOld = 0;
      let valorPercepcionOld = 0;
      let valorTotalOld = 0;
      let valorExentoOld = 0;

      for (var i = 0; i < articulosComprobante.length; i++) {
        valorSubtotalOld =
          Number(valorSubtotalOld) + Number(articulosComprobante[i].subtotal);
        valorDescuentoOld =
          Number(valorDescuentoOld) + Number(articulosComprobante[i].descuento);
        valorNetoOld =
          Number(valorNetoOld) + Number(articulosComprobante[i].neto);
        valorIvaOld = Number(valorIvaOld) + Number(articulosComprobante[i].iva);
        valorPercepcionOld =
          Number(valorPercepcionOld) +
          Number(articulosComprobante[i].percepcion);
        valorTotalOld =
          Number(valorTotalOld) + Number(articulosComprobante[i].total);

        if (proveedor.idCondicionIva === 4) {
          //Exento
          valorExentoOld =
            Number(valorExentoOld) + Number(articulosComprobante[i].neto);
        } else {
          valorExentoOld = Number(valorExentoOld) + Number(0);
        }
      }

      guardarComprobante({
        ...comprobante,
        subtotal: Number(valorSubtotal) + Number(valorSubtotalOld),
        descuento: Number(valorDescuento) + Number(valorDescuentoOld),
        neto: Number(valorNeto) + Number(valorNetoOld),
        iva: Number(valorIva) + Number(valorIvaOld),
        percepcion: Number(valorPercepcion) + Number(valorPercepcionOld),
        total: Number(valorTotal) + Number(valorTotalOld),
        exento: Number(valorExento) + Number(valorExentoOld),
        saldo: Number(valorTotal) + Number(valorTotalOld),
      });
    } else {
      guardarComprobante({
        ...comprobante,
        subtotal: valorSubtotal,
        descuento: valorDescuento,
        neto: valorNeto,
        iva: valorIva,
        percepcion: valorPercepcion,
        total: valorTotal,
        exento: valorExento,
        saldo: valorTotal,
      });
    }
  };

  const agregarItem2 = (e) => {
    //Verificar campos
    e.preventDefault();

    if (carga.codigo === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El código de artículo no es correcto.",
      });
      let elemento = document.getElementById("codigo");
      elemento.focus();
      return;
    }

    /*
    let band = false;
    for (var i = 0; i < articulos.length; i++) {
      if (articulos[i].articuloId === carga.codigo ) {
        band = true;
        break
      }
    }

    if (!band) {
      let elemento = document.getElementById("codigo");
      elemento.focus();
      return;
    }
    */

    if (Number(carga.cantidad) <= 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La cantidad no es correcta.",
      });
      let elemento = document.getElementById("cantidad");
      elemento.focus();
      return;
    }

    if (Number(carga.valorUnitario) <= 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El valor unitario no es correcta.",
      });
      let elemento = document.getElementById("valorUnitario");
      elemento.focus();
      return;
    }

    if (Number(carga.descuento) < 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La bonificación no es correcta.",
      });
      let elemento = document.getElementById("descuento");
      elemento.focus();
      return;
    }

    if (Number(carga.percepcion) < 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La percepción no es correcta.",
      });
      let elemento = document.getElementById("percepcion");
      elemento.focus();
      return;
    }

    if (Number(carga.ivaId) === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El I.V.A. no es correcto.",
      });
      let elemento = document.getElementById("iva");
      elemento.focus();
      return;
    }

    let tasaIva = 0;

    if (carga.ivaId === 5) {
      tasaIva = 21;
    }
    if (carga.ivaId === 4) {
      tasaIva = 10.5;
    }
    if (carga.ivaId === 3) {
      tasaIva = 0;
    }
    if (carga.ivaId === 6) {
      tasaIva = 27;
    }

    let tasaPercepcion = carga.percepcion;

    let valorSubtotal = Number(carga.valorUnitario) * Number(carga.cantidad);
    let valorDescuento =
      (Number(valorSubtotal) * Number(carga.descuento)) / 100;
    /*
    if (
      comprobante.abreviatura === "NCA" ||
      comprobante.abreviatura === "NCB" ||
      comprobante.abreviatura === "NCM"
    ) {
      valorSubtotal = valorSubtotal * -1;
      valorDescuento = valorDescuento * -1;
    }
    */
    let valorNeto = Number(valorSubtotal) - Number(valorDescuento);
    let valorPercepcion = (Number(valorNeto) * Number(tasaPercepcion)) / 100;
    let valorIva = (Number(valorNeto) * Number(tasaIva)) / 100;
    let valorTotal =
      Number(valorNeto) + Number(valorPercepcion) + Number(valorIva);
    let valorExento;
    if (proveedor.idCondicionIva === 4) {
      //Exento
      valorExento = valorNeto;
    } else {
      valorExento = 0;
    }
    if (!articulosComprobante) {
      guardarArticulosComprobante([
        {
          id: 0,
          articuloId: carga.codigo,
          detalle: carga.detalle,
          cantidad: carga.cantidad,
          unitario: carga.valorUnitario,
          subtotal: valorSubtotal,
          descuento: valorDescuento,
          neto: valorNeto,
          iva: valorIva,
          percepcion: valorPercepcion,
          total: valorTotal,
          exento: valorExento,
          ivaId: carga.ivaId,
          tasaIva: tasaIva,
          tasaPercepcion: tasaPercepcion,
          tasaDescuento: carga.descuento,
        },
      ]);
    } else {
      let lenState = articulosComprobante.length;

      guardarArticulosComprobante((articulosComprobante) => [
        ...articulosComprobante,
        {
          id: lenState,
          articuloId: carga.codigo,
          detalle: carga.detalle,
          cantidad: carga.cantidad,
          unitario: carga.valorUnitario,
          subtotal: valorSubtotal,
          descuento: valorDescuento,
          neto: valorNeto,
          iva: valorIva,
          percepcion: valorPercepcion,
          total: valorTotal,
          exento: valorExento,
          ivaId: carga.ivaId,
          tasaIva: tasaIva,
          tasaPercepcion: tasaPercepcion,
          tasaDescuento: carga.descuento,
        },
      ]);
    }

    if (articulosComprobante) {
      let valorSubtotalOld = 0;
      let valorDescuentoOld = 0;
      let valorNetoOld = 0;
      let valorIvaOld = 0;
      let valorPercepcionOld = 0;
      let valorTotalOld = 0;
      let valorExentoOld = 0;

      for (var i = 0; i < articulosComprobante.length; i++) {
        valorSubtotalOld =
          Number(valorSubtotalOld) + Number(articulosComprobante[i].subtotal);
        valorDescuentoOld =
          Number(valorDescuentoOld) + Number(articulosComprobante[i].descuento);
        valorNetoOld =
          Number(valorNetoOld) + Number(articulosComprobante[i].neto);
        valorIvaOld = Number(valorIvaOld) + Number(articulosComprobante[i].iva);
        valorPercepcionOld =
          Number(valorPercepcionOld) +
          Number(articulosComprobante[i].percepcion);
        valorTotalOld =
          Number(valorTotalOld) + Number(articulosComprobante[i].total);

        if (proveedor.idCondicionIva === 4) {
          //Exento
          valorExentoOld =
            Number(valorExentoOld) + Number(articulosComprobante[i].neto);
        } else {
          valorExentoOld = Number(valorExentoOld) + Number(0);
        }
      }

      guardarComprobante({
        ...comprobante,
        subtotal: Number(valorSubtotal) + Number(valorSubtotalOld),
        descuento: Number(valorDescuento) + Number(valorDescuentoOld),
        neto: Number(valorNeto) + Number(valorNetoOld),
        iva: Number(valorIva) + Number(valorIvaOld),
        percepcion: Number(valorPercepcion) + Number(valorPercepcionOld),
        total: Number(valorTotal) + Number(valorTotalOld),
        exento: Number(valorExento) + Number(valorExentoOld),
        saldo: Number(valorTotal) + Number(valorTotalOld),
      });
    } else {
      guardarComprobante({
        ...comprobante,
        subtotal: valorSubtotal,
        descuento: valorDescuento,
        neto: valorNeto,
        iva: valorIva,
        percepcion: valorPercepcion,
        total: valorTotal,
        exento: valorExento,
        saldo: valorTotal,
      });
    }
  };

  const agregarProveedor = (proveedor) => {
    obtenerProveedorxId({ idProveedor: proveedores[proveedor].id });
    //obtenerImpuestosProveedor(proveedores[proveedor].id);
  };

  const largeStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "42ch" };

  const mediaStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "36ch" };

  const shortStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "30ch" };

  const clickFacturaAsociada = () => {
    const {
      empresaId,
      tipoComprobanteIdAsociado,
      puntoVentaAsociado,
      numeroAsociado,
      proveedorId,
    } = comprobante;
    obtenerComprobanteNro({
      empresaId,
      tipoComprobanteIdAsociado,
      puntoVentaAsociado,
      numeroAsociado,
      proveedorId,
    });
  };

  const verPdf = () => {
    var doc = new jsPDF();
    doc.setProperties({
      title: "new Report",
    });

    doc.text("Hello world!", 10, 10);
    doc.output("dataurlnewwindow");
    //doc.save("a4.pdf");
  };

  const descargarPdf = () => {
    var doc = new jsPDF();
    doc.setProperties({
      title: "new Report",
    });

    doc.text("Hello world!", 10, 10);
    //doc.output('dataurlnewwindow');
    doc.save("a4.pdf");
  };

  const borrarLineaArticulo = () => {
    guardarCarga({
      ...carga,
      codigo: "",
      detalle: "",
      cantidad: "",
      valorUnitario: "",
      descuento: "",
      percepcion: "",
      iva: 0,
      ivaId: 5,
      valorTotal: 0,
    });
  };

  //console.log(carga);
  //console.log(comprobante);

  return (
    <Fragment>
      {!comprobante ||
      comprobante.numero === 0 ||
      comprobante.fecha === "" ||
      comprobante.listaPrecioId === 0 ||
      !delay ? (
        <Spinner />
      ) : (
        <div className="background-fc">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-md-12 mt-3">
              <div className="card-body text-black background mb-3 ml-1 mr-1">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                  style={{ color: "Blue" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <Growl ref={(el) => (growl = el)} />

                <Tabs>
                  <TabList>
                    <Tab style={{ background: "#28BAEC" }}>
                      Datos comprobante
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className={classes.root}>
                      <Grid container spacing={0}>
                        <Grid
                          container
                          xs={12}
                          md={6}
                          className="card-body text-black background mt-0 mb-3 shadow-box"
                        >
                          <Grid item xs={12} md={3} className={classes.grid}>
                            <TextField
                              id="idproveedor"
                              label="ID Proveedor"
                              style={{ color: "blue" }}
                              type="number"
                              fullWidth
                              value={proveedor.id}
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) => {
                                guardarProveedor({
                                  ...proveedor,
                                  id: e.target.value,
                                });
                                borrarLineaArticulo();
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  if (
                                    proveedor.id !== "" &&
                                    proveedor.id !== "0"
                                  ) {
                                    obtenerProveedorxId({
                                      idProveedor: proveedor.id,
                                    });
                                    let elemento = document.getElementById(
                                      "codigo"
                                    );
                                    elemento.focus();
                                  } else {
                                    obtenerProveedores();
                                    setModalProveedores(true);
                                  }
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} className={classes.grid}>
                            <TextField
                              id="cuit"
                              label="C.U.I.T."
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={comprobante.cuitProveedor}
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) =>
                                guardarComprobante({
                                  ...comprobante,
                                  cuitProveedor: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.grid}>
                            <TextField
                              id="nombre"
                              label="Nombre"
                              onKeyDown={handleEnter}
                              style={{ color: "blue" }}
                              fullWidth
                              value={proveedor.nombre}
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) => {
                                guardarProveedor({
                                  ...proveedor,
                                  nombre: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.grid}>
                            <TextField
                              id="condicioniva"
                              select
                              label="Condición de I.V.A."
                              style={{ color: "blue" }}
                              fullWidth
                              value={proveedor.idCondicionIva}
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) => {
                                guardarProveedor({
                                  ...proveedor,
                                  idCondicionIva: Number(e.target.value),
                                });
                              }}
                              onKeyDown={handleEnter}
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {condicionesIva
                                ? condicionesIva.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))
                                : null}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6} className={classes.grid}>
                            <TextField
                              id="domicilioCompleto"
                              label="Dirección"
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={
                                !verComprobante
                                  ? proveedor.domicilioCompleto
                                  : proveedor.domicilio
                              }
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) => {
                                guardarProveedor({
                                  ...proveedor,
                                  domicilioCompleto: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.grid}>
                            <TextField
                              id="email"
                              label="Email"
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={proveedor.email}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          md={4}
                          className="card-body text-black background mt-0 mb-3 shadow-box"
                        >
                          <Grid item xs={12} md={4} className={classes.grid}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={esLocale}
                            >
                              <KeyboardDatePicker
                                clearable
                                label="Fecha"
                                style={{ color: "blue" }}
                                fullWidth
                                value={comprobante.fecha}
                                disabled={
                                  verComprobante && verComprobante.estado
                                }
                                onChange={(date) =>
                                  guardarComprobante({
                                    ...comprobante,
                                    fecha: date,
                                  })
                                }
                                minDate={!verComprobante ? new Date(-10) : null}
                                format="dd/MM/yyyy"
                                onKeyDown={handleEnter}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>

                          <Grid item xs={12} md={3} className={classes.grid}>
                            <TextField
                              id="periodo"
                              disabled={verComprobante && verComprobante.estado}
                              label="Período"
                              type="text"
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={comprobante.periodo}
                              onChange={(e) => {
                                guardarComprobante({
                                  ...comprobante,
                                  periodo: e.target.value,
                                });
                              }}
                            >
                              <InputMask mask="99/9999" maskChar=" " />
                            </TextField>
                          </Grid>

                          <Grid item xs={12} md={4} className={classes.grid}>
                            <TextField
                              id="comprobante"
                              select
                              label="Comprobante"
                              value={comprobante.tipoComprobanteId}
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) => updateComprobante(e)}
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {tiposComprobantes
                                ? tiposComprobantes.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))
                                : null}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.grid}>
                            <TextField
                              id="puntoventa"
                              disabled={verComprobante && verComprobante.estado}
                              label="Punto de venta"
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={("0000" + comprobante.puntoVenta).slice(
                                -4
                              )}
                              onChange={(e) => {
                                guardarComprobante({
                                  ...comprobante,
                                  puntoVenta: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} className={classes.grid}>
                            <TextField
                              id="numero"
                              disabled={verComprobante && verComprobante.estado}
                              label="Número"
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={comprobante.numero}
                              onChange={(e) => {
                                guardarComprobante({
                                  ...comprobante,
                                  numero: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.grid}>
                            <TextField
                              id="condicionventa"
                              select
                              label="Condición de venta"
                              style={{ color: "blue" }}
                              fullWidth
                              value={comprobante.condicionVentaId}
                              disabled={verComprobante && verComprobante.estado}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                guardarComprobante({
                                  ...comprobante,
                                  condicionVentaId: Number(e.target.value),
                                })
                              }
                              onKeyDown={handleEnter}
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {condicionesVentas
                                ? condicionesVentas.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))
                                : null}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.grid}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{
                                    color: "blue",
                                    marginTop: "0.5em",
                                  }}
                                  fullWidth
                                  onKeyDown={handleEnter}
                                  checked={comprobante.remito}
                                  disabled={
                                    verComprobante && verComprobante.estado
                                  }
                                  onChange={(e) =>
                                    guardarComprobante({
                                      ...comprobante,
                                      remito: e.target.checked,
                                    })
                                  }
                                  name="remito"
                                  color="primary"
                                />
                              }
                              label="Remito"
                              labelPlacement="start"
                            />
                          </Grid>

                          {comprobante.remito ? (
                            <Grid item xs={12} md={4} className={classes.grid}>
                              <TextField
                                id="numeroRemito"
                                disabled={
                                  verComprobante && verComprobante.estado
                                }
                                label="Nº Remito "
                                style={{ color: "blue" }}
                                fullWidth
                                onKeyDown={handleEnter}
                                value={comprobante.numeroRemito}
                                onChange={(e) => {
                                  guardarComprobante({
                                    ...comprobante,
                                    numeroRemito: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </div>
                  </TabPanel>
                </Tabs>
                <Tabs onSelect={(tabIndex) => clickAdd()}>
                  <TabList>
                    {proveedor.id === "" ||
                    (verComprobante && verComprobante.estado) ? (
                      <Tab disabled={true} style={{ background: "#28BAEC" }}>
                        Productos
                      </Tab>
                    ) : (
                      <Fragment>
                        <Grid container xs={12} md={12} spacing={0}>
                          <Grid item xs={12} md={1} className={classes.grid}>
                            <Tab
                              disabled={false}
                              style={{ background: "#28BAEC" }}
                            >
                              Productos
                            </Tab>
                          </Grid>
                          {!verComprobante ? (
                            <Fragment>
                              <Grid
                                item
                                xs={12}
                                md={1}
                                className={classes.grid}
                              >
                                <TextField
                                  id="codigo"
                                  label="Código"
                                  style={{ color: "blue" }}
                                  type="number"
                                  //onKeyDown={handleEnter}
                                  value={carga.codigo}
                                  onChange={(e) =>
                                    guardarCarga({
                                      ...carga,
                                      codigo: e.target.value,
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      if (
                                        carga.codigo !== "" &&
                                        carga.codigo !== "0"
                                      ) {
                                        obtenerArticuloxId({
                                          idArticulo: carga.codigo,
                                          impuestos,
                                          empresa,
                                        });
                                        let elemento = document.getElementById(
                                          "cantidad"
                                        );
                                        elemento.focus();
                                      } else {
                                        clickAdd();
                                      }
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                className={classes.grid}
                              >
                                <TextField
                                  id="detalle"
                                  label="Detalle"
                                  style={{ color: "blue", width: "300px" }}
                                  //onKeyDown={handleEnter}
                                  value={carga.detalle}
                                  disabled
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={1}
                                className={classes.grid}
                              >
                                <TextField
                                  id="cantidad"
                                  label="Cantidad"
                                  type="number"
                                  style={{ color: "blue" }}
                                  //onKeyDown={handleEnter}
                                  value={carga.cantidad}
                                  onChange={(e) =>
                                    guardarCarga({
                                      ...carga,
                                      cantidad: e.target.value,
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      if (carga.cantidad > 0) {
                                        if (comprobante.abreviatura !== "R") {
                                          let elemento = document.getElementById(
                                            "valorUnitario"
                                          );
                                          elemento.focus();
                                        } else {
                                          agregarItem2(e);
                                          borrarLineaArticulo();
                                          let elemento = document.getElementById(
                                            "codigo"
                                          );
                                          elemento.focus();
                                        }
                                      }
                                    }
                                    if (e.key === "Escape") {
                                      let elemento = document.getElementById(
                                        "codigo"
                                      );
                                      elemento.focus();
                                    }
                                  }}
                                />
                              </Grid>
                              {comprobante.abreviatura !== "R" ? (
                                <Fragment>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <TextField
                                      id="valorUnitario"
                                      label="V. Unitario"
                                      type="number"
                                      style={{ color: "blue" }}
                                      //onKeyDown={handleEnter}
                                      value={carga.valorUnitario}
                                      onChange={(e) =>
                                        guardarCarga({
                                          ...carga,
                                          valorUnitario: e.target.value,
                                        })
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (carga.valorUnitario > 0) {
                                            let elemento = document.getElementById(
                                              "descuento"
                                            );
                                            elemento.focus();
                                          }
                                        }
                                        if (e.key === "Escape") {
                                          let elemento = document.getElementById(
                                            "cantidad"
                                          );
                                          elemento.focus();
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <TextField
                                      id="descuento"
                                      label="% Bonif."
                                      type="number"
                                      style={{ color: "blue" }}
                                      //onKeyDown={handleEnter}
                                      value={carga.descuento}
                                      onChange={(e) =>
                                        guardarCarga({
                                          ...carga,
                                          descuento: e.target.value,
                                        })
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (carga.descuento >= 0) {
                                            let elemento;
                                            elemento = document.getElementById(
                                              "percepcion"
                                            );
                                            elemento.focus();
                                          }
                                        }
                                        if (e.key === "Escape") {
                                          let elemento = document.getElementById(
                                            "valorUnitario"
                                          );
                                          elemento.focus();
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <TextField
                                      id="percepcion"
                                      label="% Percep."
                                      type="number"
                                      //disabled={!empresa.percepcion}
                                      style={{ color: "blue" }}
                                      //onKeyDown={handleEnter}
                                      value={carga.percepcion}
                                      onChange={(e) =>
                                        guardarCarga({
                                          ...carga,
                                          percepcion: e.target.value,
                                        })
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (carga.percepcion >= 0) {
                                            let elemento = document.getElementById(
                                              "iva"
                                            );
                                            elemento.focus();
                                          }
                                        }
                                        if (e.key === "Escape") {
                                          let elemento = document.getElementById(
                                            "descuento"
                                          );
                                          elemento.focus();
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <TextField
                                      id="iva"
                                      select
                                      label="I.V.A."
                                      value={carga.ivaId}
                                      onChange={(e) => {
                                        guardarCarga({
                                          ...carga,
                                          ivaId: Number(e.target.value),
                                        });
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (
                                            carga.ivaId !== "" &&
                                            carga.ivaId !== "0"
                                          ) {
                                            agregarItem2(e);
                                            borrarLineaArticulo();
                                            let elemento = document.getElementById(
                                              "codigo"
                                            );
                                            elemento.focus();
                                          }
                                        }
                                        if (e.key === "Escape") {
                                          let elemento;
                                          if (empresa.percepcion) {
                                            elemento = document.getElementById(
                                              "percepcion"
                                            );
                                          } else {
                                            elemento = document.getElementById(
                                              "descuento"
                                            );
                                          }
                                          elemento.focus();
                                        }
                                      }}
                                      SelectProps={{
                                        native: true,
                                      }}
                                    >
                                      {ivas
                                        ? ivas.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))
                                        : null}
                                    </TextField>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={classes.button}
                                      onClick={(e) => {
                                        agregarItem2(e);
                                        borrarLineaArticulo();
                                        let elemento = document.getElementById(
                                          "codigo"
                                        );
                                        elemento.focus();
                                      }}
                                    >
                                      Confirmar artículo
                                    </Button>{" "}
                                  </Grid>
                                </Fragment>
                              ) : null}
                            </Fragment>
                          ) : null}
                        </Grid>
                      </Fragment>
                    )}
                  </TabList>
                  <TabPanel>
                    <div style={{ maxWidth: "100%" }}>
                      <div className="row">
                        <div className="col-xs-12 col-md-10">
                          {articulosComprobante ? (
                            <MaterialTable
                              columns={[
                                {
                                  title: "Código",
                                  field: "articuloId",
                                  editable: "never",
                                },
                                {
                                  title: "Detalle",
                                  field: "detalle",
                                  editable: "never",
                                  cellStyle: {
                                    width: 300,
                                    minWidth: 300,
                                  },
                                  headerStyle: {
                                    width: 300,
                                    minWidth: 300,
                                  },
                                },
                                {
                                  title: "Cantidad",
                                  field: "cantidad",
                                  editable: "onUpdate",
                                  type: "numeric",
                                },
                                {
                                  title: "Valor Unitario",
                                  field: "unitario",
                                  editable: "onUpdate",
                                  type: "currency",
                                },
                                {
                                  title: "% Descuento",
                                  field: "tasaDescuento",
                                  editable: "onUpdate",
                                  type: "numeric",
                                },
                                {
                                  title: "% Percepcion",
                                  field: "tasaPercepcion",
                                  editable: "onUpdate",
                                  type: "numeric",
                                },
                                {
                                  title: "I.V.A.",
                                  field: "ivaId",
                                  editable: "onUpdate",
                                  type: "numeric",
                                  lookup: {
                                    3: "IVA 0%",
                                    4: "IVA 10.5%",
                                    5: "IVA 21%",
                                    6: "IVA 27%",
                                  },
                                },
                                {
                                  title: "Valor Total",
                                  field: "total",
                                  editable: "never",
                                  type: "currency",
                                },
                              ]}
                              data={articulosComprobante}
                              options={{
                                search: false,
                                sorting: true,
                                toolbar: false,
                                actionsColumnIndex: -1,
                              }}
                              localization={{
                                header: {
                                  actions: "Acción",
                                },
                                body: {
                                  editTooltip: "Editar",
                                  editRow: {
                                    cancelTooltip: "Cancelar",
                                    saveTooltip: "Aceptar",
                                    deleteText: "Esta seguro de borrar ?",
                                  },
                                  deleteTooltip: "Borrar",
                                  emptyDataSourceMessage: "No hay registros",
                                },
                                pagination: {
                                  labelRowsSelect: "filas",
                                },
                              }}
                              editable={{
                                ...(!verComprobante
                                  ? {
                                      isEditable: (rowData) => rowData, // only name(a) rows would be editable
                                      onRowAddCancelled: (rowData) =>
                                        console.log("Row adding cancelled"),
                                      onRowUpdateCancelled: (rowData) =>
                                        console.log("Row editing cancelled"),
                                      onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                          setTimeout(() => {
                                            const dataUpdate = [
                                              ...articulosComprobante,
                                            ];
                                            const index = oldData.id;

                                            // Actualizar states

                                            let item = { ...newData };
                                            const {
                                              cantidad,
                                              unitario,
                                              tasaDescuento,
                                              ivaId,
                                              tasaPercepcion,
                                            } = item;

                                            let tasaIva = 0;
                                            if (ivaId == 3) {
                                              tasaIva = 0;
                                            }
                                            if (ivaId == 4) {
                                              tasaIva = 10.5;
                                            }
                                            if (ivaId == 5) {
                                              tasaIva = 21;
                                            }
                                            if (ivaId == 6) {
                                              tasaIva = 27;
                                            }

                                            let valorSubtotal =
                                              cantidad * unitario;
                                            let valorDescuento =
                                              (valorSubtotal * tasaDescuento) /
                                              100;

                                            /*  
                                        if (
                                          comprobante.abreviatura === "NCA" ||
                                          comprobante.abreviatura === "NCB" ||
                                          comprobante.abreviatura === "NCM"
                                        ) {
                                          valorSubtotal = Number(valorSubtotal) * Number(-1);
                                          valorDescuento = Number(valorDescuento) * Number(-1);
                                        }
                                        */

                                            let valorNeto =
                                              Number(valorSubtotal) -
                                              Number(valorDescuento);

                                            let valorExento = 0;
                                            if (
                                              proveedor.idCondicionIva === 4
                                            ) {
                                              //Exento
                                              valorExento =
                                                Number(valorSubtotal) -
                                                Number(valorDescuento);
                                            } else {
                                              valorExento = 0;
                                            }

                                            let valorPercepcion =
                                              (Number(valorNeto) *
                                                Number(tasaPercepcion)) /
                                              100;

                                            let valorIva =
                                              (Number(valorNeto) *
                                                Number(tasaIva)) /
                                              100;

                                            let valorTotal =
                                              Number(valorNeto) +
                                              Number(valorIva) +
                                              Number(valorPercepcion);
                                            dataUpdate[index] = newData;
                                            dataUpdate[
                                              index
                                            ].subtotal = valorSubtotal;
                                            dataUpdate[
                                              index
                                            ].descuento = valorDescuento;
                                            dataUpdate[index].neto = valorNeto;
                                            dataUpdate[index].iva = valorIva;
                                            dataUpdate[
                                              index
                                            ].percepcion = valorPercepcion;
                                            dataUpdate[
                                              index
                                            ].total = valorTotal;
                                            dataUpdate[index].tasaIva = tasaIva;
                                            dataUpdate[
                                              index
                                            ].tasaPercepcion = tasaPercepcion;
                                            dataUpdate[
                                              index
                                            ].exento = valorExento;

                                            guardarArticulosComprobante([
                                              ...dataUpdate,
                                            ]);

                                            valorSubtotal = 0;
                                            valorDescuento = 0;
                                            valorNeto = 0;
                                            valorIva = 0;
                                            valorPercepcion = 0;
                                            valorTotal = 0;
                                            valorExento = 0;

                                            for (
                                              var i = 0;
                                              i < dataUpdate.length;
                                              i++
                                            ) {
                                              valorSubtotal =
                                                Number(valorSubtotal) +
                                                Number(dataUpdate[i].subtotal);
                                              valorDescuento =
                                                Number(valorDescuento) +
                                                Number(dataUpdate[i].descuento);
                                              valorNeto =
                                                Number(valorNeto) +
                                                Number(dataUpdate[i].neto);
                                              valorIva =
                                                Number(valorIva) +
                                                Number(dataUpdate[i].iva);
                                              valorPercepcion =
                                                Number(valorPercepcion) +
                                                Number(
                                                  dataUpdate[i].percepcion
                                                );
                                              valorTotal =
                                                Number(valorTotal) +
                                                Number(dataUpdate[i].total);
                                              valorExento =
                                                Number(valorExento) +
                                                Number(dataUpdate[i].exento);
                                            }

                                            guardarComprobante({
                                              ...comprobante,
                                              subtotal: valorSubtotal,
                                              descuento: valorDescuento,
                                              neto: valorNeto,
                                              iva: valorIva,
                                              percepcion: valorPercepcion,
                                              total: valorTotal,
                                              exento: valorExento,
                                              saldo: valorTotal,
                                            });

                                            resolve();
                                          }, 1000);
                                        }),

                                      onRowDelete: (oldData) =>
                                        new Promise((resolve, reject) => {
                                          setTimeout(() => {
                                            const dataDelete = [
                                              ...articulosComprobante,
                                            ];
                                            const index = oldData.tableData.id;
                                            dataDelete.splice(index, 1);
                                            guardarArticulosComprobante([
                                              ...dataDelete,
                                            ]);

                                            //Actualizar state

                                            let valorSubtotal = 0;
                                            let valorDescuento = 0;
                                            let valorNeto = 0;
                                            let valorIva = 0;
                                            let valorPercepcion = 0;
                                            let valorTotal = 0;
                                            let valorExento = 0;
                                            if (dataDelete) {
                                              for (
                                                var i = 0;
                                                i < dataDelete.length;
                                                i++
                                              ) {
                                                valorSubtotal =
                                                  Number(valorSubtotal) +
                                                  Number(
                                                    dataDelete[i].subtotal
                                                  );
                                                valorDescuento =
                                                  Number(valorDescuento) +
                                                  Number(
                                                    dataDelete[i].descuento
                                                  );
                                                valorNeto =
                                                  Number(valorNeto) +
                                                  Number(dataDelete[i].neto);
                                                valorIva =
                                                  Number(valorIva) +
                                                  Number(dataDelete[i].iva);
                                                valorPercepcion =
                                                  Number(valorPercepcion) +
                                                  Number(
                                                    dataDelete[i].percepcion
                                                  );
                                                valorTotal =
                                                  Number(valorTotal) +
                                                  Number(dataDelete[i].total);

                                                if (
                                                  proveedor.idCondicionIva === 4
                                                ) {
                                                  //Exento
                                                  valorExento =
                                                    Number(valorExento) +
                                                    Number(
                                                      dataDelete[i].exento
                                                    );
                                                } else {
                                                  valorExento = 0;
                                                }
                                              }
                                            }
                                            guardarComprobante({
                                              ...comprobante,
                                              subtotal: valorSubtotal,
                                              descuento: valorDescuento,
                                              neto: valorNeto,
                                              iva: valorIva,
                                              percepcion: valorPercepcion,
                                              total: valorTotal,
                                              exento: valorExento,
                                              saldo: valorTotal,
                                            });

                                            resolve();
                                          }, 1000);
                                        }),
                                    }
                                  : {}),
                              }}
                            />
                          ) : null}
                        </div>
                        <div className="col-xs-12 col-md-2">
                          {comprobante.tipo === "A" ? (
                            <Fragment>
                              <h6>
                                Subtotal:{"$ "}
                                <span className="badge badge-secondary">
                                  {parseFloat(comprobante.subtotal).toFixed(2)}
                                </span>
                              </h6>
                              <h6>
                                Descuento:{"$ "}
                                <span className="badge badge-secondary">
                                  {parseFloat(comprobante.descuento).toFixed(2)}
                                </span>
                              </h6>
                              <h6>
                                Neto:{"$ "}
                                <span className="badge badge-secondary">
                                  {parseFloat(comprobante.neto).toFixed(2)}
                                </span>
                              </h6>
                              <h6>
                                I.V.A.:{"$ "}
                                <span className="badge badge-secondary">
                                  {parseFloat(comprobante.iva).toFixed(2)}
                                </span>
                              </h6>
                              <h6>
                                Impuestos:{"$ "}
                                <span className="badge badge-secondary">
                                  {parseFloat(comprobante.percepcion).toFixed(
                                    2
                                  )}
                                </span>
                              </h6>
                              <h6>
                                Total:{"$ "}
                                <span className="badge badge-secondary">
                                  {parseFloat(comprobante.total).toFixed(2)}
                                </span>
                              </h6>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <h6>
                                Subtotal:{"$ "}
                                <span className="badge badge-secondary">
                                  {`${parseFloat(
                                    Number(comprobante.subtotal) +
                                      Number(comprobante.iva)
                                  ).toFixed(2)}`}
                                </span>
                              </h6>
                              <h6>
                                Descuento:{"$ "}
                                <span className="badge badge-secondary">
                                  {parseFloat(comprobante.descuento).toFixed(2)}
                                </span>
                              </h6>
                              <h6>
                                Impuestos:{"$ "}
                                <span className="badge badge-secondary">
                                  {parseFloat(comprobante.percepcion).toFixed(
                                    2
                                  )}
                                </span>
                              </h6>
                              <h6>
                                Total:{"$ "}
                                <span className="badge badge-secondary">
                                  {parseFloat(comprobante.total).toFixed(2)}
                                </span>
                              </h6>
                            </Fragment>
                          )}
                          {!articulosComprobante || !verComprobante ? (
                            <button
                              type="button"
                              className="btn btn-info btn-large mt-4"
                              onClick={generarFactura}
                              disabled={!articulosComprobante}
                            >
                              Generar comprobante
                            </button>
                          ) : (
                            <Fragment>
                              <Button
                                label="Pdf"
                                className="btn btn-info btn-large mt-4"
                                icon="pi pi-file-pdf"
                                onClick={verPdf}
                              />
                              <Button
                                label="Descargar"
                                className="btn btn-info btn-large mt-4 ml-4"
                                icon="pi pi-download"
                                onClick={descargarPdf}
                              />
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>

                    <Dialog
                      visible={modalArticulos}
                      style={{ width: "50vw" }}
                      modal={true}
                      showHeader={false}
                      dismissableMask={true}
                      onHide={() => setModalArticulos(false)}
                    >
                      <div style={{ maxWidth: "100%" }}>
                        {articulos ? (
                          <MaterialTable
                            columns={[
                              {
                                title: "ID del artículo",
                                field: "articuloId",
                                editable: "never",
                              },
                              {
                                title: "Nombre del artículo",
                                field: "nombre",
                                editable: "never",
                                cellStyle: {
                                  width: 300,
                                  minWidth: 300,
                                },
                                headerStyle: {
                                  width: 300,
                                  minWidth: 300,
                                },
                              },
                              {
                                title: "Costo",
                                field: "costo",
                                editable: "never",
                                type: "numeric",
                              },
                              {
                                title: "I.V.A.",
                                field: "ivaId",
                                editable: "never",
                                type: "numeric",
                                lookup: {
                                  3: "IVA 0%",
                                  4: "IVA 10.5%",
                                  5: "IVA 21%",
                                  6: "IVA 27%",
                                },
                              },
                            ]}
                            data={articulos}
                            title="Artículos"
                            options={{
                              search: true,
                              sorting: true,
                              actionsColumnIndex: -1,
                              rowStyle: (rowData) => ({
                                backgroundColor:
                                  selectedRow === rowData.tableData.id
                                    ? "#EEE"
                                    : "#FFF",
                              }),
                            }}
                            onRowClick={(evt, selectedRow) => (
                              setSelectedRow(selectedRow.tableData.id),
                              agregarItem(selectedRow.tableData.id)
                            )}
                            localization={{
                              toolbar: {
                                searchPlaceholder: "Buscar",
                              },
                            }}
                          />
                        ) : null}
                      </div>
                    </Dialog>

                    <Dialog
                      visible={modalProveedores}
                      style={{ width: "50vw" }}
                      modal={true}
                      showHeader={false}
                      dismissableMask={true}
                      onHide={() => setModalProveedores(false)}
                    >
                      <div style={{ maxWidth: "100%" }}>
                        {proveedores ? (
                          <MaterialTable
                            columns={[
                              {
                                title: "ID del proveedor",
                                field: "id",
                                editable: "never",
                              },
                              {
                                title: "Nombre/Razón social",
                                field: "nombre",
                                editable: "never",
                                cellStyle: {
                                  width: 400,
                                  minWidth: 400,
                                },
                                headerStyle: {
                                  width: 400,
                                  minWidth: 400,
                                },
                              },
                              {
                                title: "C.U.I.T.",
                                field: "cuit",
                                editable: "never",
                              },
                            ]}
                            data={proveedores}
                            title="Proveedores"
                            options={{
                              search: true,
                              sorting: true,
                              actionsColumnIndex: -1,
                              rowStyle: (rowData) => ({
                                backgroundColor:
                                  selectedRow === rowData.tableData.id
                                    ? "#EEE"
                                    : "#FFF",
                              }),
                            }}
                            onRowClick={(evt, selectedRow) => (
                              setSelectedRow(selectedRow.tableData.id),
                              agregarProveedor(selectedRow.tableData.id)
                            )}
                            localization={{
                              toolbar: {
                                searchPlaceholder: "Buscar",
                              },
                            }}
                          />
                        ) : null}
                      </div>
                    </Dialog>
                  </TabPanel>
                </Tabs>

                {/*
                <input
                  type="submit"
                  className="btn btn-background btn-block mt-4"
                  value="Guardar comprobante"
                />
                
                */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ComprobanteCompras;
