import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AuthContext } from "../../../context/AuthContext";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";

function Concepto(props) {
  // extraer los valores del context

  const authContext = useContext(AuthContext);
  const { autenticado, registrarUsuario } = authContext;

  const archivoContext = useContext(ArchivoContext);
  const {
    concepto,
    conceptos,
    obtenerConcepto,
    guardarConcepto,
    obtenerConceptos,
    crearConcepto,
    actualizarConcepto,
  } = archivoContext;

  let growl = useRef();

  const idConcepto = props.match.params.id;

  useEffect(() => {
    if (idConcepto !== "0") {
      obtenerConcepto({ idConcepto });
    } else {
      guardarConcepto({
        id: "",
        descripcion: "",
        habilitado: true,
      });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (concepto.descripcion.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Conceptos",
        detail: "El nombre del concepto es obligatorio.",
      });
      return;
    }


    //console.log(concepto);

    if (idConcepto === "0") {
      crearConcepto(concepto);
    } else {
      actualizarConcepto(concepto);
    }
  };

  const cerrar = () => {
    props.history.push("/conceptos");
  };

  return (
    <Fragment>
      {!concepto ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {concepto.descripcion}
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <div class="row">
                    <div className="col-xs-12 col-md-6">

                    <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="id"
                            type="numeric"
                            className="form-control mt-4"
                            value={concepto.id}
                            disabled={ idConcepto !== "0" } 
                            onChange={(e) =>
                              guardarConcepto({
                                ...concepto,
                                id: Number(e.target.value),
                              })
                            }
                          />
                          <label htmlFor="id" style={{ color: "black" }}>
                            ID del concepto
                          </label>
                        </span>
                      </div>


                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="descripcion"
                            type="text"
                            className="form-control mt-4"
                            value={concepto.descripcion}
                            onChange={(e) =>
                              guardarConcepto({
                                ...concepto,
                                descripcion: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="descripcion" style={{ color: "black" }}>
                            Descripción del concepto
                          </label>
                        </span>
                      </div>


                      <div className="form-group">
                        <label
                          htmlFor="habilitado"
                          style={{ color: "black", marginRight: "1em" }}
                        >
                          Habilitado:
                        </label>
                        <ToggleButton
                          onLabel="Si"
                          offLabel="No"
                          onIcon="pi pi-check"
                          offIcon="pi pi-times"
                          checked={concepto.habilitado}
                          onChange={(e) =>
                            guardarConcepto({
                              ...concepto,
                              habilitado: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn btn-background btn-block mt-4"
                    value="Guardar concepto"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Concepto;
