import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AuthContext } from "../../../context/AuthContext";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";

function Localidad(props) {
  // extraer los valores del context

  const authContext = useContext(AuthContext);
  const { autenticado, registrarUsuario } = authContext;

  const archivoContext = useContext(ArchivoContext);
  const {
    localidad,
    localidades,
    obtenerLocalidad,
    guardarLocalidad,
    obtenerLocalidades,
    crearLocalidad,
    actualizarLocalidad,
  } = archivoContext;

  let growl = useRef();

  const idLocalidad = props.match.params.id;

  useEffect(() => {
    if (idLocalidad !== "0") {
      obtenerLocalidad({ idLocalidad });
    } else {
      guardarLocalidad({
        nombre: "",
        cp: "",
        habilitado: true,
      });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (localidad.nombre.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Localidades",
        detail: "El nombre de la localidad es obligatorio.",
      });
      return;
    }


    console.log(localidad);

    if (idLocalidad === "0") {
      crearLocalidad(localidad);
    } else {
      actualizarLocalidad(localidad);
    }
  };

  const cerrar = () => {
    props.history.push("/localidades");
  };

  return (
    <Fragment>
      {!localidad ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {localidad.nombre}
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <div class="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="nombre"
                            type="text"
                            className="form-control mt-4"
                            value={localidad.nombre}
                            onChange={(e) =>
                              guardarLocalidad({
                                ...localidad,
                                nombre: e.target.value.toUpperCase(),
                              })
                            }
                          />
                          <label htmlFor="nombre" style={{ color: "black" }}>
                            Nombre de la localidad
                          </label>
                        </span>
                      </div>


                      <div className="form-group">
                        <label
                          htmlFor="habilitado"
                          style={{ color: "black", marginRight: "1em" }}
                        >
                          Habilitado:
                        </label>
                        <ToggleButton
                          onLabel="Si"
                          offLabel="No"
                          onIcon="pi pi-check"
                          offIcon="pi pi-times"
                          checked={localidad.habilitado}
                          onChange={(e) =>
                            guardarLocalidad({
                              ...localidad,
                              habilitado: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn btn-background btn-block mt-4"
                    value="Guardar localidad"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Localidad;
