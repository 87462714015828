import React, { useState, useRef, Fragment } from "react";
import clienteAxios from "../config/axios";
import { createContext } from "react";
import { Growl } from "primereact/growl";

const ArchivoContext = createContext();
const { Provider, Consumer } = ArchivoContext;

function ArchivoProvider({ children }) {
  let growl = useRef();

  const [clientes, guardarClientes] = useState(null);
  const [cliente, guardarCliente] = useState(null);
  const [proveedores, guardarProveedores] = useState(null);
  const [proveedor, guardarProveedor] = useState(null);
  const [empresas, guardarEmpresas] = useState(null);
  const [empresaNavbar, guardarEmpresaNavbar] = useState(null);
  const [empresasNavbar, guardarEmpresasNavbar] = useState(null);
  const [tiposComprobantes, guardarTiposComprobantes] = useState(null);
  const [tipoComprobantes, guardarTipoComprobantes] = useState(null);
  const [
    tiposComprobantesAsociado,
    guardarTiposComprobantesAsociado,
  ] = useState(null);
  const [empresa, guardarEmpresa] = useState(null);
  const [localidades, guardarLocalidades] = useState(null);
  const [localidad, guardarLocalidad] = useState(null);
  const [vendedores, guardarVendedores] = useState(null);
  const [vendedor, guardarVendedor] = useState(null);
  const [bancos, guardarBancos] = useState(null);
  const [banco, guardarBanco] = useState(null);
  const [condicionesIva, guardarCondicionesIva] = useState(null);
  const [condicionesVenta, guardarCondicionesVenta] = useState(null);
  const [impuestos, guardarImpuestos] = useState(null);
  const [impuesto, guardarImpuesto] = useState(null);
  const [tiposRetenciones, guardarTiposRetenciones] = useState(null);
  const [tipoRetencion, guardarTipoRetencion] = useState(null);
  const [articulos, guardarArticulos] = useState(null);
  const [articulo, guardarArticulo] = useState(null);
  const [listasPrecios, guardarListasPrecios] = useState(null);
  const [listaPrecio, guardarListaPrecio] = useState(null);
  const [provincias, guardarProvincias] = useState(null);
  const [cuentas, guardarCuentas] = useState(null);
  const [cuenta, guardarCuenta] = useState(null);
  const [conceptos, guardarConceptos] = useState(null);
  const [concepto, guardarConcepto] = useState(null);
  const [retencionesGanancias, guardarRetencionesGanancias] = useState(null);
  const [fletes, guardarFletes] = useState(null);
  const [flete, guardarFlete] = useState(null);
  const [rubros, guardarRubros] = useState(null);
  const [rubro, guardarRubro] = useState(null);

  //PROVINCIAS //

  const obtenerProvinciasSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/provincias/obtenerprovinciasselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }

      guardarProvincias(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // IVA //

  const obtenerCondicionesIvaSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/condicionesivas/obtenercondicionesivaselect"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }

      guardarCondicionesIva(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // CONDICIONES VENTA //

  const obtenerCondicionesVentaSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/condicionesventas/obtenercondicionesventas"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }

      guardarCondicionesVenta(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  //CLIENTES //

  const obtenerClientesSelect = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/clientes/obtenerclientes");
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].id + " - " + data[i].nombre,
          value: data[i].id,
          nombre: data[i].nombre,
        });
      }

      guardarClientes(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerClientes = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/clientes/obtenerclientes");
      let data = respuesta.data;

      guardarClientes(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const buscarClientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/clientes/buscarclientes",
        datos
      );
      let data = respuesta.data.response;

      guardarClientes(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearCliente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/clientes/crearcliente",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Clientes",
        detail: "El cliente se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerCliente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/clientes/obtenercliente",
        datos
      );
      let data = respuesta.data;

      guardarCliente(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const actualizarCliente = async (datosCliente, datosImpuestos) => {
    let datosImpuestosHabilitados = [];
    for (var i = 0; i < datosImpuestos.length; i++) {
      if (parseFloat(datosImpuestos[i].alicuota) !== 0) {
        datosImpuestosHabilitados.push({
          idCliente: datosImpuestos[i].idCliente,
          idImpuesto: datosImpuestos[i].idImpuesto,
          alicuota: datosImpuestos[i].alicuota,
        });
      }
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/clientes/actualizarcliente",
        { datosCliente, datosImpuestosHabilitados }
      );

      await growl.show({
        severity: "success",
        summary: "Clientes",
        detail: "El cliente se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // PROVEEDORES //

  const obtenerProveedoresSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/proveedores/obtenerproveedores"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].id + " - " + data[i].nombre,
          value: data[i].id,
          nombre: data[i].nombre,
        });
      }

      guardarProveedores(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerProveedores = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/proveedores/obtenerproveedores"
      );
      let data = respuesta.data;

      guardarProveedores(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const buscarProveedores = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/proveedores/buscarproveedores",
        datos
      );
      let data = respuesta.data.response;

      guardarProveedores(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/proveedores/crearproveedor",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Proveedores",
        detail: "El proveedor se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/proveedores/obtenerproveedor",
        datos
      );
      let data = respuesta.data;

      guardarProveedor(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const actualizarProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/proveedores/actualizarproveedor",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Proveedores",
        detail: "El proveedor se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // EMPRESAS //

  const obtenerEmpresasSelect = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/empresas/obtenerempresas");
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }
      guardarEmpresas(local);
      guardarEmpresasNavbar(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerEmpresas = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/empresas/obtenerempresas");
      let data = respuesta.data;

      guardarEmpresas(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/empresas/obtenerempresa",
        datos
      );
      let data = respuesta.data;
      guardarEmpresa(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearEmpresa = async (datosEmpresa, datosImpuestos) => {
    let datosImpuestosHabilitados = [];
    for (var i = 0; i < datosImpuestos.length; i++) {
      if (datosImpuestos[i].habilitado === true) {
        datosImpuestosHabilitados.push({
          idImpuesto: datosImpuestos[i].idImpuesto,
          habilitado: true,
        });
      }
    }

    try {
      const respuesta = await clienteAxios.post("/api/empresas/crearempresa", {
        datosEmpresa,
        datosImpuestosHabilitados,
      });

      await growl.show({
        severity: "success",
        summary: "Empresas",
        detail: "La empresa se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
      await growl.show({
        severity: "error",
        summary: "Empresas",
        detail: "El nombre de la empresa ya existe.",
      });
    }
  };

  const actualizarEmpresa = async (
    datosEmpresa,
    datosImpuestos,
    datosTiposComprobantes
  ) => {
    let datosImpuestosHabilitados = [];
    for (var i = 0; i < datosImpuestos.length; i++) {
      if (datosImpuestos[i].habilitado === true) {
        datosImpuestosHabilitados.push({
          idEmpresa: datosImpuestos[i].idEmpresa,
          idImpuesto: datosImpuestos[i].idImpuesto,
          habilitado: true,
        });
      }
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/empresas/actualizarempresa",
        { datosEmpresa, datosImpuestosHabilitados, datosTiposComprobantes }
      );

      await growl.show({
        severity: "success",
        summary: "Empresas",
        detail: "La empresa se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // LOCALIDADES //

  const obtenerLocalidadesSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/localidades/obtenerlocalidadesselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }
      guardarLocalidades(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerLocalidades = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/localidades/obtenerlocalidades"
      );
      let data = respuesta.data;

      guardarLocalidades(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerLocalidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/localidades/obtenerlocalidad",
        datos
      );
      let data = respuesta.data;
      guardarLocalidad(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearLocalidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/localidades/crearlocalidad",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Localidades",
        detail: "La localidad se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const actualizarLocalidad = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/localidades/actualizarlocalidad",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Localidades",
        detail: "La localidad se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // VENDEDORES //

  const obtenerVendedoresSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/vendedores/obtenervendedoresselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }
      guardarVendedores(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerVendedores = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/vendedores/obtenervendedores"
      );
      let data = respuesta.data;

      guardarVendedores(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerVendedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/vendedores/obtenervendedor",
        datos
      );
      let data = respuesta.data;
      guardarVendedor(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearVendedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/vendedores/crearvendedor",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Vendedores",
        detail: "El vendedor se ha creado correctamente.",
      });
    } catch (error) {
      console.log("error.response");

      await growl.show({
        severity: "error",
        summary: "Vendedores",
        detail: "El ID o nombre del vendedor ya existe.",
      });
    }
  };

  const actualizarVendedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/vendedores/actualizarvendedor",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Vendedores",
        detail: "El vendedor se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // BANCOS //

  const obtenerBancosLookup = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/bancos/obtenerbancosselect"
      );
      let data = respuesta.data;

      var obj = data.reduce(function (acc, cur, i) {
        acc[cur.id] = cur.nombre;

        return acc;
      }, {});

      guardarBancos(obj);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerBancosSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/bancos/obtenerbancosselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }
      guardarBancos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerBancos = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/bancos/obtenerbancos");
      let data = respuesta.data;

      guardarBancos(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerBanco = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/bancos/obtenerbanco",
        datos
      );
      let data = respuesta.data;
      guardarBanco(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearBanco = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/bancos/crearbanco",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Bancos",
        detail: "El banco se ha creado correctamente.",
      });
    } catch (error) {
      console.log("error.response");

      await growl.show({
        severity: "error",
        summary: "Bancos",
        detail: "El nombre del banco ya existe.",
      });
    }
  };

  const actualizarBanco = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/bancos/actualizarbanco",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Bancos",
        detail: "El banco se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // FLETES //

  const obtenerFletesLookup = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/fletes/obtenerfletesselect"
      );
      let data = respuesta.data;

      var obj = data.reduce(function (acc, cur, i) {
        acc[cur.id] = cur.nombre;

        return acc;
      }, {});

      guardarFletes(obj);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerFletesSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/fletes/obtenerfletesselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
          domicilio: data[i].domicilio,
          cuit: data[i].cuit,
        });
      }
      guardarFletes(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerFletes = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/fletes/obtenerfletes");
      let data = respuesta.data;

      guardarFletes(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerFlete = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fletes/obtenerflete",
        datos
      );
      let data = respuesta.data;
      guardarFlete(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearFlete = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fletes/crearflete",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Fletes",
        detail: "El flete se ha creado correctamente.",
      });
    } catch (error) {
      console.log("error.response");

      await growl.show({
        severity: "error",
        summary: "Fletes",
        detail: "El nombre del flete ya existe.",
      });
    }
  };

  const actualizarFlete = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/fletes/actualizarflete",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Fletes",
        detail: "El flete se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // RUBROS //

  const obtenerRubrosLookup = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/rubros/obtenerrubrosselect"
      );
      let data = respuesta.data;

      var obj = data.reduce(function (acc, cur, i) {
        acc[cur.id] = cur.nombre;

        return acc;
      }, {});

      guardarRubros(obj);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerRubrosSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/rubros/obtenerrubrosselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }
      guardarRubros(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerRubros = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/rubros/obtenerrubros");
      let data = respuesta.data;

      guardarRubros(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerRubro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/rubros/obtenerrubro",
        datos
      );
      let data = respuesta.data;
      guardarRubro(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearRubro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/rubros/crearrubro",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Rubros",
        detail: "El rubro se ha creado correctamente.",
      });
    } catch (error) {
      console.log("error.response");

      await growl.show({
        severity: "error",
        summary: "Rubros",
        detail: "El nombre del rubro ya existe.",
      });
    }
  };

  const actualizarRubro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/rubros/actualizarrubro",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Rubros",
        detail: "El rubro se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // IMPUESTOS //

  const obtenerImpuestosSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/impuestos/obtenerimpuestosselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }
      guardarImpuestos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerImpuestos = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/impuestos/obtenerimpuestos"
      );
      let data = respuesta.data;

      guardarImpuestos(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerImpuestosEmpresa = async (dato) => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/impuestos/obtenerimpuestosselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        let idImpuesto = data[i].id;
        let idEmpresa = Number(dato);

        let datos = { idEmpresa, idImpuesto };

        try {
          const respuesta = await clienteAxios.post(
            "/api/impuestosempresas/obtenerimpuestosempresa",
            datos
          );
          let newData = respuesta.data;

          local.push({
            id: i,
            idEmpresa: idEmpresa,
            idImpuesto: idImpuesto,
            nombre: data[i].nombre,
            habilitado: newData.habilitado,
          });
        } catch (error) {
          //console.log(error.response);

          local.push({
            id: i,
            idEmpresa: idEmpresa,
            idImpuesto: idImpuesto,
            nombre: data[i].nombre,
            habilitado: false,
          });
        }
      }
      guardarImpuestos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerImpuestosCliente = async (dats) => {
    const { id, empresa } = dats;

    try {
      const respuesta = await clienteAxios.get(
        "/api/impuestos/obtenerimpuestosselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        let idImpuesto = data[i].id;
        let idCliente = Number(id);

        let datos = { idCliente, idImpuesto };

        try {
          const respuesta = await clienteAxios.post(
            "/api/impuestosclientes/obtenerimpuestocliente",
            datos
          );
          let newData = respuesta.data;

         if (!empresa) {
            local.push({
              id: i,
              idCliente: idCliente,
              idImpuesto: idImpuesto,
              nombre: data[i].nombre,
              alicuota: newData.alicuota,
            });
          } else {
            local.push({
              id: i,
              idCliente: idCliente,
              idImpuesto: idImpuesto,
              nombre: data[i].nombre,
              alicuota: empresa.percepciones ? newData.alicuota : 0,
            });
          }
        } catch (error) {
          //console.log(error.response);

          local.push({
            id: i,
            idCliente: idCliente,
            idImpuesto: idImpuesto,
            nombre: data[i].nombre,
            alicuota: 0,
          });
        }
      }

      guardarImpuestos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // TIPOS DE RETENCIONES //

  const obtenerTiposRetencionesLookup = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/tiposretenciones/obtenertiposretenciones"
      );
      let data = respuesta.data;

      var obj = data.reduce(function (acc, cur, i) {
        acc[cur.id] = cur.nombre;

        return acc;
      }, {});

      guardarTiposRetenciones(obj);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTiposRetencionesSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/tiposretenciones/obtenertiposretencionesselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }
      guardarTiposRetenciones(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTiposRetenciones = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/tiposretenciones/obtenertiposretenciones"
      );
      let data = respuesta.data;

      guardarTiposRetenciones(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTipoRetencion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposretenciones/obtenertiporetencion",
        datos
      );
      let data = respuesta.data;
      guardarTipoRetencion(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearTipoRetencion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposretenciones/creartiporetencion",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Tipos de retenciones",
        detail: "El tipo de retención se ha creado correctamente.",
      });
    } catch (error) {
      console.log("error.response");

      await growl.show({
        severity: "error",
        summary: "Tipos de retenciones",
        detail: "El ID o nombre del tipo de retención ya existe.",
      });
    }
  };

  const actualizarTipoRetencion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposretenciones/actualizartiporetencion",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Tipos de retenciones",
        detail: "El tipo de retención se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerConceptosRetenciones = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/tiposretenciones/obtenerconceptosretenciones"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].detalle,
          value: data[i].id,
        });
      }
      guardarRetencionesGanancias(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // IMPUESTOS

  const obtenerImpuesto = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/impuestos/obtenerimpuesto",
        datos
      );
      let data = respuesta.data;
      guardarImpuesto(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearImpuesto = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/impuestos/crearimpuesto",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Impuestos",
        detail: "El impuesto se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const actualizarImpuesto = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/impuestos/actualizarimpuesto",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Impuestos",
        detail: "El impuesto se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // ARTICULOS //

  const obtenerArticulosSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/articulos/obtenerarticulosselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }
      guardarArticulos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerArticulos = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticulos"
      );
      let data = respuesta.data;
      guardarArticulos(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerArticulo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticulo",
        datos
      );
      let data = respuesta.data;
      guardarArticulo(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearArticulo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/creararticulo",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Artículos",
        detail: "El artículo se ha creado correctamente.",
      });
    } catch (error) {
      console.log("error.response");

      await growl.show({
        severity: "error",
        summary: "Artículos",
        detail: "El ID o nombre del artículo ya existe.",
      });
    }
  };

  const actualizarArticulo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/actualizararticulo",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Artículos",
        detail: "El artículo se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerArticulosListasPrecios = async (dato) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticuloshabilitados"
      );
      let data = respuesta.data;
      let idListaPrecio = Number(dato);
      const local = [];


      console.log(data)


      for (var i = 0; i < data.length; i++) {
        let precio = 0;
        let ivaId = 5; //21%
        let foundStatus = false;

        for (var j = 0; j < data[i].articuloslistasprecios.length; j++) {
          if (
            data[i].articuloslistasprecios[j].idListaPrecio === Number(dato)
          ) {
            precio = data[i].articuloslistasprecios[j].precio;
            ivaId = data[i].articuloslistasprecios[j].iva.id;
            foundStatus = true;



            local.push({
              id: i,
              articulosListasId: data[i].articuloslistasprecios[j].id,
              idListaPrecio: idListaPrecio,
              articuloId: data[i].id,
              nombre: data[i].nombre + " " + data[i].unidad,
              precio: precio,
              ivaId: ivaId,
            });
          }
        }
        if (!foundStatus) {
          local.push({
            id: i,
            articulosListasId: null,
            idListaPrecio: idListaPrecio,
            articuloId: data[i].id,
            nombre: data[i].nombre,
            precio: precio,
            ivaId: ivaId,
          });
        }
      }

      guardarArticulos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerArticulosCompras = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticuloshabilitadoscompras"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        let costo = 0;
        let ivaId = 5; //21%

        if (data[i].costo !== null) {
          costo = data[i].costo;
        }
        local.push({
          id: i,
          articuloId: data[i].id,
          nombre: data[i].nombre,
          costo: costo,
          ivaId: ivaId,
        });
      }

      guardarArticulos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // LISTAS DE PRECIOS //

  const obtenerListasPreciosSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/listasprecios/obtenerlistaspreciosselect"
      );

      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }

      guardarListasPrecios(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerListasPrecios = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/listasprecios/obtenerlistasprecios"
      );
      let data = respuesta.data;

      guardarListasPrecios(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerListaPrecio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/listasprecios/obtenerlistaprecio",
        datos
      );
      let data = respuesta.data;
      guardarListaPrecio(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearListaPrecio = async (datosListaPrecio, datosArticulos) => {
    let datosArticulosHabilitados = [];
    for (var i = 0; i < datosArticulos.length; i++) {
      if (datosArticulos[i].precio !== 0) {
        datosArticulosHabilitados.push({
          idArticulo: datosArticulos[i].idArticulo,
          precio: datosArticulos[i].precio,
        });
      }
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/listasprecios/crearlistaprecio",
        { datosListaPrecio, datosArticulosHabilitados }
      );

      await growl.show({
        severity: "success",
        summary: "Listas de precios",
        detail: "La lista de precios se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
      await growl.show({
        severity: "error",
        summary: "Listas de precios",
        detail: "El nombre de la empresa ya existe.",
      });
    }
  };

  const actualizarListaPrecio = async (datosListaPrecio, datosArticulos) => {
    let datosArticulosHabilitados = [];
    for (var i = 0; i < datosArticulos.length; i++) {
      datosArticulosHabilitados.push({
        id: datosArticulos[i].articulosListasId,
        idListaPrecio: datosArticulos[i].idListaPrecio,
        articuloId: datosArticulos[i].articuloId,
        precio: datosArticulos[i].precio,
        ivaId: datosArticulos[i].ivaId,
      });
    }
  
    try {
      const respuesta = await clienteAxios.post(
        "/api/listasprecios/actualizarlistaprecio",
        { datosArticulosHabilitados, datosListaPrecio }
      );

      await growl.show({
        severity: "success",
        summary: "Listas de precios",
        detail: "La lista de precios se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // TIPOS COMPROBANTES //

  const obtenerTiposComprobantesAsociadoEmpresaSelect = async (datos) => {
    const { tipo } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposcomprobantesempresas/obtenertiposcomprobantesempresa",
        datos
      );

      let data = respuesta.data;

      let nRemito = 0;
      let tipoComprobanteRemitoId = 0;
      let puntoVentaRemito = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i].tiposcomprobantes.abreviatura === "R") {
          nRemito = data[i].numero;
          tipoComprobanteRemitoId = data[i].tiposcomprobanteId;
          puntoVentaRemito = data[i].puntoVenta;
        }
      }

      let abreviatura = "";
      const local = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].tiposcomprobantes.abreviatura !== "R") {
          //Remitos

          if (tipo === "todos") {
            local.push({
              label: data[i].tiposcomprobantes.nombre,
              value: data[i].tiposcomprobanteId,
              codigoAfip: data[i].tiposcomprobantes.codigoAfip,
              abreviatura: data[i].tiposcomprobantes.abreviatura,
              puntoVenta: data[i].puntoVenta,
              numero: data[i].numero,
              tipo: data[i].tiposcomprobantes.tipo,
              numeroRemito: nRemito,
              tipoComprobanteRemitoId: tipoComprobanteRemitoId,
              puntoVentaRemito: puntoVentaRemito,
            });
          }

          if (tipo === "fc") {
            abreviatura = data[i].tiposcomprobantes.abreviatura;
            if (
              abreviatura === "FA" ||
              abreviatura === "FB" ||
              abreviatura === "FC" ||
              abreviatura === "FM" ||
              abreviatura === "FCEA" ||
              abreviatura === "FCEB" ||
              abreviatura === "FCEC"
            ) {
              local.push({
                label: data[i].tiposcomprobantes.nombre,
                value: data[i].tiposcomprobanteId,
                codigoAfip: data[i].tiposcomprobantes.codigoAfip,
                abreviatura: data[i].tiposcomprobantes.abreviatura,
                puntoVenta: data[i].puntoVenta,
                numero: data[i].numero,
                tipo: data[i].tiposcomprobantes.tipo,
                numeroRemito: nRemito,
                tipoComprobanteRemitoId: tipoComprobanteRemitoId,
                puntoVentaRemito: puntoVentaRemito,
              });
            }
          }

          if (tipo === "nc") {
            abreviatura = data[i].tiposcomprobantes.abreviatura;
            if (
              abreviatura === "NCA" ||
              abreviatura === "NCB" ||
              abreviatura === "NCC" ||
              abreviatura === "NCM" ||
              abreviatura === "NCEA" ||
              abreviatura === "NCEB" ||
              abreviatura === "NCEC"
            ) {
              local.push({
                label: data[i].tiposcomprobantes.nombre,
                value: data[i].tiposcomprobanteId,
                codigoAfip: data[i].tiposcomprobantes.codigoAfip,
                abreviatura: data[i].tiposcomprobantes.abreviatura,
                puntoVenta: data[i].puntoVenta,
                numero: data[i].numero,
                tipo: data[i].tiposcomprobantes.tipo,
                numeroRemito: nRemito,
                tipoComprobanteRemitoId: tipoComprobanteRemitoId,
                puntoVentaRemito: puntoVentaRemito,
              });
            }
          }

          if (tipo === "nd") {
            abreviatura = data[i].tiposcomprobantes.abreviatura;
            if (
              abreviatura === "NDA" ||
              abreviatura === "NDB" ||
              abreviatura === "NDC" ||
              abreviatura === "NDM" ||
              abreviatura === "NDEA" ||
              abreviatura === "NDEB" ||
              abreviatura === "NDEC"
            ) {
              local.push({
                label: data[i].tiposcomprobantes.nombre,
                value: data[i].tiposcomprobanteId,
                codigoAfip: data[i].tiposcomprobantes.codigoAfip,
                abreviatura: data[i].tiposcomprobantes.abreviatura,
                puntoVenta: data[i].puntoVenta,
                numero: data[i].numero,
                tipo: data[i].tiposcomprobantes.tipo,
                numeroRemito: nRemito,
                tipoComprobanteRemitoId: tipoComprobanteRemitoId,
                puntoVentaRemito: puntoVentaRemito,
              });
            }
          }
        }
      }

      guardarTiposComprobantesAsociado(local);
    } catch (error) {
      console.log(error.response);
      guardarTiposComprobantesAsociado(null);
    }
  };

  const obtenerTiposComprobantesEmpresaSelect = async (datos) => {
    const { tipo } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposcomprobantesempresas/obtenertiposcomprobantesempresa",
        datos
      );

      let data = respuesta.data;

      let nRemito = 0;
      let tipoComprobanteRemitoId = 0;
      let puntoVentaRemito = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i].tiposcomprobantes.abreviatura === "R") {
          nRemito = data[i].numero;
          tipoComprobanteRemitoId = data[i].tiposcomprobanteId;
          puntoVentaRemito = data[i].puntoVenta;
        }
      }

      let abreviatura = "";
      const local = [];
      for (var i = 0; i < data.length; i++) {
        if (tipo === "todos") {
          local.push({
            label: data[i].tiposcomprobantes.nombre,
            value: data[i].tiposcomprobanteId,
            codigoAfip: data[i].tiposcomprobantes.codigoAfip,
            abreviatura: data[i].tiposcomprobantes.abreviatura,
            puntoVenta: data[i].puntoVenta,
            numero: data[i].numero,
            tipo: data[i].tiposcomprobantes.tipo,
            numeroRemito: nRemito,
            tipoComprobanteRemitoId: tipoComprobanteRemitoId,
            puntoVentaRemito: puntoVentaRemito,
          });
        }

        if (tipo === "fc") {
          abreviatura = data[i].tiposcomprobantes.abreviatura;
          if (
            abreviatura === "FA" ||
            abreviatura === "FB" ||
            abreviatura === "FC" ||
            abreviatura === "FM" ||
            abreviatura === "FCEA" ||
            abreviatura === "FCEB" ||
            abreviatura === "FCEC" ||
            abreviatura === "P"
          ) {
            local.push({
              label: data[i].tiposcomprobantes.nombre,
              value: data[i].tiposcomprobanteId,
              codigoAfip: data[i].tiposcomprobantes.codigoAfip,
              abreviatura: data[i].tiposcomprobantes.abreviatura,
              puntoVenta: data[i].puntoVenta,
              numero: data[i].numero,
              tipo: data[i].tiposcomprobantes.tipo,
              numeroRemito: nRemito,
              tipoComprobanteRemitoId: tipoComprobanteRemitoId,
              puntoVentaRemito: puntoVentaRemito,
            });
          }
        }

        if (tipo === "nc") {
          abreviatura = data[i].tiposcomprobantes.abreviatura;
          if (
            abreviatura === "NCA" ||
            abreviatura === "NCB" ||
            abreviatura === "NCC" ||
            abreviatura === "NCM" ||
            abreviatura === "NCEA" ||
            abreviatura === "NCEB" ||
            abreviatura === "NCEC"
          ) {
            local.push({
              label: data[i].tiposcomprobantes.nombre,
              value: data[i].tiposcomprobanteId,
              codigoAfip: data[i].tiposcomprobantes.codigoAfip,
              abreviatura: data[i].tiposcomprobantes.abreviatura,
              puntoVenta: data[i].puntoVenta,
              numero: data[i].numero,
              tipo: data[i].tiposcomprobantes.tipo,
              numeroRemito: nRemito,
              tipoComprobanteRemitoId: tipoComprobanteRemitoId,
              puntoVentaRemito: puntoVentaRemito,
            });
          }
        }

        if (tipo === "nd") {
          abreviatura = data[i].tiposcomprobantes.abreviatura;
          if (
            abreviatura === "NDA" ||
            abreviatura === "NDB" ||
            abreviatura === "NDC" ||
            abreviatura === "NDM" ||
            abreviatura === "NDEA" ||
            abreviatura === "NDEB" ||
            abreviatura === "NDEC"
          ) {
            local.push({
              label: data[i].tiposcomprobantes.nombre,
              value: data[i].tiposcomprobanteId,
              codigoAfip: data[i].tiposcomprobantes.codigoAfip,
              abreviatura: data[i].tiposcomprobantes.abreviatura,
              puntoVenta: data[i].puntoVenta,
              numero: data[i].numero,
              tipo: data[i].tiposcomprobantes.tipo,
              numeroRemito: nRemito,
              tipoComprobanteRemitoId: tipoComprobanteRemitoId,
              puntoVentaRemito: puntoVentaRemito,
            });
          }
        }

        if (tipo === "r") {
          abreviatura = data[i].tiposcomprobantes.abreviatura;
          if (abreviatura === "R") {
            local.push({
              label: data[i].tiposcomprobantes.nombre,
              value: data[i].tiposcomprobanteId,
              codigoAfip: data[i].tiposcomprobantes.codigoAfip,
              abreviatura: data[i].tiposcomprobantes.abreviatura,
              puntoVenta: data[i].puntoVenta,
              numero: data[i].numero,
              tipo: data[i].tiposcomprobantes.tipo,
              numeroRemito: nRemito,
              tipoComprobanteRemitoId: tipoComprobanteRemitoId,
              puntoVentaRemito: puntoVentaRemito,
            });
          }
        }
      }

      guardarTiposComprobantes(local);
    } catch (error) {
      console.log(error.response);
      guardarTiposComprobantes(null);
    }
  };

  const obtenerTiposComprobantesComprasEmpresaSelect = async (datos) => {
    const { tipo } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposcomprobantesempresas/obtenertiposcomprobantesempresa",
        datos
      );

      let data = respuesta.data;

      let nRemito = 0;
      let tipoComprobanteRemitoId = 0;
      let puntoVentaRemito = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i].tiposcomprobantes.abreviatura === "R") {
          nRemito = data[i].numero;
          tipoComprobanteRemitoId = data[i].tiposcomprobanteId;
          puntoVentaRemito = data[i].puntoVenta;
        }
      }

      let abreviatura = "";
      const local = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].tiposcomprobantes.abreviatura !== "R") {
          //Remitos

          if (tipo === "todos") {
            local.push({
              label: data[i].tiposcomprobantes.nombre,
              value: data[i].tiposcomprobanteId,
              codigoAfip: data[i].tiposcomprobantes.codigoAfip,
              abreviatura: data[i].tiposcomprobantes.abreviatura,
              puntoVenta: data[i].puntoVenta,
              numero: data[i].numero,
              tipo: data[i].tiposcomprobantes.tipo,
              numeroRemito: nRemito,
              tipoComprobanteRemitoId: tipoComprobanteRemitoId,
              puntoVentaRemito: puntoVentaRemito,
            });
          }

          if (tipo === "fc") {
            abreviatura = data[i].tiposcomprobantes.abreviatura;
            if (
              abreviatura === "FA" ||
              abreviatura === "FB" ||
              abreviatura === "FC" ||
              abreviatura === "FM" ||
              abreviatura === "FCEA" ||
              abreviatura === "FCEB" ||
              abreviatura === "FCEC"
            ) {
              local.push({
                label: data[i].tiposcomprobantes.nombre,
                value: data[i].tiposcomprobanteId,
                codigoAfip: data[i].tiposcomprobantes.codigoAfip,
                abreviatura: data[i].tiposcomprobantes.abreviatura,
                puntoVenta: 1,
                numero: 1,
                tipo: data[i].tiposcomprobantes.tipo,
                numeroRemito: 0,
                tipoComprobanteRemitoId: tipoComprobanteRemitoId,
                puntoVentaRemito: 0,
              });
            }
          }

          if (tipo === "nc") {
            abreviatura = data[i].tiposcomprobantes.abreviatura;
            if (
              abreviatura === "NCA" ||
              abreviatura === "NCB" ||
              abreviatura === "NCC" ||
              abreviatura === "NCM" ||
              abreviatura === "NCEA" ||
              abreviatura === "NCEB" ||
              abreviatura === "NCEC"
            ) {
              local.push({
                label: data[i].tiposcomprobantes.nombre,
                value: data[i].tiposcomprobanteId,
                codigoAfip: data[i].tiposcomprobantes.codigoAfip,
                abreviatura: data[i].tiposcomprobantes.abreviatura,
                puntoVenta: 1,
                numero: 1,
                tipo: data[i].tiposcomprobantes.tipo,
                numeroRemito: 0,
                tipoComprobanteRemitoId: tipoComprobanteRemitoId,
                puntoVentaRemito: 0,
              });
            }
          }

          if (tipo === "nd") {
            abreviatura = data[i].tiposcomprobantes.abreviatura;
            if (
              abreviatura === "NDA" ||
              abreviatura === "NDB" ||
              abreviatura === "NDC" ||
              abreviatura === "NDM" ||
              abreviatura === "NDEA" ||
              abreviatura === "NDEB" ||
              abreviatura === "NDEC"
            ) {
              local.push({
                label: data[i].tiposcomprobantes.nombre,
                value: data[i].tiposcomprobanteId,
                codigoAfip: data[i].tiposcomprobantes.codigoAfip,
                abreviatura: data[i].tiposcomprobantes.abreviatura,
                puntoVenta: 1,
                numero: 1,
                tipo: data[i].tiposcomprobantes.tipo,
                numeroRemito: 0,
                tipoComprobanteRemitoId: tipoComprobanteRemitoId,
                puntoVentaRemito: 0,
              });
            }
          }
        }
      }

      guardarTiposComprobantes(local);
    } catch (error) {
      console.log(error.response);
      guardarTiposComprobantes(null);
    }
  };

  const obtenerTiposComprobantesEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposcomprobantesempresas/obtenertiposcomprobantesempresa",
        datos
      );

      let data = respuesta.data;

      const local = [];
      for (var i = 0; i < data.length; i++) {
        local.push({
          id: i,
          tiposcomprobanteId: data[i].tiposcomprobanteId,
          codigoAfip: data[i].tiposcomprobantes.codigoAfip,
          idEmpresa: data[i].idEmpresa,
          nombre: data[i].tiposcomprobantes.nombre,
          numero: data[i].numero,
          puntoVenta: data[i].puntoVenta,
          abreviatura: data[i].tiposcomprobantes.abreviatura,
          tipo: data[i].tiposcomprobantes.tipo,
          codigoAfip: data[i].tiposcomprobantes.codigoAfip,
        });
      }
      guardarTiposComprobantes(local);
    } catch (error) {
      console.log(error.response);
      guardarTiposComprobantes(null);
    }
  };

  const obtenerTiposComprobantesEmpresaAjuste = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposcomprobantesempresas/obtenertiposcomprobantesempresaajuste",
        datos
      );

      let data = respuesta.data;

      const local = [];
      for (var i = 0; i < data.length; i++) {
        local.push({
          id: i,
          tiposcomprobanteId: data[i].tiposcomprobanteId,
          codigoAfip: data[i].tiposcomprobante.codigoAfip,
          idEmpresa: data[i].idEmpresa,
          nombre: data[i].tiposcomprobante.nombre,
          numero: data[i].numero,
          puntoVenta: data[i].puntoVenta,
          abreviatura: data[i].tiposcomprobante.abreviatura,
          tipo: data[i].tiposcomprobante.tipo,
          codigoAfip: data[i].tiposcomprobante.codigoAfip,
        });
      }
      guardarTiposComprobantes(data);
    } catch (error) {
      console.log(error.response);
      guardarTiposComprobantes(null);
    }
  };

  const obtenerTiposRecibosEmpresaSelect = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposcomprobantesempresas/obtenertiposcomprobantesempresa",
        datos
      );

      let data = respuesta.data;

      const local = [];
      for (var i = 0; i < data.length; i++) {
        if (
          data[i].tiposcomprobantes.abreviatura === "RCA" ||
          data[i].tiposcomprobantes.abreviatura === "RCB"
        ) {
          //Recibos
          local.push({
            label: data[i].tiposcomprobantes.nombre,
            value: data[i].tiposcomprobanteId,
            codigoAfip: data[i].tiposcomprobantes.codigoAfip,
            abreviatura: data[i].tiposcomprobantes.abreviatura,
            puntoVenta: data[i].puntoVenta,
            numero: data[i].numero,
            tipo: data[i].tiposcomprobantes.tipo,
          });
        }
      }

      guardarTiposComprobantes(local);
    } catch (error) {
      console.log(error.response);
      guardarTiposComprobantes(null);
    }
  };

  const obtenerTiposOrdenesPagosEmpresaSelect = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/tiposcomprobantesempresas/obtenertiposcomprobantesempresa",
        datos
      );

      let data = respuesta.data;

      const local = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].tiposcomprobantes.abreviatura === "OP") {
          //Ordenes Pagos
          local.push({
            label: data[i].tiposcomprobantes.nombre,
            value: data[i].tiposcomprobanteId,
            codigoAfip: data[i].tiposcomprobantes.codigoAfip,
            abreviatura: data[i].tiposcomprobantes.abreviatura,
            puntoVenta: data[i].puntoVenta,
            numero: data[i].numero,
            tipo: data[i].tiposcomprobantes.tipo,
          });
        }
      }

      guardarTiposComprobantes(local);
    } catch (error) {
      console.log(error.response);
      guardarTiposComprobantes(null);
    }
  };

  // CUENTAS //

  const obtenerCuentasSelect = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/cuentas/obtenercuentas");
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].numero + " - " + data[i].bancos.nombre,
          value: data[i].id,
        });
      }
      guardarCuentas(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerCuentasSelectTodos = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/cuentas/obtenercuentas");
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].numero + " - " + data[i].bancos.nombre,
          value: data[i].id,
        });
      }
      local.push({
        label: "TODAS",
        value: -1,
      });

      guardarCuentas(local);
      guardarCuenta(-1);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerCuentas = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/cuentas/obtenercuentas");
      let data = respuesta.data;

      guardarCuentas(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerCuenta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/cuentas/obtenercuenta",
        datos
      );
      let data = respuesta.data;
      guardarCuenta(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearCuenta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/cuentas/crearcuenta",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Cuentas",
        detail: "La cuenta se ha creado correctamente.",
      });
    } catch (error) {
      console.log("error.response");

      await growl.show({
        severity: "error",
        summary: "Cuentas",
        detail: "La cuenta ya existe.",
      });
    }
  };

  const actualizarCuenta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/cuentas/actualizarcuenta",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Cuentas",
        detail: "El cuenta se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // CONCEPTOS //

  const obtenerConceptosSelect = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/conceptos/obtenerconceptosselect"
      );
      let data = respuesta.data;
      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].descripcion,
          value: data[i].id,
        });
      }

      guardarConceptos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerConceptos = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/conceptos/obtenerconceptos"
      );
      let data = respuesta.data;

      guardarConceptos(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerConcepto = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/conceptos/obtenerconcepto",
        datos
      );
      let data = respuesta.data;
      guardarConcepto(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearConcepto = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/conceptos/crearconcepto",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Conceptos",
        detail: "El concepto se ha creado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
      await growl.show({
        severity: "error",
        summary: "Conceptos",
        detail: "El ID o la descripcion ya existe.",
      });
    }
  };

  const actualizarConcepto = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/conceptos/actualizarconcepto",
        datos
      );

      await growl.show({
        severity: "success",
        summary: "Conceptos",
        detail: "El concepto se ha actualizado correctamente.",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Fragment>
      <Growl ref={(el) => (growl = el)} />
      <Provider
        value={{
          clientes,
          cliente,
          empresas,
          empresasNavbar,
          empresaNavbar,
          tipoComprobantes,
          empresa,
          localidades,
          localidad,
          vendedores,
          vendedor,
          bancos,
          banco,
          condicionesIva,
          condicionesVenta,
          impuestos,
          impuesto,
          tiposRetenciones,
          tipoRetencion,
          articulos,
          articulo,
          listasPrecios,
          listaPrecio,
          provincias,
          tiposComprobantes,
          cuentas,
          cuenta,
          conceptos,
          concepto,
          proveedores,
          proveedor,
          retencionesGanancias,
          tiposComprobantesAsociado,
          fletes,
          flete,
          rubros,
          rubro,
          guardarCliente,
          buscarClientes,
          crearCliente,
          obtenerCliente,
          obtenerClientesSelect,
          actualizarCliente,
          obtenerClientes,
          obtenerEmpresas,
          obtenerEmpresasSelect,
          guardarEmpresa,
          guardarTipoComprobantes,
          obtenerEmpresa,
          obtenerLocalidades,
          obtenerLocalidadesSelect,
          crearEmpresa,
          actualizarEmpresa,
          obtenerLocalidades,
          guardarLocalidad,
          obtenerLocalidad,
          crearLocalidad,
          actualizarLocalidad,
          obtenerVendedores,
          obtenerVendedoresSelect,
          obtenerVendedor,
          guardarVendedor,
          crearVendedor,
          actualizarVendedor,
          obtenerBancos,
          obtenerBanco,
          guardarBanco,
          crearBanco,
          actualizarBanco,
          obtenerBancosSelect,
          obtenerBancosLookup,
          obtenerFletes,
          obtenerFlete,
          guardarFlete,
          crearFlete,
          actualizarFlete,
          obtenerFletesSelect,
          obtenerFletesLookup,
          obtenerCondicionesIvaSelect,
          obtenerCondicionesVentaSelect,
          obtenerImpuestosSelect,
          obtenerImpuestos,
          obtenerImpuesto,
          guardarImpuesto,
          guardarImpuestos,
          crearImpuesto,
          actualizarImpuesto,
          obtenerImpuestosEmpresa,
          obtenerImpuestosCliente,
          obtenerTiposRetenciones,
          obtenerTipoRetencion,
          guardarTipoRetencion,
          crearTipoRetencion,
          actualizarTipoRetencion,
          obtenerArticulos,
          obtenerArticulo,
          guardarArticulo,
          guardarArticulos,
          crearArticulo,
          actualizarArticulo,
          obtenerArticulosListasPrecios,
          obtenerArticulosCompras,
          obtenerListasPreciosSelect,
          obtenerListasPrecios,
          guardarListaPrecio,
          obtenerListaPrecio,
          crearListaPrecio,
          actualizarListaPrecio,
          obtenerProvinciasSelect,
          obtenerTiposComprobantesEmpresa,
          obtenerTiposComprobantesEmpresaSelect,
          obtenerTiposComprobantesEmpresaAjuste,
          obtenerTiposRecibosEmpresaSelect,
          obtenerTiposOrdenesPagosEmpresaSelect,
          obtenerTiposComprobantesComprasEmpresaSelect,
          guardarTiposComprobantes,
          guardarEmpresaNavbar,
          guardarTiposComprobantes,
          obtenerCuentas,
          obtenerCuenta,
          guardarCuenta,
          crearCuenta,
          actualizarCuenta,
          obtenerCuentasSelect,
          obtenerCuentasSelectTodos,
          obtenerTiposRetencionesLookup,
          obtenerConceptos,
          guardarConcepto,
          obtenerConcepto,
          crearConcepto,
          actualizarConcepto,
          obtenerConceptosSelect,
          guardarProveedor,
          buscarProveedores,
          crearProveedor,
          obtenerProveedor,
          obtenerProveedoresSelect,
          actualizarProveedor,
          obtenerProveedores,
          obtenerConceptosRetenciones,
          obtenerTiposComprobantesAsociadoEmpresaSelect,
          obtenerRubros,
          obtenerRubro,
          guardarRubro,
          crearRubro,
          actualizarRubro,
          obtenerRubrosSelect,
          obtenerRubrosLookup,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { ArchivoProvider, Consumer as ArchivoConsumer, ArchivoContext };
