import React, { useState, useRef, Fragment } from "react";
import clienteAxios from "../config/axios";
import tokenAuth from "../config/token";
import { createContext } from "react";
import { Growl } from "primereact/growl";

const AuthContext = createContext();
const { Provider, Consumer } = AuthContext;

function AuthProvider({ children }) {
  let growl = useRef();

  const [state, guardarState] = useState({
    token: localStorage.getItem("token"),
    autenticado: null,
    usuario: null,
    cargando: true,
  });

  const registrarUsuario = async (datos) => {

    try {
      const respuesta = await clienteAxios.post("/api/usuarios", datos);
      console.log(respuesta);
      await growl.show({
        severity: "success",
        summary: "Registro",
        detail: "El usuario se ha creado correctamente.",
      });

      //iniciarSesion(datos);  

    } catch (error) {

      console.log(error.response);
      await growl.show({
        severity: "error",
        summary: "Registro",
        detail: "El usuario ya existe.",
      });

      localStorage.removeItem("token");
      guardarState({
        ...state,
        token: null,
        usuario: null,
        autenticado: null,
        cargando: false,
      });
    }
  };

  // Retorna el usuario autenticado
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    tokenAuth(token);

    try {
      const respuesta = await clienteAxios.get("/api/auth");
      //console.log(respuesta);

      guardarState({
        ...state,
        autenticado: true,
        usuario: respuesta.data.usuario,
        cargando: false,
      });
    } catch (error) {
      console.log(error.response);
      guardarState({
        ...state,
        autenticado: false,
        usuario: null,
        cargando: false,
      });
    }
  };

  // Cuando el usuario inicia sesión
  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/api/auth", datos);
      localStorage.setItem("token", respuesta.data.token);
      // Obtener el usuario
      usuarioAutenticado();
    } catch (error) {
      console.log(error.response.data.msg);
      await growl.show({
        severity: "error",
        summary: "Login",
        detail: "Usuario o password incorrecto.",
      });

      localStorage.removeItem("token");
      guardarState({
        ...state,
        token: null,
        usuario: null,
        autenticado: null,
        cargando: false,
      });
    }
  };

  // Cierra la sesión del usuario
  const cerrarSesion = () => {
    localStorage.removeItem("token");
    localStorage.removeItem('empresa');
    guardarState({
      ...state,
      token: null,
      usuario: null,
      autenticado: null,
      cargando: false,
    });
  };


  return (
    <Fragment>
      <Growl ref={(el) => (growl = el)} />
      <Provider
        value={{
          token: state.token,
          autenticado: state.autenticado,
          usuario: state.usuario,
          cargando: state.cargando,
          registrarUsuario,
          iniciarSesion,
          usuarioAutenticado,
          cerrarSesion,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { AuthProvider, Consumer as AuthConsumer, AuthContext };
