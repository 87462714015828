import React, { useState, useRef, Fragment } from "react";
import clienteAxios from "../config/axios";
import { createContext } from "react";
import { Growl } from "primereact/growl";

const CompraContext = createContext();
const { Provider, Consumer } = CompraContext;

function CompraProvider({ children }) {
  let growl = useRef();

  const [comprobantes, guardarComprobantes] = useState(null);
  const [comprobante, guardarComprobante] = useState(null);
  const [articulosComprobante, guardarArticulosComprobante] = useState(null);
  const [proveedor, guardarProveedor] = useState(null);
  const [condicionesVentas, guardarCondicionesVentas] = useState(null);
  const [verComprobante, guardarVerComprobante] = useState(null);
  const [ajuste, guardarAjuste] = useState(null);
  const [ctacte, guardarCtaCte] = useState(null);
  const [saldoCtaCte, guardarSaldoCtaCte] = useState(null);
  const [ordenesPagos, guardarOrdenesPagos] = useState(null);
  const [ordenPago, guardarOrdenPago] = useState(null);
  const [verOrdenPago, guardarVerOrdenPago] = useState(null);
  const [comprobantesOrdenPago, guardarComprobantesOrdenPago] = useState(null);
  const [retenciones, guardarRetenciones] = useState(null);
  const [chequesTerceros, guardarChequesTerceros] = useState(null);
  const [chequesPropios, guardarChequesPropios] = useState(null);
  const [carga, guardarCarga] = useState({
    codigo: "",
    detalle: "",
    cantidad: "",
    valorUnitario: "",
    descuento: "",
    percepcion: "",
    iva: 0,
    ivaId: 5,
    valorTotal: 0,
  });

  function tranformDate(strDate) {
    let result = "";

    if (strDate) {
      let parts = strDate.split("-");
      result = `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    return result;
  }

  // COMPROBANTES //

  const obtenerComprobantesEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantescompras/obtenercomprobantesempresa",
        datos
      );
      let data = respuesta.data;

      guardarComprobantes(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerComprobanteNro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantescompras/obtenercomprobantenro",
        datos
      );

      let fecha = respuesta.data.fecha;

      await growl.show({
        severity: "success",
        summary: "Comprobantes",
        detail: "El comprobante fue asociado correctamente.",
      });

      guardarComprobante({
        ...comprobante,
        fechaAsociado: fecha,
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantescompras/obtenercomprobante",
        datos
      );
      let data = respuesta.data;
      data.fecha = data.fecha.replace(/-/g, "/");
      guardarComprobante(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerComprobantesxIdOrdenPago = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantescompras/obtenercomprobantesxidorden",
        datos
      );
      let data = respuesta.data;

      guardarComprobantesOrdenPago(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearComprobante = async (
    empresa,
    comprobante,
    proveedor,
    articulosComprobante
  ) => {
    let comprobanteBulk = [];
    comprobanteBulk.push(comprobante);

    //Comprobantes

    if (
      comprobanteBulk[0].abreviatura === "NCA" ||
      comprobanteBulk[0].abreviatura === "NCB" ||
      comprobanteBulk[0].abreviatura === "NCC" ||
      comprobanteBulk[0].abreviatura === "NCM" ||
      comprobanteBulk[0].abreviatura === "NCEA" ||
      comprobanteBulk[0].abreviatura === "NCEB" ||
      comprobanteBulk[0].abreviatura === "NCEC" ||
      comprobanteBulk[0].abreviatura === "NDA" ||
      comprobanteBulk[0].abreviatura === "NDB" ||
      comprobanteBulk[0].abreviatura === "NDC" ||
      comprobanteBulk[0].abreviatura === "NDM" ||
      comprobanteBulk[0].abreviatura === "NDEA" ||
      comprobanteBulk[0].abreviatura === "NDEB" ||
      comprobanteBulk[0].abreviatura === "NDEC"
    ) {
      comprobanteBulk[0].subtotal = Number(comprobanteBulk[0].subtotal) * -1;
      comprobanteBulk[0].descuento = Number(comprobanteBulk[0].descuento) * -1;
      comprobanteBulk[0].neto = Number(comprobanteBulk[0].neto) * -1;
      comprobanteBulk[0].iva = Number(comprobanteBulk[0].iva) * -1;
      comprobanteBulk[0].percepcion =
        Number(comprobanteBulk[0].percepcion) * -1;
      comprobanteBulk[0].total = Number(comprobanteBulk[0].total) * -1;
      comprobanteBulk[0].excento = Number(comprobanteBulk[0].excento) * -1;
      comprobanteBulk[0].saldo = Number(comprobanteBulk[0].saldo) * -1;
    }

    //Remito
    if (!comprobanteBulk[0].remito) {
      comprobanteBulk[0].numeroRemito = 0;
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantescompras/crearcomprobante",
        { empresa, comprobanteBulk, proveedor, articulosComprobante }
      );

      const idComprobante = respuesta.data[0].id;

      await growl.show({
        severity: "success",
        summary: "Comprobantes",
        detail: "El comprobante se ha creado correctamente.",
      });

      guardarVerComprobante({
        id: idComprobante,
        estado: true,
      });
    } catch (error) {
      console.log(error.response);

      await growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El comprobante o remito ya existen.",
      });
    }
  };

  // PROVEEDORES //

  const obtenerProveedorxId = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/proveedores/obtenerproveedor",
        datos
      );
      let data = respuesta.data;
      guardarProveedor(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerProveedorxIdComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/proveedores/obtenerproveedorxidcomprobante",
        datos
      );
      let data = respuesta.data;

      console.log(data);

      guardarProveedor(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerProveedorxIdOrdenPago = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/proveedores/obtenerproveedorxidorden",
        datos
      );
      let data = respuesta.data;

      guardarProveedor(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  //CTACTE

  const obtenerCtaCteProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/ctacteproveedor/obtenerctacteproveedor",
        datos
      );
      let data = respuesta.data;

      //console.log(data);

      const local = [];

      let saldoAnterior = 0;
      for (var i = 0; i < data.length; i++) {
        saldoAnterior =
          Number(saldoAnterior) + Number(data[i].debe) - Number(data[i].haber);

        if (data[i].abreviatura !== "OP") {
          local.push({
            fecha: data[i].fecha,
            comprobante:
              data[i].comprobantescompra.comprobante +
              " - " +
              ("0000" + data[i].comprobantescompra.puntoVenta).slice(-4) +
              "-" +
              ("00000000" + data[i].comprobantescompra.numero).slice(-8),
            debe: parseFloat(data[i].debe).toFixed(2),
            haber: parseFloat(data[i].haber).toFixed(2),
            saldo: parseFloat(saldoAnterior).toFixed(2),
          });
        } else {
          local.push({
            fecha: data[i].fecha,
            comprobante:
              data[i].abreviatura +
              " - " +
              ("0000" + 0).slice(-4) +
              "-" +
              ("00000000" + data[i].numero).slice(-8),
            debe: parseFloat(data[i].debe).toFixed(2),
            haber: parseFloat(data[i].haber).toFixed(2),
            saldo: parseFloat(saldoAnterior).toFixed(2),
          });
        }
      }

      guardarCtaCte(local);
      guardarSaldoCtaCte(saldoAnterior);
    } catch (error) {
      guardarCtaCte(null);
      guardarSaldoCtaCte(0);
      console.log(error.response);
    }
  };

  const obtenerCtaCtePendienteProveedor = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/ctacteproveedor/obtenerctactependienteproveedor",
        datos
      );
      let data = respuesta.data;

      const local = [];
      let saldoPendiente = 0;
      for (var i = 0; i < data.length; i++) {
        saldoPendiente = Number(saldoPendiente) + Number(data[i].saldo);
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          comprobante:
            data[i].abreviatura +
            " - " +
            ("0000" + data[i].puntoVenta).slice(-4) +
            "-" +
            ("00000000" + data[i].numero).slice(-8),
          importe: parseFloat(data[i].saldo).toFixed(2),
          pendiente: parseFloat(data[i].pendiente).toFixed(2),
          cancelado: parseFloat(0).toFixed(2),
        });
      }

      guardarComprobantesOrdenPago(local);
      guardarSaldoCtaCte(saldoPendiente);
    } catch (error) {
      guardarComprobantesOrdenPago(null);
      guardarSaldoCtaCte(0);
      console.log(error.response);
    }
  };

  //ARTICULOS

  const obtenerArticuloxId = async (datos) => {
    const { impuestos, empresa } = datos;

    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticulo",
        datos
      );
      let tasaImpuestos = 0;

      let data = respuesta.data;
      guardarCarga({
        codigo: data.id,
        detalle: data.nombre + " " + data.unidad,
        cantidad: "",
        valorUnitario: "",
        descuento: proveedor.descuento,
        percepcion: 0,
        iva: 0,
        ivaId: 5,
        valorTotal: 0,
      });
    } catch (error) {
      guardarCarga({
        codigo: "",
        detalle: "",
        cantidad: "",
        valorUnitario: 0,
        descuento: "",
        percepcion: "",
        iva: 0,
        valorTotal: 0,
      });

      console.log(error.response);
    }
  };

  const obtenerArticulosxIdComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticulosxidcomprobantecompras",
        datos
      );
      let data = respuesta.data;

      guardarArticulosComprobante(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  // CONDICIONES VENTAS //

  const obtenerCondicionesVentasSelect = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/condicionesventas/obtenercondicionesventas"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }

      guardarCondicionesVentas(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // ORDENES PAGOS //

  const obtenerOrdenesPagosEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/ordenespagos/obtenerordenespagosempresa",
        datos
      );
      let data = respuesta.data;

      guardarOrdenesPagos(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerOrdenPago = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/ordenespagos/obtenerordenpago",
        datos
      );
      let data = respuesta.data;
      data.fecha = data.fecha.replace(/-/g, "/");
      guardarOrdenPago(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearOrdenPago = async (
    empresa,
    orden,
    proveedor,
    comprobantesOrden,
    valoresPendiente,
    valoresOrden,
    valoresTotal,
    saldoCtaCte,
    deposito,
    chequesTabla,
    chequesTablaPropios,
    retencionesTabla
  ) => {
    let ordenBulk = [];
    ordenBulk.push(orden);

    try {
      const respuesta = await clienteAxios.post(
        "/api/ordenespagos/crearordenpago",
        {
          empresa,
          ordenBulk,
          proveedor,
          comprobantesOrden,
          valoresPendiente,
          valoresOrden,
          valoresTotal,
          saldoCtaCte,
          deposito,
          chequesTabla,
          chequesTablaPropios,
        }
      );

      const idOrden = respuesta.data[0].id;

      await growl.show({
        severity: "success",
        summary: "Ordenes",
        detail: "La orden se ha creado correctamente.",
      });

      guardarVerOrdenPago({
        id: idOrden,
        estado: true,
      });
    } catch (error) {
      console.log(error.response.data);
      await growl.show({
        severity: "error",
        summary: "Ordenes",
        detail: error.response.data,
      });
    }
  };

  //CHEQUES TERCEROS

  const obtenerChequesTercerosCartera = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/cheques/obtenerchequesterceroscartera",
        datos
      );
      let data = respuesta.data;
      //console.log(data)

      guardarChequesTerceros(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  //CHEQUES PROPIOS

  const obtenerChequesPropiosCartera = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/cheques/obtenerchequespropioscartera",
        datos
      );
      let data = respuesta.data;
      //console.log(data)

      guardarChequesPropios(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Fragment>
      <Growl ref={(el) => (growl = el)} />
      <Provider
        value={{
          comprobantes,
          comprobante,
          proveedor,
          condicionesVentas,
          verComprobante,
          articulosComprobante,
          ajuste,
          ctacte,
          saldoCtaCte,
          verOrdenPago,
          ordenesPagos,
          ordenPago,
          comprobantesOrdenPago,
          chequesTerceros,
          chequesPropios,
          carga,
          crearComprobante,
          obtenerComprobantesEmpresa,
          guardarComprobante,
          obtenerComprobante,
          obtenerComprobanteNro,
          obtenerCtaCtePendienteProveedor,
          obtenerProveedorxId,
          obtenerProveedorxIdComprobante,
          obtenerProveedorxIdOrdenPago,
          guardarProveedor,
          guardarCondicionesVentas,
          tranformDate,
          guardarVerComprobante,
          guardarArticulosComprobante,
          obtenerArticulosxIdComprobante,
          guardarAjuste,
          obtenerCtaCteProveedor,
          guardarSaldoCtaCte,
          guardarVerOrdenPago,
          crearOrdenPago,
          obtenerOrdenesPagosEmpresa,
          guardarOrdenPago,
          obtenerOrdenPago,
          guardarComprobantesOrdenPago,
          obtenerComprobantesxIdOrdenPago,
          obtenerCondicionesVentasSelect,
          obtenerChequesTercerosCartera,
          obtenerChequesPropiosCartera,
          obtenerArticuloxId,
          guardarCarga,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { CompraProvider, Consumer as CompraConsumer, CompraContext };
