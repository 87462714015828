import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AuthContext } from "../../../context/AuthContext";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { FondoContext } from "../../../context/FondoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { Dialog } from "@material-ui/core";
import { Button } from "primereact/button";

function ChequeTercero(props) {
  // extraer los valores del context

  const authContext = useContext(AuthContext);
  const { autenticado, registrarUsuario } = authContext;

  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    bancos,
    clientes,
    obtenerBancosSelect,
    obtenerClientesSelect,
  } = archivoContext;

  const fondoContext = useContext(FondoContext);
  const {
    chequeTercero,
    obtenerChequeTercero,
    guardarChequeTercero,
    crearChequeTercero,
    actualizarChequeTercero,
  } = fondoContext;

  let growl = useRef();

  const idChequeTercero = props.match.params.id;
  const idOrigen = props.match.params.origen;

  useEffect(() => {
    if (idChequeTercero !== "0") {
      obtenerChequeTercero({ idChequeTercero });
    } else {
      guardarChequeTercero({
        fecha: new Date(),
        bancoId: 0,
        numero: "",
        emision: new Date(),
        vencimiento: new Date(),
        detalle: "",
        importe: "",
        ingreso: "",
        egreso: "",
        empresaId: empresaNavbar,
        reciboId: 0,
        clienteId: 0,
        librador: "",
        nombreCliente: "",
        estado: "CARTERA",
        noalaOrden: false,
      });
    }
    obtenerBancosSelect();
    obtenerClientesSelect();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (chequeTercero.fecha === "") {
      growl.show({
        severity: "error",
        summary: "Cartera de cheques",
        detail: "La fecha es inválida.",
      });
      return;
    }

    if (chequeTercero.bancoId === 0) {
      growl.show({
        severity: "error",
        summary: "Cartera de cheques",
        detail: "El banco es necesario.",
      });
      return;
    }

    if (chequeTercero.clienteId === 0) {
      growl.show({
        severity: "error",
        summary: "Cartera de cheques",
        detail: "El cliente es necesario.",
      });
      return;
    }

    if (chequeTercero.numero.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Cartera de cheques",
        detail: "El número es necesario.",
      });
      return;
    }

    if (chequeTercero.emision === "") {
      growl.show({
        severity: "error",
        summary: "Cartera de cheques",
        detail: "La fecha de emisión es necesaria.",
      });
      return;
    }

    if (chequeTercero.vencimiento === "") {
      growl.show({
        severity: "error",
        summary: "Cartera de cheques",
        detail: "La fecha de vencimiento es necesaria.",
      });
      return;
    }

    if (chequeTercero.importe === "" || chequeTercero.importe === 0) {
      growl.show({
        severity: "error",
        summary: "Cartera de cheques",
        detail: "El importe es necesario.",
      });
      return;
    }

    if (chequeTercero.empresaId === 0) {
      growl.show({
        severity: "error",
        summary: "Cartera de cheques",
        detail: "La empresa es necesaria.",
      });
      return;
    }

    if (idChequeTercero === "0") {
      crearChequeTercero(chequeTercero);
    } else {
      actualizarChequeTercero(chequeTercero);
    }
  };

  const cerrar = () => {
    if (idOrigen === "0") {
      props.history.push("/carterachequesterceros");
    } else {
      props.history.push("/historialchequesterceros");
    }
  };

  const onChangeCliente = (e) => {
    for (var i = 0; i < clientes.length; i++) {
      if (clientes[i].value === e.target.value) {
        guardarChequeTercero({
          ...chequeTercero,
          clienteId: e.target.value,
          nombreCliente: clientes[i].nombre,
        });
      }
    }
  };

  const onSubmitClear = (e) => {
    e.preventDefault();
    guardarChequeTercero({
      fecha: new Date(),
      bancoId: 0,
      numero: "",
      emision: new Date(),
      vencimiento: new Date(),
      detalle: "",
      importe: "",
      ingreso: "",
      egreso: "",
      empresaId: empresaNavbar,
      reciboId: 0,
      clienteId: 0,
      librador: "",
      nombreCliente: "",
      estado: "CARTERA",
      noalaOrden: false,
    });
  };

  //console.log(chequeTercero);

  return (
    <Fragment>
      {!chequeTercero ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                  style={{ color: "Blue" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Cheque de terceros
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <div class="row">
                    <div className="col-xs-12 col-md-12">
                      <div class="row">
                        <div className="form-group">
                          <h6 style={{ color: "Blue", marginLeft: "2em" }}>
                            Datos del Cheque
                          </h6>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-12 col-md-2">
                          <div className="form-group">
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={esLocale}
                            >
                              <KeyboardDatePicker
                                clearable
                                label="Fecha de ingreso"
                                value={chequeTercero.fecha}
                                disabled={
                                  idOrigen === "1" ||
                                  chequeTercero.reciboId !== 0
                                }
                                format="dd/MM/yyyy"
                                style={{ color: "blue" }}
                                onChange={(date) =>
                                  guardarChequeTercero({
                                    ...chequeTercero,
                                    fecha: date,
                                  })
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>

                        <div className="col-xs-6 col-md-2">
                          <div className="form-group">
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={esLocale}
                            >
                              <KeyboardDatePicker
                                clearable
                                label="Emisión"
                                disabled={idOrigen === "1"}
                                style={{ width: "60%", color: "blue" }}
                                value={chequeTercero.emision}
                                format="dd/MM/yyyy"
                                onChange={(date) =>
                                  guardarChequeTercero({
                                    ...chequeTercero,
                                    emision: date,
                                  })
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>

                        <div className="col-xs-6 col-md-2">
                          <div className="form-group">
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={esLocale}
                            >
                              <KeyboardDatePicker
                                clearable
                                label="Vencimiento"
                                disabled={idOrigen === "1"}
                                style={{ width: "60%", color: "blue" }}
                                value={chequeTercero.vencimiento}
                                format="dd/MM/yyyy"
                                onChange={(date) =>
                                  guardarChequeTercero({
                                    ...chequeTercero,
                                    vencimiento: date,
                                  })
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>

                        <div className="col-xs-6 col-md-2">
                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="numero"
                                type="text"
                                className="form-control mt-2"
                                disabled={idOrigen === "1"}
                                value={chequeTercero.numero}
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  guardarChequeTercero({
                                    ...chequeTercero,
                                    numero: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="numero"
                                style={{ color: "black" }}
                              >
                                Número
                              </label>
                            </span>
                          </div>
                        </div>

                        <div className="col-xs-6 col-md-2">
                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="importe"
                                type="number"
                                disabled={
                                  idOrigen === "1" ||
                                  chequeTercero.reciboId !== 0
                                }
                                className="form-control mt-2"
                                style={{ width: "100%" }}
                                value={
                                  chequeTercero ? chequeTercero.importe : 0
                                }
                                onChange={(e) =>
                                  guardarChequeTercero({
                                    ...chequeTercero,
                                    importe: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="importe"
                                style={{ color: "black" }}
                              >
                                Importe
                              </label>
                            </span>
                          </div>
                        </div>

                        <div className="col-xs-6 col-md-2">
                          <div className="form-group">
                            <label
                              htmlFor="noalaOrden"
                              style={{ color: "black", marginRight: "1em" }}
                            >
                              No a la orden:
                            </label>
                            <ToggleButton
                              onLabel="Si"
                              offLabel="No"
                              onIcon="pi pi-check"
                              offIcon="pi pi-times"
                              disabled={idOrigen === "1"}
                              className="mt-2"
                              checked={chequeTercero.noalaOrden}
                              onChange={(e) =>
                                guardarChequeTercero({
                                  ...chequeTercero,
                                  noalaOrden: e.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div className="col-xs-12 col-md-6">
                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="banco"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Banco
                              </label>
                            </span>

                            <Dropdown
                              id="banco"
                              name="banco"
                              value={chequeTercero.bancoId}
                              style={{ width: "100%" }}
                              className="mt-3"
                              disabled={idOrigen === "1"}
                              options={bancos}
                              onChange={(e) =>
                                guardarChequeTercero({
                                  ...chequeTercero,
                                  bancoId: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná un banco."
                            />
                          </div>
                        </div>

                        <div className="col-xs-12 col-md-6">
                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="cliente"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Cliente
                              </label>
                            </span>

                            <Dropdown
                              id="cliente"
                              name="cliente"
                              value={chequeTercero.clienteId}
                              style={{ width: "100%" }}
                              className="mt-3"
                              disabled={
                                idOrigen === "1" || chequeTercero.reciboId !== 0
                              }
                              options={clientes}
                              onChange={onChangeCliente}
                              filter={true}
                              placeholder="Seleccioná un cliente."
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-6 col-md-6">
                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="detalle"
                                type="text"
                                className="form-control mt-2"
                                disabled={
                                  idOrigen === "1" ||
                                  chequeTercero.reciboId !== 0
                                }
                                value={chequeTercero.detalle}
                                onChange={(e) =>
                                  guardarChequeTercero({
                                    ...chequeTercero,
                                    detalle: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="detalle"
                                style={{ color: "black" }}
                              >
                                Detalle
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="col-xs-6 col-md-6">
                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="librador"
                                type="text"
                                className="form-control mt-2"
                                disabled={idOrigen === "1"}
                                value={chequeTercero.librador}
                                onChange={(e) =>
                                  guardarChequeTercero({
                                    ...chequeTercero,
                                    librador: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="librador"
                                style={{ color: "black" }}
                              >
                                Librador
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>

                      {idChequeTercero !== "0" ? (
                        <Fragment>
                          <div class="row">
                            <div className="col-xs-6 col-md-3">
                              <div className="form-group">
                                <h6
                                  htmlFor="datos1"
                                  style={{ color: "Blue", marginLeft: "1em" }}
                                >
                                  Ingreso a Cartera
                                </h6>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div className="col-xs-6 col-md-3">
                              <div className="form-group">
                                <span className="p-float-label">
                                  <InputText
                                    id="recibo"
                                    type="text"
                                    className="form-control mt-2"
                                    disabled={true}
                                    value={chequeTercero.numeroRecibo}
                                    style={{ width: "100%" }}
                                  />
                                  <label
                                    htmlFor="recibo"
                                    style={{ color: "black" }}
                                  >
                                    Recibo
                                  </label>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div className="col-xs-6 col-md-3">
                              <div className="form-group">
                                <h6
                                  htmlFor="datos1"
                                  style={{ color: "Blue", marginLeft: "1em" }}
                                >
                                  Aplicación
                                </h6>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            {chequeTercero.fechaDepositado ? (
                              <div className="col-xs-6 col-md-3">
                                <div className="form-group">
                                  <span className="p-float-label">
                                    <InputText
                                      id="fechaDepositado"
                                      type="text"
                                      className="form-control mt-2"
                                      disabled={true}
                                      value={chequeTercero.fechaDepositado}
                                      style={{ width: "100%" }}
                                    />
                                    <label
                                      htmlFor="fechaDepositado"
                                      style={{ color: "black" }}
                                    >
                                      Fecha depósito
                                    </label>
                                  </span>
                                </div>
                              </div>
                            ) : null}

                            {chequeTercero.fechaAcreditadoCaja ? (
                              <div className="col-xs-6 col-md-3">
                                <div className="form-group">
                                  <span className="p-float-label">
                                    <InputText
                                      id="fechaAcreditadoCaja"
                                      type="text"
                                      className="form-control mt-2"
                                      disabled={true}
                                      value={chequeTercero.fechaAcreditadoCaja}
                                      style={{ width: "100%" }}
                                    />
                                    <label
                                      htmlFor="fechaAcreditadoCaja"
                                      style={{ color: "black" }}
                                    >
                                      Fecha acreditado en caja
                                    </label>
                                  </span>
                                </div>
                              </div>
                            ) : null}
                            {chequeTercero.fechaAcreditadoBanco ? (
                              <div className="col-xs-6 col-md-3">
                                <div className="form-group">
                                  <span className="p-float-label">
                                    <InputText
                                      id="fechaAcreditadoBanco"
                                      type="text"
                                      className="form-control mt-2"
                                      disabled={true}
                                      value={chequeTercero.fechaAcreditadoBanco}
                                      style={{ width: "100%" }}
                                    />
                                    <label
                                      htmlFor="fechaAcreditadoBanco"
                                      style={{ color: "black" }}
                                    >
                                      Fecha acreditado en Banco
                                    </label>
                                  </span>
                                </div>
                              </div>
                            ) : null}

                            {chequeTercero.nombreCuenta ? (
                              <div className="col-xs-6 col-md-3">
                                <div className="form-group">
                                  <span className="p-float-label">
                                    <InputText
                                      id="nombreCuenta"
                                      type="text"
                                      className="form-control mt-2"
                                      disabled={true}
                                      value={chequeTercero.nombreCuenta}
                                      style={{ width: "100%" }}
                                    />
                                    <label
                                      htmlFor="nombreCuenta"
                                      style={{ color: "black" }}
                                    >
                                      Cuenta
                                    </label>
                                  </span>
                                </div>
                              </div>
                            ) : null}

                            {chequeTercero.fechaRechazado ? (
                              <div className="col-xs-6 col-md-3">
                                <div className="form-group">
                                  <span className="p-float-label">
                                    <InputText
                                      id="fechaRechazado"
                                      type="text"
                                      className="form-control mt-2"
                                      disabled={true}
                                      value={chequeTercero.fechaRechazado}
                                      style={{ width: "100%" }}
                                    />
                                    <label
                                      htmlFor="fechaRechazado"
                                      style={{ color: "black" }}
                                    >
                                      Fecha rechazado
                                    </label>
                                  </span>
                                </div>
                              </div>
                            ) : null}

                            {chequeTercero.fechaEntregado ? (
                              <Fragment>
                                <div className="col-xs-6 col-md-3">
                                  <div className="form-group">
                                    <span className="p-float-label">
                                      <InputText
                                        id="fechaEntregado"
                                        type="text"
                                        className="form-control mt-2"
                                        disabled={true}
                                        value={chequeTercero.fechaEntregado}
                                        style={{ width: "100%" }}
                                      />
                                      <label
                                        htmlFor="fechaEntregado"
                                        style={{ color: "black" }}
                                      >
                                        Fecha entregado
                                      </label>
                                    </span>
                                  </div>
                                </div>

                                <div className="col-xs-6 col-md-3">
                                  <div className="form-group">
                                    <span className="p-float-label">
                                      <InputText
                                        id="orden"
                                        type="text"
                                        className="form-control mt-2"
                                        disabled={true}
                                        value={chequeTercero.numeroOrden}
                                        style={{ width: "100%" }}
                                      />
                                      <label
                                        htmlFor="orden"
                                        style={{ color: "black" }}
                                      >
                                        Orden
                                      </label>
                                    </span>
                                  </div>
                                </div>
                              </Fragment>
                            ) : null}
                          </div>
                        </Fragment>
                      ) : null}
                    </div>
                  </div>
                  {idOrigen === "0" ? (
                    <button
                      className="btn btn-success btn-block"
                      onClick={onSubmit}
                    >
                      Guardar cheque tercero
                    </button>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ChequeTercero;
