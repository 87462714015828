import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AuthContext } from "../../../context/AuthContext";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { FondoContext } from "../../../context/FondoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

function MovimientoCaja(props) {
  // extraer los valores del context

  const authContext = useContext(AuthContext);
  const { autenticado, registrarUsuario } = authContext;

  const archivoContext = useContext(ArchivoContext);
  const { empresaNavbar, conceptos, obtenerConceptosSelect } = archivoContext;

  const fondoContext = useContext(FondoContext);
  const {
    movimientoCaja,
    movimientosCajas,
    chequesSelected,
    obtenerMovimientoCaja,
    guardarMovimientoCaja,
    crearMovimientoCaja,
    actualizarMovimientoCaja,
  } = fondoContext;

  const [nombreCuenta, guardarNombreCuenta] = useState(null);

  let growl = useRef();

  const idMovimientoCaja = props.match.params.id;

  useEffect(() => {
    if (idMovimientoCaja !== "0") {
      obtenerMovimientoCaja({ idMovimientoCaja });
    } else {
      guardarMovimientoCaja({
        fecha: new Date(),
        conceptoId: 0,
        detalleConcepto: "",
        detalle: "",
        importe: "",
        ingreso: "",
        egreso: "",
        empresaId: empresaNavbar,
        reciboId: 0,
      });
    }
    obtenerConceptosSelect();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (movimientoCaja.fecha === "") {
      growl.show({
        severity: "error",
        summary: "Movimiento de caja",
        detail: "La fecha es iválida.",
      });
      return;
    }

    if (movimientoCaja.importe === "" || movimientoCaja.importe === 0) {
      growl.show({
        severity: "error",
        summary: "Movimiento de caja",
        detail: "El importe es obligatorio.",
      });
      return;
    }

    if (movimientoCaja.conceptoId === 0) {
      growl.show({
        severity: "error",
        summary: "Movimiento de caja",
        detail: "El concepto es obligatorio.",
      });
      return;
    }

    if (movimientoCaja.empresaId === 0) {
      growl.show({
        severity: "error",
        summary: "Movimiento de caja",
        detail: "La empresa es obligatoria.",
      });
      return;
    }

    if (idMovimientoCaja === "0") {
      crearMovimientoCaja(movimientoCaja, chequesSelected, nombreCuenta);
    } else {
      actualizarMovimientoCaja(movimientoCaja);
    }
  };

  const cerrar = () => {
    props.history.push("/movimientoscajas");
  };

  const onChangeConcepto = (e) => {
    for (var i = 0; i < conceptos.length; i++) {
      if (conceptos[i].value === e.target.value) {
        guardarMovimientoCaja({
          ...movimientoCaja,
          conceptoId: e.target.value,
          detalleConcepto: conceptos[i].label,
        });
      }
    }
  };

  const onSubmitClear = (e) => {
    e.preventDefault();
    guardarMovimientoCaja({
      fecha: new Date(),
      conceptoId: 0,
      detalleConcepto: "",
      detalle: "",
      importe: "",
      ingreso: "",
      egreso: "",
      empresaId: empresaNavbar,
      reciboId: 0,
    });
  };

  //console.log(movimientoCaja);

  return (
    <Fragment>
      {!movimientoCaja ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-6 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                  style={{ color: "Blue" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Movimiento de caja
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <div class="row">
                    <div className="col-xs-12 col-md-12">
                      <div className="form-group">
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={esLocale}
                        >
                          <KeyboardDatePicker
                            clearable
                            label="Fecha"
                            value={movimientoCaja.fecha}
                            disabled={idMovimientoCaja !== "0"}
                            onChange={(date) =>
                              guardarMovimientoCaja({
                                ...movimientoCaja,
                                fecha: date,
                              })
                            }
                            format="dd/MM/yyyy"
                            style={{ color: "blue" }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <label
                            htmlFor="concepto"
                            style={{ color: "black", fontSize: "0.8em" }}
                          >
                            Concepto
                          </label>
                        </span>

                        <Dropdown
                          id="concepto"
                          name="concepto"
                          disabled={idMovimientoCaja !== "0"}
                          value={movimientoCaja.conceptoId}
                          style={{ width: "50%" }}
                          className="mt-3"
                          options={conceptos}
                          onChange={onChangeConcepto}
                          filter={true}
                          placeholder="Seleccioná un concepto."
                        />
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="detalle"
                            type="text"
                            className="form-control mt-4"
                            disabled={idMovimientoCaja !== "0"}
                            value={movimientoCaja.detalle}
                            onChange={(e) =>
                              guardarMovimientoCaja({
                                ...movimientoCaja,
                                detalle: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="detalle" style={{ color: "black" }}>
                            Detalle
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <h4>
                          {movimientoCaja.importe > 0 ? (
                            <span
                              className="badge badge-primary"
                              style={{ float: "right", marginRight: "15em" }}
                            >
                              Ingreso
                            </span>
                          ) : null}
                          {movimientoCaja.importe < 0 ? (
                            <span
                              className="badge badge-info"
                              style={{ float: "right", marginRight: "15em" }}
                            >
                              Egreso
                            </span>
                          ) : null}
                        </h4>
                        <span className="p-float-label">
                          <InputText
                            id="importe"
                            type="number"
                            disabled={idMovimientoCaja !== "0"}
                            className="form-control mt-4"
                            style={{ width: "40%" }}
                            value={movimientoCaja ? movimientoCaja.importe : 0}
                            onChange={(e) =>
                              guardarMovimientoCaja({
                                ...movimientoCaja,
                                importe: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="importe" style={{ color: "black" }}>
                            Importe (ingreso en positivo)
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-md-6">
                      <button
                        className="btn btn-success btn-block"
                        disabled={idMovimientoCaja !== "0"}
                        onClick={onSubmit}
                      >
                        Guardar movimiento de caja
                      </button>
                    </div>
                    <div className="col-xs-6 col-md-6">
                      <button
                        className="btn btn-warning btn-block"
                        disabled={idMovimientoCaja !== "0"}
                        onClick={onSubmitClear}
                      >
                        Limpiar movimientos
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default MovimientoCaja;
