import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";

function Vendedor(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    vendedor,
    obtenerVendedor,
    guardarVendedor,
    crearVendedor,
    actualizarVendedor,
  } = archivoContext;

  let growl = useRef();

  const idVendedor = props.match.params.id;

  useEffect(() => {
    if (idVendedor !== "0") {
      obtenerVendedor({ idVendedor });
    } else {
      guardarVendedor({
        id: "",
        nombre: "",
        telefono: "",
        email: "",
        habilitado: true,
      });
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (vendedor.nombre !== null && vendedor.nombre.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Vendedores",
        detail: "El nombre del vendedor es obligatorio.",
      });
      return;
    }

    if (vendedor.email !== null && vendedor.email.trim() !== "") {
      if (!EmailValidator.validate(vendedor.email)) {
        growl.show({
          severity: "error",
          summary: "Vendedores",
          detail: "El email es incorrecto.",
        });
        return;
      }
    }

    if (idVendedor === "0") {
      crearVendedor(vendedor);
    } else {
      actualizarVendedor(vendedor);
    }
  };

  const cerrar = () => {
    props.history.push("/vendedores");
  };

  return (
    <Fragment>
      {!vendedor ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {vendedor.nombre}
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <div class="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="nombre"
                            type="text"
                            className="form-control mt-4"
                            value={vendedor.nombre}
                            onChange={(e) =>
                              guardarVendedor({
                                ...vendedor,
                                nombre: e.target.value.toUpperCase(),
                              })
                            }
                          />
                          <label htmlFor="nombre" style={{ color: "black" }}>
                            Nombre del vendedor
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="telefono"
                            type="text"
                            className="form-control mt-4"
                            value={vendedor.telefono}
                            onChange={(e) =>
                              guardarVendedor({
                                ...vendedor,
                                telefono: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="telefono" style={{ color: "black" }}>
                            Teléfono
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="email"
                            type="text"
                            className="form-control mt-4"
                            value={vendedor.email}
                            onChange={(e) =>
                              guardarVendedor({
                                ...vendedor,
                                email: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="email" style={{ color: "black" }}>
                            Email
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="habilitado"
                          style={{ color: "black", marginRight: "1em" }}
                        >
                          Habilitado:
                        </label>
                        <ToggleButton
                          onLabel="Si"
                          offLabel="No"
                          onIcon="pi pi-check"
                          offIcon="pi pi-times"
                          checked={vendedor.habilitado}
                          onChange={(e) =>
                            guardarVendedor({
                              ...vendedor,
                              habilitado: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn btn-background btn-block mt-4"
                    value="Guardar vendedor"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Vendedor;
