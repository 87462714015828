import React, { useState, useRef, Fragment } from "react";
import clienteAxios from "../config/axios";
import { createContext } from "react";
import { Growl } from "primereact/growl";

const VentaContext = createContext();
const { Provider, Consumer } = VentaContext;

function VentaProvider({ children }) {
  let growl = useRef();

  const [comprobantes, guardarComprobantes] = useState(null);
  const [comprobante, guardarComprobante] = useState(null);
  const [articuloListaPrecios, guardarArticuloListaPrecios] = useState({
    precio: 0,
    iva: 0,
  });
  const [articulosComprobante, guardarArticulosComprobante] = useState(null);
  const [impuestosComprobante, guardarImpuestosComprobante] = useState(null);
  const [cliente, guardarCliente] = useState(null);
  const [condicionesVentas, guardarCondicionesVentas] = useState(null);
  const [facturaAsociada, guardarFacturaAsociada] = useState(false);
  const [verComprobante, guardarVerComprobante] = useState(null);
  const [ajuste, guardarAjuste] = useState(null);
  const [ctacte, guardarCtaCte] = useState(null);
  const [saldoCtaCte, guardarSaldoCtaCte] = useState(null);
  const [recibos, guardarRecibos] = useState(null);
  const [recibo, guardarRecibo] = useState(null);
  const [verRecibo, guardarVerRecibo] = useState(null);
  const [comprobantesRecibo, guardarComprobantesRecibo] = useState(null);
  const [ultimoComprobanteAfip, guardarUltimoComprobanteAfip] = useState(null);
  const [qrValue, setQrValue] = useState(null);
  const [remitos, guardarRemitos] = useState(null);
  const [remito, guardarRemito] = useState(null);
  const [verRemito, guardarVerRemito] = useState(null);
  const [carga, guardarCarga] = useState({
    codigo: "",
    detalle: "",
    cantidad: "",
    valorUnitario: 0,
    descuento: "",
    percepcion: "",
    iva: 0,
    valorTotal: 0,
  });

  function tranformDate(strDate) {
    let result = "";

    if (strDate) {
      let parts = strDate.split("-");
      result = `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    return result;
  }

  // COMPROBANTES //

  const obtenerComprobantesEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantes/obtenercomprobantesempresa",
        datos
      );
      let data = respuesta.data;

      guardarComprobantes(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerComprobanteNro = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantes/obtenercomprobantenro",
        datos
      );

      let fecha = respuesta.data.fecha;

      await growl.show({
        severity: "success",
        summary: "Comprobantes",
        detail: "El comprobante fue asociado correctamente.",
      });

      guardarComprobante({
        ...comprobante,
        fechaAsociado: fecha,
      });

      guardarFacturaAsociada(true);
    } catch (error) {
      guardarFacturaAsociada(false);
      console.log(error.response);
    }
  };

  const obtenerComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantes/obtenercomprobante",
        datos
      );
      let data = respuesta.data;
      data.fecha = data.fecha.replace(/-/g, "/");
      data.fechaVencimiento = data.fechaVencimiento.replace(/-/g, "/");
      guardarComprobante(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerComprobantesxIdRecibo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantes/obtenercomprobantesxidrecibo",
        datos
      );
      let data = respuesta.data;

      guardarComprobantesRecibo(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerUltimoComprobanteAfip = async (datos) => {
    const { empresa } = datos;
    const { row } = datos;
    let cuit = empresa.cuit;
    let puntoVenta = row.puntoVenta;
    let codigo = Number(row.codigoAfip);
    try {
      const respuesta = await clienteAxios.post(
        "/api/afip/obtenerultimafactura",
        { cuit, puntoVenta, codigo }
      );
      let data = respuesta.data;

      growl.show({
        severity: "success",
        summary: "Ultimo comprobante",
        detail: data,
      });

      //guardarUltimoComprobanteAfip(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearComprobante = async (
    empresa,
    comprobante,
    cliente,
    impuestos,
    articulosComprobante
  ) => {
    if (comprobante.electronica) {
      let cuit = empresa.cuit;
      let puntoVenta = comprobante.puntoVenta;
      let codigo = Number(comprobante.tipoComprobanteIdAfip);
      let numeroAfip = 0;
      try {
        const respuesta = await clienteAxios.post(
          "/api/afip/obtenerultimafactura",
          { cuit, puntoVenta, codigo }
        );
        numeroAfip = respuesta.data;
      } catch (error) {
        console.log(error.response);
      }

      if (comprobante.numero !== numeroAfip + 1) {
        await growl.show({
          severity: "error",
          summary: "Comprobantes",
          detail:
            "La numeració de AFIP no coincide con el sistema (" +
            (numeroAfip + 1) +
            ")",
        });

        return;
      }
    }

    let comprobanteBulk = [];
    comprobanteBulk.push(comprobante);

    //Comprobantes
    if (
      comprobanteBulk[0].abreviatura !== "NCA" &&
      comprobanteBulk[0].abreviatura !== "NCB" &&
      comprobanteBulk[0].abreviatura !== "NCC" &&
      comprobanteBulk[0].abreviatura !== "NCM" &&
      comprobanteBulk[0].abreviatura !== "NCEA" &&
      comprobanteBulk[0].abreviatura !== "NCEB" &&
      comprobanteBulk[0].abreviatura !== "NCEC" &&
      comprobanteBulk[0].abreviatura !== "NDA" &&
      comprobanteBulk[0].abreviatura !== "NDB" &&
      comprobanteBulk[0].abreviatura !== "NDC" &&
      comprobanteBulk[0].abreviatura !== "NDM" &&
      comprobanteBulk[0].abreviatura !== "NDEA" &&
      comprobanteBulk[0].abreviatura !== "NDEB" &&
      comprobanteBulk[0].abreviatura !== "NDEC"
    ) {
      comprobanteBulk[0].tipoComprobanteIdAsociado = 0;
      comprobanteBulk[0].tipoComprobanteIdAsociadoAfip = 0;
      comprobanteBulk[0].puntoVentaAsociado = 0;
      comprobanteBulk[0].numeroAsociado = 0;
    }

    if (
      comprobanteBulk[0].abreviatura === "NCA" ||
      comprobanteBulk[0].abreviatura === "NCB" ||
      comprobanteBulk[0].abreviatura === "NCC" ||
      comprobanteBulk[0].abreviatura === "NCM" ||
      comprobanteBulk[0].abreviatura === "NCEA" ||
      comprobanteBulk[0].abreviatura === "NCEB" ||
      comprobanteBulk[0].abreviatura === "NCEC" ||
      comprobanteBulk[0].abreviatura === "NDA" ||
      comprobanteBulk[0].abreviatura === "NDB" ||
      comprobanteBulk[0].abreviatura === "NDC" ||
      comprobanteBulk[0].abreviatura === "NDM" ||
      comprobanteBulk[0].abreviatura === "NDEA" ||
      comprobanteBulk[0].abreviatura === "NDEB" ||
      comprobanteBulk[0].abreviatura === "NDEC"
    ) {
      comprobanteBulk[0].subtotal = Number(comprobanteBulk[0].subtotal) * -1;
      comprobanteBulk[0].descuento = Number(comprobanteBulk[0].descuento) * -1;
      comprobanteBulk[0].neto = Number(comprobanteBulk[0].neto) * -1;
      comprobanteBulk[0].iva = Number(comprobanteBulk[0].iva) * -1;
      comprobanteBulk[0].percepcion =
        Number(comprobanteBulk[0].percepcion) * -1;
      comprobanteBulk[0].total = Number(comprobanteBulk[0].total) * -1;
      comprobanteBulk[0].excento = Number(comprobanteBulk[0].excento) * -1;
      comprobanteBulk[0].saldo = Number(comprobanteBulk[0].saldo) * -1;
    }

    //Remito
    if (!comprobanteBulk[0].remito) {
      comprobanteBulk[0].numeroRemito = 0;
    }

    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantes/crearcomprobante",
        { empresa, comprobanteBulk, cliente, articulosComprobante, impuestos }
      );

      const idComprobante = respuesta.data[0].id;

      await growl.show({
        severity: "success",
        summary: "Comprobantes",
        detail: "El comprobante se ha creado correctamente.",
      });

      guardarVerComprobante({
        id: idComprobante,
        estado: true,
        tipo: "F"
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  
  //REMITO

  const crearRemito = async (
    empresa,
    comprobante,
    cliente,
    impuestos,
    articulosComprobante
  ) => {
    let comprobanteBulk = [];
    comprobanteBulk.push(comprobante);

    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantes/crearremito",
        { empresa, comprobanteBulk, cliente, articulosComprobante, impuestos }
      );

      const idRemito = respuesta.data[0].id;

      await growl.show({
        severity: "success",
        summary: "Remitos",
        detail: "El remito se ha creado correctamente.",
      });

      guardarVerComprobante({
        id: idRemito,
        estado: true,
        tipo: "R",
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // Observaciones
  const grabarObservaciones = async (datos) => {

    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantes/grabarobservaciones",
        datos
      );
      let data = respuesta.data;

    } catch (error) {
      console.log(error.response);
    }
  };


  // CLIENTES //

  const obtenerClientexId = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/clientes/obtenercliente",
        datos
      );
      let data = respuesta.data;
      guardarCliente(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerClientexIdComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/clientes/obtenerclientexidcomprobante",
        datos
      );
      let data = respuesta.data;

      guardarCliente(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerClientexIdRecibo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/clientes/obtenerclientexidrecibo",
        datos
      );
      let data = respuesta.data;

      guardarCliente(data);
    } catch (error) {
      console.log(error.response);
    }
  };


  const obtenerClientexIdRemito = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/clientes/obtenerclientexidremito",
        datos
      );
      let data = respuesta.data;

      guardarCliente(data);
    } catch (error) {
      console.log(error.response);
    }
  };



  //CTACTE

  const obtenerCtaCteCliente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/ctacte/obtenerctactecliente",
        datos
      );
      let data = respuesta.data;
      const local = [];

      let saldoAnterior = 0;
      for (var i = 0; i < data.length; i++) {
        saldoAnterior =
          Number(saldoAnterior) + Number(data[i].debe) - Number(data[i].haber);

        if (data[i].comprobantes) {
          local.push({
            fecha: data[i].fecha,
            comprobante:
              data[i].comprobantes.comprobante +
              " - " +
              ("0000" + data[i].comprobantes.puntoVenta).slice(-4) +
              "-" +
              ("00000000" + data[i].comprobantes.numero).slice(-8),
            debe: parseFloat(data[i].debe).toFixed(2),
            haber: parseFloat(data[i].haber).toFixed(2),
            saldo: parseFloat(saldoAnterior).toFixed(2),
          });
        }
        if (data[i].recibos) {
          local.push({
            fecha: data[i].fecha,
            comprobante:
              data[i].recibos.comprobante +
              " - " +
              ("0000" + data[i].recibos.puntoVenta).slice(-4) +
              "-" +
              ("00000000" + data[i].recibos.numero).slice(-8),
            debe: parseFloat(data[i].debe).toFixed(2),
            haber: parseFloat(data[i].haber).toFixed(2),
            saldo: parseFloat(saldoAnterior).toFixed(2),
          });
        }
      }

      guardarCtaCte(local);
      guardarSaldoCtaCte(saldoAnterior);
    } catch (error) {
      guardarCtaCte(null);
      guardarSaldoCtaCte(0);
      console.log(error.response);
    }
  };

  const obtenerCtaCtePendienteCliente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/ctacte/obtenerctactependientecliente",
        datos
      );
      let data = respuesta.data;

      const local = [];
      let saldoPendiente = 0;
      for (var i = 0; i < data.length; i++) {
        saldoPendiente = Number(saldoPendiente) + Number(data[i].saldo);
        local.push({
          id: data[i].id,
          fecha: data[i].fecha,
          comprobante:
            data[i].abreviatura +
            " - " +
            ("0000" + data[i].puntoVenta).slice(-4) +
            "-" +
            ("00000000" + data[i].numero).slice(-8),
          importe: parseFloat(data[i].saldo).toFixed(2),
          pendiente: parseFloat(data[i].pendiente).toFixed(2),
          cancelado: parseFloat(0).toFixed(2),
        });
      }

      guardarComprobantesRecibo(local);
      guardarSaldoCtaCte(saldoPendiente);
    } catch (error) {
      guardarComprobantesRecibo(null);
      guardarSaldoCtaCte(0);
      console.log(error.response);
    }
  };

  //ARTICULOS

  const obtenerArticuloxId = async (datos) => {


    const {impuestos, empresa} = datos

    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticulo",
        datos
      );

      let tasaImpuestos = 0
      for (var i = 0; i < impuestos.length; i++) {
        tasaImpuestos = tasaImpuestos + impuestos[i].alicuota
      }
      let data = respuesta.data;
      guardarCarga({
        codigo: data.id,
        detalle: data.nombre + " " + data.unidad,
        cantidad: "",
        valorUnitario: 0,
        descuento: cliente.descuento,
        percepcion: empresa.percepciones ? tasaImpuestos : 0,
        iva: 0,
        valorTotal: 0,
      });
    } catch (error) {

      guardarCarga({
        codigo: "",
        detalle:  "",
        cantidad: "",
        valorUnitario: 0,
        descuento: "",
        percepcion: "",
        iva: 0,
        valorTotal: 0,
      });
      
      console.log(error.response);
    }
  };

  const obtenerArticuloListaPrecios = async (datos) => {

    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticulolistaprecios",
        datos
      );
      
      let data = respuesta.data;
      let tasaIva = 0;

      if (data.ivaId == 5) {
        tasaIva = 21;
      }
      if (data.ivaId == 4) {
        tasaIva = 10.5;
      }
      if (data.ivaId == 3) {
        tasaIva = 0;
      }
      if (data.ivaId == 6) {
        tasaIva = 27;
      }

      guardarArticuloListaPrecios({
        ...articuloListaPrecios,
        precio: data.precio,
        iva: tasaIva,
        ivaId: Number(data.ivaId),
      });
    } catch (error) {
      guardarArticuloListaPrecios({
        ...articuloListaPrecios,
        precio: 0,
        iva: 21,
      });

      console.log(error.response);
    }
  };

  const obtenerArticulosxIdComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticulosxidcomprobante",
        datos
      );
      let data = respuesta.data;
      guardarArticulosComprobante(data);
    } catch (error) {
      console.log(error.response);
    }
  };


  const obtenerArticulosxIdRemito = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/articulos/obtenerarticulosxidremito",
        datos
      );
      let data = respuesta.data;
      guardarArticulosComprobante(data);
    } catch (error) {
      console.log(error.response);
    }
  };



  //IMPUESTOS

  const obtenerImpuestosxIdComprobante = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/impuestos/obtenerimpuestosxidcomprobante",
        datos
      );
      let data = respuesta.data;
      guardarImpuestosComprobante(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  // CONDICIONES VENTAS //

  const obtenerCondicionesVentasSelect = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/condicionesventas/obtenercondicionesventas"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          label: data[i].nombre,
          value: data[i].id,
        });
      }

      guardarCondicionesVentas(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // RECIBOS //

  const obtenerRecibosEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/recibos/obtenerrecibosempresa",
        datos
      );
      let data = respuesta.data;

      guardarRecibos(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerRecibo = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/recibos/obtenerrecibo",
        datos
      );
      let data = respuesta.data;
      data.fecha = data.fecha.replace(/-/g, "/");
      guardarRecibo(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const crearRecibo = async (
    empresa,
    recibo,
    cliente,
    comprobantesRecibo,
    valoresPendiente,
    valoresRecibo,
    valoresTotal,
    saldoCtaCte,
    deposito,
    chequesTabla,
    retencionesTabla
  ) => {
    let reciboBulk = [];
    reciboBulk.push(recibo);

    try {
      const respuesta = await clienteAxios.post("/api/recibos/crearrecibo", {
        empresa,
        reciboBulk,
        cliente,
        comprobantesRecibo,
        valoresPendiente,
        valoresRecibo,
        valoresTotal,
        saldoCtaCte,
        deposito,
        chequesTabla,
        retencionesTabla,
      });

      const idRecibo = respuesta.data[0].id;

      await growl.show({
        severity: "success",
        summary: "Recibos",
        detail: "El recibo se ha creado correctamente.",
      });

      guardarVerRecibo({
        id: idRecibo,
        estado: true,
      });
    } catch (error) {
      console.log(error.response.data);
      await growl.show({
        severity: "error",
        summary: "Recibos",
        detail: error.response.data,
      });
    }
  };

  // REMITOS //

  const obtenerRemitosEmpresa = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantes/obtenerremitosempresa",
        datos
      );
      let data = respuesta.data;

      guardarRemitos(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerRemito = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/comprobantes/obtenerremito",
        datos
      );
      let data = respuesta.data;

      //console.log(data);

      data.fecha = data.fecha.replace(/-/g, "/");
      guardarComprobante(data);
    } catch (error) {
      console.log(error.response);
    }
  };


  return (
    <Fragment>
      <Growl ref={(el) => (growl = el)} />
      <Provider
        value={{
          comprobantes,
          comprobante,
          cliente,
          condicionesVentas,
          facturaAsociada,
          verComprobante,
          articulosComprobante,
          ajuste,
          ctacte,
          saldoCtaCte,
          verRecibo,
          recibos,
          recibo,
          comprobantesRecibo,
          ultimoComprobanteAfip,
          impuestosComprobante,
          qrValue,
          verRemito,
          remitos,
          remito,
          carga,
          articuloListaPrecios,
          crearComprobante,
          obtenerComprobantesEmpresa,
          guardarComprobante,
          obtenerComprobante,
          obtenerComprobanteNro,
          obtenerCtaCtePendienteCliente,
          obtenerClientexId,
          obtenerClientexIdComprobante,
          obtenerClientexIdRecibo,
          guardarCliente,
          guardarCondicionesVentas,
          obtenerCondicionesVentasSelect,
          guardarFacturaAsociada,
          tranformDate,
          guardarVerComprobante,
          guardarArticulosComprobante,
          obtenerArticulosxIdComprobante,
          guardarAjuste,
          obtenerCtaCteCliente,
          guardarSaldoCtaCte,
          guardarVerRecibo,
          crearRecibo,
          obtenerRecibosEmpresa,
          guardarRecibo,
          obtenerRecibo,
          guardarComprobantesRecibo,
          obtenerComprobantesxIdRecibo,
          obtenerUltimoComprobanteAfip,
          obtenerImpuestosxIdComprobante,
          guardarImpuestosComprobante,
          setQrValue,
          guardarVerRemito,
          crearRemito,
          obtenerRemitosEmpresa,
          guardarRemito,
          obtenerRemito,
          obtenerArticuloxId,
          guardarCarga,
          obtenerArticuloListaPrecios,
          guardarArticuloListaPrecios,
          obtenerRemito,
          obtenerClientexIdRemito,
          obtenerArticulosxIdRemito,
          grabarObservaciones,
      
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { VentaProvider, Consumer as VentaConsumer, VentaContext };
