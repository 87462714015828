import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { PanelMenu } from "primereact/panelmenu";
import { ArchivoContext } from "../../context/ArchivoContext";
import { Dialog } from "primereact/dialog";

function Barra(props) {
  const authContext = useContext(AuthContext);
  const { autenticado, usuario, usuarioAutenticado } = authContext;

  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    obtenerEmpresasSelect,
    guardarEmpresaNavbar,
  } = archivoContext;

  const [visible, guardarVisible] = useState(false);
  const [visibleModal, guardarVisibleModal] = useState(false);

  useEffect(() => {
    usuarioAutenticado();
    obtenerEmpresasSelect();
    guardarEmpresaNavbar(Number(localStorage.getItem("empresa")));
  }, []);

  const items = [
    {
      label: "Archivos",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "Clientes",
          command: () => {
            props.history.push("/clientes");
          },
          icon: "fa fa-users",
        },
        {
          label: "Proveedores",
          command: () => {
            props.history.push("/proveedores");
          },
          icon: "fa fa-users",
        },
        {
          label: "Empresas",
          command: () => {
            props.history.push("/empresas");
          },
          icon: "fa fa-industry",
        },
        {
          label: "Localidades",
          command: () => {
            props.history.push("/localidades");
          },
          icon: "fa fa-map-marker",
        },
        {
          label: "percepciones",
          command: () => {
            props.history.push("/impuestos");
          },
          icon: "fa fa-money",
        },
        {
          label: "Vendedores",
          command: () => {
            props.history.push("/vendedores");
          },
          icon: "fa fa-user-circle-o",
        },
        {
          label: "Tipos de retenciones",
          command: () => {
            props.history.push("/tiposretenciones");
          },
          icon: "fa fa-arrows-alt",
        },
        {
          label: "Artículos",
          command: () => {
            props.history.push("/articulos");
          },
          icon: "fa fa-cubes",
        },
        {
          label: "Listas de precios",
          command: () => {
            props.history.push("/listasprecios");
          },
          icon: "fa fa-usd",
        },
        {
          label: "Bancos",
          command: () => {
            props.history.push("/bancos");
          },
          icon: "fa fa-bank",
        },
        {
          label: "Cuentas bancarias",
          command: () => {
            props.history.push("/cuentas");
          },
          icon: "fa fa-handshake-o",
        },
        {
          label: "Conceptos de caja",
          command: () => {
            props.history.push("/conceptos");
          },
          icon: "fa fa-list",
        },
        {
          label: "Fletes",
          command: () => {
            props.history.push("/fletes");
          },
          icon: "fa fa-truck",
        },
        {
          label: "Rubros",
          command: () => {
            props.history.push("/rubros");
          },
          icon: "fa fa-sitemap",
        },
      ],
    },
    {
      label: "Ventas",
      icon: "pi pi-chart-line",
      items: [
        {
          label: "Comprobantes",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/comprobantes");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-files-o",
        },
        {
          label: "Remitos",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/remitos");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-align-justify",
        },
        {
          label: "Recibos",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/recibos");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-file-text",
        },
      ],
    },

    {
      label: "Compras",
      icon: "pi pi-shopping-cart",
      items: [
        {
          label: "Comprobantes",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/comprobantescompras");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-files-o",
        },
        {
          label: "Remitos",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/comprobantecompras/0/nd");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-align-justify",
        },
        {
          label: "Orden de Pago",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/ordenespagoscompras");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-file-text",
        },

      ],
    },
    {
      label: "Caja",
      icon: "pi pi-inbox",
      items: [
        {
          label: "Movimientos de caja",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/movimientoscajas");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-exchange",
        },
        {
          label: "Movimientos de cajas",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/movimientoscajas");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-archive",
        },
      ],
    },
    {
      label: "Bancos",
      icon: "fa fa-archive",
      items: [
        {
          label: "Conciliación bancaria",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/conciliacionbancaria");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-retweet",
        },
        {
          label: "Movimientos de bancos",
          command: () => {
            if (empresaNavbar !== "") {
              props.history.push("/movimientosbancos");
            } else {
              guardarVisibleModal(true);
            }
          },
          icon: "fa fa-university",
        },
      ],
    },

    {
      label: "Cheques",
      icon: "fa fa-outdent",
      items: [
        {
          label: "Cheques de terceros",
          icon: "fa fa-outdent",
          items: [
            {
              label: "Cartera de cheques de terceros",
              command: () => {
                if (empresaNavbar !== "") {
                  props.history.push("/carterachequesterceros");
                } else {
                  guardarVisibleModal(true);
                }
              },
              icon: "fa fa-briefcase",
            },
            {
              label: "Historial de cheques de terceros",
              command: () => {
                if (empresaNavbar !== "") {
                  props.history.push("/historialchequesterceros");
                } else {
                  guardarVisibleModal(true);
                }
              },
              icon: "fa fa-briefcase",
            },
          ],
        },
        {
          label: "Cheques propios",
          icon: "fa fa-outdent",
          items: [
            {
              label: "Cartera de Cheques Propios",
              command: () => {
                if (empresaNavbar !== "") {
                  props.history.push("/carterachequespropios");
                } else {
                  guardarVisibleModal(true);
                }
              },
              icon: "fa fa-indent",
            },
          ],
        },
      ],
    },

    /*
    {
      label: "Users",
      icon: "pi pi-fw pi-user",
      items: [
        {
          label: "New",
          icon: "pi pi-fw pi-user-plus",
        },
        {
          label: "Delete",
          icon: "pi pi-fw pi-user-minus",
        },
        {
          label: "Search",
          icon: "pi pi-fw pi-users",
          items: [
            {
              label: "Filter",
              icon: "pi pi-fw pi-filter",
              items: [
                {
                  label: "Print",
                  icon: "pi pi-fw pi-print",
                },
              ],
            },
            {
              icon: "pi pi-fw pi-bars",
              label: "List",
            },
          ],
        },
      ],
    },
    {
      label: "Events",
      icon: "pi pi-fw pi-calendar",
      items: [
        {
          label: "Edit",
          icon: "pi pi-fw pi-pencil",
          items: [
            {
              label: "Save",
              icon: "pi pi-fw pi-calendar-plus",
            },
            {
              label: "Delete",
              icon: "pi pi-fw pi-calendar-minus",
            },
          ],
        },
        {
          label: "Archieve",
          icon: "pi pi-fw pi-calendar-times",
          items: [
            {
              label: "Remove",
              icon: "pi pi-fw pi-calendar-minus",
            },
          ],
        },
      ],
    },
    */
  ];

  //console.log(empresaNavbar);

  return (
    <Fragment>
      {autenticado && usuario && empresaNavbar !== 0 ? (
        <Fragment>
          <Sidebar visible={visible} onHide={() => guardarVisible(false)}
          style={{backgroundColor: "#E0E4E9"}}
          >
            <h5 className="mt-4" style={{ fontWeight: "bold" }}>
              Hola {usuario.nombre}
            </h5>

            <PanelMenu
              model={items}
              style={{ width: "250px", marginTop: "1em", backgroundColor: "#9ECAE5" }}
            />
          </Sidebar>

          <Button
            icon="pi pi-arrow-right"
            onClick={(e) => guardarVisible(true)}
          />
        </Fragment>
      ) : null}

      <Dialog
        header="Empresas"
        visible={visibleModal}
        style={{ fontSize: "large", width: "50vw" }}
        modal={true}
        onHide={() => guardarVisibleModal(false)}
      >
        Es necesario seleccionar una empresa para ingresar a comprobantes.
      </Dialog>
    </Fragment>
  );
}

export default Barra;
