import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";

function Cuenta(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    cuenta,
    bancos,
    banco,
    obtenerCuenta,
    guardarCuenta,
    crearCuenta,
    actualizarCuenta,
    obtenerBancosSelect,
  } = archivoContext;

  let growl = useRef();

  const idCuenta = props.match.params.id;

  useEffect(() => {
    if (idCuenta !== "0") {
      obtenerCuenta({ idCuenta });
    } else {
      guardarCuenta({
        id: "",
        numero: "",
        bancoId: 0,
        detalle: "",
      });
    }
    obtenerBancosSelect();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (cuenta.numero !== null && cuenta.numero.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Cuentas",
        detail: "El Nro. de cuenta es obligatorio.",
      });
      return;
    }


    if (cuenta.bancoId !== null && cuenta.bancoId === 0) {
      growl.show({
        severity: "error",
        summary: "Cuentas",
        detail: "El banco es obligatorio.",
      });
      return;
    }

    if (idCuenta === "0") {
      crearCuenta(cuenta);
    } else {
      actualizarCuenta(cuenta);
    }
  };

  const cerrar = () => {
    props.history.push("/cuentas");
  };


  //onsole.log(cuenta);



  return (
    <Fragment>
      {!cuenta ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                  style={{ color: "Blue" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {cuenta.numero}
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <div class="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="id"
                            type="text"
                            className="form-control mt-4"
                            value={cuenta.numero}
                            onChange={(e) =>
                              guardarCuenta({
                                ...cuenta,
                                numero: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="id" style={{ color: "black" }}>
                            Nro. de cuenta
                          </label>
                        </span>
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <label
                            htmlFor="banco"
                            style={{ color: "black", fontSize: "0.8em" }}
                          >-
                            Banco
                          </label>
                        </span>
                        <Dropdown
                          id="banco"
                          value={cuenta.bancoId}
                          style={{ width: "70%" }}
                          className="mt-3"
                          options={bancos}
                          onChange={(e) =>
                            guardarCuenta({
                              ...cuenta,
                              bancoId: e.target.value,
                            })
                          }
                          filter={true}
                          placeholder="Seleccioná un banco"
                          style={{ width: "23em" }}
                        />
                      </div>

                      <div className="form-group">
                        <span className="p-float-label">
                          <InputText
                            id="detalle"
                            type="text"
                            className="form-control mt-4"
                            value={cuenta.detalle}
                            onChange={(e) =>
                              guardarCuenta({
                                ...cuenta,
                                detalle: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="detalle" style={{ color: "black" }}>
                            Detalle
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="btn btn-background btn-block mt-4"
                    value="Guardar cuenta"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Cuenta;
