import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { FondoContext } from "../../../context/FondoContext";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";

function HistorialChequesTerceros(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const { empresaNavbar } = archivoContext;

  const fondoContext = useContext(FondoContext);
  const {
    chequesTerceros,
    totalCheques,
    chequesSelected,
    bancosSelected,
    chequeTercero,
    totalIngresos,
    totalEgresos,
    saldoAnterior,
    dateCaja,
    rangeCaja,
    radioCaja,
    agrupar,
    field,
    sort,
    todas,
    obtenerMovimientosCaja,
    obtenerChequesTerceros,
    guardarChequesTerceros,
    guardarChequesSelected,
    tranformDate,
    guardarDateCaja,
    guardarRangeCaja,
    guardarRadioCaja,
    setAgrupar,
    setField,
    setSort,
    setTodas,
    obtenerBancoCheque,
    guardarBancosSelected,
    actualizarChequeRechazado,
  } = fondoContext;

  const [globalFilter, guardarGlobalFilter] = useState(null);
  const [estadosCheques, guardarEstadosCheques] = useState([
    {
      value: 1,
      label: "CARTERA",
    },
    {
      value: 2,
      label: "DEPOSITADO",
    },
    {
      value: 3,
      label: "ENTREGADO",
    },
    {
      value: 4,
      label: "RECHAZADO",
    },
    {
      value: 5,
      label: "VENTANILLA",
    },
    {
      value: 6,
      label: "TODOS",
    },
  ]);

  const [estadoCheque, guardarEstadoCheque] = useState(6); //TODOS
  let dt = useRef();

  const fullStyles =
    window.screen.availWidth < 780 ? { width: "90vw" } : { width: "50vw" };

  const largeStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "42ch" };

  const mediaStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "36ch" };

  const shortStyles =
    window.screen.availWidth < 780 ? { width: "25ch" } : { width: "30ch" };

  useEffect(() => {
    //guardarChequesTerceros(null);
    obtenerChequesTerceros({
      idEmpresa: empresaNavbar,
      todas,
      estado: estadoCheque,
    });
    guardarChequesSelected(null);
    guardarBancosSelected(null);
  }, []);

  const cerrar = () => {
    props.history.push("/menu");
  };

  let es = {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
    dateFormat: "dd/mm/yy",
    weekHeader: "Sm",
  };

  const rechazarCheque = () => {
    if (chequesSelected) {
      if (
        chequesSelected.estado === "CARTERA" ||
        chequesSelected.estado === "DEPOSITADO" ||
        chequesSelected.estado === "ENTREGADO"
      ) {
        actualizarChequeRechazado({ chequesSelected, bancosSelected });
        setTimeout(() => {
          actualizar();
        }, 2000);
      }
    }
  };

  const actualizar = () => {
    obtenerChequesTerceros({
      idEmpresa: empresaNavbar,
      todas,
      estado: estadoCheque,
    });
  };

  let header = (
    <Fragment>
      <button
        type="button"
        className="close xclose"
        aria-label="Close"
        onClick={cerrar}
        style={{ color: "Blue" }}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <h3 className="fwhite titulos text-center py=4">
        Historial cheques de terceros
      </h3>
      <hr className="linea-negra" />
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => guardarGlobalFilter(e.target.value)}
          placeholder="Buscador"
        />
        {/*
        <button className="btn btn-success ml-1" onClick={buscarDatos}>
          Buscar
        </button>
        <Link to={`chequetercero/0`}>
          <button type="button" className="btn btn-info btn-large ml-4">
            Nuevo
          </button>
        </Link>
        */}

        <button
          type="button"
          className="btn btn-warning btn-large ml-4"
          onClick={(e) => {
            rechazarCheque();
          }}
        >
          <i class="fa fa-window-close" aria-hidden="true"></i> Rechazar
        </button>

        <div className="row">
          <div className="custom-control custom-switch ml-3 mt-4">
            <input
              type="checkbox"
              className="custom-control-input"
              id="todas"
              checked={todas}
              onClick={(e) => {
                setTodas(e.target.checked);
                obtenerChequesTerceros({
                  idEmpresa: empresaNavbar,
                  todas: e.target.checked,
                  estado: estadoCheque,
                });
              }}
            />
            <label className="custom-control-label" for="todas">
              Todas
            </label>
          </div>

          <label
            style={{ color: "black", marginLeft: "2.5em", marginTop: "1.5em" }}
          >
            Estado
          </label>

          <Dropdown
            id="estado"
            name="estado"
            value={estadoCheque}
            style={shortStyles}
            className="mt-3 ml-5"
            options={estadosCheques}
            onChange={(e) => {
              guardarEstadoCheque(e.target.value);
              obtenerChequesTerceros({
                idEmpresa: empresaNavbar,
                todas: e.target.checked,
                estado: e.target.value,
              });
            }}
            filter={false}
          />
        </div>
      </div>
    </Fragment>
  );

  const rowNoalaOrden = (rowData, column) => {
    if (rowData.noalaOrden === true || rowData.noalaOrden === "Si") {
      return (rowData.noalaOrden = "Si");
    }
    if (
      rowData.noalaOrden === null ||
      rowData.noalaOrden === false ||
      rowData.noalaOrden === "No"
    ) {
      return (rowData.noalaOrden = "No");
    }
  };

  const rowFecha = (rowData, column) => {
    return tranformDate(rowData.fecha);
  };

  const rowImporte = (rowData, column) => {
    return parseFloat(rowData.importe).toFixed(2);
  };

  const rowEgreso = (rowData, column) => {
    return parseFloat(rowData.egreso).toFixed(2);
  };

  const botonVer = (rowData, column) => {
    return (
      <div>
        <Link to={`chequetercero/${rowData.id}/1`}>
          <button type="button" className="btn btn-success btn-block">
            Ver
          </button>
        </Link>
      </div>
    );
  };

  let rCount = chequesTerceros ? chequesTerceros.length : 0;
  let footer = "Total " + rCount + " movimientos";

  ////

  //console.log(bancosSelected);

  return (
    <div className="background-body">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-md-12 mt-3">
          <div className="card-body text-black background mb-3">
            <div className="mlpc-table">
              <DataTable
                ref={(el) => {
                  dt = el;
                }}
                value={chequesTerceros}
                paginator={true}
                rows={10}
                header={header}
                footer={footer}
                globalFilter={globalFilter}
                scrollable={false}
                responsive={true}
                resizableColumns={true}
                selection={chequesSelected}
                onSelectionChange={(e) => {
                  guardarChequesSelected(e.value);
                  obtenerBancoCheque({
                    idMovimiento: e.value.movimientoBancoId,
                  });
                }}
                selectionMode="single"
                dataKey="id"
              >
                <Column field="id" header="ID de Cheque" sortable={true} />
                <Column
                  field="fecha"
                  header="Fecha"
                  sortable={true}
                  body={rowFecha}
                />
                <Column field="banco.nombre" header="Banco" sortable={true} />
                <Column field="numero" header="Número" sortable={true} />
                <Column
                  field="emision"
                  header="Emisión"
                  sortable={true}
                  body={rowFecha}
                />
                <Column
                  field="vencimiento"
                  header="Vencimiento"
                  sortable={true}
                  body={rowFecha}
                />
                <Column
                  field="importe"
                  header="Importe"
                  sortable={true}
                  style={{ textAlign: "right" }}
                  body={rowImporte}
                />
                <Column
                  field="nombreCliente"
                  header="Cliente"
                  sortable={true}
                />
                <Column field="librador" header="Librador" sortable={true} />
                <Column
                  field="noalaOrden"
                  header="No a la orden"
                  sortable={true}
                  body={rowNoalaOrden}
                />
                <Column field="estado" header="Estado" sortable={true} />
                <Column field="botones" header="" body={botonVer} />
              </DataTable>{" "}
            </div>
            <h3>
              <div style={{ textAlign: "right" }}>
                Total cheques
                <span className="badge badge-secondary ml-2 mr-2">
                  {"$ " + parseFloat(totalCheques).toFixed(2)}
                </span>
              </div>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistorialChequesTerceros;
