import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { CompraContext } from "../../../context/CompraContext";
import { ArchivoContext } from "../../../context/ArchivoContext";

function OrdenesPagos(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const { empresaNavbar } = archivoContext;

  const compraContext = useContext(CompraContext);
  const {
    ordenesPagos,
    orden,
    verOrdenPagoPago,
    obtenerOrdenesPagosEmpresa,
    tranformDate,
    guardarVerOrdenPago,
  } = compraContext;

  const [globalFilter, guardarGlobalFilter] = useState(null);

  let dt = useRef();

  useEffect(() => {
    obtenerOrdenesPagosEmpresa({ idEmpresa: empresaNavbar} );
  }, []);

  const cerrar = () => {
    props.history.push("/menu");
  };

  let header = (
    <Fragment>
      <button
        type="button"
        className="close xclose"
        aria-label="Close"
        onClick={cerrar}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <h3 className="fwhite titulos text-center py=4">Ordenes de pago</h3>
      <hr className="linea-negra" />
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => guardarGlobalFilter(e.target.value)}
          placeholder="Buscador"
          size={{ xs: "10", md: "50" }}
        />

        <Link to={`ordenpagocompras/0`}>
          <button
            type="button"
            className="btn btn-info btn-nuevo ml-4"
            onClick={guardarVerOrdenPago(null)}
          >
            Nuevo
          </button>
        </Link>
      </div>
    </Fragment>
  );

  const rowAnulado = (rowData, column) => {
    if (rowData.anulado === true || rowData.anulado === "Si") {
      return (rowData.anulado = "Si");
    }
    if (rowData.anulado === false || rowData.anulado === "No") {
      return (rowData.anulado = "No");
    }
  };

  const rowFecha = (rowData, column) => {
    return tranformDate(rowData.fecha);
  };

  const rowMoneda = (rowData, column) => {
    return parseFloat(rowData.total).toFixed(2);
  };

  const botonVer = (rowData, column) => {

    return (
      <div>
        <Link to={`ordenpagocompras/${rowData.id}`}>
          <button type="button" className="btn btn-success btn-block">
            Ver
          </button>
        </Link>
      </div>
    );
  };

  let rCount = ordenesPagos ? ordenesPagos.length : 0;
  let footer = "Total " + rCount + " ordenes";

  //console.log(ordenes)


  return (
    <div className="background-body">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-md-12 mt-3">
          <div className="card-body text-black background mb-3">
            <div className="mlpc-table">
              <DataTable
                ref={(el) => {
                  dt = el;
                }}
                value={ordenesPagos}
                paginator={true}
                rows={10}
                header={header}
                footer={footer}
                globalFilter={globalFilter}
                scrollable={false}
                responsive={true}
                resizableColumns={true}
              >
                {/*}
            <Column
              field="descripcion"
              header="Descripción"
              sortable={true}
              filter={true}
              filterPlaceholder="Buscar"
            />
            */}
                <Column
                  field="id"
                  header="ID"
                  sortable={true}
                  style={{ width: "60px", textAlign: "center" }}
                />
                                <Column
                  field="abreviatura"
                  header="Comprobante"
                  sortable={true}
                />
                <Column
                  field="proveedorId"
                  header="ID Proveedor"
                  sortable={true}
                  style={{ width: "120px" }}
                />
                <Column
                  field="nombreProveedor"
                  header="Proveedor"
                  sortable={true}
                  style={{ width: "250px" }}
                />
                <Column
                  field="cuitProveedor"
                  header="C.U.I.T"
                  sortable={true}
                  style={{ width: "140px" }}
                />
                <Column
                  field="fecha"
                  header="Fecha"
                  sortable={true}
                  body={rowFecha}
                  style={{ textAlign: "center" }}
                />
                <Column
                  field="puntoVenta"
                  header="Punto de venta"
                  sortable={true}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="numero"
                  header="Número"
                  sortable={true}
                  style={{ textAlign: "right" }}
                />
                <Column
                  field="total"
                  header="Total"
                  sortable={true}
                  style={{ textAlign: "right" }}
                  body={rowMoneda}
                />
                <Column field="botones" header="" body={botonVer} />
              </DataTable>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrdenesPagos;
