import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { CompraContext } from "../../../context/CompraContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import MaterialTable from "material-table";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

function Proveedor(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    proveedor,
    empresa,
    vendedores,
    impuestos,
    listasPrecios,
    provincias,
    tiposComprobantes,
    condicionesIva,
    condicionesVenta,
    obtenerProveedor,
    guardarProveedor,
    obtenerLocalidadesSelect,
    obtenerVendedoresSelect,
    crearProveedor,
    actualizarProveedor,
    guardarImpuestos,
    obtenerImpuestosProveedor,
    obtenerListasPreciosSelect,
    obtenerProvinciasSelect,
    obtenerCondicionesIvaSelect,
    obtenerCondicionesVentaSelect,
    obtenerTiposComprobantesEmpresaAjuste,
    guardarTiposComprobantes,
    obtenerEmpresa,
  } = archivoContext;

  const compraContext = useContext(CompraContext);
  const {
    ajuste,
    comprobante,
    ctacte,
    saldoCtaCte,
    articulosComprobante,
    guardarAjuste,
    guardarComprobante,
    guardarArticulosComprobante,
    crearComprobante,
    obtenerCtaCteProveedor,
    tranformDate,
  } = compraContext;

  const [modalAjuste, setModalAjuste] = useState(false);
  const [modalCtaCte, setModalCtaCte] = useState(false);
  const [todas, setTodas] = useState(false);

  let growl = useRef();

  const idProveedor = props.match.params.id;

  useEffect(() => {
    if (idProveedor !== "0") {
      obtenerProveedor({ idProveedor });
    } else {
      guardarProveedor({
        nombre: "",
        cuit: "",
        domicilio: "",
        entre1: "",
        entre2: "",
        telefono: "",
        localidad: "",
        cp: 0,
        idProvincia: 0,
        descuento: 0,
        cbu: "",
        idCondicionIva: 0,
        retencionesGanancias: false,
        retencionesIIBB: false,
      });
    }
    obtenerLocalidadesSelect();
    obtenerProvinciasSelect();
    obtenerCondicionesIvaSelect();
    obtenerEmpresa({ idEmpresa: empresaNavbar });
    guardarComprobante(null);
    guardarArticulosComprobante(null);
    guardarTiposComprobantes(null);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (proveedor.nombre !== null && proveedor.nombre.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Proveedores",
        detail: "El nombre o razón social es obligatorio.",
      });
      return;
    }

    if (proveedor.idCondicionIva === 0) {
      growl.show({
        severity: "error",
        summary: "Proveedores",
        detail: "La condición de I.V.A. es necesaria.",
      });
      return;
    }

    if (proveedor.cuit !== null && proveedor.cuit.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Proveedores",
        detail: "El C.U.I.T. es necesario.",
      });
      return;
    }

    if (proveedor.idProvincia === 0) {
      growl.show({
        severity: "error",
        summary: "Proveedores",
        detail: "La provincia es necesaria.",
      });
      return;
    }

    if (idProveedor === "0") {
      crearProveedor(proveedor);
    } else {
      actualizarProveedor(proveedor);
    }
  };

  const cerrar = () => {
    props.history.push("/proveedores");
  };

  const onClickAjuste = () => {
    crearComprobante(empresa, comprobante, proveedor, articulosComprobante);
  };

  if (
    tiposComprobantes && typeof(tiposComprobantes.tiposcomprobantes) !== "undefined" && 
    proveedor &&
    (!comprobante || comprobante.numero !== tiposComprobantes.numero)
  ) {
    guardarComprobante({
      ...comprobante,
      fecha: new Date(),
      comprobante: tiposComprobantes.tiposcomprobantes.nombre,
      abreviatura: tiposComprobantes.tiposcomprobantes.abreviatura,
      tipo: tiposComprobantes.tiposcomprobantes.tipo,
      puntoVenta: tiposComprobantes.puntoVenta,
      numero: tiposComprobantes.numero,
      electronica: false,
      proveedorId: proveedor.id,
      nombreProveedor: proveedor.nombre,
      cuitProveedor: proveedor.cuit,
      cbuProveedor: proveedor.cbu,
      vendedorId: proveedor.idVendedor,
      subtotal: 0,
      descuento: 0,
      neto: 0,
      iva: 0,
      percepcion: 0,
      total: 0,
      exento: 0,
      tipoComprobanteId: tiposComprobantes.tiposcomprobanteId,
      tipoComprobanteIdAfip: tiposComprobantes.tiposcomprobantes.codigoAfip,
      condicionVentaId: 0,
      condicionIvaId: 0,
      listaPrecioId: 0,
      empresaId: empresaNavbar,
      anulado: false,
      cae: "",
      vtocae: null,
      tipoComprobanteIdAsociado: 0,
      tipoComprobanteIdAsociadoAfip: 0,
      puntoVentaAsociado: 0,
      numeroAsociado: 0,
      fechaAsociado: null,
      remito: false,
      puntoVentaRemito: 0,
      numeroRemito: 0,
      tipoComprobanteRemitoId: 0,
      saldo: 0,
      fechaVencimiento: null,
      comentarios: "",
    });

    guardarProveedor({
      ...proveedor,
      domicilioCompleto: `${proveedor.domicilio} ${proveedor.cp} ${proveedor.localidad}`,
    });
  }

  //console.log(tiposComprobantes);
  //console.log(ctacte);

  return (
    <Fragment>
      {!proveedor ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                  style={{ color: "Blue" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {proveedor.nombre}
                </h3>
                <Dialog
                  header="Ajuste de Cuenta Corriente"
                  visible={modalAjuste}
                  style={{ width: "50vw" }}
                  modal={true}
                  onHide={() => setModalAjuste(false)}
                >
                  <div className="form-group">
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <KeyboardDatePicker
                        clearable
                        label="Fecha"
                        value={comprobante ? comprobante.fecha : new Date()}
                        onChange={(date) =>
                          guardarComprobante({
                            ...comprobante,
                            fecha: date,
                          })
                        }
                        format="dd/MM/yyyy"
                        style={{ color: "blue" }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="form-group">
                    <span className="p-float-label">
                      <InputText
                        id="ajuste"
                        type="number"
                        className="form-control mt-4"
                        style={{ width: "30%" }}
                        value={comprobante ? comprobante.subtotal : 0}
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            subtotal: e.target.value,
                            neto: e.target.value,
                            total: e.target.value,
                            saldo: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="ajuste" style={{ color: "black" }}>
                        Ajuste (a favor del proveedor en positivo)
                      </label>
                    </span>
                  </div>

                  <div className="form-group">
                    <span className="p-float-label">
                      <InputText
                        id="comentarios"
                        type="text"
                        className="form-control mt-4"
                        value={comprobante ? comprobante.comentarios : ""}
                        onChange={(e) =>
                          guardarComprobante({
                            ...comprobante,
                            comentarios: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="comentarios" style={{ color: "black" }}>
                        Detalle
                      </label>
                    </span>
                  </div>

                  <input
                    type="button"
                    className="btn btn-background btn-block mt-4"
                    value="Guardar ajuste"
                    onClick={onClickAjuste}
                  />
                </Dialog>

                <Dialog
                  header="Cuenta Corriente"
                  visible={modalCtaCte}
                  style={{ fontSize: "small", width: "50vw" }}
                  modal={true}
                  onHide={() => setModalCtaCte(false)}
                >
                  {ctacte ? (
                    <div style={{ maxWidth: "100%" }}>
                      <MaterialTable
                        columns={[
                          {
                            title: "Fecha",
                            field: "fecha",
                            render: (row) => (
                              <span>
                                {
                                  tranformDate(
                                    row["fecha"]
                                  ) /*formatting code here*/
                                }
                              </span>
                            ),
                            editable: "never",
                          },
                          {
                            title: "Comprobante",
                            field: "comprobante",
                            editable: "never",
                            cellStyle: {
                              width: 400,
                              minWidth: 400,
                            },
                          },
                          {
                            title: "Debe",
                            field: "debe",
                            type: "numeric",
                            editable: "never",
                          },
                          {
                            title: "Haber",
                            field: "haber",
                            type: "numeric",
                            editable: "never",
                          },
                          {
                            title: "Saldo",
                            field: "saldo",
                            type: "numeric",
                            editable: "never",
                          },
                        ]}
                        data={ctacte}
                        title={
                          ctacte
                            ? `Saldo: ${parseFloat(saldoCtaCte).toFixed(2)}`
                            : null
                        }
                        options={{
                          search: false,
                          sorting: true,
                          actionsColumnIndex: -1,
                          showTitle: true,
                          toolbar: true,
                        }}
                        localization={{
                          toolbar: {
                            searchPlaceholder: "Buscar",
                          },
                          header: {
                            actions: "Habilitado",
                          },
                          body: {
                            emptyDataSourceMessage: "No hay registros",
                          },
                          pagination: {
                            labelRowsSelect: "filas",
                          },
                        }}
                      />
                    </div>
                  ) : null}
                </Dialog>

                {idProveedor !== "0" && empresaNavbar !== 0 ? (
                  <Fragment>
                    <Button
                      label="Ajustes"
                      icon="fa fa-sort"
                      onClick={(e) => {
                        obtenerTiposComprobantesEmpresaAjuste({
                          idEmpresa: empresaNavbar,
                        });
                        setModalAjuste(true);
                      }}
                    />

                    <Button
                      label="Cuenta Corriente"
                      icon="fa fa-exchange"
                      style={{ marginLeft: "1em" }}
                      onClick={(e) => {
                        obtenerCtaCteProveedor({
                          idProveedor: idProveedor,
                          idEmpresa: empresaNavbar,
                          todas,
                        });
                        setModalCtaCte(true);
                      }}
                    />
                    <div
                      className="custom-control custom-switch"
                      style={{ float: "right" }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch1"
                        onClick={(e) => {
                          setTodas(e.target.checked);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        for="customSwitch1"
                      >
                        Todas
                      </label>
                    </div>
                  </Fragment>
                ) : null}
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <Tabs>
                    <TabList>
                      <Tab>Datos Generales</Tab>
                    </TabList>

                    <TabPanel>
                      <div class="row">
                        <div className="col-xs-12 col-md-6">
                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="nombre"
                                type="text"
                                className="form-control mt-4"
                                value={proveedor.nombre}
                                onChange={(e) =>
                                  guardarProveedor({
                                    ...proveedor,
                                    nombre: e.target.value.toUpperCase(),
                                  })
                                }
                              />
                              <label
                                htmlFor="nombre"
                                style={{ color: "black" }}
                              >
                                Nombre de la proveedor
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="condicioniva"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Condición de I.V.A.
                              </label>
                            </span>
                            <Dropdown
                              id="condicioniva"
                              value={proveedor.idCondicionIva}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={condicionesIva}
                              onChange={(e) =>
                                guardarProveedor({
                                  ...proveedor,
                                  idCondicionIva: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná la condición de I.V.A."
                            />
                          </div>

                          {proveedor.idCondicionIva !== 5 ? (
                            <div className="form-group">
                              <span className="p-float-label">
                                <InputMask
                                  mask="99-99999999-9"
                                  id="cuit"
                                  type="text"
                                  className="form-control mt-4"
                                  value={proveedor.cuit}
                                  onChange={(e) =>
                                    guardarProveedor({
                                      ...proveedor,
                                      cuit: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  htmlFor="cuit"
                                  style={{ color: "black" }}
                                >
                                  C.U.I.T
                                </label>
                              </span>
                            </div>
                          ) : (
                            <div className="form-group">
                              <span className="p-float-label">
                                <InputMask
                                  mask="99999999"
                                  id="cuit"
                                  type="text"
                                  className="form-control mt-4"
                                  value={proveedor.cuit}
                                  onChange={(e) =>
                                    guardarProveedor({
                                      ...proveedor,
                                      cuit: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  htmlFor="cuit"
                                  style={{ color: "black" }}
                                >
                                  D.N.I.
                                </label>
                              </span>
                            </div>
                          )}

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="domicilio"
                                type="text"
                                className="form-control mt-4"
                                value={proveedor.domicilio}
                                onChange={(e) =>
                                  guardarProveedor({
                                    ...proveedor,
                                    domicilio: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="domicilio"
                                style={{ color: "black" }}
                              >
                                Domicilio
                              </label>
                            </span>
                          </div>
                          <div className="row">
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <span className="p-float-label">
                                  <InputText
                                    id="entre1"
                                    type="text"
                                    className="form-control mt-4"
                                    value={proveedor.entre1}
                                    onChange={(e) =>
                                      guardarProveedor({
                                        ...proveedor,
                                        entre1: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="entre1"
                                    style={{ color: "black" }}
                                  >
                                    Entre
                                  </label>
                                </span>
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <span className="p-float-label">
                                  <InputText
                                    id="entre2"
                                    type="text"
                                    className="form-control mt-4"
                                    value={proveedor.entre2}
                                    onChange={(e) =>
                                      guardarProveedor({
                                        ...proveedor,
                                        entre2: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="entre2"
                                    style={{ color: "black" }}
                                  >
                                    Entre
                                  </label>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="localidad"
                                type="text"
                                className="form-control mt-4"
                                value={proveedor.localidad}
                                onChange={(e) =>
                                  guardarProveedor({
                                    ...proveedor,
                                    localidad: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="localidad"
                                style={{ color: "black" }}
                              >
                                Localidad
                              </label>
                            </span>
                          </div>
                        </div>

                        <div className="col-xs-12 col-md-6">
                          <div class="row">
                            <div className="col-xs-12 col-md-4">
                              <div className="form-group">
                                <span className="p-float-label">
                                  <InputText
                                    id="cp"
                                    type="text"
                                    className="form-control mt-4"
                                    value={proveedor.cp}
                                    onChange={(e) =>
                                      guardarProveedor({
                                        ...proveedor,
                                        cp: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="cp"
                                    style={{ color: "black" }}
                                  >
                                    Cód. Postal
                                  </label>
                                </span>
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-8">
                              <div className="form-group">
                                <span className="p-float-label">
                                  <InputText
                                    id="telefono"
                                    type="text"
                                    className="form-control mt-4"
                                    value={proveedor.telefono}
                                    onChange={(e) =>
                                      guardarProveedor({
                                        ...proveedor,
                                        telefono: e.target.value,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="telefono"
                                    style={{ color: "black" }}
                                  >
                                    Teléfono
                                  </label>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="provincia"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Provincia
                              </label>
                            </span>
                            <Dropdown
                              id="provincia"
                              value={proveedor.idProvincia}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={provincias}
                              onChange={(e) =>
                                guardarProveedor({
                                  ...proveedor,
                                  idProvincia: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná una provincia"
                            />
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="descuento"
                                type="text"
                                style={{ width: "30%" }}
                                className="form-control mt-4"
                                value={proveedor.descuento}
                                onChange={(e) =>
                                  guardarProveedor({
                                    ...proveedor,
                                    descuento: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="descuento"
                                style={{ color: "black" }}
                              >
                                Descuento
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="cbu"
                                type="number"
                                className="form-control mt-4"
                                value={proveedor.cbu}
                                onChange={(e) =>
                                  guardarProveedor({
                                    ...proveedor,
                                    cbu: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="cbu" style={{ color: "black" }}>
                                C.B.U.
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="retencionesGanancias"
                              style={{ color: "black", marginRight: "1em" }}
                            >
                              Retenciones Ganancias:
                            </label>
                            <ToggleButton
                              onLabel="Si"
                              offLabel="No"
                              onIcon="pi pi-check"
                              offIcon="pi pi-times"
                              checked={proveedor.retencionesGanancias}
                              onChange={(e) =>
                                guardarProveedor({
                                  ...proveedor,
                                  retencionesGanancias: e.value,
                                })
                              }
                            />
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="retencionesIIBB"
                              style={{ color: "black", marginRight: "1em" }}
                            >
                              Retenciones IIBB:
                            </label>
                            <ToggleButton
                              onLabel="Si"
                              offLabel="No"
                              onIcon="pi pi-check"
                              offIcon="pi pi-times"
                              checked={proveedor.retencionesIIBB}
                              onChange={(e) =>
                                guardarProveedor({
                                  ...proveedor,
                                  retencionesIIBB: e.value,
                                })
                              }
                            />
                          </div>


                        </div>
                      </div>
                    </TabPanel>

                    <input
                      type="submit"
                      className="btn btn-background btn-block mt-4"
                      value="Guardar proveedor"
                    />
                  </Tabs>
                  <br />
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Proveedor;
