import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { ArchivoProvider } from "./context/ArchivoContext";
import { VentaProvider } from "./context/VentaContext";
import { CompraProvider } from "./context/CompraContext";
import { FondoProvider } from "./context/FondoContext";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Navbar from "./components/layout/Navbar";
import Account from "./components/auth/Account";
import Login from "./components/auth/Login";
import Barra from "./components/layout/Barra";
import RutaPrivada from "./components/rutas/RutaPrivada";
import Clientes from "./components/menu/archivos/Clientes";
import Cliente from "./components/menu/archivos/Cliente";
import Proveedores from "./components/menu/archivos/Proveedores";
import Proveedor from "./components/menu/archivos/Proveedor";
import Empresas from "./components/menu/archivos/Empresas";
import Empresa from "./components/menu/archivos/Empresa";
import Localidades from "./components/menu/archivos/Localidades";
import Localidad from "./components/menu/archivos/Localidad";
import Impuestos from "./components/menu/archivos/Impuestos";
import Impuesto from "./components/menu/archivos/Impuesto";
import Vendedores from "./components/menu/archivos/Vendedores";
import Vendedor from "./components/menu/archivos/Vendedor";
import Bancos from "./components/menu/archivos/Bancos";
import Banco from "./components/menu/archivos/Banco";
import Conceptos from "./components/menu/archivos/Conceptos";
import Concepto from "./components/menu/archivos/Concepto";
import TiposRetenciones from "./components/menu/archivos/TiposRetenciones";
import TipoRetencion from "./components/menu/archivos/TipoRetencion";
import Articulos from "./components/menu/archivos/Articulos";
import Articulo from "./components/menu/archivos/Articulo";
import Fletes from "./components/menu/archivos/Fletes";
import Flete from "./components/menu/archivos/Flete";
import Rubros from "./components/menu/archivos/Rubros";
import Rubro from "./components/menu/archivos/Rubro";
import ListasPrecios from "./components/menu/archivos/ListasPrecios";
import ListaPrecio from "./components/menu/archivos/ListaPrecio";
import Cuentas from "./components/menu/archivos/Cuentas";
import Cuenta from "./components/menu/archivos/Cuenta";
import Comprobantes from "./components/menu/ventas/Comprobantes";
import Comprobante from "./components/menu/ventas/Comprobante";
import Ajuste from "./components/menu/ventas/Ajuste";
import Recibos from "./components/menu/ventas/Recibos";
import Recibo from "./components/menu/ventas/Recibo";
import MovimientosCajas from "./components/menu/fondos/MovimientosCajas";
import MovimientoCaja from "./components/menu/fondos/MovimientoCaja";
import MovimientosBancos from "./components/menu/fondos/MovimientosBancos";
import MovimientoBanco from "./components/menu/fondos/MovimientoBanco";
import HistorialChequesTerceros from "./components/menu/fondos/HistorialChequesTerceros";
import ChequeTercero from "./components/menu/fondos/ChequeTercero";
import CarteraChequesTerceros from "./components/menu/fondos/CarteraChequesTerceros";
import ConciliacionBancaria from "./components/menu/fondos/ConciliacionBancaria";
import CarteraChequesPropios from "./components/menu/fondos/CarteraChequesPropios";
import ComprobantesCompras from "./components/menu/compras/Comprobantes";
import ComprobanteCompras from "./components/menu/compras/Comprobante";
import AjusteCompras from "./components/menu/compras/Ajuste";
import OrdenesPagos from "./components/menu/compras/OrdenesPagos";
import OrdenPago from "./components/menu/compras/OrdenPago";
import ChequePropio from "./components/menu/fondos/ChequePropio";
import Remitos from "./components/menu/ventas/Remitos";
//import Prueba from "./components/menu/archivos/Prueba";

function App() {
  return (
    <AuthProvider>
      <ArchivoProvider>
        <VentaProvider>
          <CompraProvider>
            <FondoProvider>
              <Router>
                <Navbar />
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/account" component={Account} />
                  <RutaPrivada exact path="/" component={Barra} />
                  <RutaPrivada exact path="/menu" component={Barra} />
                  <RutaPrivada exact path="/clientes" component={Clientes} />
                  <RutaPrivada exact path="/cliente/:id" component={Cliente} />
                  <RutaPrivada
                    exact
                    path="/proveedores"
                    component={Proveedores}
                  />
                  <RutaPrivada
                    exact
                    path="/proveedor/:id"
                    component={Proveedor}
                  />
                  <RutaPrivada exact path="/empresas" component={Empresas} />
                  <RutaPrivada exact path="/empresa/:id" component={Empresa} />
                  <RutaPrivada
                    exact
                    path="/localidades"
                    component={Localidades}
                  />
                  <RutaPrivada
                    exact
                    path="/localidad/:id"
                    component={Localidad}
                  />
                  <RutaPrivada exact path="/impuestos" component={Impuestos} />
                  <RutaPrivada
                    exact
                    path="/impuesto/:id"
                    component={Impuesto}
                  />
                  <RutaPrivada
                    exact
                    path="/vendedores"
                    component={Vendedores}
                  />
                  <RutaPrivada
                    exact
                    path="/vendedor/:id"
                    component={Vendedor}
                  />
                  <RutaPrivada exact path="/bancos" component={Bancos} />
                  <RutaPrivada exact path="/banco/:id" component={Banco} />
                  <RutaPrivada exact path="/conceptos" component={Conceptos} />
                  <RutaPrivada
                    exact
                    path="/concepto/:id"
                    component={Concepto}
                  />

                  <RutaPrivada exact path="/fletes" component={Fletes} />
                  <RutaPrivada exact path="/flete/:id" component={Flete} />
                  <RutaPrivada exact path="/rubros" component={Rubros} />
                  <RutaPrivada exact path="/rubro/:id" component={Rubro} />

                  <RutaPrivada
                    exact
                    path="/tiposretenciones"
                    component={TiposRetenciones}
                  />
                  <RutaPrivada
                    exact
                    path="/tiporetencion/:id"
                    component={TipoRetencion}
                  />
                  <RutaPrivada exact path="/articulos" component={Articulos} />
                  <RutaPrivada
                    exact
                    path="/articulo/:id"
                    component={Articulo}
                  />
                  <RutaPrivada
                    exact
                    path="/listasprecios"
                    component={ListasPrecios}
                  />
                  <RutaPrivada
                    exact
                    path="/listaprecio/:id"
                    component={ListaPrecio}
                  />
                  <RutaPrivada exact path="/cuentas" component={Cuentas} />
                  <RutaPrivada exact path="/cuenta/:id" component={Cuenta} />
                  <RutaPrivada
                    exact
                    path="/comprobantes"
                    component={Comprobantes}
                  />
                  <RutaPrivada
                    exact
                    path="/comprobante/:id/:tipo"
                    component={Comprobante}
                  />
                  <RutaPrivada exact path="/ajuste/:id" component={Ajuste} />
                  <RutaPrivada exact path="/recibos" component={Recibos} />
                  <RutaPrivada exact path="/recibo/:id" component={Recibo} />
                  <RutaPrivada exact path="/remitos" component={Remitos} />

                  <RutaPrivada
                    exact
                    path="/comprobantescompras"
                    component={ComprobantesCompras}
                  />
                  <RutaPrivada
                    exact
                    path="/comprobantecompras/:id/:tipo"
                    component={ComprobanteCompras}
                  />
                  <RutaPrivada
                    exact
                    path="/ajustecompras/:id"
                    component={AjusteCompras}
                  />
                  <RutaPrivada
                    exact
                    path="/ordenespagoscompras"
                    component={OrdenesPagos}
                  />
                  <RutaPrivada
                    exact
                    path="/ordenpagocompras/:id"
                    component={OrdenPago}
                  />

                  <RutaPrivada
                    exact
                    path="/movimientoscajas"
                    component={MovimientosCajas}
                  />
                  <RutaPrivada
                    exact
                    path="/movimientocaja/:id"
                    component={MovimientoCaja}
                  />
                  <RutaPrivada
                    exact
                    path="/movimientosbancos"
                    component={MovimientosBancos}
                  />
                  <RutaPrivada
                    exact
                    path="/movimientobanco/:id/:origen"
                    component={MovimientoBanco}
                  />
                  <RutaPrivada
                    exact
                    path="/carterachequesterceros"
                    component={CarteraChequesTerceros}
                  />
                  <RutaPrivada
                    exact
                    path="/historialchequesterceros"
                    component={HistorialChequesTerceros}
                  />
                  <RutaPrivada
                    exact
                    path="/chequetercero/:id/:origen"
                    component={ChequeTercero}
                  />
                  <RutaPrivada
                    exact
                    path="/conciliacionbancaria"
                    component={ConciliacionBancaria}
                  />

                  <RutaPrivada
                    exact
                    path="/carterachequespropios"
                    component={CarteraChequesPropios}
                  />
                  <RutaPrivada
                    exact
                    path="/chequepropio/:id/:origen"
                    component={ChequePropio}
                  />
                </Switch>
              </Router>
            </FondoProvider>
          </CompraProvider>
        </VentaProvider>
      </ArchivoProvider>
    </AuthProvider>
  );
}

export default App;
