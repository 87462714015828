import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { VentaContext } from "../../../context/VentaContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "primereact/button";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/AddAlarm";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

function Empresa(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    empresa,
    localidades,
    condicionesIva,
    impuestos,
    tiposComprobantes,
    obtenerEmpresa,
    guardarEmpresa,
    obtenerLocalidadesSelect,
    crearEmpresa,
    actualizarEmpresa,
    obtenerCondicionesIvaSelect,
    obtenerImpuestosEmpresa,
    obtenerTiposComprobantesEmpresa,
    guardarImpuestos,
    guardarTiposComprobantes,
  } = archivoContext;

  const ventaContext = useContext(VentaContext);
  const { ultimoComprobanteAfip, obtenerUltimoComprobanteAfip } = ventaContext;

  let growl = useRef();

  const idEmpresa = props.match.params.id;

  useEffect(() => {
    if (idEmpresa !== "0") {
      obtenerEmpresa({ idEmpresa });
    } else {
      guardarEmpresa({
        nombre: "",
        cuit: "",
        idCondicionIva: 0,
        cbu: "",
        domicilio: "",
        idLocalidad: 0,
        cp: 0,
        electronica: false,
        habilitado: true,
        retenciones: false,
        percepciones: false,
        remitos: false,
      });
    }
    obtenerLocalidadesSelect();
    obtenerCondicionesIvaSelect();
    obtenerImpuestosEmpresa(idEmpresa);
    obtenerTiposComprobantesEmpresa({ idEmpresa });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (empresa.nombre !== null && empresa.nombre.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Empresas",
        detail: "El nombre de la empresa es obligatorio.",
      });
      return;
    }

    if (idEmpresa === "0") {
      crearEmpresa(empresa, impuestos, tiposComprobantes);
    } else {
      actualizarEmpresa(empresa, impuestos, tiposComprobantes);
    }
  };

  const cerrar = () => {
    props.history.push("/empresas");
  };

  const onUpdateItem = (i, value) => {
    let items = [...impuestos];
    items[i].habilitado = !value;
    guardarImpuestos(items);
  };

  const obtenerUltimo = (row) => {
    obtenerUltimoComprobanteAfip({ row, empresa });
  };

  return (
    <Fragment>
      {!empresa || !impuestos ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-10 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="fwhite titulos text-center py=4">
                  Datos de {empresa.nombre}
                </h3>
                <hr className="linea-negra" />

                <Growl ref={(el) => (growl = el)} />

                <form onSubmit={onSubmit}>
                  <Tabs>
                    <TabList>
                      <Tab>Datos Generales</Tab>
                      <Tab>Percepciones</Tab>
                      <Tab>Comprobantes</Tab>
                    </TabList>

                    <TabPanel>
                      <div className="row">
                        <div className="col-xs-12 col-md-6">
                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="nombre"
                                type="text"
                                className="form-control mt-4"
                                value={empresa.nombre}
                                onChange={(e) =>
                                  guardarEmpresa({
                                    ...empresa,
                                    nombre: e.target.value.toUpperCase(),
                                  })
                                }
                              />
                              <label
                                htmlFor="nombre"
                                style={{ color: "black" }}
                              >
                                Razón Social
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputMask
                                mask="99-99999999-9"
                                id="cuit"
                                type="text"
                                className="form-control mt-4"
                                value={empresa.cuit}
                                onChange={(e) =>
                                  guardarEmpresa({
                                    ...empresa,
                                    cuit: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="cuit" style={{ color: "black" }}>
                                C.U.I.T
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="condicioniva"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Condición de I.V.A.
                              </label>
                            </span>
                            <Dropdown
                              id="condicioniva"
                              value={empresa.idCondicionIva}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={condicionesIva}
                              onChange={(e) =>
                                guardarEmpresa({
                                  ...empresa,
                                  idCondicionIva: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná la condición de I.V.A."
                            />
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="cbu"
                                type="number"
                                className="form-control mt-4"
                                value={empresa.cbu}
                                onChange={(e) =>
                                  guardarEmpresa({
                                    ...empresa,
                                    cbu: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="cbu" style={{ color: "black" }}>
                                C.B.U.
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="domicilio"
                                type="text"
                                className="form-control mt-4"
                                value={empresa.domicilio}
                                onChange={(e) =>
                                  guardarEmpresa({
                                    ...empresa,
                                    domicilio: e.target.value,
                                  })
                                }
                              />
                              <label
                                htmlFor="domicilio"
                                style={{ color: "black" }}
                              >
                                Domicilio
                              </label>
                            </span>
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <label
                                htmlFor="localidad"
                                style={{ color: "black", fontSize: "0.8em" }}
                              >
                                Localidad
                              </label>
                            </span>
                            <Dropdown
                              id="localidad"
                              value={empresa.idLocalidad}
                              style={{ width: "50%" }}
                              className="mt-3"
                              options={localidades}
                              onChange={(e) =>
                                guardarEmpresa({
                                  ...empresa,
                                  idLocalidad: e.target.value,
                                })
                              }
                              filter={true}
                              placeholder="Seleccioná un barrio o localidad"
                              style={{ width: "20em" }}
                            />
                          </div>

                          <div className="form-group">
                            <span className="p-float-label">
                              <InputText
                                id="cp"
                                type="number"
                                style={{ width: "30%" }}
                                className="form-control mt-4"
                                value={empresa.cp}
                                onChange={(e) =>
                                  guardarEmpresa({
                                    ...empresa,
                                    cp: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="cp" style={{ color: "black" }}>
                                Cód. postal
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="habilitado"
                              style={{ color: "black", marginRight: "1em" }}
                            >
                              Habilitado:
                            </label>
                            <ToggleButton
                              onLabel="Si"
                              offLabel="No"
                              onIcon="pi pi-check"
                              offIcon="pi pi-times"
                              checked={empresa.habilitado}
                              onChange={(e) =>
                                guardarEmpresa({
                                  ...empresa,
                                  habilitado: e.value,
                                })
                              }
                            />
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="electronica"
                              style={{ color: "black", marginRight: "1em" }}
                            >
                              Facturación electrónica:
                            </label>
                            <ToggleButton
                              onLabel="Si"
                              offLabel="No"
                              onIcon="pi pi-check"
                              offIcon="pi pi-times"
                              checked={empresa.electronica}
                              onChange={(e) =>
                                guardarEmpresa({
                                  ...empresa,
                                  electronica: e.value,
                                })
                              }
                            />
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="percepciones"
                              style={{ color: "black", marginRight: "1em" }}
                            >
                              Percepciones:
                            </label>
                            <ToggleButton
                              onLabel="Si"
                              offLabel="No"
                              onIcon="pi pi-check"
                              offIcon="pi pi-times"
                              checked={empresa.percepciones}
                              onChange={(e) =>
                                guardarEmpresa({
                                  ...empresa,
                                  percepciones: e.value,
                                })
                              }
                            />
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="retenciones"
                              style={{ color: "black", marginRight: "1em" }}
                            >
                              Retenciones:
                            </label>
                            <ToggleButton
                              onLabel="Si"
                              offLabel="No"
                              onIcon="pi pi-check"
                              offIcon="pi pi-times"
                              checked={empresa.retenciones}
                              onChange={(e) =>
                                guardarEmpresa({
                                  ...empresa,
                                  retenciones: e.value,
                                })
                              }
                            />
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="remitos"
                              style={{ color: "black", marginRight: "1em" }}
                            >
                              Remitos:
                            </label>
                            <ToggleButton
                              onLabel="Si"
                              offLabel="No"
                              onIcon="pi pi-check"
                              offIcon="pi pi-times"
                              checked={empresa.remitos}
                              onChange={(e) =>
                                guardarEmpresa({
                                  ...empresa,
                                  remitos: e.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                          columns={[
                            {
                              title: "Nombre de la percepción",
                              field: "nombre",
                              editable: "never",
                            },
                          ]}
                          data={impuestos}
                          title="Percepciones"
                          options={{
                            search: true,
                            sorting: true,
                            actionsColumnIndex: -1,
                          }}
                          actions={[
                            {
                              onClick: (event, rowData) =>
                                onUpdateItem(
                                  rowData.tableData.id,
                                  rowData.habilitado
                                ),
                            },
                          ]}
                          localization={{
                            toolbar: {
                              searchPlaceholder: "Buscar",
                            },
                            header: {
                              actions: "Habilitado",
                            },
                            body: {
                              emptyDataSourceMessage: "No hay registros",
                            },
                            pagination: {
                              labelRowsSelect: "filas",
                            },
                          }}
                          components={{
                            Action: (props) => (
                              <ToggleButton
                                onLabel="Si"
                                offLabel="No"
                                onIcon="pi pi-check"
                                offIcon="pi pi-times"
                                checked={props.data.habilitado}
                                onChange={(event) =>
                                  props.action.onClick(event, props.data)
                                }
                              />
                            ),
                          }}
                        />
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                          columns={[
                            {
                              title: "Comprobante",
                              field: "nombre",
                              editable: "never",
                            },
                            {
                              title: "Punto de venta",
                              field: "puntoVenta",
                              editable: "onUpdate",
                              type: "numeric",
                            },
                            {
                              title: "Número",
                              field: "numero",
                              editable: "onUpdate",
                              type: "numeric",
                            },
                            {
                              title: "Certificado",
                              field: "certificado",
                              editable: "onUpdate",
                              type: "text",
                            },
                            {
                              title: "Key",
                              field: "key",
                              editable: "onUpdate",
                              type: "text",
                            },
                            {
                              field: "ver",
                              title: "Ultimo",
                              editable: "never",
                              render: (rowData) =>
                                rowData && (
                                  <IconButton
                                    color="secondary"
                                    onClick={() => obtenerUltimo(rowData)}
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                ),
                            },
                          ]}
                          data={tiposComprobantes}
                          title="Comprobantes"
                          options={{
                            search: true,
                            sorting: true,
                            actionsColumnIndex: -1,
                          }}
                          localization={{
                            toolbar: {
                              searchPlaceholder: "Buscar",
                            },
                            header: {
                              actions: "Acción",
                            },
                            body: {
                              editTooltip: "Editar",
                              editRow: {
                                cancelTooltip: "Cancelar",
                                saveTooltip: "Guardar",
                              },
                              emptyDataSourceMessage: "No hay registros",
                            },
                            pagination: {
                              labelRowsSelect: "filas",
                            },
                          }}
                          editable={{
                            isEditable: (rowData) => rowData, // only name(a) rows would be editable
                            onRowAddCancelled: (rowData) =>
                              console.log("Row adding cancelled"),
                            onRowUpdateCancelled: (rowData) =>
                              console.log("Row editing cancelled"),
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  const dataUpdate = [...tiposComprobantes];
                                  const index = oldData.id;
                                  dataUpdate[index] = newData;
                                  guardarTiposComprobantes([...dataUpdate]);

                                  resolve();
                                }, 1000);
                              }),
                          }}
                        />
                      </div>
                    </TabPanel>

                    <input
                      type="submit"
                      className="btn btn-background btn-block mt-4"
                      value="Guardar empresa"
                    />
                  </Tabs>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Empresa;
