import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { FondoContext } from "../../../context/FondoContext";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

function MovimientosCajas(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const { empresaNavbar } = archivoContext;

  const fondoContext = useContext(FondoContext);
  const {
    movimientosCajas,
    movimientoCaja,
    totalIngresos,
    totalEgresos,
    saldoAnterior,
    dateCaja,
    rangeCaja,
    radioCaja,
    agrupar,
    field,
    sort,
    todas,
    obtenerMovimientosCaja,
    buscarMovimientosCajas,
    obtenerMovimientosCajas,
    guardarMovimientosCajas,
    tranformDate,
    guardarDateCaja,
    guardarRangeCaja,
    guardarRadioCaja,
    setAgrupar,
    setField,
    setSort,
    setTodas,
    eliminarMovimientoCaja,
  } = fondoContext;

  const [globalFilter, guardarGlobalFilter] = useState(null);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [idMovimiento, setIdMovimiento] = useState(null);

  let dt = useRef();

  useEffect(() => {
    //guardarMovimientosCajas(null);
    obtenerMovimientosCajas({
      idEmpresa: empresaNavbar,
      fechaCaja: dateCaja,
      rangeCaja: rangeCaja,
      todas,
    });
  }, []);

  const cerrar = () => {
    props.history.push("/menu");
  };

  const buscarDatos = () => {
    buscarMovimientosCajas({ idEmpresa: empresaNavbar, globalFilter });
  };

  let es = {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
    dateFormat: "dd/mm/yy",
    weekHeader: "Sm",
  };

  const actualizar = () => {
    obtenerMovimientosCajas({
      idEmpresa: empresaNavbar,
      fechaCaja: dateCaja,
      rangeCaja: rangeCaja,
      todas,
    });
  };

  let header = (
    <Fragment>
      <button
        type="button"
        className="close xclose"
        aria-label="Close"
        onClick={cerrar}
        style={{ color: "Blue" }}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <h3 className="fwhite titulos text-center py=4">Movimientos de Caja</h3>
      <hr className="linea-negra" />
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => guardarGlobalFilter(e.target.value)}
          placeholder="Buscador"
        />
        {/*
        <button className="btn btn-success ml-1" onClick={buscarDatos}>
          Buscar
        </button>
        */}
        <Link to={`movimientocaja/0`}>
          <button type="button" className="btn btn-info btn-large ml-4">
            Nuevo
          </button>
        </Link>

        <RadioButton
          style={{ marginLeft: "1em" }}
          value="val1"
          name="caja"
          onChange={(e) => guardarRadioCaja(e.value)}
          checked={radioCaja === "val1"}
        />

        <label style={{ color: "black", marginLeft: "0.5em" }}>Fecha</label>
        <Calendar
          locale={es}
          dateFormat="dd/mm/yy"
          style={{ marginLeft: "1em", width: "20em" }}
          value={dateCaja}
          onChange={(e) => {
            guardarDateCaja(e.value);
            guardarRangeCaja(null);
          }}
          disabled={radioCaja === "val2"}
        ></Calendar>

        <RadioButton
          style={{ marginLeft: "0em" }}
          value="val2"
          name="caja"
          onChange={(e) => guardarRadioCaja(e.value)}
          checked={radioCaja === "val2"}
        />

        <label style={{ color: "black", marginLeft: "0.5em" }}>Rango</label>
        <Calendar
          selectionMode="range"
          inputStyle={{ width: "15em" }}
          locale={es}
          dateFormat="dd/mm/yy"
          style={{ marginLeft: "1em", width: "20em" }}
          value={rangeCaja}
          onChange={(e) => {
            guardarRangeCaja(e.value);
            guardarDateCaja(null);
          }}
          disabled={radioCaja === "val1"}
        ></Calendar>

        <button
          type="button"
          className="btn btn-info btn-large ml-0"
          onClick={actualizar}
        >
          Actualizar
        </button>

        <div className="row">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="agrupar"
              checked={agrupar}
              onClick={(e) => {
                setAgrupar(e.target.checked);
              }}
            />
            <label
              className="custom-control-label"
              for="agrupar"
              style={{ marginRight: "1em", marginLeft: "2em" }}
            >
              Agrupar
            </label>
          </div>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="todas"
              checked={todas}
              onClick={(e) => {
                setTodas(e.target.checked);
              }}
            />
            <label className="custom-control-label" for="todas">
              Todas
            </label>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const rowHabilitado = (rowData, column) => {
    if (rowData.habilitado === true || rowData.habilitado === "Si") {
      return (rowData.habilitado = "Si");
    }
    if (rowData.habilitado === false || rowData.habilitado === "No") {
      return (rowData.habilitado = "No");
    }
  };

  const rowFecha = (rowData, column) => {
    return tranformDate(rowData.fecha);
  };

  const rowIngreso = (rowData, column) => {
    return parseFloat(rowData.ingreso).toFixed(2);
  };

  const rowEgreso = (rowData, column) => {
    return parseFloat(rowData.egreso).toFixed(2);
  };

  const botones = (rowData, column) => {
    return (
      <div className="row">
        <div className="col-xs-6 col-md-6" style={{ paddingRight: "0" }}>
          <Link to={`movimientocaja/${rowData.id}`}>
            <button
              type="button"
              className="btn btn-success btn-block"
              style={{ fontSize: "small" }}
            >
              Ver
            </button>
          </Link>
        </div>
        <div className="col-xs-6 col-md-6" style={{ paddingLeft: "0" }}>
          <button
            type="button"
            className="btn btn-danger btn-block"
            disabled={rowData.reciboId !== 0}
            style={{ fontSize: "small" }}
            onClick={(e) => {
              setModalEliminar(true);
              setIdMovimiento(rowData.id);
            }}
          >
            Eliminar
          </button>
        </div>
      </div>
    );
  };

  let rCount = movimientosCajas ? movimientosCajas.length : 0;
  let footer = "Total " + rCount + " movimientos";

  ////

  function headerTemplate(data, column) {}

  function footerTemplate(data) {
    return (
      <>
        <td colSpan="3" style={{ textAlign: "right" }}>
          Total registros
        </td>
        <td>{calculateCustomerTotal(data[field])}</td>
        <td style={{ textAlign: "right" }}>
          {parseFloat(calculateCustomerIngresos(data[field])).toFixed(2)}
        </td>
        <td style={{ textAlign: "right" }}>
          {parseFloat(calculateCustomerEgresos(data[field])).toFixed(2)}
        </td>
      </>
    );
  }

  function calculateCustomerTotal(name) {
    let total = 0;

    if (movimientosCajas) {
      for (let customer of movimientosCajas) {
        if (customer[field] === name) {
          total++;
        }
      }
    }
    return total;
  }

  function calculateCustomerIngresos(name) {
    let total = 0;

    if (movimientosCajas) {
      for (let customer of movimientosCajas) {
        if (customer[field] === name) {
          total = Number(total) + Number(customer.ingreso);
        }
      }
    }
    return total;
  }

  function calculateCustomerEgresos(name) {
    let total = 0;

    if (movimientosCajas) {
      for (let customer of movimientosCajas) {
        if (customer[field] === name) {
          total = Number(total) + Number(customer.egreso);
        }
      }
    }
    return total;
  }

  function ver(data, column) {
    setField(data.sortField);
    if (data.sortField === field) {
      if (sort === 1) {
        setSort(-1);
      } else {
        setSort(1);
      }
    }
  }

  //console.log(idMovimiento);

  return (
    <div className="background-body">
      <div className="row justify-content-center">
        <div className="col-xs-10 col-md-10 mt-3">
          <div className="card-body text-black background mb-3">
            {!agrupar ? (
              <div className="mlpc-table">
                <DataTable
                  ref={(el) => {
                    dt = el;
                  }}
                  value={movimientosCajas}
                  paginator={true}
                  rows={10}
                  header={header}
                  footer={footer}
                  globalFilter={globalFilter}
                  scrollable={false}
                  responsive={true}
                  resizableColumns={true}
                >
                  <Column
                    field="id"
                    header="ID de Movimiento de Caja"
                    sortable={true}
                  />
                  <Column
                    field="fecha"
                    header="Fecha"
                    sortable={true}
                    body={rowFecha}
                  />
                  <Column
                    field="detalleConcepto"
                    header="Concepto"
                    sortable={true}
                    style={{ width: "20%" }}
                  />
                  <Column
                    field="detalle"
                    header="Detalle"
                    sortable={true}
                    style={{ width: "20%" }}
                  />
                  <Column
                    field="nombreCliente"
                    header="Cliente"
                    sortable={true}
                  />
                  <Column
                    field="ingreso"
                    header="Ingreso"
                    sortable={true}
                    style={{ textAlign: "right" }}
                    body={rowIngreso}
                  />
                  <Column
                    field="egreso"
                    header="Egreso"
                    sortable={true}
                    style={{ textAlign: "right" }}
                    body={rowEgreso}
                  />
                  <Column field="botones" header="" body={botones} />
                </DataTable>{" "}
              </div>
            ) : (
              <div className="mlpc-table">
                <DataTable
                  ref={(el) => {
                    dt = el;
                  }}
                  value={movimientosCajas}
                  paginator={true}
                  rows={10}
                  header={header}
                  footer={footer}
                  globalFilter={globalFilter}
                  scrollable={false}
                  responsive={true}
                  resizableColumns={true}
                  rowGroupMode="subheader"
                  groupField={field}
                  sortField={field}
                  sortOrder={sort}
                  rowGroupHeaderTemplate={headerTemplate}
                  rowGroupFooterTemplate={footerTemplate}
                  onSort={ver}
                >
                  <Column
                    field="id"
                    header="ID de Movimiento de Caja"
                    sortable={true}
                  />
                  <Column
                    field="fecha"
                    header="Fecha"
                    sortable={true}
                    body={rowFecha}
                  />
                  <Column
                    field="detalleConcepto"
                    header="Concepto"
                    sortable={true}
                    style={{ width: "20%" }}
                  />
                  <Column
                    field="detalle"
                    header="Detalle"
                    sortable={true}
                    style={{ width: "20%" }}
                  />
                  <Column
                    field="ingreso"
                    header="Ingreso"
                    sortable={true}
                    style={{ textAlign: "right" }}
                    body={rowIngreso}
                  />
                  <Column
                    field="egreso"
                    header="Egreso"
                    sortable={true}
                    style={{ textAlign: "right" }}
                    body={rowEgreso}
                  />
                  <Column field="botones" header="" body={botones} />
                </DataTable>{" "}
              </div>
            )}

            <div style={{ textAlign: "right" }}>
              Saldo Anterior
              <span className="badge badge-secondary ml-2 mr-2">
                {"$ " + parseFloat(saldoAnterior).toFixed(2)}
              </span>
            </div>
            <div style={{ textAlign: "right" }}>
              Total ingresos
              <span className="badge badge-secondary ml-2 mr-2">
                {"$ " + parseFloat(totalIngresos).toFixed(2)}
              </span>
              Total egresos
              <span className="badge badge-secondary ml-2 mr-2">
                {"$ " + parseFloat(totalEgresos).toFixed(2)}
              </span>
            </div>
            <div style={{ textAlign: "right" }}>
              Saldo actual
              <span className="badge badge-secondary ml-2 mr-2">
                {"$ " +
                  parseFloat(
                    Number(saldoAnterior) +
                      Number(totalIngresos) -
                      Number(totalEgresos)
                  ).toFixed(2)}
              </span>
            </div>

            <Dialog
              header="Movimiento de caja"
              visible={modalEliminar}
              modal={true}
              onHide={() => setModalEliminar(false)}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle mt-2 mr-3"
                  style={{ fontSize: "2rem" }}
                />
                <span>
                  {" "}
                  Esta seguro de eliminar el movimiento {idMovimiento} ?
                </span>
              </div>
              <br />

              <div style={{ maxWidth: "100%" }}>
                <div className="col-xs-10 col-md-12 text-center">
                  <Button
                    label="No"
                    icon="pi pi-times"
                    onClick={() => setModalEliminar(false)}
                    className="p-button-danger linea mr-2"
                  />
                  <Button
                    label="Si"
                    icon="pi pi-check"
                    onClick={() => {
                      eliminarMovimientoCaja({ idMovimiento });
                      obtenerMovimientosCajas({
                        idEmpresa: empresaNavbar,
                        fechaCaja: dateCaja,
                        rangeCaja: rangeCaja,
                        todas,
                      });

                      setModalEliminar(false);
                    }}
                    className="p-button-success linea ml-2"
                    autoFocus
                  />
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovimientosCajas;
