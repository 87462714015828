import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { VentaContext } from "../../../context/VentaContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "primereact/button";
import Buton from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import MaterialTable from "material-table";
import Icon from "@material-ui/core/Icon";
import { Dialog } from "primereact/dialog";
import jsPDF from "jspdf";
import logo from "../../../assets/images/gym2.png";
import afipLogo from "../../../assets/images/codigo_qr.jpg";
import QrCode from "qrcode.react";
import $ from "jquery";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Tooltip } from 'primereact/tooltip';

function Comprobante(props) {
  // extraer los valores del context
  const archivoContext = useContext(ArchivoContext);
  const {
    impuestos,
    empresaNavbar,
    empresa,
    articulos,
    clientes,
    provincias,
    listasPrecios,
    fletes,
    condicionesIva,
    obtenerEmpresa,
    tiposComprobantes,
    tiposComprobantesAsociado,
    obtenerTiposComprobantesEmpresaSelect,
    obtenerTiposComprobantesAsociadoEmpresaSelect,
    obtenerListasPreciosSelect,
    obtenerArticulosListasPrecios,
    obtenerFletesSelect,
    guardarArticulos,
    obtenerImpuestosCliente,
    obtenerCondicionesIvaSelect,
    guardarTiposComprobantes,
    obtenerClientes,
    obtenerProvinciasSelect,
    obtenerImpuestosSelect,
  } = archivoContext;

  const ventaContext = useContext(VentaContext);
  const {
    comprobante,
    cliente,
    facturaAsociada,
    verComprobante,
    condicionesVentas,
    articulosComprobante,
    impuestosComprobante,
    qrValue,
    carga,
    articuloListaPrecios,
    obtenerComprobante,
    guardarComprobante,
    guardarCliente,
    crearComprobante,
    crearRemito,
    obtenerClientexId,
    obtenerArticuloxId,
    obtenerClientexIdComprobante,
    obtenerCondicionesVentasSelect,
    obtenerComprobanteNro,
    guardarFacturaAsociada,
    guardarVerComprobante,
    guardarArticulosComprobante,
    obtenerArticulosxIdComprobante,
    obtenerImpuestosxIdComprobante,
    setQrValue,
    guardarCarga,
    obtenerArticuloListaPrecios,
    guardarArticuloListaPrecios,
    obtenerRemito,
    obtenerClientexIdRemito,
    obtenerArticulosxIdRemito,
    grabarObservaciones,
  } = ventaContext;

  const [modalArticulos, setModalArticulos] = useState(false);
  const [modalClientes, setModalClientes] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [delay, setDelay] = useState(false);
  const [opcionPdf, setOpcionPdf] = useState(0);
  const [imprimeRemito, setImprimeRemito] = useState(true);
  const [modalObservaciones, setModalObservaciones] = useState(false);
  const [transportista, guardarTransportista] = useState(null);
  const [copias, guardarCopias] = useState({
    factura: 3,
    remito: 2,
  });

  const ivas = [
    { value: 3, label: "IVA 0%" },
    { value: 4, label: "IVA 10.5%" },
    { value: 5, label: "IVA 21%" },
    { value: 6, label: "IVA 27%" },
  ];

  let growl = useRef();

  const idComprobante = props.match.params.id;
  const tipoComprobanteNuevo = props.match.params.tipo;

  useEffect(() => {
    if (empresaNavbar) {
      if (idComprobante !== "0") {
        if (tipoComprobanteNuevo !== "r") {
          obtenerComprobante({ idComprobante });
          obtenerClientexIdComprobante({ idComprobante });
          obtenerArticulosxIdComprobante({ idComprobante });
        } else {
          obtenerRemito({ idComprobante });
          obtenerClientexIdRemito({ idComprobante });
          obtenerArticulosxIdRemito({ idComprobante });
        }
        guardarTiposComprobantes(null);
        obtenerEmpresa({ idEmpresa: empresaNavbar });
        obtenerTiposComprobantesEmpresaSelect({
          idEmpresa: empresaNavbar,
          tipo: tipoComprobanteNuevo,
        });
        obtenerTiposComprobantesAsociadoEmpresaSelect({
          idEmpresa: empresaNavbar,
          tipo: "todos",
        });
        obtenerCondicionesVentasSelect();
        obtenerListasPreciosSelect();
        obtenerCondicionesIvaSelect();
        obtenerProvinciasSelect();
        obtenerImpuestosSelect();
        obtenerFletesSelect();
        obtenerImpuestosxIdComprobante({ idComprobante });
        guardarVerComprobante({
          ...verComprobante,
          estado: true,
        });
        setTimeout(function () {
          setDelay(true);
        }, 1000);
      } else {
        guardarTiposComprobantes(null);
        obtenerEmpresa({ idEmpresa: empresaNavbar });
        obtenerTiposComprobantesEmpresaSelect({
          idEmpresa: empresaNavbar,
          tipo: tipoComprobanteNuevo,
        });
        obtenerTiposComprobantesAsociadoEmpresaSelect({
          idEmpresa: empresaNavbar,
          tipo: "todos",
        });
        obtenerCondicionesVentasSelect();
        obtenerListasPreciosSelect();
        obtenerCondicionesIvaSelect();
        obtenerProvinciasSelect();
        guardarComprobante({
          id: null,
          fecha: "",
          comprobante: "",
          abreviatura: "",
          tipo: "",
          puntoVenta: 0,
          numero: 0,
          electronica: false,
          clienteId: "",
          nombreCliente: "",
          cuitCliente: "",
          cbu: "",
          vendedorId: 0,
          subtotal: 0,
          descuento: 0,
          neto: 0,
          iva: 0,
          percepcion: 0,
          total: 0,
          exento: 0,
          tipoComprobanteId: 0,
          tipoComprobanteIdAfip: 0,
          condicionVentaId: 0,
          condicionIvaId: 0,
          listaPrecioId: 0,
          empresaId: 0,
          anulado: false,
          cae: "",
          vtocae: null,
          tipoComprobanteIdAsociado: 0,
          tipoComprobanteIdAsociadoAfip: 0,
          puntoVentaAsociado: 0,
          numeroAsociado: 0,
          fechaAsociado: null,
          remito: false,
          puntoVentaRemito: 0,
          numeroRemito: 0,
          tipoComprobanteRemitoId: 0,
          saldo: parseFloat(0).toFixed(2),
          fechaVencimiento: "",
          observaciones1: null,
          observaciones2: null,
          bultos: null,
        });

        guardarCliente({
          id: "",
          nombre: "",
          cuit: "",
          domicilio: "",
          cp: "",
          localidad: "",
          email: "",
          idVendedor: "",
          idFlete: "",
          idCondicionIva: "",
          idCondicionVenta: "",
          idListaPrecio: "",
          descuento: "",
          domicilioCompleto: "",
          cbu: "",
        });

        setTimeout(function () {
          setDelay(true);
        }, 1000);
      }
    }
  }, [idComprobante]);

  const generarFactura = async (e) => {
    e.preventDefault();

    //Validar

    if (cliente.id === null || cliente.id === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El ID de Cliente es obligatorio.",
      });
      return;
    }

    if (
      cliente.idCondicionIva !== 5 &&
      (cliente.cuit === null ||
        cliente.cuit === "" ||
        cliente.cuit.length !== 13)
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "C.U.I.T. de Cliente es inválido.",
      });
      return;
    }

    if (
      cliente.idCondicionIva === 5 &&
      (cliente.cuit === null || cliente.cuit === "")
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "D.N.I. de Cliente es inválido.",
      });
      return;
    }

    if (
      cliente.idCondicionIva !== 1 &&
      (comprobante.abreviatura === "FCEA" ||
        comprobante.abreviatura === "FCEB" ||
        comprobante.abreviatura === "FCEC" ||
        comprobante.abreviatura === "NDEA" ||
        comprobante.abreviatura === "NDEB" ||
        comprobante.abreviatura === "NDEC" ||
        comprobante.abreviatura === "NCEA" ||
        comprobante.abreviatura === "NCEB" ||
        comprobante.abreviatura === "NCEC") &&
      (cliente.cuit === null ||
        cliente.cuit === "" ||
        cliente.cuit.length !== 13)
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "C.U.I.T. de Cliente es inválido.",
      });
      return;
    }

    if (cliente.idListaPrecio === null || cliente.idListaPrecio === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La lista de precios es inválida.",
      });
      return;
    }

    if (cliente.nombre === null || cliente.nombre === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El nombre es inválido.",
      });
      return;
    }

    if (cliente.idCondicionIva === null || cliente.idCondicionIva === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La condición de iva es inválida.",
      });
      return;
    }

    if (
      cliente.domicilioCompleto === null ||
      cliente.domicilioCompleto === ""
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El domicilio es inválido.",
      });
      return;
    }

    if (comprobante.fecha === null || comprobante.fecha === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La fecha es inválida.",
      });
      return;
    }

    if (comprobante.comprobante === null || comprobante.comprobante === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El comprobante es inválido.",
      });
      return;
    }

    if (comprobante.tipo === null || comprobante.tipo === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El tipo de comprobante es inválido.",
      });
      return;
    }

    if (comprobante.puntoVenta === null || comprobante.puntoVenta === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El punto de venta del comprobante es inválido.",
      });
      return;
    }

    if (comprobante.numero === null || comprobante.numero === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El número del comprobante es inválido.",
      });
      return;
    }

    if (cliente.condicionVentaId === null || cliente.condicionVentaId === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La condición de venta es inválida.",
      });
      return;
    }

    if (
      cliente.idCondicionIva === 1 &&
      comprobante.tipo !== "A" &&
      comprobante.tipo !== "M" &&
      comprobante.tipo !== "X"
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail:
          "El comprobante no corresponde con la condición de iva del cliente.",
      });
      return;
    }

    if (cliente.idCondicionIva !== 1 && comprobante.tipo !== "B") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail:
          "El comprobante no corresponde con la condición de iva del cliente.",
      });
      return;
    }

    if (
      (comprobante.abreviatura === "NCA" ||
        comprobante.abreviatura === "NCB" ||
        comprobante.abreviatura === "NCM") &&
      !facturaAsociada
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail:
          "Es necesario especificar el comprobante asociado a la nota de crédito.",
      });
      return;
    }

    if (
      (comprobante.abreviatura === "NDA" ||
        comprobante.abreviatura === "NDB" ||
        comprobante.abreviatura === "NDM") &&
      !facturaAsociada
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail:
          "Es necesario especificar el comprobante asociado a la nota de débito.",
      });
      return;
    }

    if (
      (comprobante.abreviatura === "FCEA" ||
        comprobante.abreviatura === "FCEB" ||
        comprobante.abreviatura === "FCEC" ||
        comprobante.abreviatura === "NDEA" ||
        comprobante.abreviatura === "NDEB" ||
        comprobante.abreviatura === "NDEC" ||
        comprobante.abreviatura === "NCEA" ||
        comprobante.abreviatura === "NCEB" ||
        comprobante.abreviatura === "NCEC") &&
      (comprobante.cbu === "" || comprobante.fechaVencimiento === "")
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail:
          "Es necesario especificar C.B.U. y fecha de vencimiento para este comprobante.",
      });
      return;
    }

    if (comprobante.remito && comprobante.numeroRemito === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El remito es necesario si esta marcado.",
      });
      return;
    }

    if (idComprobante === "0") {
      crearComprobante(
        empresa,
        comprobante,
        cliente,
        impuestos,
        articulosComprobante
      );
    }
  };

  const generarRemito = async (e) => {
    e.preventDefault();

    //Validar

    if (cliente.id === null || cliente.id === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El ID de Cliente es obligatorio.",
      });
      return;
    }

    if (
      cliente.idCondicionIva !== 5 &&
      (cliente.cuit === null ||
        cliente.cuit === "" ||
        cliente.cuit.length !== 13)
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "C.U.I.T. de Cliente es inválido.",
      });
      return;
    }

    if (
      cliente.idCondicionIva === 5 &&
      (cliente.cuit === null || cliente.cuit === "")
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "D.N.I. de Cliente es inválido.",
      });
      return;
    }

    if (
      cliente.idCondicionIva !== 1 &&
      (comprobante.abreviatura === "FCEA" ||
        comprobante.abreviatura === "FCEB" ||
        comprobante.abreviatura === "FCEC" ||
        comprobante.abreviatura === "NDEA" ||
        comprobante.abreviatura === "NDEB" ||
        comprobante.abreviatura === "NDEC" ||
        comprobante.abreviatura === "NCEA" ||
        comprobante.abreviatura === "NCEB" ||
        comprobante.abreviatura === "NCEC") &&
      (cliente.cuit === null ||
        cliente.cuit === "" ||
        cliente.cuit.length !== 13)
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "C.U.I.T. de Cliente es inválido.",
      });
      return;
    }

    if (cliente.idListaPrecio === null || cliente.idListaPrecio === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La lista de precios es inválida.",
      });
      return;
    }

    if (cliente.nombre === null || cliente.nombre === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El nombre es inválido.",
      });
      return;
    }

    if (cliente.idCondicionIva === null || cliente.idCondicionIva === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La condición de iva es inválida.",
      });
      return;
    }

    if (
      cliente.domicilioCompleto === null ||
      cliente.domicilioCompleto === ""
    ) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El domicilio es inválido.",
      });
      return;
    }

    if (comprobante.fecha === null || comprobante.fecha === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La fecha es inválida.",
      });
      return;
    }

    if (comprobante.comprobante === null || comprobante.comprobante === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El comprobante es inválido.",
      });
      return;
    }

    if (comprobante.tipo === null || comprobante.tipo === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El tipo de comprobante es inválido.",
      });
      return;
    }

    if (comprobante.puntoVenta === null || comprobante.puntoVenta === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El punto de venta del comprobante es inválido.",
      });
      return;
    }

    if (comprobante.numero === null || comprobante.numero === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El número del comprobante es inválido.",
      });
      return;
    }

    if (idComprobante === "0") {
      crearRemito(
        empresa,
        comprobante,
        cliente,
        impuestos,
        articulosComprobante
      );
    }
  };

  // Despues de generar comprobante
  if (verComprobante && verComprobante.estado && idComprobante === "0") {
    if (verComprobante.tipo === "F") {
      props.history.push(`/comprobante/${verComprobante.id}/todos`);
    } else {
      props.history.push(`/comprobante/${verComprobante.id}/r`);
    }
  }

  //Fletes

  if (idComprobante !== "0" && fletes && !transportista && cliente) {
    for (var i = 0; i < fletes.length; i++) {
      if (fletes[i].value === cliente.fleteId) {
        guardarTransportista({
          nombre: fletes[i].label,
          domicilio: fletes[i].domicilio,
          cuit: fletes[i].cuit,
        });
      }
    }
  }

  const cerrar = () => {
    guardarVerComprobante(null);
    guardarArticulosComprobante(null);
    if (tipoComprobanteNuevo !== "r") {
      props.history.push("/comprobantes");
    } else {
      props.history.push("/remitos");
    }
  };

  const useStyles = makeStyles((theme) => ({
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    grid: {
      marginRight: "0.5em",
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },

    marco: {
      marginLeft: 1,
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },

    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },

    text: {
      "& > *": {
        marginLeft: theme.spacing(2),
        width: "23ch",
      },
    },
    button: {
      [theme.breakpoints.down("sm")]: {
        visibility: "visible",
      },
      [theme.breakpoints.up("sm")]: {
        visibility: "hidden",
      },
    },
  }));

  if (
    empresa &&
    cliente &&
    comprobante &&
    comprobante.id == idComprobante &&
    !qrValue
  ) {
    // QR

    let empresaCUIT =
      empresa.cuit.slice(0, 2) +
      empresa.cuit.slice(3, 11) +
      empresa.cuit.slice(12, 13);

    let clienteCUIT =
      cliente.cuit.slice(0, 2) +
      cliente.cuit.slice(3, 11) +
      cliente.cuit.slice(12, 13);

    let qr = {
      ver: 1,
      fecha: comprobante.fecha,
      cuit: empresaCUIT,
      ptoVta: comprobante.puntoVenta,
      tipoCmp: Number(comprobante.tipoComprobanteIdAfip),
      nroCmp: comprobante.numero,
      importe: Number(comprobante.total),
      moneda: "PES",
      ctz: 1,
      tipoDocRec: 80,
      nroDocRec: clienteCUIT,
      tipoCodAut: "E",
      codAut: Number(comprobante.cae),
    };

    var encoded = btoa(JSON.stringify(qr));
    setQrValue(`https://www.afip.gob.ar/fe/qr/?p=${encoded}`);
  }

  const classes = useStyles();

  function handleEnter(event) {
    if (event.keyCode === 13) {
      /*
      if (event.target.id === "idcliente") {
        if (event.target.value !== "" && event.target.value !== "0") {
          obtenerClientexId({ idCliente: event.target.value });
          obtenerImpuestosCliente(cliente.id);
        } else {
          obtenerClientes();
          setModalClientes(true);
        }
      }
      */

      if (event.target.id === "numeroAsociado") {
        return;
      }


      return

      const form = event.target.id;

      const index = Array.prototype.indexOf.call(form, event.target);


      if (typeof form.elements[index + 1] !== "undefined" && typeof form.elements[index + 1] !== null) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    }
    if (event.keyCode === 27) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (typeof form.elements[index - 1] !== "undefined") {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  }

  const updateComprobante = (e) => {
    var data;

    for (var i = 0; i < tiposComprobantes.length; i++) {
      if (tiposComprobantes[i].value == e.target.value) {
        data = i;
      }
    }

    guardarComprobante({
      ...comprobante,
      tipoComprobanteId: tiposComprobantes[data].value,
      tipoComprobanteIdAfip: tiposComprobantes[data].codigoAfip,
      puntoVenta: tiposComprobantes[data].puntoVenta,
      numero: tiposComprobantes[data].numero,
      comprobante: tiposComprobantes[data].label,
      tipo: tiposComprobantes[data].tipo,
      abreviatura: tiposComprobantes[data].abreviatura,
    });
    if (articulosComprobante) {
      guardarArticulosComprobante(null);
    }
    guardarFacturaAsociada(false);
  };

  const updateComprobanteAsociado = (e) => {
    var data;

    for (var i = 0; i < tiposComprobantesAsociado.length; i++) {
      if (tiposComprobantesAsociado[i].value == e.target.value) {
        data = i;
      }
    }

    guardarComprobante({
      ...comprobante,
      tipoComprobanteIdAsociado: tiposComprobantesAsociado[data].value,
      tipoComprobanteIdAsociadoAfip: tiposComprobantesAsociado[data].codigoAfip,
      puntoVentaAsociado: tiposComprobantesAsociado[data].puntoVenta,
      numeroAsociado: 0,
    });
  };

  if (
    cliente &&
    cliente.id !== "" &&
    comprobante &&
    tiposComprobantesAsociado &&
    cliente.id !== comprobante.clienteId &&
    cliente.nombre !== comprobante.nombreCliente &&
    !verComprobante
  ) {
    let tipoComprobante;

    if ((empresa.idCondicionIva === 1) & (cliente.idCondicionIva === 1)) {
      tipoComprobante = "FA";
    }
    if ((empresa.idCondicionIva === 1) & (cliente.idCondicionIva !== 1)) {
      tipoComprobante = "FB";
    }

    let data = 0;
    for (var i = 0; i < tiposComprobantes.length; i++) {
      if (tiposComprobantes[i].abreviatura == tipoComprobante) {
        data = i;
      }
    }

    guardarComprobante({
      ...comprobante,
      clienteId: cliente.id,
      nombreCliente: cliente.nombre,
      cuitCliente: cliente.cuit,
      cbu: empresa.cbu,
      vendedorId: cliente.idVendedor,
      tipoComprobanteId: tiposComprobantes[data].value,
      tipoComprobanteIdAfip: tiposComprobantes[data].codigoAfip,
      tipoComprobanteRemitoId: tiposComprobantes[data].tipoComprobanteRemitoId,
      puntoVenta: tiposComprobantes[data].puntoVenta,
      numero: tiposComprobantes[data].numero,
      puntoVentaRemito: tiposComprobantes[data].puntoVentaRemito,
      numeroRemito: tiposComprobantes[data].numeroRemito,
      comprobante: tiposComprobantes[data].label,
      tipo: tiposComprobantes[data].tipo,
      abreviatura: tiposComprobantes[data].abreviatura,
      subtotal: 0,
      descuento: 0,
      neto: 0,
      iva: 0,
      percepcion: 0,
      total: 0,
      exento: 0,
      saldo: 0,
      tipoComprobanteIdAsociado: tiposComprobantesAsociado[1].value,
      tipoComprobanteIdAsociadoAfip: tiposComprobantesAsociado[1].codigoAfip,
      puntoVentaAsociado: tiposComprobantesAsociado[1].puntoVenta,
      numeroAsociado: 0,
      condicionVentaId: 1,
      condicionIvaId: cliente.idCondicionIva,
      condicionVentaId: cliente.idCondicionVenta,
      listaPrecioId: cliente.idListaPrecio,
    });

    guardarCliente({
      ...cliente,
      domicilioCompleto: `${cliente.domicilio} ${cliente.cp} ${cliente.localidad}`,
    });
    if (articulosComprobante) {
      guardarArticulosComprobante(null);
    }
  }

  if (
    empresa &&
    tiposComprobantes &&
    comprobante &&
    comprobante.tipoComprobanteId === 0 &&
    comprobante.numero === 0 &&
    comprobante.fecha === "" &&
    comprobante.abreviatura === "" &&
    comprobante.puntoVenta === 0
  ) {
    guardarComprobante({
      ...comprobante,
      puntoVenta: tiposComprobantes[0].puntoVenta,
      numero: tiposComprobantes[0].numero,
      puntoVentaRemito: tiposComprobantes[0].puntoVentaRemito,
      numeroRemito: tiposComprobantes[0].numeroRemito,
      tipoComprobanteRemitoId: tiposComprobantes[0].tipoComprobanteRemitoId,
      electronica: empresa.electronica,
      remito: empresa.remitos,
      tipoComprobanteId: tiposComprobantes[0].value,
      tipoComprobanteIdAfip: tiposComprobantes[0].codigoAfip,
      comprobante: tiposComprobantes[0].label,
      tipo: tiposComprobantes[0].tipo,
      abreviatura: tiposComprobantes[0].abreviatura,
      fecha: new Date(),
      fechaVencimiento: new Date(),
      puntoVentaAsociado: tiposComprobantes[0].puntoVenta,
      empresaId: empresa.id,
      listaPrecioId: 1,
    });
  }

  const clickAdd = () => {
    obtenerArticulosListasPrecios(cliente.idListaPrecio);
    setModalArticulos(true);
    borrarLineaArticulo();
  };

  const agregarItem = (item) => {
    //Verificar campos

    if (articulos[item].ivaId == 0) {
      articulos[item].ivaId = 3;
    }

    // Si es presupuesto iva = 0
    if (comprobante.abreviatura === "P") {
      articulos[item].ivaId = 3;
    }

    let tasaIva = 0;
    if (articulos[item].ivaId == 5) {
      tasaIva = 21;
    }
    if (articulos[item].ivaId == 4) {
      tasaIva = 10.5;
    }
    if (articulos[item].ivaId == 3) {
      tasaIva = 0;
    }
    if (articulos[item].ivaId == 6) {
      tasaIva = 27;
    }

    let tasaPercepcion = 0;

    if (empresa.percepciones && impuestos) {
      for (var i = 0; i < impuestos.length; i++) {
        tasaPercepcion = tasaPercepcion + impuestos[i].alicuota;
      }
    }

    let valorSubtotal = articulos[item].precio;
    let valorDescuento = (articulos[item].precio * cliente.descuento) / 100;
    /*
    if (
      comprobante.abreviatura === "NCA" ||
      comprobante.abreviatura === "NCB" ||
      comprobante.abreviatura === "NCM"
    ) {
      valorSubtotal = valorSubtotal * -1;
      valorDescuento = valorDescuento * -1;
    }
    */
    let valorNeto = valorSubtotal - valorDescuento;
    let valorPercepcion = (valorNeto * tasaPercepcion) / 100;
    let valorIva = (valorNeto * tasaIva) / 100;
    let valorTotal = valorNeto + valorPercepcion + valorIva;
    let valorExento;
    if (cliente.idCondicionIva === 4) {
      //Exento
      valorExento = valorNeto;
    } else {
      valorExento = 0;
    }
    if (!articulosComprobante) {
      guardarArticulosComprobante([
        {
          id: 0,
          articuloId: articulos[item].articuloId,
          detalle: articulos[item].nombre,
          cantidad: "1",
          unitario: articulos[item].precio,
          subtotal: valorSubtotal,
          descuento: valorDescuento,
          neto: valorNeto,
          iva: valorIva,
          percepcion: valorPercepcion,
          total: valorTotal,
          exento: valorExento,
          ivaId: articulos[item].ivaId,
          tasaIva: tasaIva,
          tasaPercepcion: tasaPercepcion,
          tasaDescuento: cliente.descuento,
        },
      ]);
    } else {
      let lenState = articulosComprobante.length;

      guardarArticulosComprobante((articulosComprobante) => [
        ...articulosComprobante,
        {
          id: lenState,
          articuloId: articulos[item].articuloId,
          detalle: articulos[item].nombre,
          cantidad: "1",
          unitario: articulos[item].precio,
          subtotal: valorSubtotal,
          descuento: valorDescuento,
          neto: valorNeto,
          iva: valorIva,
          percepcion: valorPercepcion,
          total: valorTotal,
          exento: valorExento,
          ivaId: articulos[item].ivaId,
          tasaIva: tasaIva,
          tasaPercepcion: tasaPercepcion,
          tasaDescuento: cliente.descuento,
        },
      ]);
    }

    if (articulosComprobante) {
      let valorSubtotalOld = 0;
      let valorDescuentoOld = 0;
      let valorNetoOld = 0;
      let valorIvaOld = 0;
      let valorPercepcionOld = 0;
      let valorTotalOld = 0;
      let valorExentoOld = 0;

      for (var i = 0; i < articulosComprobante.length; i++) {
        valorSubtotalOld =
          Number(valorSubtotalOld) + Number(articulosComprobante[i].subtotal);
        valorDescuentoOld =
          Number(valorDescuentoOld) + Number(articulosComprobante[i].descuento);
        valorNetoOld =
          Number(valorNetoOld) + Number(articulosComprobante[i].neto);
        valorIvaOld = Number(valorIvaOld) + Number(articulosComprobante[i].iva);
        valorPercepcionOld =
          Number(valorPercepcionOld) +
          Number(articulosComprobante[i].percepcion);
        valorTotalOld =
          Number(valorTotalOld) + Number(articulosComprobante[i].total);

        if (cliente.idCondicionIva === 4) {
          //Exento
          valorExentoOld =
            Number(valorExentoOld) + Number(articulosComprobante[i].neto);
        } else {
          valorExentoOld = Number(valorExentoOld) + Number(0);
        }
      }

      guardarComprobante({
        ...comprobante,
        subtotal: Number(valorSubtotal) + Number(valorSubtotalOld),
        descuento: Number(valorDescuento) + Number(valorDescuentoOld),
        neto: Number(valorNeto) + Number(valorNetoOld),
        iva: Number(valorIva) + Number(valorIvaOld),
        percepcion: Number(valorPercepcion) + Number(valorPercepcionOld),
        total: Number(valorTotal) + Number(valorTotalOld),
        exento: Number(valorExento) + Number(valorExentoOld),
        saldo: Number(valorTotal) + Number(valorTotalOld),
      });
    } else {
      guardarComprobante({
        ...comprobante,
        subtotal: valorSubtotal,
        descuento: valorDescuento,
        neto: valorNeto,
        iva: valorIva,
        percepcion: valorPercepcion,
        total: valorTotal,
        exento: valorExento,
        saldo: valorTotal,
      });
    }
  };

  const agregarItem2 = (e) => {
    //Verificar campos
    e.preventDefault();

    console.log("si");

    if (carga.codigo === "") {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El código de artículo no es correcto.",
      });
      let elemento = document.getElementById("codigo");
      elemento.focus();
      return;
    }

    /*
    let band = false;
    for (var i = 0; i < articulos.length; i++) {
      if (articulos[i].articuloId === carga.codigo ) {
        band = true;
        break
      }
    }

    if (!band) {
      let elemento = document.getElementById("codigo");
      elemento.focus();
      return;
    }
    */

    if (Number(carga.cantidad) <= 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La cantidad no es correcta.",
      });
      let elemento = document.getElementById("cantidad");
      elemento.focus();
      return;
    }

    if (Number(articuloListaPrecios.precio) <= 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El valor unitario no es correcta.",
      });
      let elemento = document.getElementById("valorUnitario");
      elemento.focus();
      return;
    }

    if (Number(carga.descuento) < 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La bonificación no es correcta.",
      });
      let elemento = document.getElementById("descuento");
      elemento.focus();
      return;
    }

    if (Number(carga.percepcion) < 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "La percepción no es correcta.",
      });
      let elemento = document.getElementById("percepcion");
      elemento.focus();
      return;
    }

    if (Number(articuloListaPrecios.ivaId) === 0) {
      growl.show({
        severity: "error",
        summary: "Comprobantes",
        detail: "El I.V.A. no es correcto.",
      });
      let elemento = document.getElementById("iva");
      elemento.focus();
      return;
    }

    let tasaIva = 0;

    if (articuloListaPrecios.ivaId === 5) {
      tasaIva = 21;
    }
    if (articuloListaPrecios.ivaId === 4) {
      tasaIva = 10.5;
    }
    if (articuloListaPrecios.ivaId === 3) {
      tasaIva = 0;
    }
    if (articuloListaPrecios.ivaId === 6) {
      tasaIva = 27;
    }

    let tasaPercepcion = carga.percepcion;

    let valorSubtotal =
      Number(articuloListaPrecios.precio) * Number(carga.cantidad);
    let valorDescuento =
      (Number(valorSubtotal) * Number(carga.descuento)) / 100;
    /*
    if (
      comprobante.abreviatura === "NCA" ||
      comprobante.abreviatura === "NCB" ||
      comprobante.abreviatura === "NCM"
    ) {
      valorSubtotal = valorSubtotal * -1;
      valorDescuento = valorDescuento * -1;
    }
    */
    let valorNeto = Number(valorSubtotal) - Number(valorDescuento);
    let valorPercepcion = (Number(valorNeto) * Number(tasaPercepcion)) / 100;
    let valorIva = (Number(valorNeto) * Number(tasaIva)) / 100;
    let valorTotal =
      Number(valorNeto) + Number(valorPercepcion) + Number(valorIva);
    let valorExento;
    if (cliente.idCondicionIva === 4) {
      //Exento
      valorExento = valorNeto;
    } else {
      valorExento = 0;
    }
    if (!articulosComprobante) {
      guardarArticulosComprobante([
        {
          id: 0,
          articuloId: carga.codigo,
          detalle: carga.detalle,
          cantidad: carga.cantidad,
          unitario: articuloListaPrecios.precio,
          subtotal: valorSubtotal,
          descuento: valorDescuento,
          neto: valorNeto,
          iva: valorIva,
          percepcion: valorPercepcion,
          total: valorTotal,
          exento: valorExento,
          ivaId: articuloListaPrecios.ivaId,
          tasaIva: tasaIva,
          tasaPercepcion: tasaPercepcion,
          tasaDescuento: carga.descuento,
        },
      ]);
    } else {
      let lenState = articulosComprobante.length;

      guardarArticulosComprobante((articulosComprobante) => [
        ...articulosComprobante,
        {
          id: lenState,
          articuloId: carga.codigo,
          detalle: carga.detalle,
          cantidad: carga.cantidad,
          unitario: articuloListaPrecios.precio,
          subtotal: valorSubtotal,
          descuento: valorDescuento,
          neto: valorNeto,
          iva: valorIva,
          percepcion: valorPercepcion,
          total: valorTotal,
          exento: valorExento,
          ivaId: articuloListaPrecios.ivaId,
          tasaIva: tasaIva,
          tasaPercepcion: tasaPercepcion,
          tasaDescuento: carga.descuento,
        },
      ]);
    }

    if (articulosComprobante) {
      let valorSubtotalOld = 0;
      let valorDescuentoOld = 0;
      let valorNetoOld = 0;
      let valorIvaOld = 0;
      let valorPercepcionOld = 0;
      let valorTotalOld = 0;
      let valorExentoOld = 0;

      for (var i = 0; i < articulosComprobante.length; i++) {
        valorSubtotalOld =
          Number(valorSubtotalOld) + Number(articulosComprobante[i].subtotal);
        valorDescuentoOld =
          Number(valorDescuentoOld) + Number(articulosComprobante[i].descuento);
        valorNetoOld =
          Number(valorNetoOld) + Number(articulosComprobante[i].neto);
        valorIvaOld = Number(valorIvaOld) + Number(articulosComprobante[i].iva);
        valorPercepcionOld =
          Number(valorPercepcionOld) +
          Number(articulosComprobante[i].percepcion);
        valorTotalOld =
          Number(valorTotalOld) + Number(articulosComprobante[i].total);

        if (cliente.idCondicionIva === 4) {
          //Exento
          valorExentoOld =
            Number(valorExentoOld) + Number(articulosComprobante[i].neto);
        } else {
          valorExentoOld = Number(valorExentoOld) + Number(0);
        }
      }

      guardarComprobante({
        ...comprobante,
        subtotal: Number(valorSubtotal) + Number(valorSubtotalOld),
        descuento: Number(valorDescuento) + Number(valorDescuentoOld),
        neto: Number(valorNeto) + Number(valorNetoOld),
        iva: Number(valorIva) + Number(valorIvaOld),
        percepcion: Number(valorPercepcion) + Number(valorPercepcionOld),
        total: Number(valorTotal) + Number(valorTotalOld),
        exento: Number(valorExento) + Number(valorExentoOld),
        saldo: Number(valorTotal) + Number(valorTotalOld),
      });
    } else {
      guardarComprobante({
        ...comprobante,
        subtotal: valorSubtotal,
        descuento: valorDescuento,
        neto: valorNeto,
        iva: valorIva,
        percepcion: valorPercepcion,
        total: valorTotal,
        exento: valorExento,
        saldo: valorTotal,
      });
    }
  };

  const agregarCliente = (cliente) => {
    obtenerClientexId({ idCliente: clientes[cliente].id });
    obtenerImpuestosCliente({ id: clientes[cliente].id, empresa });
  };

  const largeStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "42ch" };

  const mediaStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "36ch" };

  const shortStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "30ch" };

  const clickFacturaAsociada = () => {
    const {
      empresaId,
      tipoComprobanteIdAsociado,
      puntoVentaAsociado,
      numeroAsociado,
      clienteId,
    } = comprobante;
    obtenerComprobanteNro({
      empresaId,
      tipoComprobanteIdAsociado,
      puntoVentaAsociado,
      numeroAsociado,
      clienteId,
    });
  };

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
  }

  const observaciones = (opcion) => {
    if (opcion === 3) {
      guardarCopias({
        factura: 3,
        remito: 2,
      });
    }

    if (opcion === 1 || opcion === 2) {
      guardarCopias({
        factura: 1,
        remito: 0,
      });
    }

    if (opcion === 4 || opcion === 5) {
      guardarCopias({
        factura: 0,
        remito: 1,
      });
    }

    setModalObservaciones(true);
    setOpcionPdf(opcion);
    let cBultos = 0;
    for (var i = 0; i < articulosComprobante.length; i++) {
      cBultos = cBultos + Number(articulosComprobante[i].cantidad);
    }
    guardarComprobante({
      ...comprobante,
      bultos: cBultos,
    });
  };

  const pdf = () => {
    onHide();

    let condicionVentaFactura;
    let condicionIvaFactura;
    let provinciaFactura;

    for (var i = 0; i < condicionesVentas.length; i++) {
      if (condicionesVentas[i].value === comprobante.condicionVentaId) {
        condicionVentaFactura = condicionesVentas[i].label;
        break;
      }
    }

    for (var i = 0; i < condicionesIva.length; i++) {
      if (condicionesIva[i].value === cliente.condicionIvaId) {
        condicionIvaFactura = condicionesIva[i].label;
        break;
      }
    }

    for (var i = 0; i < provincias.length; i++) {
      if (provincias[i].value === cliente.provinciaId) {
        provinciaFactura = provincias[i].label;
        break;
      }
    }

    var doc = new jsPDF();

    doc.setProperties({
      title: "Comprobantes",
    });

    for (var y = 0; y < copias.factura; y++) {
      // Encabezado Izquierda
      doc.setLineWidth(0.1);
      doc.rect(5, 5, 200, 40);
      doc.setFont("times");
      doc.setFontSize(10);
      doc.text("Panificación G&M S.A.", 50, 10);
      doc.setFontSize(8);
      doc.text("Cabrera 2540", 52, 15);
      doc.text("San Justo (CP: 1754", 52, 19);
      doc.text("Provincia de Buenos Aires", 52, 23);
      doc.text("Argentina", 52, 27);
      doc.text("galletasgym@yahoo.com", 52, 31);
      doc.setFontSize(10);
      doc.text("I.V.A. RESPONSABLE INSCRIPTO", 10, 42);
      // Logo
      doc.addImage(logo, "PNG", 7, 7, 40, 30);
      // Tipo
      doc.rect(90, 5, 16, 21);
      doc.line(98, 26, 98, 45);
      doc.setFont("Courier", "bold");
      doc.setFontSize(36);
      doc.text("A", 94, 16);
      doc.setFontSize(8);
      doc.text(
        "COD. " + ("000" + comprobante.tipoComprobanteIdAfip).slice(-3),
        92,
        22
      );

      // Encabezado Derecha
      doc.setLineWidth(0.1);
      doc.setFontSize(14);
      doc.setFont("Courier", "bold");
      doc.text(comprobante.comprobante, 110, 10);
      doc.setFontSize(10);
      doc.text(
        "Número: " +
          ("0000" + comprobante.puntoVenta).slice(-4) +
          "-" +
          ("0000" + comprobante.numero).slice(-8),
        110,
        15
      );
      doc.text("Fecha: " + convertDate(comprobante.fecha), 110, 20);
      doc.text("C.U.I.T.: " + empresa.cuit, 120, 30);
      doc.text("Ing. Brutos: 902-789260-1", 120, 35);
      doc.text("Inicio de Actividades: 01/10/1998", 120, 40);

      //Cliente

      doc.rect(5, 47, 200, 35);
      doc.setFontSize(10);
      doc.setFont("Courier", "bold");
      doc.text("Razón Social: " + cliente.nombre, 10, 55);
      doc.text("Cliente: " + cliente.clienteId, 160, 55);
      doc.text(
        "Domicilio: " + cliente.domicilio + " " + provinciaFactura,
        10,
        60
      );
      doc.text("C.U.I.T.: " + cliente.cuit, 10, 65);
      doc.text("Condición de I.V.A. " + condicionIvaFactura, 10, 70);
      doc.text("Condición de Venta: " + condicionVentaFactura, 10, 75);
      doc.text(
        "Remito: " +
          ("0000" + comprobante.puntoVentaRemito).slice(-4) +
          "-" +
          ("0000" + comprobante.numeroRemito).slice(-8),
        160,
        75
      );

      doc.rect(5, 82, 200, 10);
      doc.rect(5, 92, 200, 10);
      doc.setFont("times");
      doc.setFontSize(10);
      doc.setFontSize(8);
      doc.text("Código", 7, 98);
      doc.text("Producto", 25, 98);
      doc.text("Cantidad", 100, 98);
      doc.text("Precio Unit.", 115, 98);
      doc.text("Bonificación", 132, 98);
      doc.text("Subtotal", 152, 98);
      doc.text("Alícuota IVA", 166, 98);
      doc.text("Subtotal c/IVA", 185, 98);

      let unitario = 0;
      let descuento = 0;
      let tasaIva = 0;
      let subtotal = 0;
      let neto = 0;

      for (var i = 0; i < articulosComprobante.length; i++) {
        unitario = String(Number(articulosComprobante[i].unitario).toFixed(2));
        descuento = String(
          Number(articulosComprobante[i].descuento).toFixed(2)
        );
        tasaIva = String(Number(articulosComprobante[i].tasaIva).toFixed(2));
        neto = String(Number(articulosComprobante[i].neto).toFixed(2));
        subtotal = String(
          (
            Number(articulosComprobante[i].neto) +
            Number(articulosComprobante[i].iva)
          ).toFixed(2)
        );
        doc.text(String(articulosComprobante[i].articuloId), 8, 106 + 5 * i);
        doc.text(articulosComprobante[i].detalle, 20, 106 + 5 * i);
        doc.text(
          String(articulosComprobante[i].cantidad),
          110,
          106 + 5 * i,
          "right"
        );
        doc.text(unitario, 126, 106 + 5 * i, "right");
        doc.text(descuento, 143, 106 + 5 * i, "right");
        doc.text(neto, 162, 106 + 5 * i, "right");
        doc.text(tasaIva + "%", 180, 106 + 5 * i, "right");
        doc.text(subtotal, 200, 106 + 5 * i, "right");
      }

      if (comprobante.observaciones1 !== null) {
        doc.text(comprobante.observaciones1, 8, 180);
      }
      if (comprobante.observaciones2 !== null) {
        doc.text(comprobante.observaciones2, 8, 185);
      }

      doc.rect(5, 190, 200, 60);
      doc.text("Otros Tributos", 8, 193);
      doc.rect(7, 195, 100, 5);
      doc.text("Descripción", 8, 198);
      doc.text("Alic. %", 80, 198);
      doc.text("Importe", 95, 198);

      doc.setFont("times");
      doc.setFontSize(8);

      let tasa;
      let total;

      for (var i = 0; i < impuestos.length; i++) {
        doc.text(impuestos[i].label, 8, 205 + i * 5);
        tasa = String(Number(0).toFixed(2));
        total = String(Number(0).toFixed(2));
        for (var j = 0; j < impuestosComprobante.length; j++) {
          if (impuestos[i].value === impuestosComprobante[j].impuestoId) {
            tasa = String(Number(impuestosComprobante[j].alicuota).toFixed(2));
            total = String(
              (
                (Number(impuestosComprobante[j].alicuota) *
                  Number(Math.abs(comprobante.neto))) /
                100
              ).toFixed(2)
            );
            break;
          }
        }
        doc.text(tasa, 88, 205 + i * 5, "right");
        doc.text(total, 103, 205 + i * 5, "right");
      }
      doc.text("Importe Otros Tributos: $", 50, 230);
      let percepcion = String(
        Number(Math.abs(comprobante.percepcion)).toFixed(2)
      );
      doc.text(percepcion, 103, 230, "right");

      neto = String(Number(Math.abs(comprobante.neto)).toFixed(2));
      let iva27 = 0;
      let iva21 = 0;
      let iva105 = 0;
      let iva0 = 0;

      for (var i = 0; i < articulosComprobante.length; i++) {
        if (articulosComprobante[i].tasaIva === 27) {
          iva27 = iva27 + Number(Math.abs(articulosComprobante[i].iva));
        }
        if (articulosComprobante[i].tasaIva === 21) {
          iva21 = iva21 + Number(Math.abs(articulosComprobante[i].iva));
        }
        if (articulosComprobante[i].tasaIva === 10.5) {
          iva105 = iva105 + Number(Math.abs(articulosComprobante[i].iva));
        }
        if (articulosComprobante[i].tasaIva === 0) {
          iva0 = iva0 + Number(Math.abs(articulosComprobante[i].iva));
        }
      }

      let importeIva27 = String(Number(iva27).toFixed(2));
      let importeIva21 = String(Number(iva21).toFixed(2));
      let importeIva105 = String(Number(iva105).toFixed(2));
      let importeIva0 = String(Number(iva0).toFixed(2));
      total = String(Number(Math.abs(comprobante.total)).toFixed(2));

      doc.setFontSize(8);
      doc.text("Importe Neto Gravado: $", 170, 210, "right");
      doc.text("Iva 27%: $", 170, 215, "right");
      doc.text("Iva 21%: $", 170, 220, "right");
      doc.text("Iva 10.5%: $", 170, 225, "right");
      doc.text("Iva 0%: $", 170, 230, "right");
      doc.text("Importe Otros Tributos: $", 170, 235, "right");
      doc.text("Importe Total: $", 170, 240, "right");

      doc.text(neto, 200, 210, "right");
      doc.text(importeIva27, 200, 215, "right");
      doc.text(importeIva21, 200, 220, "right");
      doc.text(importeIva105, 200, 225, "right");
      doc.text(importeIva0, 200, 230, "right");
      doc.text(percepcion, 200, 235, "right");
      doc.text(total, 200, 240, "right");

      doc.setFontSize(10);
      if (comprobante.electronica) {
        doc.text("CAE Nº", 160, 255, "right");
        doc.text("Fecha de Vto. de CAE", 160, 260, "right");

        const parts = comprobante.vtocae.split(/[- :]/);
        const wanted =
          parts[2].substring(0, 2) + "/" + parts[1] + "/" + parts[0];

        doc.setFont("times", "normal");
        doc.text(comprobante.cae, 165, 255);
        doc.text(wanted, 165, 260);

        doc.addImage(afipLogo, "JPG", 60, 260, 40, 20);

        let qrcode = document.getElementById("qrcode");

        var imageData = getBase64Image($("#qrcode").get(0));
        doc.addImage(imageData, "JPG", 10, 255, 30, 30);
      }
      if (y < copias.factura - 1) {
        doc.addPage();
      }
    } // Fin copias

    /*
    var imgData = 'data:image/jpeg;base64,'+ Base64.encode(logo);
    console.log(imgData);
    doc.setFontSize(40);
    doc.text(30, 20, 'Hello world!');
    doc.addImage(imgData, 'JPEG', 15, 40, 180, 160);
    */

    if (copias.factura > 0 && copias.remito > 0) {
      doc.addPage();
    }

    doc.setFont("times", "normal");
    doc.setFontSize(10);

    for (var y = 0; y < copias.remito; y++) {
      doc.text(convertDate(comprobante.fecha), 175, 24);
      //Cliente

      doc.setFontSize(10);
      doc.setFont("Courier", "bold");
      doc.text(cliente.nombre, 30, 47);
      doc.text(cliente.domicilio + " " + provinciaFactura, 30, 52);
      doc.text(condicionIvaFactura, 20, 63);
      doc.text(cliente.cuit, 90, 63);
      doc.text(String(cliente.clienteId), 160, 63);

      //Transportista

      if (transportista) {
        doc.text(transportista.nombre, 10, 79);
        doc.text(transportista.domicilio, 93, 79);
        doc.text(transportista.cuit, 172, 79);
      }

      //Articulos

      for (var i = 0; i < articulosComprobante.length; i++) {
        doc.text(String(articulosComprobante[i].articuloId), 10, 96 + 5 * i);
        doc.text(
          String(articulosComprobante[i].cantidad),
          37,
          96 + 5 * i,
          "right"
        );

        doc.text(articulosComprobante[i].detalle, 60, 96 + 5 * i);
        doc.text(String(comprobante.bultos), 190, 252, "right");
      }

      if (comprobante.observaciones1 !== null) {
        doc.text(comprobante.observaciones1, 10, 227);
      }
      if (comprobante.observaciones2 !== null) {
        doc.text(comprobante.observaciones2, 10, 232);
      }

      if (y < copias.remito - 1) {
        doc.addPage();
      }
    }

    if (opcionPdf === 1 || opcionPdf === 3 || opcionPdf === 4) {
      doc.output("dataurlnewwindow");
    } else {
      doc.save(
        `${cliente.cuit}-${comprobante.abreviatura}-${comprobante.puntoVenta}-${comprobante.numero}`
      );
    }
  };

  const borrarLineaArticulo = () => {
    guardarCarga({
      ...carga,
      codigo: "",
      detalle: "",
      cantidad: "",
      valorUnitario: 0,
      descuento: "",
      percepcion: "",
      iva: 0,
      valorTotal: 0,
    });
    guardarArticuloListaPrecios({
      precio: "",
      iva: "",
      ivaId: 5,
    });
  };

  const footer = (
    <div>
      <Button
        label="Si"
        icon="pi pi-check"
        onClick={(e) => {
          grabarObservaciones({
            id: comprobante.id,
            obs1: comprobante.observaciones1,
            obs2: comprobante.observaciones2,
            tipo: comprobante.abreviatura,
          });
          pdf();
        }}
      />
      <Button label="No" icon="pi pi-times" onClick={onHide} />
    </div>
  );

  function onHide() {
    setModalObservaciones(false);
  }

  //console.log(cliente);
  //console.log(tipoComprobanteNuevo);

  return (
    <Fragment>
      {!comprobante ||
      comprobante.numero === 0 ||
      comprobante.fecha === "" ||
      comprobante.listaPrecioId === 0 ||
      !delay ? (
        <Spinner />
      ) : (
        <div className="background-fc">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-md-12 mt-3">
              <div className="card-body text-black background mb-3 ml-1 mr-1">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                  style={{ color: "Blue" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <Growl ref={(el) => (growl = el)} />

                <Tabs>
                  <TabList>
                    <Tab style={{ background: "#28BAEC" }}>
                      Datos comprobante
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className={classes.root}>
                      <Grid container spacing={0}>
                        <Grid
                          container
                          xs={12}
                          md={6}
                          className="card-body text-black background mt-0 mb-3 shadow-box"
                        >
                          <Grid item xs={12} md={3} className={classes.grid}>
                            <TextField
                              id="idcliente"
                              label="ID Cliente"
                              style={{ color: "blue" }}
                              type="number"
                              fullWidth
                              //onKeyDown={handleEnter}
                              value={cliente.clienteId}
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) => {
                                guardarCliente({
                                  ...cliente,
                                  id: e.target.value,
                                });
                                borrarLineaArticulo();
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  if (cliente.id !== "" && cliente.id !== "0") {
                                    obtenerClientexId({
                                      idCliente: cliente.id,
                                    });
                                    obtenerImpuestosCliente({
                                      id: cliente.id,
                                      empresa,
                                    });
                                    let elemento = document.getElementById(
                                      "codigo"
                                    );
                                    elemento.focus();

                                  } else {
                                    obtenerClientes();
                                    setModalClientes(true);
                                  }
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} className={classes.grid}>
                            <TextField
                              id="cuit"
                              label="C.U.I.T."
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={comprobante.cuitCliente}
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) =>
                                guardarComprobante({
                                  ...comprobante,
                                  cuitCliente: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.grid}>
                            <TextField
                              id="listaprecio"
                              select
                              label="Lista de precio"
                              style={{ color: "blue" }}
                              fullWidth
                              value={comprobante.listaPrecioId}
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) => {
                                guardarComprobante({
                                  ...comprobante,
                                  listaPrecioId: Number(e.target.value),
                                });
                                guardarArticulos(null);
                              }}
                              onKeyDown={handleEnter}
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {listasPrecios
                                ? listasPrecios.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))
                                : null}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6} className={classes.grid}>
                            <TextField
                              id="nombre"
                              label="Nombre"
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={cliente.nombre}
                              disabled={verComprobante && verComprobante.estado}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => {
                                guardarCliente({
                                  ...cliente,
                                  nombre: e.target.value,
                                });
                              }}
                              //style={largeStyles}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.grid}>
                            <TextField
                              id="condicioniva"
                              select
                              label="Condición de I.V.A."
                              style={{ color: "blue" }}
                              fullWidth
                              value={cliente.idCondicionIva}
                              disabled={verComprobante && verComprobante.estado}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => {
                                guardarCliente({
                                  ...cliente,
                                  idCondicionIva: Number(e.target.value),
                                });
                              }}
                              //style={shortStyles}
                              onKeyDown={handleEnter}
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {condicionesIva
                                ? condicionesIva.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))
                                : null}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6} className={classes.grid}>
                            <TextField
                              id="domicilioCompleto"
                              label="Dirección"
                              style={{ color: "blue" }}
                              fullWidth

                              //style={mediaStyles}
                              onKeyDown={handleEnter}
                              value={
                                !verComprobante
                                  ? cliente.domicilioCompleto
                                  : cliente.domicilio
                              }
                              disabled={verComprobante && verComprobante.estado}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => {
                                guardarCliente({
                                  ...cliente,
                                  domicilioCompleto: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} className={classes.grid}>
                            <TextField
                              id="email"
                              label="Email"
                              style={{ color: "blue" }}
                              fullWidth
                              //style={mediaStyles}
                              onKeyDown={handleEnter}
                              value={cliente.email}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          md={4}
                          className="card-body text-black background mt-0 mb-3 shadow-box"
                        >
                          <Grid item xs={12} md={4} className={classes.grid}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={esLocale}
                            >
                              <KeyboardDatePicker
                                clearable
                                label="Fecha"
                                style={{ color: "blue" }}
                                fullWidth
                                value={comprobante.fecha}
                                disabled={
                                  verComprobante && verComprobante.estado
                                }
                                onChange={(date) =>
                                  guardarComprobante({
                                    ...comprobante,
                                    fecha: date,
                                  })
                                }
                                minDate={!verComprobante ? new Date(-10) : null}
                                format="dd/MM/yyyy"
                                onKeyDown={handleEnter}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>

                          <Grid item xs={12} md={7} className={classes.grid}>
                            <TextField
                              id="comprobante"
                              select
                              label="Comprobante"
                              style={{ color: "blue" }}
                              fullWidth
                              value={comprobante.tipoComprobanteId}
                              disabled={verComprobante && verComprobante.estado}
                              onChange={(e) => updateComprobante(e)}
                              onKeyDown={handleEnter}
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {tiposComprobantes
                                ? tiposComprobantes.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))
                                : null}
                            </TextField>
                          </Grid>

                          <Grid item xs={12} md={3} className={classes.grid}>
                            <TextField
                              id="puntoventa"
                              disabled
                              label="Punto de venta"
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={("0000" + comprobante.puntoVenta).slice(
                                -4
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} md={4} className={classes.grid}>
                            <TextField
                              id="numero"
                              disabled
                              label="Número"
                              style={{ color: "blue" }}
                              fullWidth
                              onKeyDown={handleEnter}
                              value={comprobante.numero}
                            />
                          </Grid>

                          {comprobante.abreviatura !== "R" ? (
                            <Fragment>
                              <Grid
                                item
                                xs={12}
                                md={2}
                                className={classes.grid}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{ color: "blue" }}
                                      fullWidth
                                      onKeyDown={handleEnter}
                                      checked={comprobante.electronica}
                                      disabled={
                                        verComprobante && verComprobante.estado
                                      }
                                      onChange={(e) =>
                                        guardarComprobante({
                                          ...comprobante,
                                          electronica: e.target.checked,
                                        })
                                      }
                                      name="electronica"
                                      color="primary"
                                    />
                                  }
                                  label="Electrónica"
                                  labelPlacement="start"
                                  style={{ width: "14ch" }}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={4}
                                className={classes.grid}
                              >
                                <TextField
                                  id="condicionventa"
                                  select
                                  label="Condición de venta"
                                  style={{ color: "blue" }}
                                  fullWidth
                                  value={comprobante.condicionVentaId}
                                  disabled={
                                    verComprobante && verComprobante.estado
                                  }
                                  onChange={(e) =>
                                    guardarComprobante({
                                      ...comprobante,
                                      condicionVentaId: Number(e.target.value),
                                    })
                                  }
                                  onKeyDown={handleEnter}
                                  SelectProps={{
                                    native: true,
                                  }}
                                >
                                  {condicionesVentas
                                    ? condicionesVentas.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))
                                    : null}
                                </TextField>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                className={classes.grid}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{ color: "blue" }}
                                      fullWidth
                                      onKeyDown={handleEnter}
                                      checked={comprobante.remito}
                                      disabled={
                                        verComprobante && verComprobante.estado
                                      }
                                      onChange={(e) =>
                                        guardarComprobante({
                                          ...comprobante,
                                          remito: e.target.checked,
                                        })
                                      }
                                      name="remito"
                                      color="primary"
                                    />
                                  }
                                  label="Remito"
                                  labelPlacement="start"
                                  style={{ width: "14ch" }}
                                />
                              </Grid>

                              {comprobante.remito ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  className={classes.grid}
                                >
                                  <TextField
                                    id="numeroRemito"
                                    disabled
                                    label="Nº Remito "
                                    style={{ color: "blue" }}
                                    fullWidth
                                    onKeyDown={handleEnter}
                                    value={comprobante.numeroRemito}
                                  />
                                </Grid>
                              ) : null}
                            </Fragment>
                          ) : null}
                        </Grid>

                        {comprobante.abreviatura === "NCA" ||
                        comprobante.abreviatura === "NCB" ||
                        comprobante.abreviatura === "NCC" ||
                        comprobante.abreviatura === "NCM" ||
                        comprobante.abreviatura === "NCEA" ||
                        comprobante.abreviatura === "NCEB" ||
                        comprobante.abreviatura === "NCEC" ||
                        comprobante.abreviatura === "NDA" ||
                        comprobante.abreviatura === "NDB" ||
                        comprobante.abreviatura === "NDC" ||
                        comprobante.abreviatura === "NDM" ||
                        comprobante.abreviatura === "NDEA" ||
                        comprobante.abreviatura === "NDEB" ||
                        comprobante.abreviatura === "NDEC" ? (
                          <Grid
                            container
                            xs={12}
                            md={2}
                            className="card-body text-black background mt-0 mb-3 shadow-box"
                          >
                            <Grid item xs={12} md={12} className={classes.grid}>
                              <TextField
                                id="comprobanteAsociado"
                                select
                                label="Comprobante Asociado"
                                style={{ color: "blue" }}
                                fullWidth
                                value={comprobante.tipoComprobanteIdAsociado}
                                disabled={
                                  verComprobante && verComprobante.estado
                                }
                                onChange={(e) => updateComprobanteAsociado(e)}
                                onKeyDown={handleEnter}
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {tiposComprobantesAsociado
                                  ? tiposComprobantesAsociado.map((option) =>
                                      option.abreviatura === "FA" ||
                                      option.abreviatura === "FB" ||
                                      option.abreviatura === "FC" ||
                                      option.abreviatura === "FM" ||
                                      option.abreviatura === "NDA" ||
                                      option.abreviatura === "NDB" ||
                                      option.abreviatura === "NDC" ||
                                      option.abreviatura === "NDM" ||
                                      option.abreviatura === "FCEA" ||
                                      option.abreviatura === "FCEB" ||
                                      option.abreviatura === "FCEC" ||
                                      option.abreviatura === "NDEA" ||
                                      option.abreviatura === "NDEB" ||
                                      option.abreviatura === "NDEC" ? (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ) : null
                                    )
                                  : null}
                              </TextField>
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.grid}>
                              <TextField
                                id="puntoVentaAsociado"
                                label="Punto de venta"
                                style={{ color: "blue" }}
                                fullWidth
                                onKeyDown={handleEnter}
                                disabled={
                                  verComprobante && verComprobante.estado
                                }
                                onChange={(e) => {
                                  guardarComprobante({
                                    ...comprobante,
                                    puntoVentaAsociado: e.target.value,
                                  });
                                }}
                                value={(
                                  "0000" + comprobante.puntoVentaAsociado
                                ).slice(-4)}
                              />
                            </Grid>
                            <Grid item xs={10} md={10} className={classes.grid}>
                              <TextField
                                id="numeroAsociado"
                                label="Número"
                                style={{ color: "blue" }}
                                fullWidth
                                onKeyDown={handleEnter}
                                value={comprobante.numeroAsociado}
                                disabled={
                                  verComprobante && verComprobante.estado
                                }
                                onChange={(e) => {
                                  guardarComprobante({
                                    ...comprobante,
                                    numeroAsociado: Number(e.target.value),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} md={2} className={classes.grid}>
                              <Button
                                icon="pi pi-check"
                                className="mt-3 ml-2"
                                onClick={clickFacturaAsociada}
                                disabled={
                                  verComprobante && verComprobante.estado
                                }
                              />
                            </Grid>
                          </Grid>
                        ) : null}

                        {comprobante.abreviatura === "FCEA" ||
                        comprobante.abreviatura === "FCEB" ||
                        comprobante.abreviatura === "FCEC" ? (
                          <Grid
                            container
                            xs={12}
                            md={2}
                            className="card-body text-black background mt-0 mb-3 shadow-box"
                          >
                            <Grid item xs={12} md={11} className={classes.grid}>
                              <TextField
                                id="cbu"
                                label="C.B.U."
                                style={{ color: "blue" }}
                                fullWidth
                                onKeyDown={handleEnter}
                                value={empresa.cbu}
                                disabled={
                                  verComprobante && verComprobante.estado
                                }
                                onChange={(e) => {
                                  guardarComprobante({
                                    ...comprobante,
                                    cbu: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={11} className={classes.grid}>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={esLocale}
                              >
                                <KeyboardDatePicker
                                  clearable
                                  label="Fecha de vencimiento"
                                  style={{ color: "blue" }}
                                  fullWidth
                                  value={comprobante.fechaVencimiento}
                                  disabled={
                                    verComprobante && verComprobante.estado
                                  }
                                  onChange={(date) =>
                                    guardarComprobante({
                                      ...comprobante,
                                      fechaVencimiento: date,
                                    })
                                  }
                                  minDate={
                                    !verComprobante ? new Date(-10) : null
                                  }
                                  format="dd/MM/yyyy"
                                  onKeyDown={handleEnter}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                    </div>
                  </TabPanel>
                </Tabs>
                <Tabs onSelect={(tabIndex) => clickAdd()}>
                  <TabList>
                    {cliente.id === "" ||
                    (verComprobante && verComprobante.estado) ? (
                      <Tab disabled={true} style={{ background: "#28BAEC" }}>
                        Productos
                      </Tab>
                    ) : (
                      <Fragment>
                        <Grid container xs={12} md={12} spacing={0}>
                          <Grid item xs={12} md={1} className={classes.grid}>
                            <Tab
                              disabled={false}
                              style={{ background: "#28BAEC" }}
                            >
                              Productos
                            </Tab>
                          </Grid>
                          {!verComprobante ? (
                            <Fragment>
                              <Grid
                                item
                                xs={12}
                                md={1}
                                className={classes.grid}
                              >
                                <TextField
                                  id="codigo"
                                  label="Código"
                                  style={{ color: "blue" }}
                                  type="number"
                                  //onKeyDown={handleEnter}
                                  value={carga.codigo}
                                  onChange={(e) =>
                                    guardarCarga({
                                      ...carga,
                                      codigo: e.target.value,
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      if (
                                        carga.codigo !== "" &&
                                        carga.codigo !== "0"
                                      ) {
                                        obtenerArticuloxId({
                                          idArticulo: carga.codigo,
                                          impuestos,
                                          empresa,
                                        });
                                        obtenerArticuloListaPrecios({
                                          idArticulo: carga.codigo,
                                          idLista: cliente.idListaPrecio,
                                        });
                                        let elemento = document.getElementById(
                                          "cantidad"
                                        );
                                        elemento.focus();
                                      } else {
                                        clickAdd();
                                      }
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                className={classes.grid}
                              >
                                <TextField
                                  id="detalle"
                                  label="Detalle"
                                  style={{ color: "blue", width: "300px" }}
                                  //onKeyDown={handleEnter}
                                  value={carga.detalle}
                                  disabled
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={1}
                                className={classes.grid}
                              >
                                <TextField
                                  id="cantidad"
                                  label="Cantidad"
                                  type="number"
                                  style={{ color: "blue" }}
                                  //onKeyDown={handleEnter}
                                  value={carga.cantidad}
                                  onChange={(e) =>
                                    guardarCarga({
                                      ...carga,
                                      cantidad: e.target.value,
                                    })
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      if (carga.cantidad > 0) {
                                        if (comprobante.abreviatura !== "R") {
                                          let elemento = document.getElementById(
                                            "valorUnitario"
                                          );
                                          elemento.focus();
                                        } else {
                                          agregarItem2(e);
                                          borrarLineaArticulo();
                                          let elemento = document.getElementById(
                                            "codigo"
                                          );
                                          elemento.focus();
                                        }
                                      }
                                    }
                                    if (e.key === "Escape") {
                                      let elemento = document.getElementById(
                                        "codigo"
                                      );
                                      elemento.focus();
                                    }
                                  }}
                                />
                              </Grid>
                              {comprobante.abreviatura !== "R" ? (
                                <Fragment>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <TextField
                                      id="valorUnitario"
                                      label="V. Unitario"
                                      type="number"
                                      style={{ color: "blue" }}
                                      //onKeyDown={handleEnter}
                                      value={articuloListaPrecios.precio}
                                      onChange={(e) =>
                                        guardarArticuloListaPrecios({
                                          ...articuloListaPrecios,
                                          precio: e.target.value,
                                        })
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (articuloListaPrecios.precio > 0) {
                                            let elemento = document.getElementById(
                                              "descuento"
                                            );
                                            elemento.focus();
                                          }
                                        }
                                        if (e.key === "Escape") {
                                          let elemento = document.getElementById(
                                            "cantidad"
                                          );
                                          elemento.focus();
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <TextField
                                      id="descuento"
                                      label="% Bonif."
                                      type="number"
                                      style={{ color: "blue" }}
                                      //onKeyDown={handleEnter}
                                      value={carga.descuento}
                                      onChange={(e) =>
                                        guardarCarga({
                                          ...carga,
                                          descuento: e.target.value,
                                        })
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (carga.descuento >= 0) {
                                            let elemento;
                                            if (empresa.percepcion) {
                                              elemento = document.getElementById(
                                                "percepcion"
                                              );
                                            } else {
                                              elemento = document.getElementById(
                                                "iva"
                                              );
                                            }
                                            elemento.focus();
                                          }
                                        }
                                        if (e.key === "Escape") {
                                          let elemento = document.getElementById(
                                            "valorUnitario"
                                          );
                                          elemento.focus();
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <TextField
                                      id="percepcion"
                                      label="% Percep."
                                      type="number"
                                      //disabled={!empresa.percepcion}
                                      style={{ color: "blue" }}
                                      //onKeyDown={handleEnter}
                                      value={carga.percepcion}
                                      onChange={(e) =>
                                        guardarCarga({
                                          ...carga,
                                          percepcion: e.target.value,
                                        })
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (carga.percepcion >= 0) {
                                            let elemento = document.getElementById(
                                              "iva"
                                            );
                                            elemento.focus();
                                          }
                                        }
                                        if (e.key === "Escape") {
                                          let elemento = document.getElementById(
                                            "descuento"
                                          );
                                          elemento.focus();
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <TextField
                                      id="iva"
                                      select
                                      label="I.V.A."
                                      value={articuloListaPrecios.ivaId}
                                      onChange={(e) => {
                                        guardarArticuloListaPrecios({
                                          ...articuloListaPrecios,
                                          ivaId: Number(e.target.value),
                                        });
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (
                                            articuloListaPrecios.ivaId !== "" &&
                                            articuloListaPrecios.ivaId !== "0"
                                          ) {
                                            agregarItem2(e);
                                            borrarLineaArticulo();
                                            let elemento = document.getElementById(
                                              "codigo"
                                            );
                                            elemento.focus();
                                          }
                                        }
                                        if (e.key === "Escape") {
                                          let elemento;
                                          if (empresa.percepcion) {
                                            elemento = document.getElementById(
                                              "percepcion"
                                            );
                                          } else {
                                            elemento = document.getElementById(
                                              "descuento"
                                            );
                                          }
                                          elemento.focus();
                                        }
                                      }}
                                      SelectProps={{
                                        native: true,
                                      }}
                                    >
                                      {ivas
                                        ? ivas.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))
                                        : null}
                                    </TextField>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    className={classes.grid}
                                  >
                                    <Buton
                                      variant="contained"
                                      color="primary"
                                      className={classes.button}
                                      onClick={(e) => {
                                        agregarItem2(e);
                                        borrarLineaArticulo();
                                        let elemento = document.getElementById(
                                          "codigo"
                                        );
                                        elemento.focus();
                                      }}
                                    >
                                      Confirmar artículo
                                    </Buton>{" "}
                                  </Grid>
                                </Fragment>
                              ) : null}
                            </Fragment>
                          ) : null}
                        </Grid>
                      </Fragment>
                    )}
                  </TabList>
                  <TabPanel>
                    <div style={{ maxWidth: "100%" }}>
                      <div className="row">
                        <div className="col-xs-12 col-md-10">
                          {articulosComprobante ? (
                            <MaterialTable
                              columns={[
                                {
                                  title: "Código",
                                  field: "articuloId",
                                  editable: "never",
                                  cellStyle: {
                                    width: 100,
                                    minWidth: 100,
                                  },
                                },
                                {
                                  title: "Detalle",
                                  field: "detalle",
                                  editable: "never",
                                  cellStyle: {
                                    width: 350,
                                    minWidth: 350,
                                  },
                                  headerStyle: {
                                    width: 300,
                                    minWidth: 300,
                                  },
                                },
                                {
                                  title: "Cantidad",
                                  field: "cantidad",
                                  editable: "onUpdate",
                                  type: "numeric",
                                },
                                {
                                  title: "Valor Unitario",
                                  field: "unitario",
                                  editable: "onUpdate",
                                  type: "currency",
                                  hidden: comprobante.abreviatura === "R",
                                },
                                {
                                  title: "% Descuento",
                                  field: "tasaDescuento",
                                  editable: "onUpdate",
                                  type: "numeric",
                                  hidden: comprobante.abreviatura === "R",
                                },
                                {
                                  title: "% Percepcion",
                                  field: "tasaPercepcion",
                                  editable: "never",
                                  type: "numeric",
                                  hidden: comprobante.abreviatura === "R",
                                },
                                {
                                  title: "I.V.A.",
                                  field: "ivaId",
                                  editable: "onUpdate",
                                  type: "numeric",
                                  lookup: {
                                    3: "IVA 0%",
                                    4: "IVA 10.5%",
                                    5: "IVA 21%",
                                    6: "IVA 27%",
                                  },
                                  hidden: comprobante.abreviatura === "R",
                                },
                                {
                                  title: "Valor Total",
                                  field: "total",
                                  editable: "never",
                                  type: "currency",
                                  hidden: comprobante.abreviatura === "R",
                                },
                              ]}
                              data={articulosComprobante}
                              options={{
                                search: false,
                                sorting: true,
                                toolbar: false,
                                actionsColumnIndex: -1,
                              }}
                              localization={{
                                header: {
                                  actions: "Acción",
                                },
                                body: {
                                  editTooltip: "Editar",
                                  editRow: {
                                    cancelTooltip: "Cancelar",
                                    saveTooltip: "Aceptar",
                                    deleteText: "Esta seguro de borrar ?",
                                  },
                                  deleteTooltip: "Borrar",
                                  emptyDataSourceMessage: "No hay registros",
                                },
                                pagination: {
                                  labelRowsSelect: "filas",
                                },
                              }}
                              editable={{
                                ...(!verComprobante
                                  ? {
                                      isEditable: (rowData) => rowData, // only name(a) rows would be editable
                                      onRowAddCancelled: (rowData) =>
                                        console.log("Row adding cancelled"),
                                      onRowUpdateCancelled: (rowData) =>
                                        console.log("Row editing cancelled"),
                                      onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                          setTimeout(() => {
                                            const dataUpdate = [
                                              ...articulosComprobante,
                                            ];
                                            const index = oldData.id;

                                            // Actualizar states

                                            let item = { ...newData };
                                            const {
                                              cantidad,
                                              unitario,
                                              tasaDescuento,
                                              ivaId,
                                              tasaPercepcion,
                                            } = item;

                                            let tasaIva = 0;
                                            if (ivaId == 3) {
                                              tasaIva = 0;
                                            }
                                            if (ivaId == 4) {
                                              tasaIva = 10.5;
                                            }
                                            if (ivaId == 5) {
                                              tasaIva = 21;
                                            }
                                            if (ivaId == 6) {
                                              tasaIva = 27;
                                            }

                                            let valorSubtotal =
                                              cantidad * unitario;
                                            let valorDescuento =
                                              (valorSubtotal * tasaDescuento) /
                                              100;

                                            /*  
                                        if (
                                          comprobante.abreviatura === "NCA" ||
                                          comprobante.abreviatura === "NCB" ||
                                          comprobante.abreviatura === "NCM"
                                        ) {
                                          valorSubtotal = Number(valorSubtotal) * Number(-1);
                                          valorDescuento = Number(valorDescuento) * Number(-1);
                                        }
                                        */

                                            let valorNeto =
                                              Number(valorSubtotal) -
                                              Number(valorDescuento);

                                            let valorExento = 0;
                                            if (cliente.idCondicionIva === 4) {
                                              //Exento
                                              valorExento =
                                                Number(valorSubtotal) -
                                                Number(valorDescuento);
                                            } else {
                                              valorExento = 0;
                                            }

                                            let valorPercepcion =
                                              (Number(valorNeto) *
                                                Number(tasaPercepcion)) /
                                              100;

                                            let valorIva =
                                              (Number(valorNeto) *
                                                Number(tasaIva)) /
                                              100;

                                            let valorTotal =
                                              Number(valorNeto) +
                                              Number(valorIva) +
                                              Number(valorPercepcion);
                                            dataUpdate[index] = newData;
                                            dataUpdate[
                                              index
                                            ].subtotal = valorSubtotal;
                                            dataUpdate[
                                              index
                                            ].descuento = valorDescuento;
                                            dataUpdate[index].neto = valorNeto;
                                            dataUpdate[index].iva = valorIva;
                                            dataUpdate[
                                              index
                                            ].percepcion = valorPercepcion;
                                            dataUpdate[
                                              index
                                            ].total = valorTotal;
                                            dataUpdate[index].tasaIva = tasaIva;
                                            dataUpdate[
                                              index
                                            ].tasaPercepcion = tasaPercepcion;
                                            dataUpdate[
                                              index
                                            ].exento = valorExento;

                                            guardarArticulosComprobante([
                                              ...dataUpdate,
                                            ]);

                                            valorSubtotal = 0;
                                            valorDescuento = 0;
                                            valorNeto = 0;
                                            valorIva = 0;
                                            valorPercepcion = 0;
                                            valorTotal = 0;
                                            valorExento = 0;

                                            for (
                                              var i = 0;
                                              i < dataUpdate.length;
                                              i++
                                            ) {
                                              valorSubtotal =
                                                Number(valorSubtotal) +
                                                Number(dataUpdate[i].subtotal);
                                              valorDescuento =
                                                Number(valorDescuento) +
                                                Number(dataUpdate[i].descuento);
                                              valorNeto =
                                                Number(valorNeto) +
                                                Number(dataUpdate[i].neto);
                                              valorIva =
                                                Number(valorIva) +
                                                Number(dataUpdate[i].iva);
                                              valorPercepcion =
                                                Number(valorPercepcion) +
                                                Number(
                                                  dataUpdate[i].percepcion
                                                );
                                              valorTotal =
                                                Number(valorTotal) +
                                                Number(dataUpdate[i].total);
                                              valorExento =
                                                Number(valorExento) +
                                                Number(dataUpdate[i].exento);
                                            }

                                            guardarComprobante({
                                              ...comprobante,
                                              subtotal: valorSubtotal,
                                              descuento: valorDescuento,
                                              neto: valorNeto,
                                              iva: valorIva,
                                              percepcion: valorPercepcion,
                                              total: valorTotal,
                                              exento: valorExento,
                                              saldo: valorTotal,
                                            });

                                            resolve();
                                          }, 1000);
                                        }),

                                      onRowDelete: (oldData) =>
                                        new Promise((resolve, reject) => {
                                          setTimeout(() => {
                                            const dataDelete = [
                                              ...articulosComprobante,
                                            ];
                                            const index = oldData.tableData.id;
                                            dataDelete.splice(index, 1);
                                            guardarArticulosComprobante([
                                              ...dataDelete,
                                            ]);

                                            //Actualizar state

                                            let valorSubtotal = 0;
                                            let valorDescuento = 0;
                                            let valorNeto = 0;
                                            let valorIva = 0;
                                            let valorPercepcion = 0;
                                            let valorTotal = 0;
                                            let valorExento = 0;
                                            if (dataDelete) {
                                              for (
                                                var i = 0;
                                                i < dataDelete.length;
                                                i++
                                              ) {
                                                valorSubtotal =
                                                  Number(valorSubtotal) +
                                                  Number(
                                                    dataDelete[i].subtotal
                                                  );
                                                valorDescuento =
                                                  Number(valorDescuento) +
                                                  Number(
                                                    dataDelete[i].descuento
                                                  );
                                                valorNeto =
                                                  Number(valorNeto) +
                                                  Number(dataDelete[i].neto);
                                                valorIva =
                                                  Number(valorIva) +
                                                  Number(dataDelete[i].iva);
                                                valorPercepcion =
                                                  Number(valorPercepcion) +
                                                  Number(
                                                    dataDelete[i].percepcion
                                                  );
                                                valorTotal =
                                                  Number(valorTotal) +
                                                  Number(dataDelete[i].total);

                                                if (
                                                  cliente.idCondicionIva === 4
                                                ) {
                                                  //Exento
                                                  valorExento =
                                                    Number(valorExento) +
                                                    Number(
                                                      dataDelete[i].exento
                                                    );
                                                } else {
                                                  valorExento = 0;
                                                }
                                              }
                                            }
                                            guardarComprobante({
                                              ...comprobante,
                                              subtotal: valorSubtotal,
                                              descuento: valorDescuento,
                                              neto: valorNeto,
                                              iva: valorIva,
                                              percepcion: valorPercepcion,
                                              total: valorTotal,
                                              exento: valorExento,
                                              saldo: valorTotal,
                                            });

                                            resolve();
                                          }, 1000);
                                        }),
                                    }
                                  : {}),
                              }}
                            />
                          ) : null}
                        </div>
                        <div className="col-xs-12 col-md-2">
                          {comprobante.abreviatura !== "R" ? (
                            <Fragment>
                              {comprobante.tipo === "A" ? (
                                <Fragment>
                                  <h6>
                                    Subtotal:{"$ "}
                                    <span className="badge badge-secondary">
                                      {parseFloat(comprobante.subtotal).toFixed(
                                        2
                                      )}
                                    </span>
                                  </h6>
                                  <h6>
                                    Descuento:{"$ "}
                                    <span className="badge badge-secondary">
                                      {parseFloat(
                                        comprobante.descuento
                                      ).toFixed(2)}
                                    </span>
                                  </h6>
                                  <h6>
                                    Neto:{"$ "}
                                    <span className="badge badge-secondary">
                                      {parseFloat(comprobante.neto).toFixed(2)}
                                    </span>
                                  </h6>
                                  <h6>
                                    I.V.A.:{"$ "}
                                    <span className="badge badge-secondary">
                                      {parseFloat(comprobante.iva).toFixed(2)}
                                    </span>
                                  </h6>
                                  <h6>
                                    Impuestos:{"$ "}
                                    <span className="badge badge-secondary">
                                      {parseFloat(
                                        comprobante.percepcion
                                      ).toFixed(2)}
                                    </span>
                                  </h6>
                                  <h6>
                                    Total:{"$ "}
                                    <span className="badge badge-secondary">
                                      {parseFloat(comprobante.total).toFixed(2)}
                                    </span>
                                  </h6>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <h6>
                                    Subtotal:{"$ "}
                                    <span className="badge badge-secondary">
                                      {`${parseFloat(
                                        Number(comprobante.subtotal) +
                                          Number(comprobante.iva)
                                      ).toFixed(2)}`}
                                    </span>
                                  </h6>
                                  <h6>
                                    Descuento:{"$ "}
                                    <span className="badge badge-secondary">
                                      {parseFloat(
                                        comprobante.descuento
                                      ).toFixed(2)}
                                    </span>
                                  </h6>
                                  <h6>
                                    Impuestos:{"$ "}
                                    <span className="badge badge-secondary">
                                      {parseFloat(
                                        comprobante.percepcion
                                      ).toFixed(2)}
                                    </span>
                                  </h6>
                                  <h6>
                                    Total:{"$ "}
                                    <span className="badge badge-secondary">
                                      {parseFloat(comprobante.total).toFixed(2)}
                                    </span>
                                  </h6>
                                </Fragment>
                              )}
                              {!articulosComprobante || !verComprobante ? (
                                <button
                                  type="button"
                                  className="btn btn-info btn-large mt-4"
                                  onClick={generarFactura}
                                  disabled={!articulosComprobante}
                                >
                                  Generar comprobante
                                </button>
                              ) : (
                                <Fragment>
                                  <Button
                                    icon="pi pi-file-pdf"
                                    className="p-button-rounded p-button-success"
                                    tooltip="Pdf"
                                    tooltipOptions={{position: 'bottom'}}
                                    onClick={(e) => observaciones(1)}
                                    style={{marginRight: "0.5em", marginTop: "1em"}}
                                  />
                                  <Button
                                    icon="pi pi-download"
                                    className="p-button-rounded p-button-success"
                                    tooltip="Descargar"
                                    tooltipOptions={{position: 'bottom'}}
                                    onClick={(e) => observaciones(2)}
                                    style={{marginRight: "0.5em", marginTop: "1em"}}
                                  />
                                  <Button
                                    icon="pi pi-print"
                                    className="p-button-rounded p-button-success"
                                    tooltip="Imprimir"
                                    tooltipOptions={{position: 'bottom'}}
                                    onClick={(e) => observaciones(3)}
                                    style={{marginRight: "0.5em", marginTop: "1em"}}
                                  />
                                </Fragment>
                              )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {!articulosComprobante || !verComprobante ? (
                                <button
                                  type="button"
                                  className="btn btn-info btn-large mt-4"
                                  onClick={generarRemito}
                                  disabled={!articulosComprobante}
                                >
                                  Generar remito
                                </button>
                              ) : (
                                <Fragment>
                                  <Button
                                    icon="pi pi-file-pdf"
                                    className="p-button-rounded p-button-success"
                                    tooltip="Pdf"
                                    tooltipOptions={{position: 'bottom'}}
                                    onClick={(e) => observaciones(4)}
                                    style={{marginRight: "0.5em", marginTop: "1em"}}
                                  />
                                  <Button
                                    icon="pi pi-download"
                                    className="p-button-rounded p-button-success"
                                    tooltip="Descargar"
                                    tooltipOptions={{position: 'bottom'}}
                                    onClick={(e) => observaciones(5)}
                                    style={{marginRight: "0.5em", marginTop: "1em"}}
                                  />
                                </Fragment>
                              )}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>

                    <Dialog
                      visible={modalArticulos}
                      style={{ width: "50vw" }}
                      modal={true}
                      showHeader={false}
                      dismissableMask={true}
                      onHide={() => {
                        let elemento = document.getElementById("codigo");
                        elemento.focus();
                        setModalArticulos(false);
                      }}
                    >
                      <div style={{ maxWidth: "100%" }}>
                        {articulos ? (
                          <MaterialTable
                            columns={[
                              {
                                title: "ID del artículo",
                                field: "articuloId",
                                editable: "never",
                                cellStyle: {
                                  width: 100,
                                  minWidth: 100,
                                },
                              },
                              {
                                title: "Nombre del artículo",
                                field: "nombre",
                                editable: "never",
                                cellStyle: {
                                  width: 400,
                                  minWidth: 400,
                                },
                                headerStyle: {
                                  width: 300,
                                  minWidth: 300,
                                },
                              },
                              {
                                title: "Precio",
                                field: "precio",
                                editable: "never",
                                type: "numeric",
                              },
                              {
                                title: "I.V.A.",
                                field: "ivaId",
                                editable: "never",
                                type: "numeric",
                                lookup: {
                                  3: "IVA 0%",
                                  4: "IVA 10.5%",
                                  5: "IVA 21%",
                                  6: "IVA 27%",
                                },
                              },
                            ]}
                            data={articulos}
                            title="Artículos"
                            options={{
                              search: true,
                              sorting: true,
                              actionsColumnIndex: -1,
                              rowStyle: (rowData) => ({
                                backgroundColor:
                                  selectedRow === rowData.tableData.id
                                    ? "#EEE"
                                    : "#FFF",
                              }),
                            }}
                            onRowClick={(evt, selectedRow) => (
                              setSelectedRow(selectedRow.tableData.id),
                              agregarItem(selectedRow.tableData.id)
                            )}
                            localization={{
                              toolbar: {
                                searchPlaceholder: "Buscar",
                              },
                            }}
                          />
                        ) : null}
                      </div>
                    </Dialog>

                    <Dialog
                      visible={modalClientes}
                      style={{ width: "50vw" }}
                      modal={true}
                      showHeader={false}
                      dismissableMask={true}
                      onHide={() => setModalClientes(false)}
                    >
                      <div style={{ maxWidth: "100%" }}>
                        {clientes ? (
                          <MaterialTable
                            columns={[
                              {
                                title: "ID del cliente",
                                field: "id",
                                editable: "never",
                              },
                              {
                                title: "Nombre/Razón social",
                                field: "nombre",
                                editable: "never",
                                cellStyle: {
                                  width: 400,
                                  minWidth: 400,
                                },
                                headerStyle: {
                                  width: 400,
                                  minWidth: 400,
                                },
                              },
                              {
                                title: "C.U.I.T.",
                                field: "cuit",
                                editable: "never",
                              },
                            ]}
                            data={clientes}
                            title="Clientes"
                            options={{
                              search: true,
                              sorting: true,
                              actionsColumnIndex: -1,
                              rowStyle: (rowData) => ({
                                backgroundColor:
                                  selectedRow === rowData.tableData.id
                                    ? "#EEE"
                                    : "#FFF",
                              }),
                            }}
                            onRowClick={(evt, selectedRow) => (
                              setSelectedRow(selectedRow.tableData.id),
                              agregarCliente(selectedRow.tableData.id)
                            )}
                            localization={{
                              toolbar: {
                                searchPlaceholder: "Buscar",
                              },
                            }}
                          />
                        ) : null}
                      </div>
                    </Dialog>

                    <Dialog
                      visible={modalObservaciones}
                      header="Comprobante"
                      style={{ width: "50vw" }}
                      modal={true}
                      showHeader={true}
                      dismissableMask={true}
                      footer={footer}
                      onHide={() => onHide}
                    >
                      <div style={{ maxWidth: "100%" }}>
                        <div className="row">
                          <div className="col-xs-12 col-md-12">
                            <div className="form-group">
                              <span className="p-float-label">
                                <InputText
                                  id="observaciones1"
                                  type="text"
                                  className="form-control mt-1"
                                  value={comprobante.observaciones1}
                                  onChange={(e) =>
                                    guardarComprobante({
                                      ...comprobante,
                                      observaciones1: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  htmlFor="observaciones1"
                                  style={{ color: "black" }}
                                >
                                  Observaciones
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12 col-md-12">
                            <div className="form-group">
                              <span className="p-float-label">
                                <InputText
                                  id="observaciones2"
                                  type="text"
                                  className="form-control mt-1"
                                  value={comprobante.observaciones2}
                                  onChange={(e) =>
                                    guardarComprobante({
                                      ...comprobante,
                                      observaciones2: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  htmlFor="observaciones2"
                                  style={{ color: "black" }}
                                >
                                  Observaciones
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>

                        {opcionPdf === 3 ? (
                          <Fragment>
                            <div className="row">
                              <div className="col-xs-12 col-md-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="imprimeRemito"
                                    style={{
                                      color: "black",
                                      marginRight: "1em",
                                    }}
                                  >
                                    Imprime remito:{" "}
                                    {"Número: " +
                                      (
                                        "0000" + comprobante.puntoVentaRemito
                                      ).slice(-4) +
                                      "-" +
                                      ("0000" + comprobante.numeroRemito).slice(
                                        -8
                                      )}
                                  </label>
                                  {/*
                                  <ToggleButton
                                    onLabel="Si"
                                    offLabel="No"
                                    onIcon="pi pi-check"
                                    offIcon="pi pi-times"
                                    checked={imprimeRemito}
                                    onChange={(e) => setImprimeRemito(e.value)}
                                  />
                                  */}
                                </div>
                              </div>

                              <div className="col-xs-12 col-md-6">
                                <div className="form-group">
                                  <span className="p-float-label">
                                    <InputText
                                      id="bultos"
                                      type="number"
                                      className="form-control mt-1"
                                      value={comprobante.bultos}
                                      onChange={(e) =>
                                        guardarComprobante({
                                          ...comprobante,
                                          bultos: e.value,
                                        })
                                      }
                                    />
                                    <label
                                      htmlFor="bultos"
                                      style={{ color: "black" }}
                                    >
                                      Cantidad de bultos
                                    </label>
                                  </span>
                                </div>
                              </div>

                              <div className="col-xs-12 col-md-3">
                                <div className="form-group">
                                  <span className="p-float-label">
                                    <InputText
                                      id="copiasFactura"
                                      type="number"
                                      className="form-control mt-1"
                                      value={copias.factura}
                                      onChange={(e) =>
                                        guardarCopias({
                                          ...copias,
                                          factura: e.value,
                                        })
                                      }
                                    />
                                    <label
                                      htmlFor="copiasFactura"
                                      style={{ color: "black" }}
                                    >
                                      Cantidad de copias de factura
                                    </label>
                                  </span>
                                </div>
                              </div>

                              <div className="col-xs-12 col-md-3">
                                <div className="form-group">
                                  <span className="p-float-label">
                                    <InputText
                                      id="copiasRemito"
                                      type="number"
                                      className="form-control mt-1"
                                      value={copias.remito}
                                      onChange={(e) =>
                                        guardarCopias({
                                          ...copias,
                                          remito: e.value,
                                        })
                                      }
                                    />
                                    <label
                                      htmlFor="copiasRemito"
                                      style={{ color: "black" }}
                                    >
                                      Cantidad de copias de remito
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </Dialog>
                  </TabPanel>
                </Tabs>

                {/*
                <input
                  type="submit"
                  className="btn btn-background btn-block mt-4"
                  value="Guardar comprobante"
                />
                
                */}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container" style={{ display: "none" }}>
        {qrValue ? <QrCode value={qrValue} size={200} id="qrcode" /> : null}
      </div>
    </Fragment>
  );
}

export default Comprobante;
