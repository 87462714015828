import React, { useState, useContext, useEffect, useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { AuthContext } from "../../context/AuthContext";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";

function Login(props) {
  const authContext = useContext(AuthContext);
  const { autenticado, iniciarSesion } = authContext;

  let growl = useRef();

  // State para iniciar sesión
  const [usuario, guardarUsuario] = useState({
    email: "",
    password: "",
    estado: "",
  });

  // extraer de usuario
  const { email, password, estado } = usuario;

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar
    if (email.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Login",
        detail: "El email es obligatorio.",
      });
      return;
    }

    if (!EmailValidator.validate(email)) {
      growl.show({
        severity: "error",
        summary: "Login",
        detail: "El email es incorrecto.",
      });
      return;
    }

    if (password.trim() === "") {
      growl.show({
        severity: "error",
        summary: "Login",
        detail: "El password es incorrecto.",
      });
      return;
    }

    if (password.length < 6) {
      growl.show({
        severity: "error",
        summary: "Login",
        detail: "El password debe tener al menos 6 caracteres.",
      });
      return;
    }

    // Llamar al context
    iniciarSesion({ email, password });
  };

  const cerrar = () => {
    guardarUsuario({
      ...usuario,
      estado: true,
    });
  };

  return (
    <div className = "background-body">
    <div className="row justify-content-center">
      <div className="col-xs-5 col-md-4 mt-3">
        <div className="card-body text-black background mb-3">
          <button
            type="button"
            className="close xclose"
            aria-label="Close"
            onClick={cerrar}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <h3 className="fwhite titulos text-center py=4">
            <i className="fa fa-lock"></i> {""}
            Iniciar sesión
          </h3>
          <hr className="linea-negra" />

          <Growl ref={(el) => (growl = el)} />

          <form onSubmit={onSubmit}>
            <div className="form-group">
              <span className="p-float-label">
                <InputText
                  id="email"
                  type="text"
                  className="form-control mt-4"
                  value={email}
                  onChange={(e) =>
                    guardarUsuario({
                      ...usuario,
                      email: e.target.value,
                    })
                  }
                />
                <label htmlFor="email" style={{ color: "black" }}>
                  Email
                </label>
              </span>
            </div>
            <div className="form-group">
              <span className="p-float-label">
                <InputText
                  id="password"
                  type="password"
                  className="form-control mt-4"
                  value={password}
                  onChange={(e) =>
                    guardarUsuario({
                      ...usuario,
                      password: e.target.value,
                    })
                  }
                />
                <label htmlFor="password" style={{ color: "black" }}>
                  Password
                </label>
              </span>
            </div>
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-background btn-block mt-2"
                value="Iniciar sesión"
              />
            </div>
            <br />
            <Link
              to="/account"
              style={{ color: "black", textDecoration: "underline" }}
            >
              Crear una cuenta
            </Link>
            {autenticado || estado ? <Redirect to="/" /> : null}
          </form>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Login;
