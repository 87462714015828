import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { CompraContext } from "../../../context/CompraContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "primereact/button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import MaterialTable from "material-table";
import Icon from "@material-ui/core/Icon";
import { Dialog } from "primereact/dialog";
import jsPDF from "jspdf";

function OrdenPago(props) {
  // extraer los valores del context
  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    empresa,
    proveedores,
    bancos,
    cuentas,
    tiposRetenciones,
    tiposComprobantes,
    condicionesIva,
    obtenerEmpresa,
    retencionesGanancias,
    obtenerTiposOrdenesPagosEmpresaSelect,
    obtenerListasPreciosSelect,
    obtenerCondicionesIvaSelect,
    guardarTiposComprobantes,
    obtenerProveedores,
    obtenerBancosLookup,
    obtenerCuentasSelect,
    obtenerTiposRetencionesLookup,
    obtenerConceptosRetenciones,
  } = archivoContext;

  const compraContext = useContext(CompraContext);
  const {
    proveedor,
    ordenPago,
    verOrdenPago,
    saldoCtaCte,
    condicionesVentas,
    comprobantesOrdenPago,
    obtenerComprobante,
    chequesTerceros,
    chequesPropios,
    guardarOrdenPago,
    guardarProveedor,
    crearOrdenPago,
    obtenerProveedorxId,
    obtenerProveedorxIdComprobante,
    obtenerCondicionesVentasSelect,
    obtenerComprobanteNro,
    guardarFacturaAsociada,
    guardarVerOrdenPago,
    guardarArticulosComprobante,
    guardarComprobantesOrdenPago,
    obtenerCtaCtePendienteProveedor,
    obtenerOrdenPago,
    obtenerProveedorxIdOrdenPago,
    obtenerComprobantesxIdOrdenPago,
    tranformDate,
    obtenerChequesTercerosCartera,
    obtenerChequesPropiosCartera,
  } = compraContext;

  const [modalRefresh, setModalRefresh] = useState(false);
  const [modalEfectivo, setModalEfectivo] = useState(false);
  const [modalCheque, setModalCheque] = useState(false);
  const [modalChequePropio, setModalChequePropio] = useState(false);
  const [modalBanco, setModalBanco] = useState(false);
  const [modalRetencion, setModalRetencion] = useState(false);
  const [modalProveedores, setModalProveedores] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [delay, setDelay] = useState(false);
  const [valoresTotal, guardarValoresTotal] = useState(0);
  const [valoresPendiente, guardarValoresPendiente] = useState(0);
  const [valoresOrdenPago, guardarValoresOrdenPago] = useState({
    efectivo: "",
    cheque: "",
    chequePropio: "",
    banco: "",
    retencion: "",
  });
  const [chequesTabla, guardarChequesTabla] = useState([]);
  const [chequesTablaPropios, guardarChequesTablaPropios] = useState([]);
  const [deposito, guardarDeposito] = useState({
    cuenta: "",
    fecha: new Date(),
  });
  const [retencionesTabla, guardarRetencionesTabla] = useState([]);

  let growl = useRef();

  const idOrden = props.match.params.id;

  useEffect(() => {
    if (empresaNavbar) {
      if (idOrden !== "0") {
        obtenerOrdenPago({ idOrden });
        guardarTiposComprobantes(null);
        obtenerEmpresa({ idEmpresa: empresaNavbar });
        obtenerTiposOrdenesPagosEmpresaSelect({ idEmpresa: empresaNavbar });
        obtenerConceptosRetenciones();
        guardarVerOrdenPago({
          ...verOrdenPago,
          estado: true,
        });
        setTimeout(function () {
          setDelay(true);
        }, 1000);
      } else {
        obtenerEmpresa({ idEmpresa: empresaNavbar });
        obtenerTiposOrdenesPagosEmpresaSelect({ idEmpresa: empresaNavbar });
        obtenerConceptosRetenciones();
        obtenerBancosLookup();
        obtenerCuentasSelect();
        //obtenerTiposRetencionesLookup();
        obtenerChequesTercerosCartera({ idEmpresa: empresaNavbar });
        obtenerChequesPropiosCartera({ idEmpresa: empresaNavbar });
        guardarOrdenPago({
          fecha: "",
          comprobante: "",
          abreviatura: "",
          tipo: "",
          puntoVenta: 0,
          numero: 0,
          proveedorId: "",
          nombreProveedor: "",
          cuitProveedor: "",
          total: 0,
          tipoComprobanteId: 0,
          tipoComprobanteIdAfip: 0,
          condicionIvaId: 0,
          empresaId: 0,
          anulado: false,
          saldo: 0,
          retencionConcepto: "",
          retencionConceptoId: 0,
        });

        guardarProveedor({
          id: "",
          nombre: "",
          cuit: "",
          domicilio: "",
          cp: "",
          localidad: "",
          email: "",
          idCondicionIva: "",
          descuento: "",
          domicilioCompleto: "",
          cbu: "",
        });

        setTimeout(function () {
          setDelay(true);
        }, 1000);
      }
    }
  }, []);



  useEffect(() => {
    if (ordenPago && idOrden !== "0") {
    obtenerProveedorxId({ idProveedor: ordenPago.proveedorId });
    obtenerComprobantesxIdOrdenPago({ idOrden });
      }
  }, [ordenPago]);



  const generarOrdenPago = async (e) => {
    e.preventDefault();

    //Validar

    if (proveedor.id === null || proveedor.id === 0) {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "El ID de Proveedor es obligatorio.",
      });
      return;
    }

    if (
      proveedor.idCondicionIva !== 5 &&
      (proveedor.cuit === null ||
        proveedor.cuit === "" ||
        proveedor.cuit.length !== 13)
    ) {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "C.U.I.T. de Proveedor es inválido.",
      });
      return;
    }

    if (
      proveedor.idCondicionIva === 5 &&
      (proveedor.cuit === null || proveedor.cuit === "")
    ) {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "D.N.I. de Proveedor es inválido.",
      });
      return;
    }

    if (proveedor.nombre === null || proveedor.nombre === "") {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "El nombre es inválido.",
      });
      return;
    }

    if (ordenPago.fecha === null || ordenPago.fecha === "") {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "La fecha es inválida.",
      });
      return;
    }

    if (ordenPago.comprobante === null || ordenPago.comprobante === "") {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "El ordenPago es inválido.",
      });
      return;
    }

    if (ordenPago.tipo === null || ordenPago.tipo === "") {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "El tipo de ordenPago es inválido.",
      });
      return;
    }

    if (ordenPago.puntoVenta === null || ordenPago.puntoVenta === 0) {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "El punto de venta del ordenPago es inválido.",
      });
      return;
    }

    if (ordenPago.numero === null || ordenPago.numero === 0) {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "El número del ordenPago es inválido.",
      });
      return;
    }

    if (
      proveedor.idCondicionIva === 1 &&
      ordenPago.tipo !== "A" &&
      ordenPago.tipo !== "M" &&
      ordenPago.tipo !== "X"
    ) {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail:
          "El ordenPago no corresponde con la condición de iva del proveedor.",
      });
      return;
    }

    if (proveedor.idCondicionIva !== 1 && ordenPago.tipo !== "B") {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail:
          "El ordenPago no corresponde con la condición de iva del proveedor.",
      });
      return;
    }

    let local = comprobantesOrdenPago;
    let itemCancelado = false;
    for (var i = 0; i < local.length; i++) {
      if (Number(local[i].cancelado) !== 0) {
        itemCancelado = true;
      }
    }

    if (!itemCancelado) {
      growl.show({
        severity: "error",
        summary: "Ordenes de pago",
        detail: "Debe aplicar al menos 1 comprobante.",
      });
      return;
    }

    if (idOrden === "0") {
      crearOrdenPago(
        empresa,
        ordenPago,
        proveedor,
        comprobantesOrdenPago,
        valoresPendiente,
        valoresOrdenPago,
        valoresTotal,
        saldoCtaCte,
        deposito,
        chequesTabla,
        chequesTablaPropios,
        retencionesTabla
      );
    }
  };

  // Despues de generar comprobante
  if (verOrdenPago && verOrdenPago.estado && idOrden === "0") {
    props.history.push(`/ordenpagocompras/${verOrdenPago.id}`);
  }

  const cerrar = () => {
    guardarVerOrdenPago(null);

    guardarComprobantesOrdenPago(null);
    props.history.push("/ordenespagoscompras");
  };

  const useStyles = makeStyles((theme) => ({
    boton: {
      "& > *": {
        marginLeft: theme.spacing(0),
        width: "20ch",
      },
    },
    root: {
      "& > *": {
        marginLeft: theme.spacing(2),
        width: "20ch",
      },
    },
    table: {
      "& > *": {
        marginLeft: theme.spacing(2),
        width: "130ch",
      },
    },
  }));

  const classes = useStyles();

  function handleEnter(event) {
    if (event.keyCode === 13) {
      if (event.target.id === "idproveedor") {
        if (event.target.value !== "" && event.target.value !== "0") {
          let proveedor = event.target.value;
          obtenerProveedorxId({ idProveedor: proveedor });
          setTimeout(() => {
            obtenerCtaCtePendienteProveedor({
              idProveedor: proveedor,
              idEmpresa: empresaNavbar,
            });
            guardarOrdenPago({
              ...ordenPago,
              retencionConceptoId: retencionesGanancias[0].value,
              retencionConcepto: retencionesGanancias[0].label,
            });
          }, 500);
        } else {
          obtenerProveedores();
          setModalProveedores(true);
        }
      }

      if (event.target.id !== "efectivo" && event.target.id !== "importe") {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form, event.target);
        if (typeof form.elements[index + 1] !== "undefined") {
          form.elements[index + 1].focus();
        }
      }
      event.preventDefault();
    }
    if (event.keyCode === 27) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (typeof form.elements[index - 1] !== "undefined") {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  }

  if (
    proveedor &&
    proveedor.id !== "" &&
    ordenPago &&
    proveedor.id !== ordenPago.proveedorId &&
    proveedor.nombre !== ordenPago.nombreProveedor &&
    !verOrdenPago
  ) {
    let tipoComprobante;

    if ((empresa.idCondicionIva === 1) & (proveedor.idCondicionIva === 1)) {
      tipoComprobante = "RCA";
    }
    if ((empresa.idCondicionIva === 1) & (proveedor.idCondicionIva !== 1)) {
      tipoComprobante = "RCB";
    }

    let data = 0;
    for (var i = 0; i < tiposComprobantes.length; i++) {
      if (tiposComprobantes[i].abreviatura == tipoComprobante) {
        data = i;
      }
    }

    guardarOrdenPago({
      ...ordenPago,
      proveedorId: proveedor.id,
      nombreProveedor: proveedor.nombre,
      cuitProveedor: proveedor.cuit,
      vendedorId: proveedor.idVendedor,
      tipoComprobanteId: tiposComprobantes[data].value,
      tipoComprobanteIdAfip: tiposComprobantes[data].codigoAfip,
      puntoVenta: tiposComprobantes[data].puntoVenta,
      numero: tiposComprobantes[data].numero,
      comprobante: tiposComprobantes[data].label,
      tipo: tiposComprobantes[data].tipo,
      abreviatura: tiposComprobantes[data].abreviatura,
      total: 0,
      saldo: 0,
      condicionIvaId: proveedor.idCondicionIva,
    });

    guardarProveedor({
      ...proveedor,
      domicilioCompleto: `${proveedor.domicilio} ${proveedor.cp} ${proveedor.localidad}`,
    });

    if (comprobantesOrdenPago) {
      guardarComprobantesOrdenPago(null);
    }
  }

  if (
    tiposComprobantes &&
    ordenPago &&
    ordenPago.tipoComprobanteId === 0 &&
    ordenPago.numero === 0 &&
    ordenPago.fecha === "" &&
    ordenPago.abreviatura === "" &&
    ordenPago.puntoVenta === 0
  ) {
    guardarOrdenPago({
      ...ordenPago,
      puntoVenta: tiposComprobantes[0].puntoVenta,
      numero: tiposComprobantes[0].numero,
      electronica: false,
      tipoComprobanteId: tiposComprobantes[0].value,
      tipoComprobanteIdAfip: tiposComprobantes[0].codigoAfip,
      comprobante: tiposComprobantes[0].label,
      tipo: tiposComprobantes[0].tipo,
      abreviatura: tiposComprobantes[0].abreviatura,
      fecha: new Date(),
      empresaId: empresa.id,
    });
  }

  const agregarProveedor = (proveedor) => {
    obtenerProveedorxId({ idProveedor: proveedores[proveedor].id });
    obtenerCtaCtePendienteProveedor({
      idProveedor: proveedores[proveedor].id,
      idEmpresa: empresaNavbar,
    });
  };

  const updateComprobante = (e) => {
    var data;

    for (var i = 0; i < tiposComprobantes.length; i++) {
      if (tiposComprobantes[i].value == e.target.value) {
        data = i;
      }
    }

    guardarOrdenPago({
      ...ordenPago,
      tipoComprobanteId: tiposComprobantes[data].value,
      tipoComprobanteIdAfip: tiposComprobantes[data].codigoAfip,
      puntoVenta: tiposComprobantes[data].puntoVenta,
      numero: tiposComprobantes[data].numero,
      comprobante: tiposComprobantes[data].label,
      tipo: tiposComprobantes[data].tipo,
      abreviatura: tiposComprobantes[data].abreviatura,
    });
    if (comprobantesOrdenPago) {
      guardarComprobantesOrdenPago(null);
    }
  };

  const updateRetencion = (e) => {
    var data;

    for (var i = 0; i < retencionesGanancias.length; i++) {
      if (retencionesGanancias[i].value == e.target.value) {
        data = i;
      }
    }

    guardarOrdenPago({
      ...ordenPago,
      retencionConceptoId: retencionesGanancias[data].value,
      retencionConcepto: retencionesGanancias[data].label,
    });

    /*
    if (comprobantesOrdenPago) {
      guardarComprobantesOrdenPago(null);
    }
    */
  };

  const fullStyles =
    window.screen.availWidth < 780 ? { width: "90vw" } : { width: "50vw" };

  const largeStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "42ch" };

  const mediaStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "36ch" };

  const shortStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "30ch" };

  const verPdf = () => {
    var doc = new jsPDF();
    doc.setProperties({
      title: "new Report",
    });

    doc.text("Hello world!", 10, 10);
    doc.output("dataurlnewwindow");
    //doc.save("a4.pdf");
  };

  const descargarPdf = () => {
    var doc = new jsPDF();
    doc.setProperties({
      title: "new Report",
    });

    doc.text("Hello world!", 10, 10);
    //doc.output('dataurlnewwindow');
    doc.save("a4.pdf");
  };

  //console.log(ordenPago);
  //console.log(comprobantesOrdenPago);

  return (
    <Fragment>
      {!ordenPago ||
      ordenPago.numero === 0 ||
      ordenPago.fecha === "" ||
      !delay ? (
        <Spinner />
      ) : (
        <div className="background-fc">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-md-12 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                  style={{ color: "Blue" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <Growl ref={(el) => (growl = el)} />

                <Tabs>
                  <TabList>
                    <Tab style={{ background: "#28BAEC" }}>
                      Datos ordenes de pago
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                          <div className="card-body text-black background mb-3">
                            <form className={classes.root}>
                              <TextField
                                id="idproveedor"
                                label="ID Proveedor"
                                style={{ color: "blue" }}
                                onKeyDown={handleEnter}
                                value={proveedor.id}
                                disabled={verOrdenPago && verOrdenPago.estado}
                                onChange={(e) =>
                                  guardarProveedor({
                                    ...proveedor,
                                    id: Number(e.target.value),
                                  })
                                }
                              />
                              <TextField
                                id="cuit"
                                label="C.U.I.T."
                                style={{ color: "blue" }}
                                onKeyDown={handleEnter}
                                value={ordenPago.cuitProveedor}
                                disabled={verOrdenPago && verOrdenPago.estado}
                                onChange={(e) =>
                                  guardarOrdenPago({
                                    ...ordenPago,
                                    cuitProveedor: e.target.value,
                                  })
                                }
                              />

                              <TextField
                                id="nombre"
                                label="Nombre"
                                onKeyDown={handleEnter}
                                value={proveedor.nombre}
                                disabled={verOrdenPago && verOrdenPago.estado}
                                onChange={(e) => {
                                  guardarProveedor({
                                    ...proveedor,
                                    nombre: e.target.value,
                                  });
                                }}
                                style={largeStyles}
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                          <div className="card-body text-black background mb-3">
                            <form className={classes.root}>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={esLocale}
                              >
                                <KeyboardDatePicker
                                  clearable
                                  label="Fecha"
                                  value={ordenPago.fecha}
                                  disabled={verOrdenPago && verOrdenPago.estado}
                                  onChange={(date) =>
                                    guardarOrdenPago({
                                      ...ordenPago,
                                      fecha: date,
                                    })
                                  }
                                  minDate={!verOrdenPago ? new Date(-10) : null}
                                  format="dd/MM/yyyy"
                                  style={{ color: "blue" }}
                                  onKeyDown={handleEnter}
                                />
                              </MuiPickersUtilsProvider>
                              {/*
                              <TextField
                                id="ordenPago"
                                select
                                label="Orden de pago"
                                value={ordenPago.tipoComprobanteId}
                                disabled={verOrdenPago && verOrdenPago.estado}
                                visible={false}
                                onChange={(e) => updateComprobante(e)}
                                style={shortStyles}
                                onKeyDown={handleEnter}
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {tiposComprobantes
                                  ? tiposComprobantes.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))
                                  : null}
                              </TextField>
                              */}
                              <TextField
                                id="puntoventa"
                                disabled
                                label="Punto de venta"
                                style={{ color: "blue" }}
                                onKeyDown={handleEnter}
                                value={("0000" + ordenPago.puntoVenta).slice(
                                  -4
                                )}
                              />

                              <TextField
                                id="numero"
                                disabled
                                label="Número"
                                style={{ color: "blue", width: "10ch" }}
                                onKeyDown={handleEnter}
                                value={ordenPago.numero}
                              />

                              <TextField
                                id="retenciones"
                                select
                                label="Retenciones"
                                value={ordenPago.retencionConceptoId}
                                disabled={
                                  (verOrdenPago && verOrdenPago.estado) ||
                                  !proveedor.retencionesGanancias
                                }
                                onChange={(e) => updateRetencion(e)}
                                style={shortStyles}
                                onKeyDown={handleEnter}
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {proveedor.retencionesGanancias &&
                                retencionesGanancias
                                  ? retencionesGanancias.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))
                                  : null}
                              </TextField>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>

                <Tabs>
                  <TabList>
                    <Tab style={{ background: "#28BAEC" }}>Pendientes</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="row">
                      <div className="col-xs-12 col-md-2">
                        <div className="form-group">
                          <div className="card-body text-black background mb-1">
                            <span className="badge badge-success">
                              Formas de pago
                            </span>
                            <div className={classes.boton}>
                              <Button
                                label="Efectivo"
                                icon="fa fa-money"
                                style={{ marginTop: "1em" }}
                                disabled={verOrdenPago && verOrdenPago.estado}
                                onClick={(e) => {
                                  setModalEfectivo(true);
                                }}
                              />
                              <span className="badge badge-secondary">
                                {valoresOrdenPago.efectivo !== ""
                                  ? "$ " +
                                    parseFloat(
                                      valoresOrdenPago.efectivo
                                    ).toFixed(2)
                                  : null}
                              </span>
                              <br />
                              <Button
                                label="Cheques terceros"
                                icon="fa fa-indent"
                                style={{ marginTop: "1em" }}
                                disabled={verOrdenPago && verOrdenPago.estado}
                                onClick={(e) => {
                                  setModalCheque(true);
                                }}
                              />
                              <span className="badge badge-secondary">
                                {valoresOrdenPago.cheque !== ""
                                  ? "$ " +
                                    parseFloat(valoresOrdenPago.cheque).toFixed(
                                      2
                                    )
                                  : null}
                              </span>
                              <br />
                              <Button
                                label="Cheques propios"
                                icon="fa fa-indent"
                                style={{ marginTop: "1em" }}
                                disabled={verOrdenPago && verOrdenPago.estado}
                                onClick={(e) => {
                                  setModalChequePropio(true);
                                }}
                              />
                              <span className="badge badge-secondary">
                                {valoresOrdenPago.chequePropio !== ""
                                  ? "$ " +
                                    parseFloat(
                                      valoresOrdenPago.chequePropio
                                    ).toFixed(2)
                                  : null}
                              </span>
                              <br />
                              <Button
                                label="Banco"
                                icon="fa fa-university"
                                style={{ marginTop: "1em" }}
                                disabled={verOrdenPago && verOrdenPago.estado}
                                onClick={(e) => {
                                  setModalBanco(true);
                                }}
                              />
                              <span className="badge badge-secondary">
                                {valoresOrdenPago.banco !== ""
                                  ? "$ " +
                                    parseFloat(valoresOrdenPago.banco).toFixed(
                                      2
                                    )
                                  : null}
                              </span>
                              {/*
                              <br />
                              <Button
                                label="Retenciones"
                                icon="fa fa-reply"
                                style={{ marginTop: "1em" }}
                                disabled={verOrdenPago && verOrdenPago.estado}
                                onClick={(e) => {
                                  setModalRetencion(true);
                                }}
                              />
                              <span className="badge badge-secondary">
                                {valoresOrdenPago.retencion !== ""
                                  ? "$ " +
                                    parseFloat(
                                      valoresOrdenPago.retencion
                                    ).toFixed(2)
                                  : null}
                              </span>
                                    */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-md-8">
                        <div className="form-group">
                          <div className="card-body text-black background mb-1">
                            <div className={classes.table}>
                              <div
                                style={{ maxWidth: "100%", fontSize: "small" }}
                              >
                                {comprobantesOrdenPago ? (
                                  <MaterialTable
                                    columns={[
                                      {
                                        title: "Fecha",
                                        field: "fecha",
                                        render: (row) => (
                                          <span>
                                            {
                                              tranformDate(
                                                row["fecha"]
                                              ) /*formatting code here*/
                                            }
                                          </span>
                                        ),

                                        editable: "never",
                                      },
                                      {
                                        title: "Comprobante",
                                        field: "comprobante",
                                        editable: "never",
                                        cellStyle: {
                                          width: 200,
                                          minWidth: 200,
                                        },
                                        headerStyle: {
                                          width: 200,
                                          minWidth: 200,
                                        },
                                      },
                                      {
                                        title: "Importe",
                                        field: "importe",
                                        editable: "never",
                                        type: "numeric",
                                      },
                                      {
                                        title: "Imp. Pendiente",
                                        field: "pendiente",
                                        editable: "never",
                                        type: "numeric",
                                      },
                                      {
                                        title: "Imp. Cancelado",
                                        field: "cancelado",
                                        editable: "onUpdate",
                                        type: "currency",
                                      },
                                    ]}
                                    data={comprobantesOrdenPago}
                                    actions={
                                      !verOrdenPago
                                        ? [
                                            {
                                              icon: "360",
                                              tooltip: "Total",
                                              onClick: (event, rowData) => {
                                                new Promise(
                                                  (resolve, reject) => {
                                                    setTimeout(() => {
                                                      let local = comprobantesOrdenPago;
                                                      if (
                                                        Number(
                                                          local[
                                                            rowData.tableData.id
                                                          ].pendiente
                                                        ) <=
                                                        Number(valoresPendiente)
                                                      ) {
                                                        local[
                                                          rowData.tableData.id
                                                        ].cancelado =
                                                          local[
                                                            rowData.tableData.id
                                                          ].pendiente;
                                                        guardarComprobantesOrdenPago(
                                                          local
                                                        );
                                                      } else {
                                                        local[
                                                          rowData.tableData.id
                                                        ].cancelado = Number(
                                                          valoresPendiente
                                                        );
                                                        guardarComprobantesOrdenPago(
                                                          local
                                                        );
                                                      }

                                                      let impCancelado = 0;

                                                      for (
                                                        var i = 0;
                                                        i < local.length;
                                                        i++
                                                      ) {
                                                        impCancelado =
                                                          Number(impCancelado) +
                                                          Number(
                                                            local[i].cancelado
                                                          );
                                                      }

                                                      guardarValoresPendiente(
                                                        Number(
                                                          valoresOrdenPago.efectivo
                                                        ) +
                                                          Number(
                                                            valoresOrdenPago.cheque
                                                          ) +
                                                          Number(
                                                            valoresOrdenPago.chequePropio
                                                          ) +
                                                          Number(
                                                            valoresOrdenPago.banco
                                                          ) +
                                                          Number(
                                                            valoresOrdenPago.retencion
                                                          ) -
                                                          Number(impCancelado)
                                                      );

                                                      resolve();
                                                      setModalRefresh(true);
                                                      setModalRefresh(false);
                                                    }, 1000);
                                                  }
                                                );
                                              },
                                            },
                                          ]
                                        : null
                                    }
                                    options={{
                                      search: false,
                                      sorting: true,
                                      toolbar: false,
                                      showTitle: false,
                                      actionsColumnIndex: -1,
                                      cellStyle: { padding: "0.3em" },
                                      headerStyle: { padding: "0.3em" },
                                    }}
                                    localization={{
                                      header: {
                                        actions: "Acción",
                                      },
                                      body: {
                                        editTooltip: "Editar",
                                        editRow: {
                                          cancelTooltip: "Cancelar",
                                          saveTooltip: "Aceptar",
                                        },
                                        emptyDataSourceMessage:
                                          "No hay registros",
                                      },
                                      pagination: {
                                        labelRowsSelect: "filas",
                                      },
                                    }}
                                    editable={{
                                      ...(!verOrdenPago
                                        ? {
                                            isEditable: (rowData) => rowData, // only name(a) rows would be editable
                                            onRowAddCancelled: (rowData) =>
                                              console.log(
                                                "Row adding cancelled"
                                              ),
                                            onRowUpdateCancelled: (rowData) =>
                                              console.log(
                                                "Row editing cancelled"
                                              ),
                                            onRowUpdate: (newData, oldData) =>
                                              new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                  const dataUpdate = [
                                                    ...comprobantesOrdenPago,
                                                  ];
                                                  const index =
                                                    oldData.tableData.id;

                                                  // Actualizar states

                                                  let item = { ...newData };

                                                  const {
                                                    importe,
                                                    pendiente,
                                                    cancelado,
                                                  } = item;

                                                  let impCancelado = 0;
                                                  if (importe >= 0) {
                                                    console.log(">=0");
                                                    if (
                                                      Number(cancelado) < 0 ||
                                                      Number(cancelado) >
                                                        Number(pendiente) ||
                                                      Number(cancelado) >
                                                        Number(valoresPendiente)
                                                    ) {
                                                      impCancelado = 0;
                                                    } else {
                                                      impCancelado = cancelado;
                                                    }
                                                  } else {
                                                    console.log("<0");
                                                    if (
                                                      Number(cancelado) > 0 ||
                                                      Number(cancelado) <
                                                        Number(pendiente)
                                                    ) {
                                                      impCancelado = 0;
                                                    } else {
                                                      impCancelado = cancelado;
                                                    }
                                                  }

                                                  dataUpdate[
                                                    index
                                                  ].cancelado = parseFloat(
                                                    impCancelado
                                                  ).toFixed(2);
                                                  guardarComprobantesOrdenPago([
                                                    ...dataUpdate,
                                                  ]);

                                                  let local = comprobantesOrdenPago;
                                                  impCancelado = 0;

                                                  for (
                                                    var i = 0;
                                                    i < local.length;
                                                    i++
                                                  ) {
                                                    impCancelado =
                                                      Number(impCancelado) +
                                                      Number(
                                                        local[i].cancelado
                                                      );
                                                  }

                                                  guardarValoresPendiente(
                                                    Number(
                                                      valoresOrdenPago.efectivo
                                                    ) +
                                                      Number(
                                                        valoresOrdenPago.cheque
                                                      ) +
                                                      Number(
                                                        valoresOrdenPago.chequePropio
                                                      ) +
                                                      Number(
                                                        valoresOrdenPago.banco
                                                      ) +
                                                      Number(
                                                        valoresOrdenPago.retencion
                                                      ) -
                                                      Number(impCancelado)
                                                  );

                                                  resolve();
                                                }, 1000);
                                              }),
                                          }
                                        : {}),
                                    }}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-md-2">
                        <div className="form-group">
                          <div className="card-body text-black background mb-1">
                            {saldoCtaCte ? (
                              <Fragment>
                                <h5>
                                  Saldo actual
                                  <br />
                                  <span className="badge badge-secondary">
                                    {"$ " + parseFloat(saldoCtaCte).toFixed(2)}
                                  </span>
                                </h5>
                                <h5>
                                  Total
                                  <br />
                                  <span className="badge badge-secondary">
                                    {"$ " +
                                      parseFloat(Number(valoresTotal)).toFixed(
                                        2
                                      )}
                                  </span>
                                </h5>
                                <h5>
                                  Pendiente
                                  <br />
                                  <span className="badge badge-secondary">
                                    {"$ " +
                                      parseFloat(valoresPendiente).toFixed(2)}
                                  </span>
                                </h5>
                              </Fragment>
                            ) : null}

                            {!verOrdenPago ? (
                              <button
                                type="button"
                                className="btn btn-info btn-large mt-4"
                                onClick={generarOrdenPago}
                              >
                                Generar orden de pago
                              </button>
                            ) : (
                              <Fragment>
                                <Button
                                  label="Pdf"
                                  className="btn btn-info btn-large mt-4"
                                  icon="pi pi-file-pdf"
                                  onClick={verPdf}
                                />
                                <br />

                                <Button
                                  label="Descargar"
                                  className="btn btn-info btn-large mt-4"
                                  icon="pi pi-download"
                                  onClick={descargarPdf}
                                />
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>

                <Dialog
                  visible={modalProveedores}
                  style={fullStyles}
                  modal={true}
                  showHeader={false}
                  dismissableMask={true}
                  onHide={() => setModalProveedores(false)}
                >
                  <div style={{ maxWidth: "100%" }}>
                    {proveedores ? (
                      <MaterialTable
                        columns={[
                          {
                            title: "ID del proveedor",
                            field: "id",
                            editable: "never",
                          },
                          {
                            title: "Nombre/Razón social",
                            field: "nombre",
                            editable: "never",
                            cellStyle: {
                              width: 400,
                              minWidth: 400,
                            },
                            headerStyle: {
                              width: 400,
                              minWidth: 400,
                            },
                          },
                          {
                            title: "C.U.I.T.",
                            field: "cuit",
                            editable: "never",
                          },
                        ]}
                        data={proveedores}
                        title="Proveedores"
                        options={{
                          search: true,
                          sorting: true,
                          actionsColumnIndex: -1,
                        }}
                        onRowClick={(evt, selectedRow) => (
                          setSelectedRow(selectedRow.tableData.id),
                          agregarProveedor(selectedRow.tableData.id)
                        )}
                        localization={{
                          toolbar: {
                            searchPlaceholder: "Buscar",
                          },
                        }}
                      />
                    ) : null}
                  </div>
                </Dialog>

                <Dialog
                  visible={modalEfectivo}
                  style={fullStyles}
                  modal={true}
                  showHeader={true}
                  header="Efectivo"
                  onHide={() => setModalEfectivo(false)}
                >
                  <div style={{ maxWidth: "100%" }}>
                    {proveedor.id !== "" ? (
                      <Fragment>
                        <TextField
                          id="efectivo"
                          label="Importe"
                          style={{ color: "blue", width: "30%" }}
                          onKeyDown={handleEnter}
                          value={valoresOrdenPago.efectivo}
                          type="currency"
                          onChange={(e) =>
                            guardarValoresOrdenPago({
                              ...valoresOrdenPago,
                              efectivo: e.target.value,
                            })
                          }
                        />

                        <input
                          type="submit"
                          className="btn btn-background btn-block mt-4"
                          value="Guardar efectivo"
                          onClick={(e) => {
                            let local = comprobantesOrdenPago;
                            let impCancelado = 0;

                            for (var i = 0; i < local.length; i++) {
                              impCancelado =
                                Number(impCancelado) +
                                Number(local[i].cancelado);
                            }

                            guardarValoresPendiente(
                              Number(valoresOrdenPago.efectivo) +
                                Number(valoresOrdenPago.cheque) +
                                Number(valoresOrdenPago.chequePropio) +
                                Number(valoresOrdenPago.banco) +
                                Number(valoresOrdenPago.retencion) -
                                Number(impCancelado)
                            );
                            guardarValoresTotal(
                              Number(valoresOrdenPago.efectivo) +
                                Number(valoresOrdenPago.cheque) +
                                Number(valoresOrdenPago.chequePropio) +
                                Number(valoresOrdenPago.banco) +
                                Number(valoresOrdenPago.retencion)
                            );
                            setModalEfectivo(false);
                          }}
                        />
                      </Fragment>
                    ) : null}
                  </div>
                </Dialog>

                <Dialog
                  visible={modalCheque}
                  style={fullStyles}
                  modal={true}
                  showHeader={true}
                  header="Cheques Terceros"
                  onHide={() => {
                    let importeCheque = 0;
                    for (var i = 0; i < chequesTabla.length; i++) {
                      importeCheque =
                        Number(importeCheque) + Number(chequesTabla[i].importe);
                    }

                    guardarValoresOrdenPago({
                      ...valoresOrdenPago,
                      cheque: Number(importeCheque),
                    });

                    let local = comprobantesOrdenPago;
                    let impCancelado = 0;

                    for (var i = 0; i < local.length; i++) {
                      impCancelado =
                        Number(impCancelado) + Number(local[i].cancelado);
                    }

                    guardarValoresPendiente(
                      Number(valoresOrdenPago.efectivo) +
                        Number(importeCheque) +
                        Number(valoresOrdenPago.chequePropio) +
                        Number(valoresOrdenPago.banco) +
                        Number(valoresOrdenPago.retencion) -
                        Number(impCancelado)
                    );
                    guardarValoresTotal(
                      Number(valoresOrdenPago.efectivo) +
                        Number(importeCheque) +
                        Number(valoresOrdenPago.chequePropio) +
                        Number(valoresOrdenPago.banco) +
                        Number(valoresOrdenPago.retencion)
                    );

                    setModalCheque(false);
                  }}
                >
                  <div style={{ maxWidth: "100%" }}>
                    {proveedor.id !== "" ? (
                      <MaterialTable
                        columns={[
                          {
                            title: "Número",
                            field: "numero",
                            type: "text",
                          },
                          {
                            title: "Banco",
                            field: "banco.nombre",
                            type: "text",
                          },
                          {
                            title: "Emisión",
                            field: "emision",
                            type: "date",
                          },
                          {
                            title: "Vencimiento",
                            field: "vencimiento",
                            type: "date",
                          },
                          {
                            title: "Importe",
                            field: "importe",
                            type: "currency",
                          },
                          {
                            title: "Cliente",
                            field: "nombreCliente",
                            type: "text",
                          },
                        ]}
                        data={chequesTerceros}
                        title="Cheques Terceros"
                        options={{
                          search: true,
                          selection: true,
                          sorting: true,
                          actionsColumnIndex: -1,
                          toolbar: true,
                          showTitle: false,
                          cellStyle: { padding: "0.3em" },
                          headerStyle: { padding: "0.3em" },
                        }}
                        localization={{
                          toolbar: {
                            nRowsSelected: "{0} fila(s) seleccionada(s)",
                            dateTimePickerLocalization: esLocale,
                          },
                          pagination: {
                            labelRowsSelect: "filas",
                          },
                        }}
                        onSelectionChange={(rows) => {
                          guardarChequesTabla(rows);
                        }}
                      />
                    ) : null}
                  </div>
                </Dialog>

                <Dialog
                  visible={modalChequePropio}
                  style={fullStyles}
                  modal={true}
                  showHeader={true}
                  header="Cheques Propios"
                  onHide={() => {
                    let importeCheque = 0;
                    for (var i = 0; i < chequesTablaPropios.length; i++) {
                      importeCheque =
                        Number(importeCheque) +
                        Number(chequesTablaPropios[i].importe);
                    }

                    guardarValoresOrdenPago({
                      ...valoresOrdenPago,
                      chequePropio: Number(importeCheque),
                    });

                    let local = comprobantesOrdenPago;
                    let impCancelado = 0;

                    for (var i = 0; i < local.length; i++) {
                      impCancelado =
                        Number(impCancelado) + Number(local[i].cancelado);
                    }

                    guardarValoresPendiente(
                      Number(valoresOrdenPago.efectivo) +
                        Number(valoresOrdenPago.cheque) +
                        Number(importeCheque) +
                        Number(valoresOrdenPago.banco) +
                        Number(valoresOrdenPago.retencion) -
                        Number(impCancelado)
                    );
                    guardarValoresTotal(
                      Number(valoresOrdenPago.efectivo) +
                        Number(valoresOrdenPago.cheque) +
                        Number(importeCheque) +
                        Number(valoresOrdenPago.banco) +
                        Number(valoresOrdenPago.retencion)
                    );
                    setModalChequePropio(false);
                  }}
                >
                  <div style={{ maxWidth: "100%" }}>
                    {proveedor.id !== "" ? (
                      <MaterialTable
                        columns={[
                          {
                            title: "Número",
                            field: "numero",
                            type: "text",
                          },
                          {
                            title: "Banco",
                            field: "banco.nombre",
                            type: "text",
                          },
                          {
                            title: "Emisión",
                            field: "emision",
                            type: "date",
                          },
                          {
                            title: "Vencimiento",
                            field: "vencimiento",
                            type: "date",
                          },
                          {
                            title: "Importe",
                            field: "importe",
                            type: "currency",
                          },
                        ]}
                        data={chequesPropios}
                        title="Cheques Propios"
                        options={{
                          search: true,
                          selection: true,
                          sorting: true,
                          actionsColumnIndex: -1,
                          toolbar: true,
                          showTitle: false,
                          cellStyle: { padding: "0.3em" },
                          headerStyle: { padding: "0.3em" },
                        }}
                        localization={{
                          toolbar: {
                            nRowsSelected: "{0} fila(s) seleccionada(s)",
                            dateTimePickerLocalization: esLocale,
                          },
                          pagination: {
                            labelRowsSelect: "filas",
                          },
                        }}
                        onSelectionChange={(rows) => {
                          guardarChequesTablaPropios(rows);
                        }}
                      />
                    ) : null}
                  </div>
                </Dialog>

                <Dialog
                  visible={modalBanco}
                  style={fullStyles}
                  modal={true}
                  showHeader={true}
                  header="Depósito bancario"
                  onHide={() => setModalBanco(false)}
                >
                  <div style={{ maxWidth: "100%" }}>
                    {proveedor.id !== "" ? (
                      <Fragment>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={esLocale}
                        >
                          <KeyboardDatePicker
                            clearable
                            label="Fecha"
                            value={deposito.fecha}
                            onChange={(date) =>
                              guardarDeposito({
                                ...deposito,
                                fecha: date,
                              })
                            }
                            minDate={new Date(-10)}
                            format="dd/MM/yyyy"
                            style={{ color: "blue" }}
                            onKeyDown={handleEnter}
                          />
                        </MuiPickersUtilsProvider>

                        <div className="form-group">
                          <span className="p-float-label">
                            <label
                              htmlFor="cuenta"
                              style={{
                                color: "black",
                                fontSize: "0.8em",
                                marginTop: "1em",
                              }}
                            >
                              Cuenta
                            </label>
                          </span>
                          <Dropdown
                            id="cuenta"
                            value={deposito.cuenta}
                            style={{ width: "100%" }}
                            className="mt-4"
                            options={cuentas}
                            onChange={(e) =>
                              guardarDeposito({
                                ...deposito,
                                cuenta: e.target.value,
                              })
                            }
                            filter={true}
                            placeholder="Seleccioná una cuenta"
                          />
                        </div>

                        <TextField
                          id="importe"
                          label="Importe"
                          style={{ color: "blue", width: "30%" }}
                          onKeyDown={handleEnter}
                          value={valoresOrdenPago.banco}
                          type="currency"
                          onChange={(e) =>
                            guardarValoresOrdenPago({
                              ...valoresOrdenPago,
                              banco: e.target.value,
                            })
                          }
                        />

                        <input
                          type="submit"
                          className="btn btn-background btn-block mt-4"
                          value="Guardar depósito bancario"
                          onClick={(e) => {
                            let local = comprobantesOrdenPago;
                            let impCancelado = 0;

                            for (var i = 0; i < local.length; i++) {
                              impCancelado =
                                Number(impCancelado) +
                                Number(local[i].cancelado);
                            }

                            guardarValoresPendiente(
                              Number(valoresOrdenPago.efectivo) +
                                Number(valoresOrdenPago.cheque) +
                                Number(valoresOrdenPago.chequePropio) +
                                Number(valoresOrdenPago.banco) +
                                Number(valoresOrdenPago.retencion) -
                                Number(impCancelado)
                            );
                            guardarValoresTotal(
                              Number(valoresOrdenPago.efectivo) +
                                Number(valoresOrdenPago.cheque) +
                                Number(valoresOrdenPago.chequePropio) +
                                Number(valoresOrdenPago.banco) +
                                Number(valoresOrdenPago.retencion)
                            );

                            setModalBanco(false);
                          }}
                        />
                      </Fragment>
                    ) : null}
                  </div>
                </Dialog>

                <Dialog
                  visible={modalRetencion}
                  style={fullStyles}
                  modal={true}
                  showHeader={true}
                  header="Retenciones"
                  onHide={() => setModalRetencion(false)}
                >
                  <div style={{ maxWidth: "100%" }}>
                    {proveedor.id !== "" ? (
                      <MaterialTable
                        columns={[
                          {
                            title: "Sucursal",
                            field: "sucursal",
                            editable: "onUpdate",
                            type: "numeric",
                          },
                          {
                            title: "Número",
                            field: "numero",
                            editable: "onUpdate",
                            type: "numeric",
                          },
                          {
                            title: "Tipo",
                            field: "retencionId",
                            editable: "onUpdate",
                            type: "numeric",
                            lookup: { ...tiposRetenciones },
                          },
                          {
                            title: "Fecha",
                            field: "fecha",
                            editable: "onUpdate",
                            type: "date",
                          },
                          {
                            title: "Importe",
                            field: "importe",
                            editable: "onUpdate",
                            type: "currency",
                          },
                        ]}
                        data={retencionesTabla}
                        title="Retenciones"
                        actions={[
                          {
                            icon: "add_box",
                            tooltip: "Agregar",
                            isFreeAction: true,
                            onClick: (event, rowData) =>
                              guardarRetencionesTabla((retencionesTabla) => [
                                ...retencionesTabla,
                                {
                                  fecha: "",
                                  retencionId: "",
                                  sucursal: "",
                                  numero: "",
                                  importe: "",
                                },
                              ]),
                          },
                        ]}
                        options={{
                          search: false,
                          sorting: false,
                          actionsColumnIndex: -1,
                          toolbar: true,
                          showTitle: false,
                          cellStyle: { padding: "0.3em" },
                          headerStyle: { padding: "0.3em" },
                        }}
                        localization={{
                          header: {
                            actions: "Acción",
                          },
                          body: {
                            editTooltip: "Editar",
                            editRow: {
                              cancelTooltip: "Cancelar",
                              saveTooltip: "Aceptar",
                              deleteText: "Esta seguro de borrar ?",
                            },
                            addTooltip: "Agregar",
                            deleteTooltip: "Borrar",
                            emptyDataSourceMessage: "No hay registros",
                            dateTimePickerLocalization: esLocale,
                          },
                          pagination: {
                            labelRowsSelect: "filas",
                          },
                        }}
                        editable={{
                          ...(!verOrdenPago
                            ? {
                                isEditable: (rowData) => rowData, // only name(a) rows would be editable
                                onRowAddCancelled: (rowData) =>
                                  console.log("Row adding cancelled"),
                                onRowUpdateCancelled: (rowData) =>
                                  console.log("Row editing cancelled"),
                                onRowUpdate: (newData, oldData) =>
                                  new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                      const dataUpdate = [...retencionesTabla];
                                      const index = oldData.tableData.id;

                                      // Actualizar states

                                      let item = { ...newData };

                                      const {
                                        fecha,
                                        retencionId,
                                        sucursal,
                                        numero,
                                        importe,
                                      } = item;

                                      let band = true;

                                      if (fecha === "") {
                                        band = false;
                                        growl.show({
                                          severity: "error",
                                          summary: "Ordenes de pago",
                                          detail:
                                            "La fecha de la retención es nesesaria.",
                                        });
                                      }

                                      if (retencionId === "") {
                                        band = false;
                                        growl.show({
                                          severity: "error",
                                          summary: "Ordenes de pago",
                                          detail:
                                            "El tipo de la retención es necesario.",
                                        });
                                      }

                                      if (sucursal === "") {
                                        band = false;
                                        growl.show({
                                          severity: "error",
                                          summary: "Ordenes de pago",
                                          detail:
                                            "La sucursal de la retención es necesaria.",
                                        });
                                      }

                                      if (numero === "") {
                                        band = false;
                                        growl.show({
                                          severity: "error",
                                          summary: "Ordenes de pago",
                                          detail:
                                            "El número de la retención es necesario.",
                                        });
                                      }

                                      if (importe === "" || importe === "0") {
                                        band = false;
                                        growl.show({
                                          severity: "error",
                                          summary: "Ordenes de pago",
                                          detail:
                                            "El importe de la retención es nesesario.",
                                        });
                                      }

                                      if (band) {
                                        dataUpdate[index].fecha = fecha;
                                        dataUpdate[
                                          index
                                        ].retencionId = retencionId;
                                        dataUpdate[index].sucursal = sucursal;
                                        dataUpdate[index].numero = numero;
                                        dataUpdate[index].importe = importe;

                                        let impRetenciones = 0;
                                        for (
                                          var i = 0;
                                          i < dataUpdate.length;
                                          i++
                                        ) {
                                          impRetenciones =
                                            Number(impRetenciones) +
                                            Number(dataUpdate[i].importe);
                                        }

                                        guardarRetencionesTabla([
                                          ...dataUpdate,
                                        ]);
                                        guardarValoresOrdenPago({
                                          ...valoresOrdenPago,
                                          retencion: impRetenciones,
                                        });

                                        let local = comprobantesOrdenPago;
                                        let impCancelado = 0;

                                        for (var i = 0; i < local.length; i++) {
                                          impCancelado =
                                            Number(impCancelado) +
                                            Number(local[i].cancelado);
                                        }

                                        guardarValoresPendiente(
                                          Number(valoresOrdenPago.efectivo) +
                                            Number(valoresOrdenPago.cheque) +
                                            Number(
                                              valoresOrdenPago.chequePropio
                                            ) +
                                            Number(valoresOrdenPago.banco) +
                                            Number(impRetenciones) -
                                            Number(impCancelado)
                                        );

                                        guardarValoresTotal(
                                          Number(valoresOrdenPago.efectivo) +
                                            Number(valoresOrdenPago.cheque) +
                                            Number(
                                              valoresOrdenPago.chequePropio
                                            ) +
                                            Number(valoresOrdenPago.banco) +
                                            Number(impRetenciones)
                                        );
                                      }
                                      resolve();
                                    }, 1000);
                                  }),

                                onRowDelete: (oldData) =>
                                  new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                      const dataDelete = [...retencionesTabla];
                                      const index = oldData.tableData.id;
                                      dataDelete.splice(index, 1);

                                      let impRetenciones = 0;
                                      for (
                                        var i = 0;
                                        i < dataDelete.length;
                                        i++
                                      ) {
                                        impRetenciones =
                                          Number(impRetenciones) +
                                          Number(dataDelete[i].importe);
                                      }
                                      guardarRetencionesTabla([...dataDelete]);
                                      guardarValoresOrdenPago({
                                        ...valoresOrdenPago,
                                        retencion: impRetenciones,
                                      });

                                      let local = comprobantesOrdenPago;
                                      let impCancelado = 0;

                                      for (var i = 0; i < local.length; i++) {
                                        impCancelado =
                                          Number(impCancelado) +
                                          Number(local[i].cancelado);
                                      }

                                      guardarValoresPendiente(
                                        Number(valoresOrdenPago.efectivo) +
                                          Number(valoresOrdenPago.cheque) +
                                          Number(
                                            valoresOrdenPago.chequePropio
                                          ) +
                                          Number(valoresOrdenPago.banco) +
                                          Number(impRetenciones) -
                                          Number(impCancelado)
                                      );
                                      guardarValoresTotal(
                                        Number(valoresOrdenPago.efectivo) +
                                          Number(valoresOrdenPago.cheque) +
                                          Number(
                                            valoresOrdenPago.chequePropio
                                          ) +
                                          Number(valoresOrdenPago.banco) +
                                          Number(impRetenciones)
                                      );

                                      resolve();
                                    }, 1000);
                                  }),
                              }
                            : {}),
                        }}
                      />
                    ) : null}
                  </div>
                </Dialog>

                <Dialog
                  visible={modalRefresh}
                  style={{ width: "1em" }}
                  modal={true}
                  showHeader={true}
                  header="refresh"
                  onHide={() => setModalRefresh(false)}
                ></Dialog>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default OrdenPago;
