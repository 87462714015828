import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Link, Redirect } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ArchivoContext } from "../../../context/ArchivoContext";
import { FondoContext } from "../../../context/FondoContext";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { Growl } from "primereact/growl";
import Spinner from "../../layout/Spinner";
import { NoSsr } from "@material-ui/core";

function CarteraChequesPropios(props) {
  // extraer los valores del context

  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    cuentas,
    proveedores,
    obtenerCuentasSelect,
    obtenerProveedoresSelect,
  } = archivoContext;

  const fondoContext = useContext(FondoContext);
  const {
    chequesPropios,
    totalCheques,
    chequeTercero,
    totalIngresos,
    totalEgresos,
    saldoAnterior,
    dateCaja,
    rangeCaja,
    radioCaja,
    agrupar,
    field,
    sort,
    todas,
    movimientoBanco,
    movimientoCaja,
    chequesSelected,
    obtenerMovimientosCaja,
    obtenerCarteraChequesPropios,
    guardarChequesTerceros,
    tranformDate,
    guardarDateCaja,
    guardarRangeCaja,
    guardarRadioCaja,
    setAgrupar,
    setField,
    setSort,
    setTodas,
    guardarMovimientoBanco,
    guardarMovimientoCaja,
    crearMovimientoBanco,
    crearMovimientoCaja,
    cambiarEstadoChequeCartera,
    guardarChequesSelected,
    eliminarChequeTercero,
  } = fondoContext;

  const [globalFilter, guardarGlobalFilter] = useState(null);
  const [modalDeposito, setModalDeposito] = useState(false);
  const [nombreCuenta, guardarNombreCuenta] = useState(null);
  const [modalVentanilla, setModalVentanilla] = useState(false);
  const [modalCaja, setModalCaja] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [idMovimiento, setIdMovimiento] = useState(null);

  let dt = useRef();
  let growl = useRef();

  useEffect(() => {
    //guardarChequesTerceros(null);
    obtenerCarteraChequesPropios({
      idEmpresa: empresaNavbar,
      todas,
    });
    obtenerCuentasSelect();
    obtenerProveedoresSelect();
    guardarMovimientoBanco({
      fecha: new Date(),
      cuentaId: 0,
      detalle: "",
      importe: "",
      ingreso: "",
      egreso: "",
      empresaId: empresaNavbar,
      ordenPagoId: 0,
      proveedorId: 0,
      nombreProveedor: "",
      estado: "PENDIENTE",
    });
    guardarMovimientoCaja({
      fecha: new Date(),
      conceptoId: 0,
      detalleConcepto: "",
      detalle: "",
      importe: "",
      ingreso: "",
      egreso: "",
      empresaId: empresaNavbar,
      ordenPagoId: 0,
    });
    guardarChequesSelected(null);
  }, []);

  const cerrar = () => {
    props.history.push("/menu");
  };

  const onChangeCuenta = (e) => {
    for (var i = 0; i < cuentas.length; i++) {
      if (cuentas[i].value === e.target.value) {
        guardarMovimientoBanco({
          ...movimientoBanco,
          cuentaId: e.target.value,
        });
        guardarNombreCuenta(cuentas[i].label);
      }
    }
  };

  const onChangeProveedor = (e) => {
    for (var i = 0; i < proveedores.length; i++) {
      if (proveedores[i].value === e.target.value) {
        guardarMovimientoBanco({
          ...movimientoBanco,
          proveedorId: e.target.value,
        });
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar

    if (movimientoBanco.fecha === "") {
      growl.show({
        severity: "error",
        summary: "Movimiento de banco",
        detail: "La fecha es iválida.",
      });
      return;
    }

    if (movimientoBanco.importe === "" || movimientoBanco.importe === 0) {
      growl.show({
        severity: "error",
        summary: "Movimiento de banco",
        detail: "El importe es obligatorio.",
      });
      return;
    }

    if (movimientoBanco.cuentaId === 0) {
      growl.show({
        severity: "error",
        summary: "Movimiento de banco",
        detail: "La cuenta es obligatoria.",
      });
      return;
    }

    if (movimientoBanco.empresaId === 0) {
      growl.show({
        severity: "error",
        summary: "Movimiento de banco",
        detail: "La empresa es obligatoria.",
      });
      return;
    }

    guardarChequesSelected(null);
    setModalDeposito(false);
    crearMovimientoBanco({ movimientoBanco, chequesSelected, nombreCuenta });

    setTimeout(() => {
      obtenerCarteraChequesPropios({
        idEmpresa: empresaNavbar,
        todas,
      });
    }, 2000);
  };

  let es = {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
    dateFormat: "dd/mm/yy",
    weekHeader: "Sm",
  };

  let header = (
    <Fragment>
      <button
        type="button"
        className="close xclose"
        aria-label="Close"
        onClick={cerrar}
        style={{ color: "Blue" }}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <h3 className="fwhite titulos text-center py=4">
        Cartera de cheques de propios
      </h3>
      <hr className="linea-negra" />
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => guardarGlobalFilter(e.target.value)}
          placeholder="Buscador"
        />
        {/*
        <button className="btn btn-success ml-1" onClick={buscarDatos}>
          Buscar
        </button>
        */}
        <Link to={`chequepropio/0/0`}>
          <button type="button" className="btn btn-info btn-large ml-4">
            <i className="fa fa-plus" aria-hidden="true"></i> Nuevo
          </button>
        </Link>

        <button
          type="button"
          className="btn btn-success btn-large ml-4"
          onClick={(e) => {
            if (chequesSelected) {
              let importe = 0;
              let detalle = "";
              for (var i = 0; i < chequesSelected.length; i++) {
                importe = Number(importe) + Number(chequesSelected[i].importe);
                if (i === 0) {
                  detalle = "Cheque Nro: " + chequesSelected[i].numero;
                } else {
                  detalle = detalle + "/" + chequesSelected[i].numero;
                }
              }
              guardarMovimientoBanco({
                ...movimientoBanco,
                importe: importe,
                ingreso: importe,
                detalle: detalle,
              });
              setModalDeposito(true);
            }
          }}
        >
          <i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Depositar
        </button>

        <button
          type="button"
          className="btn btn-success btn-large ml-4"
          onClick={(e) => {
            if (chequesSelected) {
              let importe = 0;
              let detalle = "";
              for (var i = 0; i < chequesSelected.length; i++) {
                importe = Number(importe) + Number(chequesSelected[i].importe);
                if (i === 0) {
                  detalle = "Cheque Nro: " + chequesSelected[i].numero;
                } else {
                  detalle = detalle + "/" + chequesSelected[i].numero;
                }
              }
              guardarMovimientoCaja({
                ...movimientoCaja,
                importe: importe,
                ingreso: importe,
                detalle: detalle,
                conceptoId: 5, // Cobro por ventanilla
                detalleConcepto: "Cobro por ventanilla",
              });

              guardarNombreCuenta("Ventanilla");
              setModalVentanilla(true);
            }
          }}
        >
          <i class="fa fa-arrow-circle-down" aria-hidden="true"></i> Cobrar
        </button>

        <div className="row">
          <div className="custom-control custom-switch ml-3 mt-4">
            <input
              type="checkbox"
              className="custom-control-input"
              id="todas"
              checked={todas}
              onClick={(e) => {
                setTodas(e.target.checked);
                obtenerCarteraChequesPropios({
                  idEmpresa: empresaNavbar,
                  todas: e.target.checked,
                });
              }}
            />
            <label className="custom-control-label" for="todas">
              Todas
            </label>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const rowNoalaOrden = (rowData, column) => {
    if (rowData.noalaOrden === true || rowData.noalaOrden === "Si") {
      return (rowData.noalaOrden = "Si");
    }
    if (
      rowData.noalaOrden === null ||
      rowData.noalaOrden === false ||
      rowData.noalaOrden === "No"
    ) {
      return (rowData.noalaOrden = "No");
    }
  };

  const rowFecha = (rowData, column) => {
    return tranformDate(rowData.fecha);
  };

  const rowImporte = (rowData, column) => {
    return parseFloat(rowData.importe).toFixed(2);
  };

  const botones = (rowData, column) => {
    return (
      <div className="row">
        <div className="col-xs-6 col-md-6" style={{ paddingRight: "0" }}>
          <Link to={`chequetercero/${rowData.id}/0`}>
            <button
              type="button"
              className="btn btn-success btn-block"
              style={{ fontSize: "small" }}
            >
              Ver
            </button>
          </Link>
        </div>
        <div className="col-xs-6 col-md-6" style={{ paddingLeft: "0" }}>
          <button
            type="button"
            className="btn btn-danger btn-block"
            disabled={rowData.ordenPagoId !== 0 || rowData.estado !== "CARTERA"}
            style={{ fontSize: "small" }}
            onClick={(e) => {
              setModalEliminar(true);
              setIdMovimiento(rowData.id);
            }}
          >
            Eliminar
          </button>
        </div>
      </div>
    );
  };

  let rCount = chequesPropios ? chequesPropios.length : 0;
  let footer = "Total " + rCount + " movimientos";

  ////

  const fullStyles =
    window.screen.availWidth < 780 ? { width: "90vw" } : { width: "50vw" };

  const largeStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "42ch" };

  const mediaStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "36ch" };

  const shortStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "30ch" };

  console.log(chequesPropios);

  return (
    <Fragment>
      {!movimientoBanco ? (
        <Spinner />
      ) : (
        <div className="background-body">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-md-12 mt-3">
              <div className="card-body text-black background mb-3">
                <div className="mlpc-table">
                  <Growl ref={(el) => (growl = el)} />
                  <DataTable
                    ref={(el) => {
                      dt = el;
                    }}
                    value={chequesPropios}
                    paginator={true}
                    rows={10}
                    header={header}
                    footer={footer}
                    globalFilter={globalFilter}
                    scrollable={false}
                    responsive={true}
                    resizableColumns={true}
                    selection={chequesSelected}
                    onSelectionChange={(e) => guardarChequesSelected(e.value)}
                    selectionMode="multiple"
                    dataKey="id"
                  >
                    <Column field="id" header="ID de Cheque" sortable={true} />
                    <Column
                      field="fecha"
                      header="Fecha"
                      sortable={true}
                      body={rowFecha}
                    />
                    <Column
                      field="banco.nombre"
                      header="Banco"
                      sortable={true}
                    />
                    <Column field="numero" header="Número" sortable={true} />
                    <Column
                      field="emision"
                      header="Emisión"
                      sortable={true}
                      body={rowFecha}
                    />
                    <Column
                      field="vencimiento"
                      header="Vencimiento"
                      sortable={true}
                      body={rowFecha}
                    />
                    <Column
                      field="importe"
                      header="Importe"
                      sortable={true}
                      style={{ textAlign: "right" }}
                      body={rowImporte}
                    />
                    <Column
                      field="nombreProveedor"
                      header="Proveedor"
                      sortable={true}
                    />
                    <Column field="detalle" header="Detalle" sortable={true} />
                    <Column
                      field="noalaOrden"
                      header="No a la orden"
                      sortable={true}
                      body={rowNoalaOrden}
                    />
                    <Column
                      field="botones"
                      header=""
                      style={{ width: "10%" }}
                      body={botones}
                    />
                  </DataTable>{" "}
                </div>

                <h3>
                  <div style={{ textAlign: "right" }}>
                    Total cheques
                    <span className="badge badge-secondary ml-2 mr-2">
                      {"$ " + parseFloat(totalCheques).toFixed(2)}
                    </span>
                  </div>
                </h3>

                <Dialog
                  visible={modalDeposito}
                  header="Depósito de cheques de terceros"
                  modal={true}
                  style={largeStyles}
                  dismissableMask={true}
                  onHide={() => setModalDeposito(false)}
                >
                  <div style={{ maxWidth: "100%" }}>
                    <div className="form-group">
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={esLocale}
                      >
                        <KeyboardDatePicker
                          clearable
                          label="Fecha"
                          value={movimientoBanco.fecha}
                          onChange={(date) =>
                            guardarMovimientoBanco({
                              ...movimientoBanco,
                              fecha: date,
                            })
                          }
                          format="dd/MM/yyyy"
                          style={{ color: "blue", width: "70%" }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <div className="form-group">
                      <span className="p-float-label">
                        <label
                          htmlFor="cuenta"
                          style={{ color: "black", fontSize: "0.8em" }}
                        >
                          Cuenta
                        </label>
                      </span>

                      <Dropdown
                        id="cuenta"
                        name="cuenta"
                        value={movimientoBanco.cuentaId}
                        style={{ width: "90%" }}
                        className="mt-3"
                        options={cuentas}
                        onChange={onChangeCuenta}
                        filter={true}
                        placeholder="Seleccioná una cuenta."
                      />
                    </div>

                    <div className="form-group">
                      <span className="p-float-label">
                        <InputText
                          id="detalle"
                          type="text"
                          className="form-control mt-4"
                          value={movimientoBanco.detalle}
                          disabled={true}
                          style={{ width: "90%" }}
                          onChange={(e) =>
                            guardarMovimientoBanco({
                              ...movimientoBanco,
                              detalle: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="detalle" style={{ color: "black" }}>
                          Detalle
                        </label>
                      </span>
                    </div>

                    <div className="form-group">
                      <span className="p-float-label">
                        <InputText
                          id="importe"
                          type="number"
                          className="form-control mt-4"
                          style={{ width: "60%" }}
                          value={movimientoBanco.importe}
                          disabled={true}
                          onChange={(e) =>
                            guardarMovimientoBanco({
                              ...movimientoBanco,
                              importe: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="ajuste" style={{ color: "black" }}>
                          Importe
                        </label>
                      </span>
                    </div>

                    <button
                      className="btn btn-success btn-block"
                      onClick={onSubmit}
                    >
                      Guardar depósito
                    </button>
                  </div>
                </Dialog>
                <Dialog
                  header="Cobranza cheque por ventanilla"
                  visible={modalVentanilla}
                  modal={true}
                  onHide={() => setModalVentanilla(false)}
                >
                  <div className="confirmation-content">
                    <i
                      className="question-circle mt-2 mr-3"
                      style={{ fontSize: "2rem" }}
                    />
                    <span> Confirma la cobranza por ventanilla ?</span>
                  </div>
                  <br />

                  <div style={{ maxWidth: "100%" }}>
                    <div className="col-xs-10 col-md-12 text-center">
                      <Button
                        label="No"
                        icon="pi pi-times"
                        onClick={() => setModalVentanilla(false)}
                        className="p-button-danger linea mr-2"
                      />
                      <Button
                        label="Si"
                        icon="pi pi-check"
                        onClick={() => {
                          setModalVentanilla(false);
                          setModalCaja(true);
                        }}
                        className="p-button-success linea ml-2"
                        autoFocus
                      />
                    </div>
                  </div>
                </Dialog>

                <Dialog
                  header="Movimiento de caja"
                  visible={modalCaja}
                  modal={true}
                  onHide={() => setModalCaja(false)}
                >
                  <div className="confirmation-content">
                    <i
                      className="question-circle mt-2 mr-3"
                      style={{ fontSize: "2rem" }}
                    />
                    <span> Confirma movimiento de caja ?</span>
                  </div>
                  <br />

                  <div style={{ maxWidth: "100%" }}>
                    <div className="col-xs-10 col-md-12 text-center">
                      <Button
                        label="No"
                        icon="pi pi-times"
                        onClick={() => {
                          cambiarEstadoChequeCartera({
                            chequesSelected,
                            nombreCuenta,
                          });
                          setModalCaja(false);
                          setTimeout(() => {
                            obtenerCarteraChequesPropios({
                              idEmpresa: empresaNavbar,
                              todas,
                            });
                          }, 2000);
                        }}
                        className="p-button-danger linea mr-2"
                      />
                      <Button
                        label="Si"
                        icon="pi pi-check"
                        onClick={() => {
                          crearMovimientoCaja({
                            movimientoCaja,
                            chequesSelected,
                            nombreCuenta,
                          });
                          setModalCaja(false);
                          setTimeout(() => {
                            obtenerCarteraChequesPropios({
                              idEmpresa: empresaNavbar,
                              todas,
                            });
                          }, 2000);
                        }}
                        className="p-button-success linea ml-2"
                        autoFocus
                      />
                    </div>
                  </div>
                </Dialog>

                <Dialog
                  header="Cartera cheque terceros"
                  visible={modalEliminar}
                  modal={true}
                  onHide={() => setModalEliminar(false)}
                >
                  <div className="confirmation-content">
                    <i
                      className="pi pi-exclamation-triangle mt-2 mr-3"
                      style={{ fontSize: "2rem" }}
                    />
                    <span>
                      {" "}
                      Esta seguro de eliminar el cheque {idMovimiento} ?
                    </span>
                  </div>
                  <br />

                  <div style={{ maxWidth: "100%" }}>
                    <div className="col-xs-10 col-md-12 text-center">
                      <Button
                        label="No"
                        icon="pi pi-times"
                        onClick={() => setModalEliminar(false)}
                        className="p-button-danger linea mr-2"
                      />
                      <Button
                        label="Si"
                        icon="pi pi-check"
                        onClick={() => {
                          setModalEliminar(false);
                          eliminarChequeTercero({ idMovimiento });
                          setTimeout(() => {
                            obtenerCarteraChequesPropios({
                              idEmpresa: empresaNavbar,
                              todas,
                            });
                          }, 2000);
                        }}
                        className="p-button-success linea ml-2"
                        autoFocus
                      />
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default CarteraChequesPropios;
