import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";

import { Redirect, Link } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { VentaContext } from "../../../context/VentaContext";
import Spinner from "../../layout/Spinner";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "primereact/button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ArchivoContext } from "../../../context/ArchivoContext";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import MaterialTable from "material-table";
import Icon from "@material-ui/core/Icon";
import { Dialog } from "primereact/dialog";
import jsPDF from "jspdf";

function Comprobante(props) {
  // extraer los valores del context
  const archivoContext = useContext(ArchivoContext);
  const {
    empresaNavbar,
    condicionesIva,
    tiposComprobantes,
    obtenerEmpresa,
    obtenerCondicionesIvaSelect,
    obtenerTiposComprobantesEmpresaSelect,
  } = archivoContext;

  const ventaContext = useContext(VentaContext);
  const {
    comprobante,
    cliente,
    obtenerComprobante,
    obtenerClientexIdComprobante,
    guardarVerComprobante,
    verComprobante,
  } = ventaContext;

  const [delay, setDelay] = useState(false);

  let growl = useRef();

  const idComprobante = props.match.params.id;

  useEffect(() => {
    if (empresaNavbar) {
      if (idComprobante !== "0") {
        obtenerComprobante({ idComprobante });
        obtenerClientexIdComprobante({ idComprobante });
        obtenerTiposComprobantesEmpresaSelect({ idEmpresa: empresaNavbar });
        obtenerEmpresa({ idEmpresa: empresaNavbar });
        obtenerCondicionesIvaSelect();
        guardarVerComprobante({
          ...verComprobante,
          estado: true,
        });
        setTimeout(function () {
          setDelay(true);
        }, 1000);
      }
    }
  }, []);

  const cerrar = () => {
    guardarVerComprobante(null);
    props.history.push("/comprobantes");
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        marginLeft: theme.spacing(2),
        width: "20ch",
      },
    },
  }));

  const classes = useStyles();

  const largeStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "42ch" };

  const mediaStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "36ch" };

  const shortStyles =
    window.screen.availWidth < 780 ? { width: "30ch" } : { width: "30ch" };

  const verPdf = () => {
    var doc = new jsPDF();
    doc.setProperties({
      title: "new Report",
    });

    doc.text("Hello world!", 10, 10);
    doc.output("dataurlnewwindow");
    //doc.save("a4.pdf");
  };

  const descargarPdf = () => {
    var doc = new jsPDF();
    doc.setProperties({
      title: "new Report",
    });

    doc.text("Hello world!", 10, 10);
    //doc.output('dataurlnewwindow');
    doc.save("a4.pdf");
  };

  //console.log(cliente);
  //console.log(comprobante);

  return (
    <Fragment>
      {!comprobante ||
      comprobante.numero === 0 ||
      comprobante.fecha === "" ||
      !delay ? (
        <Spinner />
      ) : (
        <div className="background-fc">
          <div className="row justify-content-center">
            <div className="col-xs-10 col-md-8 mt-3">
              <div className="card-body text-black background mb-3">
                <button
                  type="button"
                  className="close xclose"
                  aria-label="Close"
                  onClick={cerrar}
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <Growl ref={(el) => (growl = el)} />

                <Tabs>
                  <TabList>
                    <Tab style={{ background: "#28BAEC" }}>
                      Datos del ajuste
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                          <div className="card-body text-black background mb-3">
                            <form className={classes.root}>
                              <TextField
                                id="idcliente"
                                label="ID Cliente"
                                style={{ color: "blue" }}
                                value={cliente.id}
                                disabled={true}
                              />
                              <TextField
                                id="cuit"
                                label="C.U.I.T."
                                style={{ color: "blue" }}
                                value={comprobante.cuitCliente}
                                disabled={true}
                              />

                              <TextField
                                id="nombre"
                                label="Nombre"
                                value={cliente.nombre}
                                disabled={true}
                                style={largeStyles}
                              />

                              <TextField
                                id="condicioniva"
                                select
                                label="Condición de I.V.A."
                                value={cliente.idCondicionIva}
                                disabled={true}
                                style={shortStyles}
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {condicionesIva
                                  ? condicionesIva.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))
                                  : null}
                              </TextField>

                              <TextField
                                id="domicilioCompleto"
                                label="Dirección"
                                style={mediaStyles}
                                value={cliente.domicilio}
                                disabled={true}
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                          <div className="card-body text-black background mb-3">
                            <form className={classes.root}>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={esLocale}
                              >
                                <KeyboardDatePicker
                                  clearable
                                  label="Fecha"
                                  value={comprobante.fecha}
                                  disabled={true}
                                  format="dd/MM/yyyy"
                                  style={{ color: "blue" }}
                                />
                              </MuiPickersUtilsProvider>
                              <TextField
                                id="comprobante"
                                select
                                label="Comprobante"
                                value={comprobante.tipoComprobanteId}
                                disabled={true}
                                style={shortStyles}
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {tiposComprobantes
                                  ? tiposComprobantes.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))
                                  : null}
                              </TextField>
                              <TextField
                                id="puntoventa"
                                disabled
                                label="Punto de venta"
                                style={{ color: "blue" }}
                                value={("0000" + comprobante.puntoVenta).slice(
                                  -4
                                )}
                              />

                              <TextField
                                id="numero"
                                disabled
                                label="Número"
                                style={{ color: "blue", width: "10ch" }}
                                value={comprobante.numero}
                              />

                              <TextField
                                id="ajuste"
                                disabled
                                label="Ajuste (a favor del cliente en negativo)"
                                style={largeStyles}
                                value={comprobante.total}
                              />

                              <TextField
                                id="comentarios"
                                disabled
                                label="Detalle"
                                style={largeStyles}
                                value={comprobante.comentarios}
                              />

                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>

                {/*
                <input
                  type="submit"
                  className="btn btn-background btn-block mt-4"
                  value="Guardar comprobante"
                />
                
                */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Comprobante;
